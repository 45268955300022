
import Icon from '@/components/Icon.vue'
import Donut from 'common/loading/Donut.vue'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'Button',
  components: { Donut, Icon },
  props: {
    icon: String,
    disabled: Boolean,
    progress: Boolean,
    sm: Boolean,
    lg: Boolean,
  },
  setup (props) {
    const iconProgress = shallowRef(false)

    const loading = computed(() => props.progress || iconProgress.value)

    watchEffect(() => {
      if (props.progress) iconProgress.value = true
    })

    return {
      loading,
      done () {
        if (!props.progress) {
          iconProgress.value = false
        }
      },
    }
  },
})
