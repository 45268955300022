<template>
<DialogTemplate>
  <template #title>
    <t path="notification_71">Top Traders</t>
  </template>
  <p style="text-align: center;width: 100%;padding: 0 32px;">
    <t as="p" path="notification_72" :args="{ trader:nikeName }"></t>
    <t as="p" v-if="type===2" path="notification_73" :args="{ symbol:code, price: price }"></t>
    <t as="p" v-else path="notification_74" :args="{ symbol:code, price: price }"></t>
  </p>
  <template #cancel>
    <Button class="flex-1 btn block" @click="cancel">
      <t path="cup_32" #="{td}" custom>{{td || 'NO'}}</t>
    </Button>
  </template>
  <template #confirm>
    <Button class="flex-1 btn block primary" @click="check(code)">
      <t path="cup_22" #="{td}" custom>{{td || 'Copy'}}</t>
    </Button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import router from '@/router'

export default defineComponent({
  name: 'CupTradeDialog',
  components: { DialogTemplate },
  props: {
    nikeName: String,
    code: String,
    price: Number,
    type: Number,
  },
  emits: ['close', 'cancel'],
  setup (props, ctx) {
    const check = (code: string | undefined) => {
      ctx.emit('close')
      setTimeout(() => {
        router.push(`/symbol/${code}`)
      }, 200)
    }

    return {
      cancel: () => {
        ctx.emit('close')
        ctx.emit('cancel')
      },
      check,
    }
  },
})
</script>

<style scoped>

</style>
