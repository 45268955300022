<template>
<router-link #="{ navigate }" :to="`/symbol/${item && item.code}`" custom>
<div class="recommend-item br-lg mr-12 p-8" @click="navigate">
  <CurrentQuote :code="item.code" #="{symbol}">
      <RealTimeQuote :symbol="symbol" #="{price, buy, spread, margin, mp, isRise}" @turn="riseTurn">
        <div class="title-wrap br-lg df-middle px-8"
          :class="isRise?'bg-s':'bg-d'">
          <div class="t-l">
            <div class="df-middle">
              <Pic :src="item.icon" width="16" height="16" style="border-radius: 50%" />
              <p class="c-black f-md ml-4 f-bold">{{item.code}}</p>
            </div>
            <p class="mt-4 c-black">{{ price }}</p>
          </div>
          <div class="t-m flex-1 df-middle df-center">
            <TheRecommendChart :code="item.code" :isRise="isRise" :key="reLoadDate"/>
          </div>
          <div class="t-r df-middle">
            <Icon class="p-0 ml-4" :class="{'c-success': isRise, 'c-danger': !isRise}"
              sm
              :name=" isRise ? 'up': 'down'" />
            <p class="f-nm ml-4" :class="{'c-success': isRise, 'c-danger': !isRise}">{{mp}}</p>
          </div>
        </div>
      </RealTimeQuote>
  </CurrentQuote>
  <div class="px-8 pt-12 f-md" style="line-height: 20px;">
    <p>
      <span class="c-white b-o px-8"
        :class="item.tag===RecommendTag.BEARISH?'bg-danger':'bg-success'">
        <t :path="item.tag===RecommendTag.BEARISH?'home_56':'home_55'">
          <!-- {{ item.tag===RecommendTag.BEARISH?'BEARISH':'BULLISH' }} -->
        </t>
      </span>
        {{ item.advantage }}
    </p>
    <!-- <p>{{ item.advantage }}</p> -->
  </div>
</div>
</router-link>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import { RecommendTag } from '@/modules/home/home.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Pic from 'common/Pic.vue'
import Icon from '@/components/Icon.vue'
import TheRecommendChart from '@/modules/home/components/recommendChart/TheRecommendChart.vue'

export default defineComponent({
  name: 'TheRecommendItem',
  components: {
    Pic,
    CurrentQuote,
    RealTimeQuote,
    Icon,
    TheRecommendChart,
  },
  props: {
    item: Object,
  },
  setup () {
    const reLoadDate = shallowRef(0)

    const riseTurn = () => {
      reLoadDate.value = new Date().valueOf()
    }
    return {
      RecommendTag,
      reLoadDate,
      riseTurn,
    }
  },
})
</script>

<style scoped>
.recommend-item{
  min-width: 296px;
  max-width: 296px;
  border: 1px solid var(--color-border);
  padding-bottom: 18px;
}
.recommend-item:last-child{
  margin-right: 0;
}
.title-wrap{
  height: 56px;
}
.b-o{
  line-height: 20px;
  border-radius: 12px;
}
.bg-s{
  background: #DAF6E6;
}
.bg-d{
  background: #FDDFDC;
}
</style>
