
// import RedPointAlert from '@/provider/RedPointAlert.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheEntries',
  components: {
    // RedPointAlert,
    Pic,
  },
})
