<template>
<DialogTemplate>
  <template #title>
    <t path="symbol_127">Mark the turning point on the chart</t>
  </template>
  <div class="pt-8 pb-16 flex-1">
    <t path="symbol_128"></t>
    <div class="df-middle mt-16">
      <Pic src="trade/computer" width="156" height="106" class="mb-8" />
      <t as="p" path="symbol_129" class="ml-16 f-md">

      </t>
    </div>
  </div>
  <template #controls>
    <button class="flex-1 btn block" @click="$emit('close', navigate)">
      <t path="symbol_130" custom #="{td}">
        {{td || 'Cancel'}}
      </t>
    </button>
    <Button class="flex-1 btn block primary" @click="confirm" :progress="loading">
      <t path="symbol_131" custom #="{td}">
        {{td || 'Yes'}}
      </t>
    </Button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'DrawLineDialog',
  components: { Pic, DialogTemplate, Button },
  emits: ['close', 'confirm'],
  setup (props, ctx) {
    const loading = shallowRef(false)
    console.log(loading.value)

    onBeforeUnmount(() => {
      loading.value = false
    })

    return {
      confirm () {
        loading.value = true
        ctx.emit('confirm')
      },
      loading,
    }
  },
})
</script>

<style scoped lang="scss">
</style>
