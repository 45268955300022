/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/25
* @description
*   PositionBarWithBadge.vue of WeTrade
*/
<template>
  <!--<div><t path="symbol_5">Positions</t> <span class="badge">{{count}}</span></div>-->
  <div><t path="symbol_5">Positions</t> ({{count}})</div>
</template>

<script lang="ts">
import { usePositionCount } from '@/modules/user/user'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PositionBarWithBadge',
  props: {
    code: String,
  },
  setup (props) {
    return {
      count: usePositionCount(props.code),
    }
  },
})
</script>
