
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import state from '@/state'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTermTrending',
  components: { Icon, Pic },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
    type: String,
  },
  setup (props) {
    const isRise = computed(() => props.marketItem.type === TradeDirection.BUY)
    const isLongTerm = computed(() => props.type === 'Long')

    return {
      isRise,
      isLongTerm,
      level: computed(() => state.account.levelModel.level),
    }
  },
})
