<template>
<div class="df-col df-middle df-center" style="height: 100vh; width: 100vw;padding: 0 84px;">
  <Pic src="home/sorry" width="48" height="48" />
  <p class="c-title mt-16"
    style="line-height: 24px;text-align: center;">
    WeTrade is not available in your country
  </p>
</div>
</template>

<script lang="ts">
import {
  ready as appReady,
} from '@/common/jsBridge.api'
import { onMounted } from 'vue'
import Pic from '@/components/Pic.vue'

export default {
  name: 'NoService',
  components: {
    Pic,
  },
  setup () {
    onMounted(() => {
      appReady()
    })

    return {}
  },
}
</script>
