
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import TermsAndPolicy from '@/components/TermsAndPolicy.vue'
import { keymap } from '@/config'
import TheContactButton from '@/modules/user/components/TheContactButton.vue'
import Page from '@/provider/Page.vue'
import { localGet } from 'essential/store/localStore'
import { defineComponent } from 'vue'
import { isLogged } from '@/state'
import TheActivity from '@/modules/user/components/TheActivity.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'

export default defineComponent({
  name: 'Me',
  components: { TheActivity, TheContactButton, Icon, Pic, Page, RedPointAlert, TermsAndPolicy },
  setup () {
    return {
      menus: [
        {
          route: '/me/history',
          icon: 'user/history',
          label: 'me_9',
          labelDefault: 'Trade History',
        },
        {
          route: '/me/io',
          icon: 'user/history_t',
          label: 'me_10',
          labelDefault: 'Transaction History',
        },
        {
          route: '/setting/locale',
          icon: 'user/locale',
          label: 'me_11',
          labelDefault: 'Language',
        },
        {
          route: '/user/aboutpc',
          icon: '',
          label: 'pc_10',
          labelDefault: 'Web platform',
          redPoint: 'm_a_p',
        },
        {
          route: '/user/aboutus',
          icon: '',
          label: 'me_22',
          labelDefault: 'About WeTrade',
          redPoint: 'm_a_u',
        },
      ],
      avatar: localGet(keymap.user.avatar),
      nickName: localGet(keymap.user.nickName),
      isLogin: isLogged(),
    }
  },
})
