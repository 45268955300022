import { googleLogin as $_googleLogin } from '@/common/jsBridge.api'
import { withDeviceIdentities } from '@/modules/app/app'
import { localGet, localSet } from 'essential/store/localStore'
import { keymap, events } from '@/config'
import { googleLogin, bindGoogle, User } from '@/features/googleLogin/googleLogin.api'
import { YesOrNo } from '@/types'
import { shallowRef, computed } from 'vue'
import { useRouter } from 'vue-router'
import { login } from '@/modules/user/user'
import state from '@/state'
import { showAlert } from '@/components/popup/popup'
import { emit } from 'essential/tools/event'
import { translate } from '@/i18n'

type GoogleParams = {
  idToken: string;
}

/**
 * fb登录
 */
export const islamFlag = shallowRef(false)

export const useGoogleLogin = () => {
  const router = useRouter()
  const countryCode = computed(() => state.countryCode)

  return async () => {
    const authResult = await $_googleLogin().catch(res => {
      showAlert(res.errorMsg || 'system error')
    }) as GoogleParams

    return withDeviceIdentities(async (ids) => {
      const resp: User = await googleLogin(
        {
          countryCode: countryCode.value,
          islamFlag: Number(islamFlag.value) || 0,
          ...authResult,
          ...ids,
        },
      )

      // 临时改为未绑定手机号也直接跳转至首页
      router.replace('/')
      // if (resp.mobileBindStatus === YesOrNo.YES) {
      //   router.replace('/')
      // } else {
      //   router.replace('/bindMobile')
      // }
      login(resp)
    })
  }
}

export const useBindGoogle = () => {
  const _hasBind = Number(localGet(keymap.user.googleBindStatus)) === YesOrNo.YES
  const hasBind = shallowRef(_hasBind)
  const googleEmail = shallowRef(localGet(keymap.user.googleEmail))

  const bind = async () => {
    const authResult = await $_googleLogin().catch(res => {
      showAlert(res.errorMsg || 'system error')
    }) as GoogleParams
    if (authResult.idToken) {
      const bindResult = await bindGoogle(authResult as Data)
      localSet(keymap.user.googleBindStatus, String(bindResult.googleBindStatus))
      hasBind.value = bindResult.googleBindStatus === YesOrNo.YES
      localSet(keymap.user.googleEmail, bindResult.googleEmail)
      googleEmail.value = bindResult.googleEmail
      emit(events.googleLogin, 'success')
      showAlert(translate('toast_19', 'bind success'))
    }
  }

  return {
    hasBind,
    bind,
    googleEmail,
  }
}
