/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/15
* @description
*   TheTradeLimitDisplay.vue of WeTrade
*/
<template>
  <span>{{value ? `${value}pips` : 'None'}}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheTradeLimitDisplay',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
})
</script>
