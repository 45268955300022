<template>
  <div v-if="showBonus && isLogin" class="content df-middle pr-8">
    <t as="p" path="market_24" class="mr-24 flex-1 c-white">100% First deposit Bonus</t>
    <router-link to="/in" class="btn primary sm df-middle f-bold deposit-btn">
      <t path="market_25" #="{td}" custom>{{td || 'Deposit'}}</t>
      <Icon name="caret-right" sm class="f-bold deposit-icon" />
    </router-link>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import state, { isLogged } from '@/state'

export default defineComponent({
  name: 'TheDepositTip',
  components: { Icon },
  setup () {
    return {
      levelModel: computed(() => state.account.levelModel),
      isLogin: computed(() => isLogged()),
      showBonus: false,
    }
  },
})
</script>

<style scoped lang="scss">
.content {
  height: 56px;
  background: var(--color-text) url(/img/first_deposit/banner@2x.png) left center no-repeat;
  background-size: auto 100%;
  padding-left: 60px;
}

.deposit-btn {
  border-radius: 16px;
  padding: 0 8px 0 16px;

  .deposit-icon {
    position: relative;
    top: 1px;

    &::before {
      font-weight: bold !important;
    }
  }
}
</style>
