/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/25
* @description
*   DemoAccountTip.vue of WeTrade
*/
<template>
<NothingOnInspectMode>
  <AccountRelated use-in-demo>
    <div class="acc-tip f-sm py-8 px-16 df-middle" v-bind="$attrs">
      <Icon name="demo-account" sm />
      <t path="trade_10" class="flex-1 t-left">You are now under demo account</t>
      <t as="button" path="symbol_81" class="f-xs br-sm" @click="toLive">Switch to Live</t>
    </div>
  </AccountRelated>
</NothingOnInspectMode>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
import { AccountType } from '@/state'
import { changeAccountType } from '@/state/accountType'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DemoAccountTip',
  components: { NothingOnInspectMode, Icon, AccountRelated },
  setup () {
    return {
      toLive () {
        changeAccountType(AccountType.REAL)
      },
    }
  },
})
</script>

<style scoped>
.acc-tip {
  color: var(--color-asst);
  background: #FCE9D6;
}

button {
  height: 22px;
  color: var(--color-white);
  background: var(--color-asst);
  border: none;
}
</style>
