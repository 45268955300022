/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/11
 * @description
 *   app.api.ts of WeTrade
 */
import requestResult, { get, jsonpRequest } from '@/common/request/request'
import { PrimeDescriber } from '@/state'
import { YesOrNo } from '@/types'
import { LocaleMessageDictionary, VueMessageType } from 'vue-i18n'
import { server } from '@/config'

export const checkAndroidUpdate = requestResult<Record<'update', boolean>>(
  '/api/version/check/android')

export const reportOpenTime = () => {
  console.log('report open time')
}

export interface RateSchema {
  code: string;
  name: string;
  rate: string;
}

export const readDepositRateList = requestResult<RateSchema>('/api/recharge/rate')

export const readWithdrawalRateList = requestResult<RateSchema>('/api/cashout/rate')

export type CC = {
  code: string;
  name: string;
  default: YesOrNo;
}

export function getTimeZone () {
  return {
    timeZone: Intl ? (new Intl.DateTimeFormat().resolvedOptions().timeZone) : '',
    timeZoneOffset: new Date().getTimezoneOffset(),
  }
}

export const readCCList = () => requestResult<Array<CC>>('/api/user/country/list')(getTimeZone())

// export const loadLocaleFile = (locale: string) => requestResult<LocaleMessageDictionary<VueMessageType>>(`/locales/${locale}.json`)(
//   null, { silent: true })

export const loadLocaleFile = (locale: string) => get<LocaleMessageDictionary<VueMessageType>>(
  `/locales/${locale}.json`)

export const readPrime = requestResult<PrimeDescriber>('/api/user/prime/info')

export const checkInspectVersion = requestResult<{ reviewVersion: string }>(
  '/api/version/review/version')

export const ifShieldCryptos = requestResult<{ flg: YesOrNo }>('/api/user/check/cryptos')

export const checkIP = jsonpRequest<{flg: YesOrNo}>(server.api + '/api/user/screen/ip/jsonp')

export const readEnv = requestResult<{
  ipFlag: YesOrNo;
  cryptosFlag: YesOrNo;
  abFlag: YesOrNo;
}>('/api/check/all')
