**
* @description TheChannels.vue of tf
* @author 贝才 beica1@outook.com
* @date 2020/4/15
*/
<template>
  <Radio
    v-for="(item, index) in channels" :key="item.id"
    v-model="selectedValue" :value="item[valueField]"
    class="df-middle mt-16" name="channel" @click="select(index)"
  >
    <div :class="['br-lg flex-1 ml-24 df-middle df-between channel', { checked: index === selectedIndex }]">
      <div class="df-middle">
        <Pic v-if="item.icon" height="32" width="32" :src="item.icon" class="mr-8" />
        {{item[labelField]}}
      </div>
<!--      <Pic v-if="item.code == 'vmcard'" src="trade/help" height="24" width="24" @click.stop="visaMaterIntro" />-->
    </div>
  </Radio>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { OutChannel } from '@/modules/out/out.api'
import { defineComponent, watch, ref, computed } from 'vue'
import Radio from '@/components/Radio.vue'
import router from '@/router'

export default defineComponent(
  {
    components: { Pic, Radio },
    emits: ['update:channel', 'change'],
    props: {
      channel: [Number, String],
      channels: {
        type: Array,
        required: true,
      },
      valueField: {
        type: String,
        default: 'type',
      },
      labelField: {
        type: String,
        default: 'showName',
      },
    },
    setup (props, ctx) {
      const valueKey = props.valueField as keyof OutChannel
      const selectedIndex = ref(0)
      const selectedValue = computed(
        () => (props.channels as Array<OutChannel>)[selectedIndex.value][valueKey])

      watch([props.channels, selectedIndex], () => {
        const channel = props.channels[selectedIndex.value] as OutChannel
        ctx.emit('update:channel', channel[valueKey])
      }, {
        immediate: true,
      })

      const visaMaterIntro = () => {
        router.push('/in/visaMasterIntro')
      }

      return {
        selectedValue,
        selectedIndex,
        visaMaterIntro,
        select (index: number) {
          ctx.emit('change', index)
          selectedIndex.value = index
        },
      }
    },
  },
)
</script>

<style scoped>
.channel {
  padding: 0 8px;
  height: 40px;
  border: 1px solid transparent;
}

.checked {
  font-weight: bold;
  border-color: var(--color-primary);
}
</style>
