/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/4
* @description
*   TabPane.vue of WeTrade
*/
<template>
  <div class="tab-pane">
    <slot v-bind="$attrs" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onActivated, watchEffect } from 'vue'

export default defineComponent({
  name: 'TabPane',
  props: {
    tab: {
      type: [String, Object],
    },
    key: {
      type: String,
      required: true,
    },
  },
})
</script>
