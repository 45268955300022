/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/23
 * @description
 *   transform.ts of WeTrade
 */
import * as R from 'ramda'

let passiveSupported = false

try {
  const options = Object.defineProperty({}, 'passive', {
    get: function () {
      passiveSupported = true
    },
  })

  window.addEventListener('test', () => {
  }, options)
} catch (err) {
}

const eventOptions = passiveSupported ? { passive: true } : false

export type Point = [x: number, y: number]

export enum Axis {
  X,
  Y
}

export type Transformer = {
  startPoint: Point;
  currentPoint: Point;
  delta: Point;
}

export interface TransformHooks {
  onWillTransform?: (start: Point) => void;
  onTransform: (transformer: Transformer) => void;
  onDidTransform: (transformer: Transformer, longTransform: boolean) => void;
}

/**
 * 只取第一个触摸点的信息参与逻辑计算
 * @param e
 */
function getFirstTouchPoint (e: TouchEvent) {
  return R.props(['clientX', 'clientY'], e.changedTouches[0]) as Point
}

/**
 * 计算两点之前的坐标差
 * @param to {Point}
 * @param from {Point}
 */
function diffPoint (to: Point, from: Point) {
  return R.zipWith(R.subtract, to, from) as unknown as Point
}

/**
 * 计算格式化的平移信息
 * @param e
 * @param startPoint
 * @return r {Object}
 * @return r.startPoint {Point}
 * @return r.currentPoint {Point}
 * @return r.delta {Point}
 */
function calcTransformer (e: TouchEvent, startPoint: Point) {
  const currentPoint = getFirstTouchPoint(e)
  const delta = diffPoint(currentPoint, startPoint)
  return { startPoint, currentPoint, delta }
}

const transform = (el: HTMLElement, hooks: TransformHooks) => {
  if (!(el instanceof HTMLElement)) throw new TypeError(
    'HTMLElement typed "el" should be supplied for transform')

  let startPoint: Point = [0, 0]
  let longTransform = false

  /**
   * 持续追踪元素的平移信息
   * @param e {TouchEvent}
   */
  const onTransform = (e: TouchEvent) => {
    e.stopPropagation()
    hooks.onTransform(calcTransformer(e, startPoint))
  }

  /**
   * 记录平移结束时时的触摸点信息
   * @param e {TouchEvent}
   */
  const onTransformEnd = (e: Event) => {
    el.removeEventListener('touchend', onTransformEnd)
    el.removeEventListener('touchcancel', onTransformEnd)
    document.removeEventListener('touchmove', onTransform)

    const transformer = calcTransformer(e as TouchEvent, startPoint)
    hooks.onDidTransform(transformer, longTransform)
  }

  /**
   * 记录元素平移动作的起始点信息
   * @param e {TouchEvent}
   */
  const onTransformStart = (e: Event) => {
    el.addEventListener('touchend', onTransformEnd, eventOptions)
    el.addEventListener('touchcancel', onTransformEnd, eventOptions)
    document.addEventListener('touchmove', onTransform, eventOptions)

    longTransform = false
    setTimeout(() => {
      longTransform = true
    }, 250)
    startPoint = getFirstTouchPoint(e as TouchEvent)
    if (typeof hooks.onWillTransform === 'function') {
      hooks.onWillTransform(startPoint)
    }
  }

  el.addEventListener('touchstart', onTransformStart, eventOptions)

  return () => el.removeEventListener('touchstart', onTransformStart)
}

export default transform
