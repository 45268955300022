<template>
  <DialogTemplate>
    <template #title>
      <t path="trader_34">Copy trading</t>
    </template>
    <div class="c-text t-center flex-1" style="line-height: 24px;">
      <Icon name="check" class="bg-success mb-8 mt-24"
        style="font-size: 40px;color: white;border-radius: 20px"
      />
      <p class="mb-24">
        <t :path="trantext" :args="{trader: prop('traderName', order), symbol: code, price: price}">
        </t>
      </p>
    </div>
    <template #cancelText>
      <t path="trader_39" custom #="{td}">
        {{td || 'Later'}}
      </t>
    </template>
    <template #confirm>
      <router-link #="{navigate}" custom to="/trade">
        <button class="flex-1 btn block primary" @click="$emit('close', navigate)">
          <t path="trader_40" custom #="{td}">{{td || 'View position'}}</t>
        </button>
      </router-link>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { computed, defineComponent, shallowRef } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'CopyTradeResult',
  components: { Icon, DialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
    type: String,
  },
  setup (props) {
    const isBuy = computed(() => props.order.type === TradeDirection.BUY)
    const trantext = shallowRef('')
    const price = computed(() => props.type ? props.order.createPrice : props.order.closePrice)
    const code = computed(() => props.order.contactCode || props.order.code)
    if (props.type) {
      trantext.value = isBuy.value ? 'trader_35' : 'trader_36'
    } else {
      trantext.value = 'trader_38'
    }
    return {
      code,
      trantext,
      price,
      isBuy,
    }
  },
})
</script>
