
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'List',
  props: {
    listData: {
      type: Array,
      required: true,
    },
    identify: {
      type: Function,
      default: (item: unknown, index: number) => index,
    },
    as: String,
  },
})
