
import Icon from '@/components/Icon.vue'
import { isInspectMode } from '@/state/inspect'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GuideContent',
  components: { Icon },
  props: {
    abs: Boolean,
    anchorPosition: {
      type: String,
      default: 'top',
    },
    anchorAlign: {
      type: Number,
      default: 10,
    },
  },
  data () {
    return {
      show: !isInspectMode(),
    }
  },
  methods: {
    gotIt () {
      this.$emit('gotIt')
      this.show = false
    },
  },
})
