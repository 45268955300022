/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/6/17
 * @description
 *   useGoogleTranslate.ts of FastTradeV3
 */
import { flag, request as requestConfig, server } from '@/config'
import i18n from '@/i18n'
import { jsonp } from '@/common/request/request'
import { computed, shallowRef } from 'vue'

const useGoogleTranslate = (originContent: string) => {
  const translated = shallowRef('')
  const isOrigin = shallowRef(true)
  const progress = shallowRef(false)

  const content = computed(() => {
    return isOrigin.value ? originContent : translated.value
  })

  const translate = async () => {
    if (isOrigin.value && !translated.value) {
      progress.value = true
      const resp = await jsonp(
        server.translate,
        {
          isTest: Number(flag.isDevMode),
          ...requestConfig.staticRequestData,
          ...requestConfig.dynamicRequestData,
          target: i18n.locale.value?.toLowerCase() ?? 'en',
          text: encodeURIComponent(originContent),
        },
      )
      translated.value = (resp as {
        data: {
          result: string
        }
      }).data?.result ?? originContent
      progress.value = false
    }
    isOrigin.value = !isOrigin.value
  }

  return {
    progress,
    isOrigin,
    translate,
    content,
  }
}

export default useGoogleTranslate
