/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/20
* @description
*   Icon.vue of WeTrade
*/
<template>
  <i class="icon" :class="[`icon${version === 1 ? '' : `v${version}`}-${name_}`, { xs, sm, lg }]" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    version: {
      type: Number,
      default: 1,
    },
    xs: Boolean,
    sm: Boolean,
    lg: Boolean,
  },
  setup (props) {
    return {
      name_: computed(() => props.name.replace(/^(\S+)(.*)/, '$1')),
    }
  },
})
</script>

<style scoped lang="scss">
.icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;

  &::before {
    font-size: inherit;
    float: left;
  }

  &.xs {
    font-size: 8px;
  }

  &.sm {
    font-size: 16px;
  }

  &.lg {
    font-size: 32px;
  }
}
</style>
