
import { defineComponent, shallowRef, onBeforeUnmount, onMounted } from 'vue'
import swipe from 'essential/dom/swipe'

export default defineComponent({
  name: 'Alert',
  emits: ['close'],
  props: {
    autoClose: Boolean,
    zIndex: Number,
  },
  setup (props, ctx) {
    const alertDom = shallowRef()
    let timer = 0 // settimeout
    const upthreshold = 20 // 上拉阈值

    onMounted(() => {
      resetTimer()
      if (alertDom.value) {
        swipe(alertDom.value, {
          direction: 'vertical',
          range: [-200, 20],
          resistanceRatio: 0.85,
          onSwiped ({ delta: [, deltaY] }, long, release) {
            if (deltaY < -upthreshold) {
              ctx.emit('close')
              clearTimeout(timer)
            } else {
              alertDom.value.style.transform = 'translate3d(0px, 0px, 0px)'
              resetTimer()
            }
            release()
          },
          onWillSwipe () {
            clearTimeout(timer)
          },
        })
      }
    })

    onBeforeUnmount(() => {
      clearTimeout(timer)
    })

    const resetTimer = () => {
      if (props.autoClose || props.autoClose === undefined) {
        timer = window.setTimeout(() => ctx.emit('close'), 1000 * 3)
      }
    }

    return {
      alertDom,
    }
  },
})
