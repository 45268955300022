/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 15:14
* @description
*   ForStock.vue of WeTrade
*   stock推荐任务卡片
*/
<template>
<t as="p" path="home_14" class="t-center f-xxl f-bold">Try out US stocks</t>
<div class="d-f mt-16 px-20">
  <t as="p" path="home_15" class="mr-16">Make trade of US stocks to get a $1 coupon</t>
  <Pic src="home/coupon$1" width="80" height="56" />
</div>
<div style="overflow:hidden;">
  <router-link :to="`/symbol/${item.code}`" v-for="item in stocks" :key="item.code"
    class="stock df-middle br-lg">
    <Pic :src="item.icon" width="48" height="48" />
    <p class="flex-1 mx-16 desc">{{item.description}}</p>
    <Icon name="caret-right" sm />
  </router-link>
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForStock',
  components: { Icon, Pic },
  props: {
    stocks: {
      type: Array,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.stock {
  padding: 8px 8px 8px 12px;
  margin-bottom: 8px;

  &:first-child {
    margin-top: 24px;
  }

  &:last-child {
    margin-bottom: 16px;
  }
}

.desc {
  overflow: hidden;
  text-overflow: ellipsis;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

</style>
