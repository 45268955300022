/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/5
 * @description
 *   trade.ts of WeTrade
 */
import { events, format } from '@/config'
import { translate } from '@/i18n'
import { readLatestQuote, useQuote } from '@/modules/market/market'
import { SymbolSnapshot } from '@/modules/market/market.types'
import TheCommissionCreatedDialog from '@/modules/trade/components/TheCommissionCreatedDialog.vue'
import ThePositionCreateResultDialog
  from '@/modules/trade/components/ThePositionCreateResultDialog.vue'
import { openDialog, openFullscreenList, showAlert } from '@/components/popup/popup'
import useRequest from '@/hooks/useRequest'
import TheTradeLimitList from '@/modules/trade/components/TheTradeLimitList.vue'
import {
  createCommission,
  createPosition,
  edit,
  editCommission as requestEditCommission,
  Product,
  readProducts,
  addDemoBalance,
} from '@/modules/trade/trade.api'
import TradeModel, { LimitType } from '@/modules/trade/TradeModel'
import { readVoucherList, VoucherStatus } from '@/pages/coupons/coupons.api'
import { YesOrNo } from '@/types'
import { emit } from 'essential/tools/event'
import { add, div, mul, sub } from 'essential/tools/math'
import { computed, inject, shallowRef, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

export const createPositionOrder = (model?: TradeModel) => {
  const router = useRouter()
  const [commit, progress] = useRequest(createPosition)

  const order = model ?? inject('model') as TradeModel
  const post = () => {
    commit(order.getValue()).then(resp => {
      resp.weight = String(Number(resp.weight))
      emit(events.transactionUpdate)
      openDialog(ThePositionCreateResultDialog, {
        order: resp,
        onClose: router.back,
      })
    })
  }

  return [post, progress]
}

export const demoBalanceRecharge = (cb: Fn) => {
  const [commit, progress] = useRequest(addDemoBalance)
  const post = () => {
    commit().then(() => {
      cb()
      showAlert(translate('toast_12', '$10000 has been topped up to your demo account!'))
    })
  }

  return [post, progress]
}

export const createCommissionOrder = () => {
  const router = useRouter()
  const [commit, progress] = useRequest(createCommission)

  const order = inject('model') as TradeModel

  const post = () => {
    commit(order.getValue()).then(resp => {
      resp.weight = String(Number(resp.weight))
      emit(events.transactionUpdate)
      openDialog(TheCommissionCreatedDialog, {
        order: resp,
        onClose: router.back,
      })
    })
  }

  return [post, progress]
}

export const editCommission = () => {
  const router = useRouter()
  const [commit, progress] = useRequest(requestEditCommission)

  const order = inject('model') as TradeModel

  const post = () => {
    commit(order.getValue()).then(() => {
      showAlert(translate('toast_5', 'Limit Order has been updated!'))
      router.back()
    })
  }

  return [post, progress]
}

export const useEdit = () => useRequest(edit)

export const useSetTradeLimit = (type: LimitType, value = 0, onChange?: Fn) => {
  const choice = shallowRef(value)
  const makeChoice = (product: Product) => {
    if (product?.productId) {
      openFullscreenList(TheTradeLimitList, {
        value: choice.value,
        type,
        product,
        onChange (value: number) {
          choice.value = value
          if (typeof onChange === 'function') onChange(value)
        },
      })
    } else {
      showAlert(translate('toast_8', 'No Product provide'))
    }
  }
  return [choice, makeChoice]
}

/**
 * 维护止盈止损选项列表
 */
export const generateLimitList = (limitType: LimitType, product: Product) => {
  const min = Number(limitType === LimitType.LOSS ? product.minStopLoss : product.minStopProfit)
  const max = Number(limitType === LimitType.PROFIT ? product.maxStopProfit : product.maxStopLoss)

  const limitFactor = limitType === LimitType.PROFIT ? 1 : -1

  const chgOfPerPip = mul(product.yk, limitFactor)

  let step = 1

  if (max - min > 100) {
    step = Math.ceil((max - min) / 100)
  }

  const a = []
  for (let i = min; i <= max; i += step) {
    a.push({
      pip: i,
      value: mul(i, chgOfPerPip),
    })
  }

  return a
}

export const checkUsableCoupon = () => {
  return readVoucherList({
    type: 1,
    status: VoucherStatus.USABLE,
    page: 1,
    pageCount: 1000,
  })
}

export const calcProduct = (mulNum: number, dot: number, baseProduct: Product) => {
  baseProduct.choiceId = baseProduct.contract + mul(baseProduct.weight, mulNum) + '-' + dot
  const times = mul(mulNum, dot)
  baseProduct.orderNumber = times
  baseProduct.weight = String(mul(baseProduct.weight, times))
  baseProduct.cost = String(mul(baseProduct.price, times))
  baseProduct.yk = String(mul(baseProduct.yk, 1))
  baseProduct.sxf = String(mul(baseProduct.sxf, times))
  baseProduct.deferred = String(mul(baseProduct.deferred, times))
  return baseProduct
}

export const getProductsByCode = async (code: string, isOften: YesOrNo) => {
  const baseProduct = await readProducts({ code })
  const products = []
  const often = [1, 10, 20, 50, 100, 200, 500, 1000]

  if (!isOften) {
    for (let i = 0; i < 4; i++) {
      for (let j = 1; j < (i < 3 ? 10 : 11); j++) {
        const tempBase = JSON.parse(JSON.stringify(baseProduct))
        const mulNum = Math.pow(10, i)
        tempBase.isOften = often.indexOf(mul(mulNum, j)) > -1 ? YesOrNo.YES : YesOrNo.NO
        products.push(calcProduct(mulNum, j, tempBase))
      }
    }
  } else {
    often.map(item => {
      const tempBase = JSON.parse(JSON.stringify(baseProduct))
      tempBase.isOften = YesOrNo.YES
      const dot = Number(String(item).substring(0, 1))
      const mulNum = Math.pow(10, item.toString().length - 1)
      products.push(calcProduct(mulNum, dot, tempBase))
    })
  }
  return products
}

export const useSelectProduct = (code: string, model?: TradeModel) => {
  const order = model ?? inject('model') as TradeModel
  const products = shallowRef<Product[]>([])

  getProductsByCode(code, YesOrNo.YES).then((res) => {
    products.value = res
  })

  watch(() => products.value, () => {
    order?.setProduct(products.value[0])
    order?.setOffset()
  })

  return {
    products,
    choice: order?.getProduct(),
    update (product: Product) {
      order?.updateProduct(product)
    },
  }
}

export const useProductLimit = (onPipChange?: (pips: number) => void, isReduce = false) => {
  const order = inject('model') as TradeModel | undefined
  const percent = shallowRef(0)

  const profit = computed(() => {
    const product = order?.getProduct().value

    if (!product) return 0

    return mul(product.price, 0.01, percent.value, order?.getValue().orderNumber ?? 1)
  })

  const pips = computed(() => {
    const product = order?.getProduct().value

    if (!product) return 0

    return Math.ceil(div(profit.value, order?.pipProfit.value ?? 1))
  })

  const estPrice = computed(() => {
    if (order) {
      return add(
        order.staticPrice || (order.symbol.value?.sell ?? 0),
        mul(pips.value, order.getProduct().value?.pointStep ?? 0, isReduce ? -1 : 1),
      )
    }

    return ''
  })

  const distance = computed(() => {
    const product = order?.getProduct()

    if (!product) return 0

    return div(
      sub(estPrice.value, order?.symbol.value?.sell ?? 0),
      product.value?.pointStep ?? 1,
    )
  })

  const limit = (p: number) => {
    percent.value = p
    onPipChange?.(pips.value)
  }

  const limitByPoint = (point: number, cb?: (v: number) => void) => {
    const product = order?.getProduct().value

    if (!product) return

    if (/^USD/.test(product.contract)) {
      const orderCount = order?.getValue().orderNumber ?? 1
      const profit = div(mul(point, product.yk, orderCount), order?.getValue().price ?? 1)
      percent.value = Math.floor((div(profit, mul(product.price, orderCount)) * 100))
    } else {
      percent.value = div(mul(point, product.yk), product.price) * 100
    }

    cb?.(percent.value)
  }

  return {
    estPrice,
    pips,
    profit,
    distance,
    limit,
    limitByPoint,
  }
}
