/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/16 16:49
 * @description
 *   datafeed.ts of WeTrade
 */
import { marketFeed, QuoteMessage } from '@/common/datafeed'
import { resolutions } from '@/modules/market/components/chart/chartConfig'
import { readBars } from '@/modules/market/market.api'
import { sub } from 'essential/tools/math'
import { Bar, IDatafeed, Periodicity, Quote, SymbolDescriber } from 'happychart'
import * as R from 'ramda'

const datafeed: IDatafeed = {
  resolveSymbol (symbol: string): Promise<SymbolDescriber> {
    return Promise.resolve({
      code: symbol,
      exchange: 'EX',
      priceScale: 0.01,
      minMove: 0.1,
      type: 'chart',
      /**
       * An array of resolutions which should be enabled in resolutions picker for this symbol.
       */
      resolutions: ['1S'],
    })
  },
  async read (symbol: SymbolDescriber, periodicity: Required<Periodicity>): Promise<Bar[]> {
    const resolution = R.find(resolution => {
      return resolution.period.timeUnit === periodicity.timeUnit && resolution.period.interval === periodicity.interval
    }, resolutions)

    if (!resolution) return []

    const bars = await readBars({
      excode: 'EX',
      code: symbol.code,
      type: resolution.value,
    })

    const d: Bar[] = []
    const count = bars.length

    for (let i = 0; i < count; i++) {
      const bar = bars[i]
      const c = Number(bar.c)
      const pc = Number(bars[i - 1]?.c)
      const chg = sub(c, pc)

      d.push({
        o: Number(bar.o ?? bar.c),
        h: Number(bar.h ?? bar.c),
        l: Number(bar.l ?? bar.c),
        c,
        pc,
        chg,
        'chg%': +(chg / (pc || 1) * 100).toFixed(2),
        // 服务端时间向上取整
        t: bar.u * 1000,
      })
    }

    return d
  },
  watch (symbol, stream): () => void {
    return marketFeed.watch(symbol.code, (quote: QuoteMessage) => {
      if (quote) {
        stream({
          last: Number(quote.last_close),
        }, Number(quote.updatetime))
      }
    })
  },
  unwatch () {
    return true
  },
}

export default datafeed
