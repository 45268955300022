<template>
  <div class="connect df-middle df-center" :class="['', 'success', 'error'][+state.value]">
    <div class="df-middle">
      <Pic class="mr-8" :src="stateList[+state.value].icon" width="16" height="16" />
      <t as="p" :path="stateList[+state.value].path">{{stateList[+state.value].text}}</t>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onUnmounted, watch } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'ReConnect',
  emits: ['close'],
  components: { Pic },
  props: {
    state: Object,
  },
  setup (props, ctx) {
    const stateList = [{
      icon: 'connect/i_n',
      text: 'Connecting to quote server',
      path: 'home_18',
    }, {
      icon: 'connect/i_s',
      text: 'Connected',
      path: 'home_19',
    }, {
      icon: 'connect/i_e',
      text: 'Connection failed',
      path: 'home_20',
    }]
    let time = 0

    watch(() => props.state?.value, () => {
      if (props.state?.value > 0) {
        time = window.setTimeout(() => {
          ctx.emit('close')
        }, 800)
      }
    })

    onUnmounted(() => {
      clearTimeout(time)
    })

    return {
      stateList,
    }
  },
})
</script>
<style scoped lang="scss">
.connect{
  z-index: 3;
  width: 100%;
  min-height: 40px;
  max-height: 40px;
  background: #FCE9D6;
  color: #F19231;
  &.success{
    background: #DAF6E6;
    color: #00B167;
  }
  &.error{
    background: #FDDFDC;
    color: #F24A3A;
  }
}
</style>
