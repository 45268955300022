<template>
<div>
  <t as="p" path="home_54" class="f-bold mt-24 mb-8">We Recommend</t>
  <HoldOn :content="list" :height="180">
    <AsyncNullableList :list-data="list" #="{item}" class="item-wrap df-middle" style="align-items:stretch;">
      <TheRecommendItem :item="item" />
    </AsyncNullableList>
  </HoldOn>
</div>
</template>

<script lang="ts">
import HoldOn from '@/provider/HoldOn.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import TheRecommendItem from '@/modules/home/components/items/TheRecommendItem.vue'
import { onActivated, onBeforeUnmount, onMounted, shallowRef } from 'vue'
import { readRecommend, Recommend } from '@/modules/home/home.api'
import datafeed from '@/common/datafeed'
import * as R from 'ramda'
import useRequest from '@/hooks/useRequest'

export default {
  name: 'TheHomeRecommend',
  components: {
    HoldOn,
    AsyncNullableList,
    TheRecommendItem,
  },
  setup () {
    const list = shallowRef<Recommend[]>([])

    const [request] = useRequest(readRecommend)

    const updateRecomend = () => {
      request().then(res => {
        list.value = res || []
        datafeed.subscribe(R.pluck('code', res), true)
      })
    }

    // updateRecomend()

    onMounted(updateRecomend)

    onActivated(updateRecomend)

    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    return {
      list,
    }
  },
}
</script>

<style scoped>
.item-wrap{
  overflow-x: auto;
  scroll-behavior: smooth;
}
</style>
