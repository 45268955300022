/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/22
 * @description
 *   reducer.ts of FastTradeV3
 */
import { parseToJson } from '@/common/format'
import { openDialog, openDialog as showDialog } from '@/components/popup/popup'
import MarginCall from '@/modules/trade/notification/MarginCall.vue'
import MissionResult from '@/modules/trade/notification/MissionResult.vue'
import RechargeResult from '@/modules/trade/notification/RechargeResult.vue'
import PostNotice from '@/modules/trade/notification/PostNotice.vue'
import ServiceNotice from '@/modules/trade/notification/ServiceNotice.vue'
import TradeResult from '@/modules/trade/notification/TradeResult.vue'
import { Position } from '@/modules/trade/trade.api'
import { isDemoAccount } from '@/state/accountType'
import SignalTrend from '@/modules/trade/notification/SignalTrend.vue'
import LimitOrderResult from '@/modules/trade/notification/LimitOrderResult.vue'
import BigNewsDialog from '@/modules/trade/notification/BigNewsDialog.vue'
import CupTradeDialog from '@/modules/trade/notification/CupTradeDialog.vue'
import TouchLineDialog from '@/modules/trade/notification/TouchLineDialog.vue'
import FirstDepositDialog from '@/modules/trade/notification/FirstDepositDialog.vue'
import { Broadcast } from '@/types'

import { getPointAalert, GetPointAlertEnum } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'
import { translate } from '@/i18n'

enum NotifyType {
  ENTRUST_NOTIFY = 3,
  DEMO_ENTRUST_NOTIFY = 4,
  CLOSE = 5,
  MARGIN_CALL,
  DEMO_CLOSE,
  DEMO_MARGIN_CALL,
  SERVICE_CALLBACK,
  POST,
  UPGREADE,
  SIGNALTREND,
  MISSION_COMPLETE = 18,
  RECHARGE,
  BIGNEWS,
  CUP_TRADE,
  CHART_LINE,
  FIRST_DEPOSIT,
  LIVE_TRADE_TIMES,
  LIVE_WATCH_TIME = 25,
  LIVE_WATCH_FIRST,
  LIVE_COMMENT_FIRST,
  LIVE_LOSS, // 直播亏损送积分
  LIVE_DEPOSIT,
}

function stockCloseNotify (message: string, demoOnly = false) {
  if ((demoOnly && isDemoAccount.value) || !demoOnly) {
    const order = parseToJson(message).json as Position
    if (order) {
      showDialog(TradeResult, { order })
    }
  }
}

function marginCall (message: string, demoOnly = false) {
  if ((demoOnly && isDemoAccount.value) || !demoOnly) {
    const body = parseToJson(message).json as { marginLevel: number }
    if (body) {
      showDialog(
        MarginCall,
        {
          margin: body.marginLevel,
        },
      )
    }
  }
}

const serviceCallback = (message: string) => {
  if (message) {
    showDialog(ServiceNotice, { message })
  }
}

const viewNewPost = (message: string) => {
  const post = parseToJson(message).json as { postId: number; content: string; }
  if (post) {
    showDialog(PostNotice, { post })
  }
}

const upgradeLevel = (message: string) => {
  console.log(message)
  // const data = parseToJson(message).json as { amount: number; level: number; }
  // if (data) {
  //   showDialog(LevelUpDialog, { data, wrapperClass: 'center' })
  // }
}

const signalTrend = (message: string) => {
  const data = parseToJson(message).json as { code: string; position: number; type: number }
  if (data) {
    showDialog(SignalTrend, { data })
  }
}

const limitOrder = (message: string, demoOnly = false) => {
  if ((demoOnly && isDemoAccount.value) || !demoOnly) {
    const order = parseToJson(message).json as Position
    if (order) {
      showDialog(LimitOrderResult, { order })
    }
  }
}

const missionComplete = (message: string) => {
  const messageObj = parseToJson(
    message).json as { award: number; awardType: number; message: string; }

  if (messageObj) {
    openDialog(MissionResult, {
      ...messageObj,
      isCoupon: messageObj.awardType === 2, // 1 points, 2 coupon
    }, {
      backToClose: false,
    })
  }
}

// 充值到账通知
const rechargeComplete = (message: string) => {
  const res = parseToJson(message).json
  if (res) {
    openDialog(RechargeResult, {
      val: res.rechargeAmount,
    })
  }
}

const bigNewsNotify = (message: string) => {
  const res = parseToJson(message).json
  if (res) {
    openDialog(BigNewsDialog, {
      ...res,
    })
  }
}

const cupTradeNotify = (message: string) => {
  const res = parseToJson(message).json
  if (res) {
    openDialog(CupTradeDialog, {
      ...res,
    })
  }
}

const touchLineNotify = (message: string) => {
  const res = parseToJson(message).json
  if (res) {
    openDialog(TouchLineDialog, {
      ...res,
    })
  }
}

const handleLiveAlert = (message: string, cmd: number) => {
  const res = parseToJson(message).json
  switch (cmd) {
    case NotifyType.LIVE_WATCH_TIME:
      return getPointAalert(GetPointAlertEnum.WATCH_TIME, {
        title: translate('', 'watch time'),
        point: res.point,
        time: res.time,
      })
    case NotifyType.LIVE_WATCH_FIRST:
      return getPointAalert(GetPointAlertEnum.DEFAULT, {
        title: translate('quickplaylive_14', 'watch livestream daily'),
        point: res.point,
      })
    case NotifyType.LIVE_COMMENT_FIRST:
      return getPointAalert(GetPointAlertEnum.DEFAULT, {
        title: translate('quickplaylive_16', 'first comment daily'),
        point: res.point,
      })
    case NotifyType.LIVE_TRADE_TIMES:
      return getPointAalert(GetPointAlertEnum.TRADE_TIMES, {
        title: translate('', 'trade times'),
        point: res.point,
        time: res.time,
      })
    case NotifyType.LIVE_LOSS:
      return getPointAalert(GetPointAlertEnum.DEFAULT, {
        title: translate('quickplaylive_75', 'loss'),
        point: res.point,
      })
    case NotifyType.LIVE_DEPOSIT:
      return getPointAalert(GetPointAlertEnum.DEFAULT, {
        title: translate('quickplaylive_74', 'deposit'),
        point: res.point,
      })
  }
}

const tradeNotificationReducer = (broadcast: Broadcast<NotifyType>) => {
  switch (broadcast.cmd) {
    case NotifyType.CLOSE:
      return stockCloseNotify(broadcast.message)
    case NotifyType.DEMO_CLOSE:
      return stockCloseNotify(broadcast.message, true)
    case NotifyType.MARGIN_CALL:
      return marginCall(broadcast.message)
    case NotifyType.DEMO_MARGIN_CALL:
      return marginCall(broadcast.message, true)
    case NotifyType.SERVICE_CALLBACK:
      return serviceCallback(broadcast.message)
    case NotifyType.POST:
      return viewNewPost(broadcast.message)
    case NotifyType.UPGREADE:
      return upgradeLevel(broadcast.message)
    case NotifyType.SIGNALTREND:
      return signalTrend(broadcast.message)
    case NotifyType.ENTRUST_NOTIFY:
      return limitOrder(broadcast.message)
    case NotifyType.DEMO_ENTRUST_NOTIFY:
      return limitOrder(broadcast.message, true)
    case NotifyType.MISSION_COMPLETE:
      return missionComplete(broadcast.message)
    case NotifyType.RECHARGE:
      return rechargeComplete(broadcast.message)
    case NotifyType.BIGNEWS:
      return bigNewsNotify(broadcast.message)
    case NotifyType.CUP_TRADE:
      return cupTradeNotify(broadcast.message)
    case NotifyType.CHART_LINE:
      return touchLineNotify(broadcast.message)
    case NotifyType.FIRST_DEPOSIT:
      return openDialog(FirstDepositDialog)
    case NotifyType.LIVE_TRADE_TIMES:
    case NotifyType.LIVE_WATCH_TIME:
    case NotifyType.LIVE_WATCH_FIRST:
    case NotifyType.LIVE_COMMENT_FIRST:
    case NotifyType.LIVE_LOSS:
    case NotifyType.LIVE_DEPOSIT:
      return handleLiveAlert(broadcast.message, broadcast.cmd)
  }
}

export default tradeNotificationReducer
