/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/11
* @description
*   UpdateDialog.vue of WeTrade
*/
<template>
  <div class="update bg-primary t-center">
    <p class="t-right p-8">
      <Icon name="close" class="c-primary" style="background: white;border-radius: 12px" @click="$emit('close')" />
    </p>
    <Pic src="rocket" width="40" height="40" />
    <t as="p" path="newversion_2" class="f-lg mt-24">New Version detected</t>
    <p class="mt-16">{{config.content || 'Experience improve'}}</p>
    <div class="wedge" style="height: 46px;"></div>
    <t as="div" path="newversion_3" class="button f-lg f-bold" @click="update">Update Now</t>
  </div>
</template>

<script lang="ts">
import { update } from '@/common/jsBridge.api'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'UpdateDialog',
  components: { Icon, Pic },
  props: {
    config: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    return {
      update () {
        update(props.config)
        ctx.emit('close')
      },
    }
  },
})
</script>

<style scoped>
.update {
  width: 73%;
  border-radius: 4px;
  overflow: hidden;
  color: var(--color-white);
}

.button {
  line-height: 48px;
  color: var(--color-primary);
  background: var(--color-white);
}
</style>
