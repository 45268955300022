/**
* @author 贝才[beica1@outook.com]
* @date 2021/7/7 15:25
* @description
*   TheStockCategory.vue of wetrade
*/
<template>
<TheMarketList :read="read" class="stocks-pane" #="{item}" type="stocks" holder-class="px-16">
  <TheStockList :stocks="item" />
</TheMarketList>
</template>

<script lang="ts">
import TheMarketList from '@/modules/market/components/TheMarketList.vue'
import TheStockList from '@/modules/market/components/TheStockList.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheStockCategory',
  components: { TheStockList, TheMarketList },
  props: {
    read: {
      type: Function,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.stocks-pane {
  ::v-deep(> .px-16) {
    padding: 0;
  }

  ::v-deep(td:first-child) {
    padding-left: 16px;
  }

  ::v-deep(td:last-child) {
    padding-right: 16px;
  }

  ::v-deep(.row td::after) {
    display: none;
  }
}
</style>
