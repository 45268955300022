
import { keymap } from '@/config'
import { ResolutionConfig, readResolutions } from '@/modules/market/components/chart/chartConfig'
import TheSelectBar from '@/modules/market/components/chart/TheSelectBar.vue'
import { localSet } from 'essential/store/localStore'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheResolutionBar',
  components: { TheSelectBar },
  props: {
    showRotate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup (props, ctx) {
    return {
      resolutions: readResolutions(),
      change (item: ResolutionConfig, apply: (item: ResolutionConfig) => void) {
        ctx.emit('change', item)
        localSet(keymap.label.resolution, item.value.toString())
        apply(item)
      },
    }
  },
})
