
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'noPointAlert',
  components: {

  },
  setup () {
    return {
    }
  },
})
