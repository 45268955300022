/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/12
* @description
*   UserGuide.vue of WeTrade
*/
<template>
<div v-if="show" class="user-guide px-16 py-8 br-lg f-md d-f" :class="{'p-r': !abs, 'p-a': abs}">
  <p class="flex-1">
    <slot />
  </p>
  <div class="ml-16">
    <Icon name="close" class="close" @click="gotIt" />
  </div>
  <span :class="['anchor', anchorPosition]" :style="{left: `${anchorAlign}%`}" />
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { isInspectMode } from '@/state/inspect'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GuideContent',
  components: { Icon },
  props: {
    abs: Boolean,
    anchorPosition: {
      type: String,
      default: 'top',
    },
    anchorAlign: {
      type: Number,
      default: 10,
    },
  },
  data () {
    return {
      show: !isInspectMode(),
    }
  },
  methods: {
    gotIt () {
      this.$emit('gotIt')
      this.show = false
    },
  },
})
</script>

<style scoped lang="scss">
.user-guide {
  color: var(--color-white);
  background: rgba(#181D24, 0.9);
}

.anchor {
  display: inline-block;
  position: absolute;
  border: 4px solid rgba(#181D24, 0.9);
  border-radius: 1px;
  transform: rotate(45deg);
  content: '';

  &.top {
    top: -3px;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }

  &.bottom {
    bottom: -3px;
    border-top-color: transparent;
    border-left-color: transparent;
  }
}

.close {
  color: rgba(#181D24, 0.9);
  background: var(--color-white);
  border-radius: 50%;
}
</style>
