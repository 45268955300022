/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/20
* @description
*   Button.vue of WeTrade
*/
<template>
  <button :class="['btn', { progress: loading, sm, lg, disabled }]">
    <Icon v-if="icon" :name="icon" :class="{ sm, lg }" />
    <span class="deck d-ib v-middle mx-6">
      <slot />
    </span>
    <Donut v-if="loading" @tick="done" sm />
    <slot v-else name="noLoading"></slot>
  </button>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Donut from 'common/loading/Donut.vue'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'Button',
  components: { Donut, Icon },
  props: {
    icon: String,
    disabled: Boolean,
    progress: Boolean,
    sm: Boolean,
    lg: Boolean,
  },
  setup (props) {
    const iconProgress = shallowRef(false)

    const loading = computed(() => props.progress || iconProgress.value)

    watchEffect(() => {
      if (props.progress) iconProgress.value = true
    })

    return {
      loading,
      done () {
        if (!props.progress) {
          iconProgress.value = false
        }
      },
    }
  },
})
</script>
