<template>
  <DialogTemplate :no-title="true">
    <div class="flex-1">
      <p class="t-center mt-32">
        <Pic src="/img/community/release@2x.png" width="56" height="56" />
      </p>
      <div class="f-bold f-lg t-center mt-24" style="margin-bottom: 40px;">
        <t path="postpublish_6" as="p">
          You are going to publish the post.<br />
          Are you sure about this?
        </t>
        <t path="postpublish_7" as="p" class="mt-16 f-md" style="font-weight: normal">
          The post will be made public in 5 minutes
        </t>
      </div>
    </div>
    <template #confirmText>
      <t path="postpublish_9" #="{td}" custom>{{ td || 'Publish' }}</t>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import Pic from 'common/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePostConfirmDialog',
  components: { Pic, DialogTemplate },
})
</script>
