
import { defineComponent, onMounted, shallowRef } from 'vue'
// import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import Donut from 'common/loading/Donut.vue'
import { useDailyBonus, loading } from '@/modules/home/dailyBonus'
import { readDailyBonus } from '@/modules/home/home.api'

export default defineComponent({
  name: 'TheDailyBonusEnter',
  components: {
    // Pic,
    Icon,
    Donut,
  },
  setup () {
    const total = shallowRef(0)
    onMounted(() => {
      readDailyBonus().then(res => {
        if (!res || res.list.length === 0) {
          return
        }
        res.list.map(item => {
          total.value = total.value + item.amount
        })
      })
    })
    return {
      total,
      loading,
      useDailyBonus,
    }
  },
})
