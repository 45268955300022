
import { translate } from '@/i18n'
import {
  readTechnicals,
  drawLine,
  LineType,
  BarTypes,
  SinalItem,
  Direction,
} from '@/modules/market/market.api'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import Line from '@/components/Line.vue'
import { useRoute } from 'vue-router'
import { emit, off, on } from 'essential/tools/event'
import { localGet } from 'essential/store/localStore'
import { events, keymap } from '@/config'
import * as R from 'ramda'
import Loading from 'common/loading/Donut.vue'
import { formatDateTime } from '@/common/format'
import { openDialog, showAlert } from '@/components/popup/popup'
import DrawLineDialog from './DrawLineDialog.vue'
import router from '@/router'

export default defineComponent({
  name: 'TheTechnicals',
  components: {
    Pic,
    Icon,
    Line,
    Loading,
  },
  setup () {
    const route = useRoute()
    const showIntraday = shallowRef(true)
    const showShort = shallowRef(false)
    const showLong = shallowRef(false)
    const resolutions = shallowRef(Number(localGet(keymap.label.resolution)) || BarTypes.M30)

    const intradayData = shallowRef<SinalItem | undefined>(undefined)
    const shortTermData = shallowRef<SinalItem | undefined>(undefined)
    const midTermData = shallowRef<SinalItem | undefined>(undefined)

    const showLoading = shallowRef(true)
    let timer = 0

    let pageNum = 0
    const pageSize = 20

    const isContinue = () => {
      return R.isNil(intradayData.value) || R.isNil(shortTermData.value) || R.isNil(midTermData.value)
    }

    const loopData = () => {
      pageNum = pageNum + 1
      readTechnicals({
        code: route.params.code,
        page: pageNum,
        pageSize: pageSize,
      }).then(res => {
        if (R.isNil(intradayData.value)) {
          intradayData.value = R.find(R.propEq('term', 'INTRADAY'))(res || []) as SinalItem | undefined
        }
        if (R.isNil(shortTermData.value)) {
          shortTermData.value = R.find(R.propEq('term', 'ST'))(res || []) as SinalItem | undefined
        }
        if (R.isNil(midTermData.value)) {
          midTermData.value = R.find(R.propEq('term', 'MT'))(res || []) as SinalItem | undefined
        }

        if (isContinue() && res.length === pageSize) {
          loopData()
        } else {
          showLoading.value = false
        }
      })
    }

    loopData()

    on(events.resolutionChange, val => {
      resolutions.value = val
    })

    onBeforeUnmount(() => {
      off(events.resolutionChange)
    })

    const handleDrawLine = (price: string, color: string) => {
      openDialog(DrawLineDialog, {
        onConfirm: () => {
          drawLine({
            code: route.params.code,
            type: LineType.LINE,
            color: color,
            kLineType: resolutions.value,
            horizontalLinePrice: price,
          }).then(() => {
            showAlert(translate('symbol_132'))
            emit(events.redraw)
            timer = window.setTimeout(() => {
              router.back()
            }, 500)
          }).catch(() => {
            router.back()
          })
        },
      })
    }

    onBeforeUnmount(() => {
      clearTimeout(timer)
    })

    return {
      showIntraday,
      showShort,
      showLong,
      intradayData,
      shortTermData,
      midTermData,
      showLoading,
      Direction,
      handleDrawLine,
      formatDateTime,
    }
  },
})
