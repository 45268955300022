/**
 * 全局状态管理
 * @author 贝才[beica1@outook.com]
 * @date 2020/9/29
 * @description
 *   index.ts of FastTrade
 */
import { keymap } from '@/config'
import { Position } from '@/modules/trade/trade.api'
import { TradeFlag, YesOrNo } from '@/types'
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { shallowReactive } from 'vue'

export type LevelModel = {
  validPoints: number;
  levelName: string;
  level: number;
  rebateRate: number;
  totalExp: number;
  totalPoints: number;
}

export type UserAccount = {
  balance: number;
  equity: number;
  freeMargin: number;
  margin: number;
  marginLevel: number;
  profit: number;
  list: Array<Position>;
  levelModel: LevelModel;
  tradeExpFlg: TradeFlag;
  isShowBubbles: number;
  earnings?: number;
}

export const initAccount = {
  balance: 0,
  equity: 0,
  freeMargin: 0,
  margin: 0,
  marginLevel: 0,
  profit: 0,
  list: [],
  levelModel: {
    validPoints: 0,
    levelName: 'V0',
    level: 0,
    rebateRate: 1,
    totalExp: 0,
    totalPoints: 0,
  },
  tradeExpFlg: 0,
  isShowBubbles: 0,
}

export type PrimeDescriber = {
  member: YesOrNo;
  renew: YesOrNo;
  startIssue: string;
  endIssue: string;
  renewIssue: string;
  price: number;
  prise?: number;
}

const defaultPrime: PrimeDescriber = {
  member: YesOrNo.NO,
  renew: YesOrNo.NO,
  startIssue: 'unknown',
  endIssue: 'unknown',
  renewIssue: 'unknown',
  price: 39900,
}

export type GlobalState = {
  guestMode: boolean;
  login: boolean;
  account: UserAccount;
  prime: PrimeDescriber;
  countryCode: string;
  group: 'A' | 'B',
}

export enum AccountType {
  REAL,
  DEMO,
}

export const DEFAULT_ACCOUNT_TYPE = Number(localGet(keymap.label.accountType) ?? AccountType.DEMO)

const state: GlobalState = shallowReactive({
  login: false,
  guestMode: false,
  account: initAccount,
  prime: defaultPrime,
  countryCode: '',
  group: 'A',
})

const indiaCountryCode = '91'

export default state

export const isLogged = () => state.login

export const isPrime = () => state.prime.member === YesOrNo.YES

export const isNewLogin = () => R.isNil(localGet(keymap.user.accountNum))

export const isRoam = () => state.guestMode

export const isIndia = () => localGet(keymap.user.countryCode) === indiaCountryCode
