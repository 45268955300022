/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/15
 * @description
 *   prepare.ts of WeTrade
 */
import * as R from 'ramda'
import { InState, readInHistory } from '@/modules/in/in.api'
import { readBindFA, Payer } from '@/modules/user/user.api'

export const hasAlreadyDeposit = async (): Promise<boolean> => {
  const history = await readInHistory({
    status: InState.SUCCESS,
    page: 1,
    pageCount: 1,
  })
  return !!history?.length
}

const isBlank = R.either(R.isNil, R.isEmpty)

export const readTradeState = async (): Promise<[complete: boolean, step: number]> => {
  const accountState = await readBindFA() as Payer
  const payer = R.props(['address', 'email', 'name'], accountState)
  if (R.any(isBlank, payer)) {
    return [false, 0]
  }
  if (isBlank(accountState.idCardImg)) {
    return [false, 1]
  }
  const haveDeposit = await hasAlreadyDeposit()
  if (!haveDeposit) return [false, 2]
  return [true, 3]
}
