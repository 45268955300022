
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import TheLiveItem from '@/modules/membership/components/TheLiveItem.vue'
import { LiveRoom, readLiveList } from '@/modules/membership/member.api'
import HoldOn from '@/provider/HoldOn.vue'
import { defineComponent, shallowRef } from 'vue'
import { readLiveList as entertainmentList } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'

export default defineComponent({
  name: 'LiveList',
  components: { HoldOn, TheLiveItem, AsyncNullableList },
  setup () {
    const list = shallowRef<Array<LiveRoom>>([])

    // readLiveList().then(resp => {
    //   list.value = resp || []
    // })

    Promise.all([readLiveList(), entertainmentList()]).then(res => {
      list.value = (res[0] || []).concat(res[1] || [])
    })

    return {
      list,
    }
  },
})
