/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 14:13
* @description
*   TheNews.vue of WeTrade
*/
<template>
<div>
  <div class="news-title">
    <t path="home_8" class="f-nm f-bold c-text">News and Ayalysis</t>
  </div>
  <div class="list pb-16">
    <TheHot v-if="topics && topics.length" :topics="topics" />
    <Holder :content="list">
      <List :list-data="list || []" #="{ item }" pull-refresh @refresh="refresh">
        <div class="items">
          <ThePostItem v-if="item.type === 0" :post="item.userPostModel" noPin />
          <TheNewsItem v-if="item.type === 1" :news="item.appcontentArticleModel" />
          <TheAnalysisItem v-if="item.type === 2" :advice="item.tradOpptortunitiesModel" />
          <Line />
        </div>
      </List>
    </Holder>
  </div>
</div>
</template>

<script lang="ts">
import Line from '@/components/Line.vue'
import { defineComponent, ref, watch } from 'vue'
import TheAnalysisItem from '@/modules/home/components/items/TheAnalysisItem.vue'
import TheNewsItem from '@/modules/home/components/items/TheNewsItem.vue'
import ThePostItem from '@/modules/home/components/items/ThePostItem.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import TheHot from '@/modules/community/communities/TheHot.vue'
import { renderTopics } from '@/modules/community/communities/community'

export default defineComponent({
  name: 'TheNews',
  props: {
    list: [Array],
    refresh: Function,
  },
  emits: ['refresh'],
  components: { Line, TheAnalysisItem, TheNewsItem, ThePostItem, List, Holder, TheHot },
  setup () {
    return {
      topics: renderTopics(),
    }
  },
})
</script>
