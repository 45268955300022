/**
* @author ayou[beica1@outook.com]
* @date 2022/1/13 14:41
* @description
*   TournamentList.vue of WeTrade
*/
<template>
<p class="f-bold my-8">
  <t path="membership_10">Tournament</t>
  <router-link to="/membership/tournament" class="pull-right f-sm c-tip df-middle"
    style="font-weight: normal;">
    <t path="membership_11">See more</t>
    <Icon name="caret-right" sm />
  </router-link>
</p>
<Holder :content="tournament">
  <AsyncNullableList :list-data="tournament" #="{ item }">
    <TheTournamentItem :list="item" />
  </AsyncNullableList>
</Holder>
</template>

<script lang="ts">
import { defaultToArray } from '@/common/common'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import Icon from '@/components/Icon.vue'
import TheTournamentItem from '@/modules/membership/tournament/components/TheTournamentItem.vue'
import {
  readTournamentList,
  TournamentSchema,
} from '@/modules/membership/tournament/tournament.api'
import Holder from '@/provider/Holder.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TournamentList',
  components: { Icon, TheTournamentItem, Holder, AsyncNullableList },
  setup () {
    const tournament = shallowRef<TournamentSchema[] | null>(null)

    readTournamentList({ status: '0,1', page: 1, pageCount: 50 })
      .then(resp => tournament.value = defaultToArray(resp) as Array<TournamentSchema>)

    return {
      tournament,
    }
  },
})
</script>

<style scoped>

</style>
