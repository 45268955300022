
import HoldOn from '@/provider/HoldOn.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import TheRecommendItem from '@/modules/home/components/items/TheRecommendItem.vue'
import { onActivated, onBeforeUnmount, onMounted, shallowRef } from 'vue'
import { readRecommend, Recommend } from '@/modules/home/home.api'
import datafeed from '@/common/datafeed'
import * as R from 'ramda'
import useRequest from '@/hooks/useRequest'

export default {
  name: 'TheHomeRecommend',
  components: {
    HoldOn,
    AsyncNullableList,
    TheRecommendItem,
  },
  setup () {
    const list = shallowRef<Recommend[]>([])

    const [request] = useRequest(readRecommend)

    const updateRecomend = () => {
      request().then(res => {
        list.value = res || []
        datafeed.subscribe(R.pluck('code', res), true)
      })
    }

    // updateRecomend()

    onMounted(updateRecomend)

    onActivated(updateRecomend)

    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    return {
      list,
    }
  },
}
