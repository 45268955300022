/**
* @author ayou[beica1@outook.com]
* @date 2022/1/12 17:18
* @description
*   TheHeader.vue of WeTrade
*/
<template>
<TheVBlock>
  <router-link to="/prime/detail">
    <RedPointAlert id="prime">
      <div class="header br-lg c-black">
        <span class="gold">
          <t pah="prime_2" class="f-bold">Prime</t>
          <span class="mx-12">|</span>
        </span>
        <t v-if="renew" path="prime_6" :args="{date: subscribed.renewIssue}" class="f-sm">
          Auto renewal on {{subscribed.renewIssue}}
        </t>
        <span v-else>
          <money :v="subscribed.price" class="gold f-bold" />
          <t path="prime_4" class="f-sm ml-4"></t>
        </span>
        <Icon name="caret-right" class="ml-4" sm />
      </div>
    </RedPointAlert>
  </router-link>
</TheVBlock>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import TheVBlock from '@/pages/prime/components/TheVBlock.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'
import state from '@/state'
import { YesOrNo } from '@/types'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheHeader',
  components: { RedPointAlert, Icon, TheVBlock },
  setup () {
    const subscribed = computed(() => state.prime)
    const renew = computed(() => subscribed.value.renew === YesOrNo.YES)

    return {
      subscribed,
      renew,
    }
  },
})
</script>

<style scoped lang="scss">
.header {
  position: relative;
  margin: 16px 32px 0;
  padding: 22px 16px;
  background: linear-gradient(90deg, #F0DABC 0%, #D7AB95 100%);

  &::after {
    position: absolute;
    top: 0;
    right: 6px;
    width: 71px;
    bottom: 0;
    background: url(/img/prime/star@2x.png) top right no-repeat;
    background-size: 100%;
    content: '';
  }
}
</style>
