/**
* @author zjc[beica1@outook.com]
* @date 2021/10/15 18:33
* @description
*   TheSelectBar.vue of WeTrade
*/
<template>
  <div class="d-f t-center">
    <slot
      v-for="item in options"
      :key="item.value"
      :item="item"
      :select="select"
      :active="item.value === value"
    />
  </div>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent, shallowRef } from 'vue'

type Options = Record<string, unknown> & {
  value: number;
  default: boolean;
}

export default defineComponent({
  name: 'TheSelectBar',
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: Number,
  },
  setup: function (props) {
    const value = shallowRef(-1)

    const select = (item: Options) => {
      value.value = item.value
    }

    const dft = R.find(
      o => props.selected ? props.selected === o.value : o.default, props.options as Options[])

    if (dft) {
      select(dft)
    }

    return {
      value,
      select,
    }
  },
})
</script>
