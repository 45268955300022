/**
* @description RealTimeQuote.vue of tf
* @author 贝才 beica1@outook.com
* @date 2020/4/24
*/
<template>
  <slot
    :code="symbolCode"
    :price="price"
    :buy="buy"
    :spread="spread"
    :margin="margin"
    :mp="mp"
    :is-rise="isRise"
    :open="open"
    :top="top"
    :low="low"
  />
</template>

<script lang="ts">
import feed, { QuoteMessage } from '@/common/datafeed'
import { defineComponent } from 'vue'

const NotANumber = 'NaN'

const defaultNumberDisplay = (x: string) => Number.isNaN(parseFloat(x)) ? NotANumber : x

export default defineComponent(
  {
    name: 'RealTimeQuote',
    emits: ['turn'],
    props: {
      code: String,
      symbol: Object,
    },
    data () {
      return {
        price: NotANumber,
        buy: NotANumber,
        spread: NotANumber,
        margin: NotANumber,
        mp: NotANumber,
        open: NotANumber,
        top: NotANumber,
        low: NotANumber,
        unwatch () {
          console.log('empty unwatch in realtime quote')
        },
      }
    },
    computed: {
      symbolCode (): string {
        return this.code || this.symbol?.code || 'N/A'
      },

      target (): QuoteMessage {
        return (this.symbol as QuoteMessage) || (this.symbolCode && feed.getLatestQuote(
          this.symbolCode))
      },

      // triple state flag - null, true, false
      isRise (): boolean | null {
        const numberMargin = parseFloat(String(this.margin))
        if (Number.isNaN(numberMargin)) return null
        return numberMargin >= 0
      },
    },
    watch: {
      isRise (val: boolean) {
        // console.log('turn', this.symbolCode, val)
        this.$emit('turn', val)
      },
      symbol () {
        this.subscribe()
      },
      code () {
        this.subscribe()
      },
    },
    created () {
      this.subscribe()
    },
    beforeUnmount () {
      this.unwatch()
    },
    methods: {
      watch (code: string, symbol?: QuoteMessage) {
        this.unwatch = feed.watch(code, this.update, { from: symbol, immediate: true })
      },
      subscribe () {
        this.unwatch()
        if (this.target) {
          this.watch(this.symbolCode, this.target)
        }
      },
      update (message?: QuoteMessage) {
        if (message) {
          this.price = defaultNumberDisplay(message.sell)
          this.buy = defaultNumberDisplay(message.buy)
          this.spread = defaultNumberDisplay(message.pointDiff)
          this.margin = defaultNumberDisplay(message.margin)
          this.mp = defaultNumberDisplay(message.mp)
          this.open = defaultNumberDisplay(message.open)
          this.top = defaultNumberDisplay(message.top)
          this.low = defaultNumberDisplay(message.low)
        }
      },
    },
  },
)
</script>
