
import { defineComponent } from 'vue'
import { GetPointAlertEnum } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'

export default defineComponent({
  name: 'TheGetPointAlert',
  props: {
    type: Number,
    title: String,
    point: Number,
    time: Number,
  },
  setup () {
    return {
      GetPointAlertEnum,
    }
  },
})
