/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   market.ts of WeTrade
 */
import datafeed from '@/common/datafeed'
import { openDialog, openFullscreenList } from '@/components/popup/popup'
import { events, flag, keymap, market } from '@/config'
import useRequest from '@/hooks/useRequest'
import AddToWatchList from '@/modules/market/AddToWatchList.vue'
import {
  readOverlayStudies,
  StudyConfig,
} from '@/modules/market/components/chart/chartConfig'
import EditWatchList from '@/modules/market/EditWatchList.vue'
import GuideOrderDialog from '@/modules/market/GuideOrderDialog.vue'
import {
  readMarketList, readMarketListWithoutCryptos, readSymbol,
  readWatchlist,
  requestSaveWatchlist,
} from '@/modules/market/market.api'
import {
  MarketCategory,
  MarketItem,
  MarketState,
  SymbolSnapshot,
} from '@/modules/market/market.types'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'
import {
  createPosition,
  Product,
  // readProducts,
} from '@/modules/trade/trade.api'
import { getProductsByCode } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import { readHistory } from '@/pages/history/history.api'
import state, { isLogged } from '@/state'
import { isDemoAccount } from '@/state/accountType'
import { TradeDirection, YesOrNo } from '@/types'
import { localGet, localSet } from 'essential/store/localStore'
import { emit, off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { onActivated, onBeforeUnmount, shallowRef, computed } from 'vue'

/**
 * 同步自选列表到本地
 * @param list
 */
const syncLocalWatchlist = (list: string) => {
  localSet(keymap.label.watchList, list)
}

/**
 * 添加/移除自选列表
 * @param code
 */
export const useToggleState = (code: string) => {
  const hasWatch = R.includes(code, localGet(keymap.label.watchList) ?? '')
  const added = shallowRef(hasWatch)
  const toggleAddState = () => added.value = !added.value

  const [save, progress] = useRequest(requestSaveWatchlist)

  const toggle = () => {
    let nextList = (localGet(keymap.label.watchList) ?? '').split(',')
    if (added.value) {
      nextList = R.reject(R.equals(code), nextList)
    } else {
      nextList = R.prepend(code, nextList)
    }

    const next = nextList.toString()
    save({ watchList: next }).then(() => {
      toggleAddState()
      syncLocalWatchlist(next)
    })
  }

  return {
    toggle,
    progress,
    added,
  }
}

/**
 * 自选列表修改信息保存在本地，并保持更新
 */
export const useWatchlist = () => {
  const read = () => readWatchlist().then(resp => {
    localSet(keymap.label.watchList, R.pluck('code', resp).toString())
    return resp
  })

  const done = () => {
    emit(events.watchListUpdate)
  }

  const add = () => openFullscreenList(AddToWatchList, { onSave: done })

  const edit = () => openFullscreenList(EditWatchList, { onSave: done })

  return {
    read,
    add,
    edit,
  }
}

/**
 * 自选列表排序
 */
export const useSortWatchlist = () => {
  const list = shallowRef<Array<MarketItem>>([])
  let editList: Array<MarketItem> = []

  readWatchlist().then(resp => {
    list.value = resp
    editList = resp
  })

  /**
   * 置顶
   * @param code
   */
  const toTop = (code: string) => {
    console.log('toTop')
    const pos = R.findIndex(R.propEq('code', code), editList)
    if (~pos) {
      editList = R.move(pos, 0, editList)
    }
  }

  const sort = (code: string, dir: number) => {
    const index = R.findIndex(R.propEq('code', code), editList)
    editList = R.move(index, index + dir, editList)
  }

  const removeItems = (codes: Array<string>) => {
    const next = R.reject(x => R.includes(x.code, codes), editList)
    list.value = next
    editList = next
  }

  const save = () => {
    const watchlist = R.pluck('code', editList).toString()
    return requestSaveWatchlist({ watchlist })
      .then(() => syncLocalWatchlist(watchlist))
  }

  return {
    list,
    toTop,
    sort,
    removeItems,
    save,
  }
}

export enum SORT_STATE {
  DESC = -1,
  NONE,
  ESC,
}

/**
 * 用户读取自选，市场行情，并编辑自选列表
 * @param defaultTab
 */
export const useMarket = (defaultTab = 0) => {
  const tabIndex = shallowRef(defaultTab)

  const filter = (type: MarketCategory = MarketCategory.ALL) => () => readMarketList(
    {
      isPre: Number(flag.isPre),
      excode: market.excode,
      type,
    })

  const tabs = [
    {
      label: 'market_3',
      value: 1,
      load: filter(MarketCategory.FOREX),
      labelDefault: 'Forex',
    }, {
      label: 'market_4',
      value: 2,
      load: filter(MarketCategory.COMMODITIES),
      labelDefault: 'Commodities',
    }, {
      label: 'market_5',
      value: 3,
      load: filter(MarketCategory.CRYPTO),
      labelDefault: 'Crypto',
    }, {
      label: 'market_6',
      value: 4,
      load: filter(MarketCategory.INDEX),
      labelDefault: 'Indices',
    }, {
      label: 'market_26',
      value: 5,
      load: filter(MarketCategory.STOCK),
      labelDefault: 'Stocks',
    },
  ]

  const switchTab = (index: number) => {
    if (index !== tabIndex.value) {
      tabIndex.value = index
    }
  }

  return {
    index: tabIndex,
    tabs,
    switchTab,
  }
}

export const useOverlayStudies = (changeStudy?: (config: StudyConfig) => void): [
  StudyConfig[],
  StudyConfig,
  (config: StudyConfig, done: (item: StudyConfig) => void) => void
] => {
  const overlayStudies = readOverlayStudies()
  const isPrime = computed(() => state.prime.member === YesOrNo.YES)

  const dft = R.find(s => (isPrime.value ? !!s?.elite : !s?.elite), overlayStudies) as StudyConfig

  const change = (config: StudyConfig, done: (item: StudyConfig) => void) => {
    if (config.elite ? isPrime.value : !config.elite) {
      changeStudy?.(config)
      done(config)
    } else {
      openDialog(LimitDialogTemplate, {
        i18nPath: 'symbol_72',
      })
    }
  }

  return [overlayStudies, dft, change]
}

export const readLatestQuote = async (code: string, realTime = false) => {
  const cache = datafeed.getLatestQuote(code)

  if (!realTime && cache) return cache

  return readSymbol({ codes: `${market.excode}|${code}` })
    .then(resp => resp[0])
}

/**
 * hook
 * 行情订阅/引用
 */
export const useQuote = (code: string, realTime = false) => {
  const symbol = shallowRef<SymbolSnapshot | null>(null)

  readLatestQuote(code, realTime)
    .then(resp => symbol.value = (resp as SymbolSnapshot))

  return symbol
}

const mostActiveSymbolsList = [
  'USDJPY', 'EURUSD', 'GBPUSD', 'AUDUSD', 'XAUUSD',
  'XAGUSD', 'USOIL', 'UKOIL', 'BTCUSD', 'ETHUSD',
]

function isWeekend () {
  const day = new Date().getDay()
  return day === 0 || day === 6
}

export const readMostActiveProduct = async () => {
  const symbols = await (isWeekend() ? readMarketList : readMarketListWithoutCryptos)({
    excode: market.excode,
  }).then(resp => R.filter(
    R.both(
      R.propSatisfies(R.includes(R.__, mostActiveSymbolsList), 'code'),
      R.complement(R.pathEq(['realTimeMicroQuotationBean', 'isClosed'], MarketState.CLOSED)),
    ),
    resp,
  ))

  // console.log('jojo', symbols)

  // 已经存在建仓历史 不提示
  if (R.length(symbols) === 0) return

  // 获取涨跌幅最大的品种
  let theMostActiveSymbol = symbols[0]
  for (let i = 1; i < symbols.length; i++) {
    if (
      Math.abs(parseFloat(symbols[i].realTimeMicroQuotationBean.mp)) >
      Math.abs(parseFloat(theMostActiveSymbol.realTimeMicroQuotationBean.mp))
    ) {
      theMostActiveSymbol = symbols[i]
    }
  }

  // 品种获取失败
  if (!theMostActiveSymbol) return

  // 获取匹配的产品
  // const products = await readProducts(
  //   { code: theMostActiveSymbol.realTimeMicroQuotationBean.code })
  const products = await getProductsByCode(
    theMostActiveSymbol.realTimeMicroQuotationBean.code, YesOrNo.NO)

  const product = R.find(R.propEq('cost', '2000000'), products)

  // 产品获取失败
  if (!product) return

  return {
    product,
    symbol: theMostActiveSymbol,
  }
}

/**
 * 引导新用户用券下单
 */
export const guideToPlaceOrder = () => {
  let checking = false

  const check = async () => {
    // 只针对虚拟账户
    if (!isDemoAccount.value) return

    // 争对登录用户
    if (!isLogged()) return

    // 登陆跳转后只执行一次check
    if (checking) return
    checking = true

    // 已经存在持仓单 不提示
    if (R.length(state.account.list) !== 0) return

    const orderHistory = await readHistory({
      page: 1,
      pageCount: 1,
    })

    const most = await readMostActiveProduct()

    if (!most) return

    const {
      product,
      symbol,
    } = most

    if (R.length(orderHistory) === 0) {
      checking = false
      openDialog(GuideOrderDialog, {
        symbol: symbol.realTimeMicroQuotationBean,
        tips: symbol.tips,
        product,
        wrapperClass: 'center',
      }, {
        sync: true,
        backToClose: false,
      })
    }
  }

  on(events.login, check)

  onBeforeUnmount(() => {
    off(events.login, check)
  })

  onActivated(check)
}

/**
 * 下单
 * @param product
 * @param isBuy
 */
export const placeOrder = (product: Product, isBuy: boolean) => {
  const model = new TradeModel(isBuy ? TradeDirection.BUY : TradeDirection.SELL)
  model.setProduct(product)
  return createPosition(model.getValue())
}
