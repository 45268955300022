
import {
  ready as appReady,
} from '@/common/jsBridge.api'
import { onMounted } from 'vue'
import Pic from '@/components/Pic.vue'

export default {
  name: 'NoService',
  components: {
    Pic,
  },
  setup () {
    onMounted(() => {
      appReady()
    })

    return {}
  },
}
