
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Pic from '@/components/Pic.vue'
import { Types } from '@/modules/membership/signal/signal.api'

export default defineComponent({
  name: 'SignalTrend',
  components: { DialogTemplate, Pic },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup () {
    return {
      kType: Types.K_1D,
    }
  },
})
