/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/18
* @description
*   TheCommissions.vue of WeTrade
*/
<template>
  <Holder :content="list">
    <Nullable :content="list">
      <!--分组显示所有持仓单-->
      <TheTradeGroup v-if="showAll" :list="list" #="{item}">
        <TheCommissionItem :order="item" class="position mt-8 box p-16" />
      </TheTradeGroup>
      <!--不分组显示指定品种的持仓-->
      <List v-else :list-data="list" #="{item}">
        <TheCommissionItem :order="item" class="position mb-8 box p-16" />
      </List>
      <template #emptyMsg><t path="symbol_52">There are currently no orders</t></template>
    </Nullable>
  </Holder>
</template>

<script lang="ts">
import Nullable from '@/components/Nullable.vue'
import { events } from '@/config'
import TheCommissionItem from '@/modules/trade/components/TheCommissionItem.vue'
import TheTradeGroup from '@/modules/trade/components/TheTradeGroup.vue'
import { Commission, readCommissions } from '@/modules/trade/trade.api'
import Holder from '@/provider/Holder.vue'
import List from '@/components/AsyncNullableList.vue'
import { off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheCommissions',
  components: { Nullable, TheTradeGroup, TheCommissionItem, List, Holder },
  props: {
    code: String,
  },
  setup (props) {
    const commissions = shallowRef<Commission[]>([])
    const leverage = ['', 'transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']

    const refresh = () => readCommissions().then(resp => {
      resp = resp.map(item => {
        if (item.levelType && item.levelType > 0) {
          item.leverage = leverage[item.levelType]
        }
        item.weight = String(Number(item.weight))
        return item
      })
      commissions.value = props.code
        ? R.filter(R.propEq('code', props.code), resp)
        : resp
    })

    on([events.transactionUpdate, events.accountChanged], refresh)

    onBeforeUnmount(() => {
      off([events.transactionUpdate, events.accountChanged], refresh)
    })

    refresh()

    return {
      showAll: !props.code,
      list: commissions,
    }
  },
})
</script>
