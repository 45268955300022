
import { isShield } from '@/decorators/withoutCryptos'
import { readMarketList } from '@/modules/market/market.api'
import { MarketItem } from '@/modules/market/market.types'
import Holder from '@/provider/Holder.vue'
import Matrix from 'common/Matrix.vue'
import * as R from 'ramda'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

enum Groups {
  ALL,
  COMMODITIES,
  FOREX,
  CRYPTO,
  INDICES,
  STOCKS,
}

export default defineComponent({
  name: 'SymbolList',
  components: {
    Holder,
    Matrix,
  },
  emits: ['save'],
  setup (props, ctx) {
    const list = shallowRef()
    const group = (resp: Array<MarketItem>) => R.groupBy(
      R.o(String, R.prop('typeId')), resp)

    const swapFirstTwoEls = R.apply(R.flip(R.unapply(R.identity)))

    readMarketList()
      .then(resp => {
        const grouped = group(resp as Array<MarketItem>)

        isShield()
          .then(yes => {
            list.value = swapFirstTwoEls(
              R.toPairs(yes ? R.omit([Groups.CRYPTO.toString()], grouped) : grouped))
          })
      })

    onBeforeUnmount(() => {
      ctx.emit('save')
    })

    return {
      list,
      // types: ['All', 'Commodities', 'Forex', 'Crypto'],
      types: {
        [Groups.ALL]: {
          label: 'managewatchlist_2',
          labelDefault: 'All',
        },
        [Groups.COMMODITIES]: {
          label: 'market_4',
          labelDefault: 'Commodities',
        },
        [Groups.FOREX]: {
          label: 'market_3',
          labelDefault: 'Forex',
        },
        [Groups.CRYPTO]: {
          label: 'market_5',
          labelDefault: 'Crypto',
        },
        [Groups.INDICES]: {
          label: 'market_6',
          labelDefault: 'Indices',
        },
        [Groups.STOCKS]: {
          label: 'market_26',
          labelDefault: 'Stocks',
        },
      },
    }
  },
})
