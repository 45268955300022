/**
* @author zjc[beica1@outook.com]
* @date 2021/11/15 19:42
* @description
*   TheCrosshair.vue of WeTrade
*/
<template>
  <div v-if="crosshair">
    <div class="line v" :style="{top: `${crosshair ? crosshair.y : 0}px`}">
      <span class="price">{{crosshair.c.toFixed(5)}}</span>
    </div>
    <div class="line h" :style="{left: `${crosshair ? crosshair.x : 0}px`}" />

    <div
      class="board f-bold"
      :style="{[crosshair.x < 100 ? 'right' : 'left']: 0}">
      <p>{{formatTime(crosshair.bar.t)}}</p>
      <p class="mt-4">
        Open <span :class="getColorClass(crosshair.bar.o)">{{crosshair.bar.o}}</span>
      </p>
      <p class="mt-4">
        High <span :class="getColorClass(crosshair.bar.h)">{{crosshair.bar.h}}</span>
      </p>
      <p class="mt-4">
        Low <span :class="getColorClass(crosshair.bar.l)">{{crosshair.bar.l}}</span>
      </p>
      <p class="mt-4">
        Close <span :class="getColorClass(crosshair.bar.c)">{{crosshair.bar.c}}</span>
      </p>
      <p class="mt-4">
        Chg <span :class="getColorClass(crosshair.bar.c)">{{crosshair.bar.chg}}</span>
      </p>
      <p class="mt-4">
        Chg% <span :class="getColorClass(crosshair.bar.c)">{{crosshair.bar['chg%']}}%</span>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { formatDateTime } from '@/common/format'
import { defineComponent } from 'vue'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheCrosshair',
  props: {
    crosshair: Object,
  },
  setup (props) {
    const formatTime = R.curryN(2, formatDateTime)('MM-dd-yyyy HH:mm')

    const getColorClass = (n: number) => {
      return n > props.crosshair?.bar?.pc ? 'c-success' : 'c-danger'
    }

    return {
      formatTime,
      getColorClass,
    }
  },
})
</script>

<style scoped lang="scss">
.line {
  position: absolute;
}

.v {
  left: 0;
  right: 0;
  height: 1px;
  background: var(--color-text);
}

.price {
  position: absolute;
  right: 0;
  top: -6px;
  font-size: 11px;
  color: var(--color-white);
  background: var(--color-text);
}

.h {
  top: 0;
  bottom: 0;
  width: 1px;
  background: var(--color-text);
}

.board {
  position: absolute;
  margin: 6px;
  padding: 6px;
  font-size: 9px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.9);
}
</style>
