<template>
<div>
  <router-link :to="`/liveForQuickPlay/${prop('channelId', liveInfo)}/${prop('chatRoomId', liveInfo)}`" custom #="{navigate}">
    <div class="le-main c-white df-col df-center df-middle pt-4 mt-16"
      @click="navigate"
      v-if="showComponent"
    >
      <p class="c-white f-lg">{{ liveInfo.channelName }}</p>
      <p class="live-time px-8 df-middle mt-8">
        <Icon name="time" class="mr-8" sm />
        <span>{{ liveInfo.liveTime }}</span>
      </p>
      <Pic :src="liveInfo.authorUrl" class="a-icon" />
    </div>
  </router-link>
</div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef, watch } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLiveEntran',
  components: {
    Icon,
    Pic,
  },
  props: {
    liveInfo: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const showComponent = shallowRef(false)
    watch(() => props.liveInfo, () => {
      if (props.liveInfo && props.liveInfo.chatRoomId) {
        showComponent.value = true
      }
    }, {
      immediate: true,
      deep: true,
    })

    return {
      showComponent,
    }
  },
})
</script>

<style scoped lang='scss'>
.le-main{
  background-image: url(/img/home/live_bg@2x.png);
  height: 92px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0, 0;
  position: relative;
  .live-time{
    height: 22px;
    background-color: var(--color-success);
    border-radius: 11px;
  }
  .a-icon{
    height: 44px;
    width: 44px;
    border-radius: 50%;
    border: 2px solid var(--color-white);
    position: absolute;
    right: 18px;
    top: 50%;
    margin-top: -20px;
  }
}
</style>
