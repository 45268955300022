/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/18
* @description
*   TheCommissionItem.vue of WeTrade
*/

<template>
  <div class="trade-item py-16">
    <div class="brief d-f f-md">
      <div class="flex-1">
        <p class="row">
          <t path="symbol_42" class="label">Volume</t>
          <span class="value">{{prop('weight', order)}} {{prop('unit', order)}}</span>
        </p>
        <p class="row">
          <t path="symbol_44" class="label">Margin required</t>
          <money class="value" :v="prop('buyMoney', order)" #="{text}">
            {{text}}
            <Icon v-if="byCoupon" name="coupon" class="c-title" sm />
          </money>
        </p>
      </div>
      <div class="flex-1 d-f">
        <div class="flex-1">
          <p class="row">
            <t path="symbol_43" class="label">Direction</t>
            <t custom #="{td, t}">
              <ColorText class="f-medium" :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
                :value="prop('type', order) - 2" />
            </t>
          </p>
          <p class="row">
            <t path="symbol_46" class="label">Entry Price</t>
            <span class="value">{{prop('entrustPrice', order)}}</span>
          </p>
        </div>
        <Icon
          :name="show ? 'caret-up' : 'caret-down'"
          class="toggle-icon" sm :class="{up: show}"
          @click="toggleSlide"
        />
      </div>
    </div>
    <div ref="acc">
      <div class="accordion__content more d-f f-md">
        <div class="flex-1">
          <p class="row">
            <t path="symbol_45" class="label">Commission</t>
            <money class="value" :v="prop('fee', order)" />
          </p>
          <p class="row">
            <t path="symbol_48" class="label">T/P</t>
            <TheTradeLimitDisplay class="value" :value="prop('stopProfitPoint', order)" />
          </p>
          <p class="row">
            <t path="transactionhistory_25" class="label">Leverage</t>
            <t class="value" :path="prop('leverage', order)"></t>
          </p>
        </div>
        <div class="flex-1">
          <p class="row">
            <t path="symbol_47" class="label">Order Time</t>
            <Date class="value f-xs" format="MM-dd-yyyy HH:mm:ss" :time="prop('entrustTime', order)" />
          </p>
          <p class="row">
            <t path="symbol_49" class="label">S/L</t>
            <!--<span class="value">{{prop('stopLossPoint', order)}}</span>-->
            <TheTradeLimitDisplay class="value" :value="prop('stopLossPoint', order)" />
          </p>
          <p class="row">
            <t path="transactionhistory_29" class="label">Profit per pip</t>
            <money class="value" :v="prop('floatProfit', order)" #={value}>{{value + ' ' + prop('ykUnit', order)}}</money>
          </p>
        </div>
      </div>
    </div>
    <div class="controls d-f f-medium mt-4">
      <router-link :to="`/edit/${prop('code', order)}/${prop('orderId', order)}`" #="{navigate}" custom>
        <button class="btn flex-3 modify sm br-sm" @click="navigate">
          <t path="symbol_50" #="{td}" custom>{{ td || 'Modify' }}</t>
        </button>
      </router-link>
      <button class="btn flex-5 ml-8 primary line sm br-sm" @click="cancel">
        <t path="symbol_51" #="{td}" custom>{{ td || 'Cancel' }}</t>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import ColorText from '@/components/ColorText.vue'
import Date from '@/components/Date'
import Icon from '@/components/Icon.vue'
import { openDialog } from '@/components/popup/popup'
import TheCommissionCancelConfirmDialog from '@/modules/trade/components/TheCommissionCancelConfirmDialog.vue'
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import accordion from 'essential/dom/accordion'
import { computed, defineComponent, onMounted, ref, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheCommissionItem',
  components: { Date, TheTradeLimitDisplay, ColorText, Icon },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const toggle = shallowRef(() => {
      console.log('accordion effect not work')
    })

    const show = ref(false)

    const acc = shallowRef<HTMLElement | null>(null)
    const byCoupon = computed(() => props.order.isJuan)

    const cancel = () => openDialog(TheCommissionCancelConfirmDialog, {
      order: props.order,
    })

    onMounted(() => {
      toggle.value = accordion(acc.value as HTMLElement)
    })

    const toggleSlide = () => {
      show.value = !(show.value)
      toggle.value()
    }

    return {
      acc,
      cancel,
      byCoupon,
      show,
      toggleSlide,
    }
  },
})
</script>

<style scoped lang="scss">
.wedge {
  margin: 0 -16px;
  background: var(--color-light);
}

.row {
  margin-bottom: 12px;
}

.label {
  margin-right: 8px;
  color: var(--color-title);
}

.value {
  font-weight: 500;
  color: var(--color-text);
}

.modify {
  color: var(--color-primary);
  background: #E6EDFE;
}

.tip {
  font-style: italic;
  color: var(--color-title);
}

.toggle-icon {
  background: var(--color-primary);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: var(--color-white);
  &.up {
    background: var(--color-tip);
  }
}

</style>
