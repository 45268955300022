/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/8
 * @description
 *   user.api.ts of WeTrade
 */
import requestResult from '@/common/request/request'
import { TradeFlag, YesOrNo } from '@/types'

export type User = {
  accountNum: string;
  avatar: string;
  countryCode: string;
  faceBookBindStatus: YesOrNo;
  fbId: string;
  invitationCode: string;
  mobile: string;
  mobileBindStatus: YesOrNo;
  nickName: string;
  token: string;
  userId: string;
  tradeExpFlg: TradeFlag;
  email: string;
  islamFlag: string | number;
  googleBindStatus?: YesOrNo;
  googleEmail: string;
}

export const sendBindCode = (countryCode: string, mobile: string) => requestResult('/api/sms/send')(
  {
    type: 3,
    countryCode,
    mobile,
  })

export const bindMobile = requestResult<User>('/api/user/bind/mobile')

// Schema用于校验服务端数据
export interface Payer {
  address: string;
  email: string;
  name: string;
  idCardImg: string;
  cashOutType: number | null;
  bankAccount: string;
  gcashAccount: string;
  vpa: string;
}

export const bindPayer = requestResult('/api/cashout/base/info/binding')

// 读取已经绑定的金融账户信息
export const readBindFA = requestResult<Payer>('/api/cashout/bank/info')

export const tagUser = requestResult('/api/user/update/trade/exp')
