/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/9
* @description
*   Market.vue of WeTrade
*/
<template>
<Page class="trade df-col">
  <DemoAccountTip />
  <TheTopMovers />
  <MarketTab />
  <TheDepositTip class="p-a" style="bottom: 0;left: 0;right:0;" />
  <NothingOnInspectMode>
    <AccountRelated use-in-roam>
      <div class="guide df-middle bg-primary f-bold p-a">
        <Icon name="person-plus" class="mr-6" />
        <t path="market_12" class="flex-1">
          Seize market opportunities
        </t>
        <router-link to="/loginSwich/0" class="reg_btn">
          <t path="market_13" #="{td}" custom>{{td || 'Sign up'}}</t>
        </router-link>
      </div>
    </AccountRelated>
  </NothingOnInspectMode>
</Page>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import MarketTab from '@/modules/market/components/MarketTab.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import { defineComponent } from 'vue'
import Page from '@/provider/Page.vue'
import TheDepositTip from '@/modules/market/components/TheDepositTip.vue'
import TheTopMovers from '@/modules/market/components/TheTopMovers.vue'

export default defineComponent({
  name: 'Market',
  components: {
    MarketTab,
    NothingOnInspectMode,
    TheDepositTip,
    DemoAccountTip,
    Icon,
    AccountRelated,
    Page,
    TheTopMovers,
  },
})
</script>

<style scoped lang="scss">
::v-deep(.wt-nav) {
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
}

.guide {
  padding: 0 30px;
  color: white;
  height: 52px;
  left: 0;
  right: 0;
  bottom: 0
}

.reg_btn {
  width: 88px;
  line-height: 28px;
  text-align: center;
  border-radius: 14px;
  color: var(--color-primary);
  background: var(--color-white);
}

.stocks-tab {
  padding: 0;

  ::v-deep(td:first-child) {
    padding-left: 16px;
  }

  ::v-deep(td:last-child) {
    padding-right: 16px;
  }
}

</style>
