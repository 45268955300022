/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   Me.vue of WeTrade
*/
<template>
<Page>
  <div class="header px-16 df-middle">
    <div class="avatar">
      <Pic
        :src="avatar" fallback="/img/user/Avatar@2x.png" width="52" height="52"
      />
    </div>
    <div class="flex-1 f-medium">
      <span v-if="isLogin" class="ml-12 f-nm f-bold">{{nickName}}</span>
      <div v-else class="ml-20">
        <router-link to="/loginSwich/0">
          <t path="me_2">Sign up</t>
        </router-link>
        <span class="mx-16">|</span>
        <router-link to="/loginSwich/1">
          <t path="me_3">Sign in</t>
        </router-link>
      </div>
    </div>
    <router-link to="/me/setting">
      <Icon name="settings" />
    </router-link>
  </div>
  <div class="px-16">
    <div class="d-f t-center c-title f-sm">
      <TheContactButton class="flex-1 box" />
      <router-link custom #="{navigate}" to="/me/coupons">
        <div class="flex-1 ml-8 box py-8" @click="navigate">
          <Pic src="user/my_coupons" width="32" height="32" />
          <t path="me_17" as="p">My Coupons</t>
        </div>
      </router-link>
    </div>
    <TheActivity class="mt-8" />
    <div class="py-0">
      <router-link v-for="menu in menus" :key="menu.route" :to="menu.route" custom #="{navigate}">
        <RedPointAlert class="menu df-middle unread-middle"
          v-if="menu.redPoint"
          :id="menu.redPoint"
          @click="navigate">
          <div class="flex-1">
            <div class="desc df-middle">
              <t as="p" :path="menu.label" class="flex-1 f-nm">{{menu.labelDefault}}</t>
              <Icon name="caret-right" class="c-tip" sm />
            </div>
          </div>
        </RedPointAlert>

        <div class="menu df-middle" v-else @click="navigate">
          <div class="flex-1">
            <div class="desc df-middle">
              <t as="p" :path="menu.label" class="flex-1 f-nm">{{menu.labelDefault}}</t>
              <Icon name="caret-right" class="c-tip" sm />
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <!-- <div class="df-cross-center mb-16" style="margin-top: 64px;padding: 0 50px">
      <Pic src="user/FSCA" width="80" height="32" class="mr-16" />
      <p class="f-xs c-tip">Regulated by South Africa FSCA License number 23497</p>
    </div> -->
  </div>

  <p class="t-center mt-12">
    <TermsAndPolicy />
  </p>
</Page>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import TermsAndPolicy from '@/components/TermsAndPolicy.vue'
import { keymap } from '@/config'
import TheContactButton from '@/modules/user/components/TheContactButton.vue'
import Page from '@/provider/Page.vue'
import { localGet } from 'essential/store/localStore'
import { defineComponent } from 'vue'
import { isLogged } from '@/state'
import TheActivity from '@/modules/user/components/TheActivity.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'

export default defineComponent({
  name: 'Me',
  components: { TheActivity, TheContactButton, Icon, Pic, Page, RedPointAlert, TermsAndPolicy },
  setup () {
    return {
      menus: [
        {
          route: '/me/history',
          icon: 'user/history',
          label: 'me_9',
          labelDefault: 'Trade History',
        },
        {
          route: '/me/io',
          icon: 'user/history_t',
          label: 'me_10',
          labelDefault: 'Transaction History',
        },
        {
          route: '/setting/locale',
          icon: 'user/locale',
          label: 'me_11',
          labelDefault: 'Language',
        },
        {
          route: '/user/aboutpc',
          icon: '',
          label: 'pc_10',
          labelDefault: 'Web platform',
          redPoint: 'm_a_p',
        },
        {
          route: '/user/aboutus',
          icon: '',
          label: 'me_22',
          labelDefault: 'About WeTrade',
          redPoint: 'm_a_u',
        },
      ],
      avatar: localGet(keymap.user.avatar),
      nickName: localGet(keymap.user.nickName),
      isLogin: isLogged(),
    }
  },
})
</script>

<style scoped lang="scss">
::v-deep(.unread-middle::after){
  top: 50% !important;
  margin-top: -4px;
  right: 24px;
}
.header {
  height: 104px;
  color: var(--color-text);
}

.menu {
  height: 48px;
}

.desc {
  height: 47px;
}

.avatar {
  width: 56px;
  height: 56px;
  border: 3px solid var(--color-light);
  border-radius: 50%;

  img {
    position: relative;
    top: -1px;
    left: -1px;
    border-radius: 26px;
  }
}
</style>
