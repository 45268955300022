/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/23
* @description
*   TheWatchList.vue of WeTrade
*/
<template>
<GuideTarget name="marketList" class="spread df-col">
  <div class="actions my-16 px-16 f-md df-middle">
    <div class="df-middle mr-32" @click="add">
      <Icon name="plus" sm class="mr-4" />
      <t path="market_7" #="{td}" custom>{{td || 'Add symbol'}}</t>
    </div>
    <div class="df-middle" @click="edit">
      <Icon name="list" sm class="mr-4" />
      <t path="market_8" #="{td}" custom>{{td || 'Manage'}}</t>
    </div>
  </div>
  <TheMarketList :read="read" class="flex-1" />
  <template #bottom>
    <GuideContent class="mx-16">
      <t path="market_14">
        You are now on the Demo account. Try trading the popular instruments.
        Click to see the chart and analysis.
      </t>
    </GuideContent>
  </template>
</GuideTarget>
</template>

<script lang="ts">
import GuideContent from '@/components/userGuide/GuideContent.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import TheMarketList from '@/modules/market/components/TheMarketList.vue'
import { useWatchlist } from '@/modules/market/market'
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheWatchList',
  components: { TheMarketList, GuideContent, GuideTarget, Icon },
  setup () {
    const { read, add, edit } = useWatchlist()

    return {
      edit,
      add,
      read,
    }
  },
})
</script>

<style scoped>
.actions {
  color: #667C8D;;
}
</style>
