<template>
  <div class="df-middle mt-8">
    <div class="s-item" v-for="(item, index) in 5" :key="index" @click="handlePoint(item)">
      <Pic
      :src="item > starNum ? 'home/starEmpty': 'home/starSolid'"
      width="32" height="32"
      class="mr-16" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
export default defineComponent({
  name: 'TheScoreItem',
  components: {
    Pic,
  },
  props: {
    score: {
      type: Number,
      default: 0,
    },
  },
  emits: ['change'],
  setup (props, ctx) {
    const starNum = shallowRef(props.score)

    const handlePoint = (index: number) => {
      starNum.value = index
      ctx.emit('change', index)
    }
    return {
      starNum,
      handlePoint,
    }
  },
})
</script>

<style>

</style>
