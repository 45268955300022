/**
 * @author ayou[beica1@outook.com]
 * @date 2022/1/6 11:28
 * @description
 *   home.ts of WeTrade
 */
import { openDialog } from '@/components/popup/popup'
import { market } from '@/config'
import TheQuizBoard from '@/modules/home/components/TheQuizBoard.vue'
import {
  readQA,
  RookieTask,
  RookieTaskType,
  Task,
  TaskType,
  EventItem,
  entryLive,
  readEventQa,
  checkScore,
} from '@/modules/home/home.api'
import ForFormalTrade from '@/modules/home/missions/ForFormalTrade.vue'
import ForRookie from '@/modules/home/missions/ForRookie.vue'
import ForStock from '@/modules/home/missions/ForStock.vue'
import ForThePure from '@/modules/home/missions/ForThePure.vue'
import ForReport from '@/modules/home/missions/ForReport.vue'
import ForDoubleTrade from '@/modules/home/missions/ForDoubleTrade.vue'
import GuideOrderDialog from '@/modules/market/GuideOrderDialog.vue'
import { readMostActiveProduct } from '@/modules/market/market'
import { readMarketList } from '@/modules/market/market.api'
import { MarketState } from '@/modules/market/market.types'
// import { readProducts } from '@/modules/trade/trade.api'
import { getProductsByCode } from '@/modules/trade/trade'
import { AccountType } from '@/state'
import { changeAccountType } from '@/state/accountType'
import { YesOrNo } from '@/types'
import * as R from 'ramda'
import { h } from 'vue'
import { useRouter } from 'vue-router'
import TheLeverageDialog from '@/modules/home/components/TheLeverageDialog.vue'
import TheEvaluateDialog from '@/modules/home/notification/TheEvaluateDialog.vue'
import { readLever } from '@/pages/leverage/leverage.api'
import router from '@/router'

export const produceMission = (task: Task) => {
  switch (task.type) {
    case TaskType.DEPOSIT$50:
      return ForThePure
    case TaskType.ROOKIE:
      return h(ForRookie, {
        tasks: task.noviceTasks,
        task: R.find(R.propEq('status', YesOrNo.NO), task.noviceTasks) ?? {},
      })
    case TaskType.STOCK:
      return h(ForStock, {
        stocks: task.stockInfoForms,
      })
    case TaskType.FORMAL_TRADE:
      return ForFormalTrade
    case TaskType.REPORT:
      return h(ForReport, {
        days: task.days || 0,
      })
    case TaskType.DOUBLE:
      return h(ForDoubleTrade, {
        info: task,
      })
    default:
      return null
  }
}

async function guideToOrder (cb?: Fn) {
  changeAccountType(AccountType.DEMO)

  const most = await readMostActiveProduct()

  if (!most) return

  const { product, symbol } = most

  openDialog(GuideOrderDialog, {
    symbol: symbol.realTimeMicroQuotationBean,
    tips: symbol.tips,
    product,
    wrapperClass: 'center',
    onComplete () {
      if (typeof cb === 'function') {
        cb()
      }
    },
  })
}

async function startQuiz (cb?: Fn) {
  const qaList = await readQA()

  if (!qaList?.length) {
    return Promise.reject('question list is empty!')
  }

  openDialog(TheQuizBoard, {
    qaList,
    wrapperClass: 'center',
    taskType: true,
    onComplete () {
      if (typeof cb === 'function') {
        cb()
      }
    },
  }, {
    transition: 'slide-down',
  })
}

async function setLeverage (cb?: Fn) {
  const res = await readLever()
  let index = 0
  res.forEach(item => {
    if (item.selected === 1) {
      index = item.type - 1
    }
  })
  openDialog(TheLeverageDialog, {
    lever: index,
    onClose () {
      cb && cb()
    },
  })
}

export const usePerformTask = (cb?: Fn) => {
  const router = useRouter()

  const tryQuickPlay = () => {
    // changeAccountType(AccountType.REAL)
    return router.push('/trade/fast')
  }

  const pageToTrade = () => {
    changeAccountType(AccountType.REAL)
    // return router.push('/trade')
    return router.push('/in')
  }

  return async (task: RookieTask | null) => {
    if (!task) return Promise.reject('Task is NULL or not available for now!')
    switch (task.tag) {
      case RookieTaskType.DEMO_ORDER:
        return guideToOrder(cb)
      case RookieTaskType.QUIZ:
        return startQuiz(cb)
      case RookieTaskType.EXCHANGE_COUPON:
        return router.push('/membership/store')
      case RookieTaskType.FAST_TRADE_ORDER:
        return tryQuickPlay()
      case RookieTaskType.FIRST_DEPOSIT:
        return pageToTrade()
      case RookieTaskType.LEVERAGE:
        return setLeverage(cb)
    }
  }
}

export const eventEnter = (item: EventItem) => {
  if (item.eventType === 1) {
    router.push('/cardGame')
  } else if (item.eventType === 4) {
    startQuizById(item.eventId)
  } else if (item.eventType === 5) {
    getLiveInfo(item.channelId)
  } else if ([2, 3, 6, 7, 8].indexOf(item.eventType) > -1) {
    router.push('/eventsList/bigNews?id=' + item.eventId + '&type=' + item.eventType)
  }
}

// 获取直播信息
const getLiveInfo = async (channelId: number) => {
  const res = await entryLive({
    channelId,
  })
  const url = `/live/${res.channelId}/${res.chatRoomId}?poster=${res.backgroundPicture}` +
  `&user=${res.onlineNumber}&tip=${res.announcement}&levelLimit=${res.liveLevel}&time=${res.liveTimeTwo}&desc=${res.tips}`
  router.push(url)
}

// 打开答题弹窗
async function startQuizById (id: number) {
  const qaList = await readEventQa({
    eventId: id,
  })

  if (!qaList?.length) {
    return Promise.reject('question list is empty!')
  }
  openDialog(TheQuizBoard, {
    qaList,
    taskType: false,
    eventId: id,
    wrapperClass: 'center',
  }, {
    transition: 'slide-down',
  })
}

export const checkEvaluateStatus = () => {
  checkScore().then(res => {
    if (res.status === 1) {
      openDialog(TheEvaluateDialog)
    }
  })
}
