<template>
<router-link #="{ navigate }" :to="`/symbol/${marketItem.code}`" custom>
  <tr @click="navigate">
    <td>
      <Pic :src="marketItem.icon" width="32" height="32" style="border-radius: 50%" />
    </td>
    <td style="max-width: 80px">
      <p class="f-medium df-middle">
        {{marketItem.code}}
        <Icon v-if="marketItem.specialIdentification===1||marketItem.specialIdentification===2" sm name="wt_mark" class="ml-4" style="color: var(--color-primary);" />
      </p>
      <p class="f-xs c-tip">{{marketItem.tips}}</p>
    </td>
    <td class="f-xs c-text f-medium t-left">
      <t as="p" path="market_35" v-if="marketItem.dataType">MACD indicator</t>
      <p v-else>
        <t path="market_34">Trend indicator</t>
        <Icon name="membership" class="c-asst" sm />
      </p>
    </td>
    <td class="t-right">
      <div v-if="level >= 1 || marketItem.dataType">
        <t :path="isRise ? 'market_36' : 'market_37'" class="c-success f-nm f-medium"
          :class="{'c-danger': !isRise}">
          {{isRise ? 'Bullish' : 'Bearish'}}
        </t>
        <Icon :name="isRise ? 'up' : 'down'" class="c-success" sm :class="{'c-danger': !isRise}" />
      </div>
      <p v-else class="font-nm c-tip">******</p>
      <t :path="isLongTerm ? 'market_38' : 'market_39'" as="p" class="f-sm c-text">
        {{isLongTerm ? 'in 1-day chart' : 'in 30-mins chart'}}
      </t>
    </td>
  </tr>
</router-link>
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import state from '@/state'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTermTrending',
  components: { Icon, Pic },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
    type: String,
  },
  setup (props) {
    const isRise = computed(() => props.marketItem.type === TradeDirection.BUY)
    const isLongTerm = computed(() => props.type === 'Long')

    return {
      isRise,
      isLongTerm,
      level: computed(() => state.account.levelModel.level),
    }
  },
})
</script>

<style scoped>
.double{
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  height: 12px;
  width: 12px;
}
</style>
