
import { computed, defineComponent, onActivated, onBeforeUnmount, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Icon from '@/components/Icon.vue'
import { readChangeProduct, ChangeProductItem } from '@/modules/market/market.api'
import datafeed from '@/common/datafeed'
import * as R from 'ramda'
import Swipe from '@/components/Swipe.vue'

export default defineComponent({
  name: 'TheTopMovers',
  components: {
    Pic,
    Icon,
    CurrentQuote,
    RealTimeQuote,
    Swipe,
  },
  setup () {
    const tops = shallowRef<ChangeProductItem[][]>([])
    const empty = computed(() => tops.value.length === 0)

    const getList = async () => {
      const resp = await readChangeProduct()
      const arr: ChangeProductItem[][] = []

      R.splitEvery(3, resp).map(item => {
        arr.push(item)
      })

      tops.value = arr
      datafeed.subscribe(R.pluck('code', resp), true)
    }

    onActivated(getList)

    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    return {
      empty,
      tops,
    }
  },
})
