/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   AddToWatchList.vue of WeTrade
*/
<template>
  <FullScreen class="px-16 df-col">
    <PageHeader>
      <template #title>
        <t path="addtowatchlist_2">Add symbols to watchlist</t>
      </template>
    </PageHeader>
    <Scroller css-only class="flex-1">
      <SymbolList #="{symbol}" @save="$emit('save')">
        <TheWatchSymbolButton :market-item="symbol" />
      </SymbolList>
    </Scroller>
  </FullScreen>
</template>

<script lang="ts">
import SymbolList from '@/modules/market/SymbolList.vue'
import Scroller from 'common/providers/Scroller.vue'
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import TheWatchSymbolButton from '@/modules/market/components/TheWatchSymbolButton.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddToWatchList',
  components: { SymbolList, Scroller, TheWatchSymbolButton, PageHeader, FullScreen },
})
</script>
