/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   public.ts of WeTrade
 */
import Home from '@/modules/home/Home.index.vue'
import Market from '@/modules/market/Market.index.vue'
import Symbol from '@/modules/market/Symbol.vue'
import Trade from '@/modules/trade/Trade.index.vue'
import Prime from '@/pages/prime/Prime.index.vue'
import Me from '@/modules/user/Me.vue'
import markPrivate from '@/router/markPrivate'
import TabPage from '@/views/TabPage.vue'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'

const publicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: TabPage,
    children: [
      {
        path: '',
        name: 'home',
        alias: '/home',
        component: Home,
        meta: {
          routeIndex: 0,
        },
      },
      {
        path: 'market',
        name: 'market',
        component: Market,
        meta: {
          routeIndex: 1,
        },
      },
      markPrivate({
        path: 'trade',
        name: 'trade',
        component: Trade,
        meta: {
          routeIndex: 2,
        },
      }),
      markPrivate({
        name: 'prime',
        path: 'prime',
        component: Prime,
        meta: {
          routeIndex: 3,
          immersive: '#383E58',
          routeFallback: '/prime/detail',
        },
      }),
      {
        path: '/me',
        name: 'me',
        component: Me,
        meta: {
          routeIndex: 4,
        },
      },
    ],
  },
  {
    path: '/symbol/:code',
    name: 'symbol',
    component: Symbol,
  },
  {
    path: '/symbolRotate/:code',
    name: 'symbolRotate',
    component: () => import('@/modules/market/symbolRotate/SymbolRotate.vue'),
    meta: {
      horizontalScreen: true,
      noCache: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login/Login.index.vue'),
    meta: {
      roamOnly: true,
      routeIndex: -1,
    },
  },
  {
    path: '/loginSwich/:index',
    name: 'loginSwich',
    component: () => import('@/pages/login/Login.vue'),
    meta: {
      roamOnly: true,
      routeIndex: -1,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/pages/register/Register.vue'),
    meta: {
      roamOnly: true,
      routeIndex: 0,
    },
  },
  {
    path: '/reset',
    name: 'reset',
    component: () => import('@/pages/reset/Reset.vue'),
    meta: {
      routeIndex: -1,
    },
  },
  {
    path: '/bindMobile',
    name: 'bindMobile',
    component: () => import('@/pages/reset/Reset.vue'),
    props: {
      bindMode: true,
    },
  },
  {
    path: '/qa',
    name: 'help',
    component: () => import('@/pages/help/Help.vue'),
  },
  {
    path: '/common/link',
    name: 'link',
    component: () => import('@/pages/Link.vue'),
    props: (to: RouteLocationNormalized) => to.query,
  },
  {
    path: '/advice/:id',
    name: 'advice',
    component: () => import('@/modules/community/analysis/Advice.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/news/:id',
    name: 'news',
    component: () => import('@/modules/community/news/Detail.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/topic/:topic',
    name: 'topic',
    component: () => import('@/modules/community/communities/Topic.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/post/:postId',
    name: 'post',
    component: () => import('@/modules/community/communities/Post.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/membership/about',
    name: 'about',
    component: () => import('@/modules/membership/AboutMember.vue'),
    meta: {
      immersive: '#326BFE',
    },
  },
  {
    path: '/setting/locale',
    name: 'locale',
    component: () => import('@/pages/Locale.vue'),
  },
  {
    path: '/link/invite',
    name: 'shareLink',
    component: () => import('@/pages/invite/InviteLink.vue'),
    meta: {
      roamOnly: true,
    },
  },
  {
    path: '/link/profit',
    name: 'profitLink',
    component: () => import('@/pages/invite/ShareLink.vue'),
    meta: {
      roamOnly: true,
    },
  },
  {
    path: '/eventsList',
    name: 'eventsList',
    component: () => import('@/modules/home/EventsList.vue'),
  },
  {
    path: '/eventsList/bigNews',
    name: 'bigNews',
    component: () => import('@/pages/bigNews/BigNews.vue'),
  },
  {
    name: 'primeDetail',
    path: '/prime/detail',
    component: () => import('@/pages/prime/Detail.vue'),
    meta: {
      immersive: '#383E58',
    },
  },
  {
    name: 'technicalAnalytics',
    path: '/prime/tc',
    component: () => import('@/pages/prime/components/TheTCPage.vue'),
  },
  {
    name: 'emailSignup',
    path: '/signup/email',
    component: () => import('@/pages/register/RegisterWithEmail.vue'),
  },
  {
    name: 'emailLogin',
    path: '/login/email',
    component: () => import('@/pages/login/LoginWithEmail.vue'),
  },
  {
    name: 'lessons',
    path: '/help/lessons',
    component: () => import('@/pages/help/Lesson.vue'),
  },
  {
    name: 'aboutus',
    path: '/user/aboutus',
    component: () => import('@/pages/aboutus/AboutUs.vue'),
  },
  {
    name: 'aboutpc',
    path: '/user/aboutpc',
    component: () => import('@/pages/aboutpc/AboutPC.vue'),
  },
  {
    name: 'leverage',
    path: '/user/leverage',
    component: () => import('@/pages/leverage/Leverage.vue'),
  },
  {
    path: '/events/happyHoli',
    name: 'happyHoli',
    component: () => import('@/events/happyHoli/HappyHoli.index.vue'),
  },
  {
    path: '/profitRank',
    name: 'profitRank',
    component: () => import('@/pages/profitRank/ProfitRank.vue'),
  },
  {
    path: '/events/happyEaster',
    name: 'happyEaster',
    component: () => import('@/events/happyEaster/HappyEaster.index.vue'),
  },
  {
    path: '/cup/wt',
    name: 'weTradeCup',
    component: () => import('@/modules/weTradeCup/WeTradeCup.index.vue'),
  },
  {
    path: '/cup/wt/detail',
    name: 'weTradeCupDetail',
    component: () => import('@/modules/weTradeCup/PrizeDetail.vue'),
  },
  {
    path: '/symbol/setIndicators',
    name: 'setIndicators',
    component: () => import('@/pages/setIndicators/SetIndicators.vue'),
  },
  {
    path: '/in/visaMasterIntro',
    name: 'visaMasterIntro',
    component: () => import('@/pages/visaMasterIntro/VisaMasterIntro.vue'),
  },
  {
    path: '/events/firstDeposit',
    name: 'firstDeposit',
    component: () => import('@/events/firstDeposit/FirstDeposit.vue'),
  },
  {
    path: '/trade/statement',
    name: 'incomeStatement',
    component: () => import('@/pages/statement/IncomeStatement.vue'),
  },
  {
    path: '/trade/tradeStyle',
    name: 'tradeStyle',
    component: () => import('@/pages/tradingStyle/TradingStyle.vue'),
  },
  {
    path: '/liveForQuickPlay/:channelId/:chatRoomId',
    name: 'liveForQuickPlay',
    component: () => import('@/pages/liveForQuickPlay/LiveForQuickPlay.vue'),
  },
  {
    path: '/events/happyDiwali',
    name: 'happyDiwali',
    component: () => import('@/events/happyDiwali/HappyDiwali.index.vue'),
  },
  {
    path: '/events/ganeshChaturthi',
    name: 'ganeshChaturthi',
    component: () => import('@/events/ganeshChaturthi/GaneshChaturthi.index.vue'),
  },
  {
    path: '/events/valentine',
    name: 'valentine',
    component: () => import('@/events/valentine/Valentine.index.vue'),
  },
]

export default publicRoutes
