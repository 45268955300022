
import TheMarketList from '@/modules/market/components/TheMarketList.vue'
import TheStockList from '@/modules/market/components/TheStockList.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheStockCategory',
  components: { TheStockList, TheMarketList },
  props: {
    read: {
      type: Function,
      required: true,
    },
  },
})
