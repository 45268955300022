/**
 *  chart.ts of project wetrade
 *  @date 2022/5/18 17:28
 *  @author 阿佑[ayooooo@petalmail.com]
 */
import { AlertState, drawLine, Line, LineType, removeLine } from '@/modules/market/market.api'
import { DrawingState } from 'happychart/core/AbstractDrawing'
import { DrawingOptions } from 'happychart/drawing/Drawing'
import { IDrawing } from 'happychart/interface/IDrawing'
import IChart, { ChartPointer } from 'happychart/interface/IChart'
import * as R from 'ramda'
import { shallowRef } from 'vue'

export const useDrawing = (modify?: (state: boolean) => void) => {
  const lines: Record<string, Line> = {}

  let activeDrawing: IDrawing | null = null

  const lineTypes = ['', 'line', 'segment', 'up', 'down', 'text']

  const drawingBindings = shallowRef<{ y: number, x?: number, alertOn: boolean | null, color: string | null, type?: number, currentMsg?: string }>({
    color: '#000',
    y: 0,
    alertOn: false,
  })

  const emptyLines = (chart: IChart | null) => {
    for (const id of R.keys(lines)) {
      chart?.drawings.remove(id)
      delete lines[id]
    }
  }

  const makeFormatLabel = (alert = false) => (c: number | string) => (alert ? '🔔' : '') + c

  const processDrawingState = (drawing: IDrawing, state: DrawingState, points?: ChartPointer[]) => {
    const line = lines[drawing.id]

    activeDrawing = drawing

    if (state === DrawingState.ACTIVE) {
      drawingBindings.value = {
        y: (points as ChartPointer[])[0].y,
        x: (points as ChartPointer[])[0].x,
        alertOn: line.type !== LineType.LINE ? null : line.warning === AlertState.ON,
        color: line.color,
        type: line.type,
        currentMsg: line.msg,
      }
    } else if (state === DrawingState.UPDATED) {
      return false
    }
  }

  /**
   * 绘制已有的直线
   * @param chart
   * @param ls
   */
  const draw = (chart: IChart | null, ls: Line[]) => {
    emptyLines(chart)

    R.map(l => {
      const drawing = chart?.drawings.add({
        type: lineTypes[l.type] as DrawingOptions['type'],
        lineColor: l.color,
        readonly: true,
        formatLabel: makeFormatLabel(l.warning === AlertState.ON),
        point: {
          t: 0,
          c: l.horizontalLinePrice,
        },
        points: [
          {
            c: l.slashStartPrice,
            t: l.slashStartTime,
          },
          {
            c: l.slashEndPrice,
            t: l.slashEndTime,
          },
        ],
        textPoint: [{
          t: l.textTime,
          c: l.textPrice,
        }],
        text: l.msg,
        arrowPoint: [{
          t: l.arrowTime,
          c: l.arrowPrice,
        }],
        ardir: lineTypes[l.type] as DrawingOptions['type'],
      }, processDrawingState)

      if (drawing) lines[drawing.id] = l
    }, ls)
  }

  const style = (color: string) => {
    if (activeDrawing) {
      const line = lines[activeDrawing.id]

      if (line) {
        line.color = color
        drawLine(line).then(() => {
          activeDrawing?.style(color)
        })
      }
    }
  }

  const toggleAlert = (done: () => void) => {
    if (activeDrawing) {
      const line = lines[activeDrawing.id]

      if (line) {
        line.warning = line.warning === AlertState.OFF ? AlertState.ON : AlertState.OFF
        drawLine(line).then(() => {
          activeDrawing?.updateLabel(t => line.warning === AlertState.ON ? `🔔${t}` : t.replace('🔔', ''))
          drawingBindings.value = {
            ...drawingBindings.value,
            alertOn: line.warning === AlertState.ON,
          }
          done()
        })
      }
    }
  }

  const remove = (chart: IChart | null) => {
    if (activeDrawing) {
      const line = lines[activeDrawing.id]

      if (line) {
        removeLine({ id: line.id }).then(() => {
          delete lines[line.id]
          chart?.drawings.remove(activeDrawing!.id)
          modify?.(false)
        })
      }
    }
  }

  return {
    drawingBindings,
    draw,
    style,
    toggleAlert,
    remove,
  }
}
