
import TheBanner from '@/modules/home/components/TheBanner.vue'
import TheHomeRecommend from '@/modules/home/components/TheRecommend.vue'
import TheNews from '@/modules/home/components/TheNews.vue'
import TheEntries from '@/modules/home/components/TheEntries.vue'
import TheEvents from '@/modules/home/components/TheEvents.vue'
import TheBuoy from '@/modules/home/components/TheBuoy.vue'
import TheMissionCard from '@/modules/home/components/TheMissionCard.vue'
import TheLiveEntran from '@/modules/home/components/TheLiveEntran.vue'
import TheGoogleAuthVue from './components/TheGoogleAuth.vue'
import { guideToPlaceOrder } from '@/modules/market/market'
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
// import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import { muteOnInspectMode } from '@/state/inspect'
import { defineComponent, onActivated, shallowRef, watch } from 'vue'
import Scroller from 'common/providers/Scroller.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import { readNewsAndAnalysis, NewsAndAnalysis } from '@/modules/home/home.api'
import { checkEvaluateStatus } from '@/modules/home/home'
import state from '@/state'
import { readLiveList } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import { useDailyBonus } from '@/modules/home/dailyBonus'
import TheFastPlayEntry from '@/pages/prime/components/TheFastPlayEntry.vue'

export default defineComponent({
  name: 'Home',
  components: {
    NothingOnInspectMode,
    TheNews,
    TheEvents,
    TheEntries,
    TheBanner,
    TheMissionCard,
    Scroller,
    TheBuoy,
    TheHomeRecommend,
    TheLiveEntran,
    TheGoogleAuthVue,
    TheFastPlayEntry,
  },
  setup () {
    const showMissionCard = shallowRef(true)

    const liveInfo = shallowRef()

    const { loaded, refresh, loadMore } = useLoadMore<NewsAndAnalysis, NewsAndAnalysis[]>(
      readNewsAndAnalysis, 10, (resp) => resp)
    refresh()

    muteOnInspectMode(guideToPlaceOrder)

    watch(() => state.login, () => {
      if (state.login) {
        checkEvaluateStatus()
      }
    }, {
      immediate: true,
    })

    const checkLiveList = () => {
      readLiveList().then(res => {
        for (let i = 0; i < res.length; i++) {
          if (res[i].channelStatus === 1) {
            liveInfo.value = res[i]
            break
          }
        }
      })
    }

    onActivated(() => {
      if (state.login) {
        checkEvaluateStatus()
      }
      checkLiveList()
      useDailyBonus()
    })

    return {
      showMissionCard,
      loaded,
      liveInfo,
      loadMore,
      refresh (done: () => void) {
        refresh().then(done)
      },
    }
  },
})
