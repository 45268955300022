<template>
<div class="pb-32">
  <t as="p" path="home_58" class="t-center f-xxl f-bold">Trade More, Profit More</t>
  <div class="d-f mt-16 px-20">
    <t as="p" path="home_59"
      :args="{last: '$' + div(info.amount, format.moneyFactor),
      this: '$' + div(info.targetAmount, format.moneyFactor),
      coupon: '$' + div(info.voucherAmount, format.moneyFactor)}"
      class="mr-16" style="line-height: 20px;">
      Your average daily trade amount last week is
      <money :v="info.amount"></money>.
      Double it to <money :v="info.targetAmount"></money> this week to get a <money :v="info.voucherAmount"></money> coupon!
    </t>
    <div class="df-middle coupon-bg mt-12">
      <money class="c-white f-bold" style="font-size: 21px"
        :v="info.voucherAmount">
      </money>
    </div>
  </div>
  <div class="mt-wrap df-between df-middle br-lg px-16">
    <div class="pro-wrap br-lg d-f flex-1 px-8 py-4">
      <div class="main-bg br-sm flex-1">
        <div class="main-pro br-sm" :style="`width: ${persent}%;`"></div>
      </div>
    </div>
    <p class="f-bold ml-24 df-middle">
      <t path="home_60">This week:</t>
      <span class="c-success ml-4">
        <money :v="info.averateAmount"></money>
      </span>
    </p>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, onActivated, shallowRef, watch } from 'vue'
import { mul, div } from 'essential/tools/math'
import { format } from '@/config'

export default defineComponent({
  name: 'ForDoubleTrade',
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const persent = shallowRef(0)
    watch(() => props.info, () => {
      persent.value = mul(div(props.info.averateAmount, props.info.targetAmount), 100)
      persent.value = persent.value > 100 ? 100 : persent.value
    }, {
      immediate: true,
      deep: true,
    })
    return {
      persent,
      format,
      div,
    }
  },
})
</script>

<style scoped lang="scss">
.mt-wrap{
  background: var(--color-white);
  height: 48px;
  margin-top: 24px;
  .pro-wrap{
    border: 1px solid #00C756;
    width: 134px;
    .main-bg{
      width: 118px;
      background: #DAF6E6;
      height: 16px;
      .main-pro{
        background: #00B167;
        height: 16px;
      }
    }
  }
}
.coupon-bg{
  min-width: 80px;
  max-width: 80px;
  height: 56px;
  background-image: url(/img/home/coupon_main@2x.png);
  background-repeat: no-repeat;
  background-size: 100%;
  padding-left: 27px;
}
</style>
