/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/9
* @description
*   Tab.vue of WeTrade
*/
<template>
  <div class="tab df-middle">
    <div v-for="tab in tabs" :key="tab.path" class="flex-1 t-center f-xs"
         :class="{'p-r': tab.labelDefault === 'membership'}"
    >
      <component v-if="tab.custom" :is="tab.component" :tab="tab" :active="isMatch(tab.path)" />
      <template v-else>
        <router-link :to="tab.path[0]">
          <TransitionPic :path="`${tab.icon}`" :active="isMatch(tab.path)" />
          <t
            :class="isMatch(tab.path) ? ['c-primary', 'f-medium'] : []" :path="tab.label" as="p"
          >
            {{ tab.labelDefault }}
          </t>
        </router-link>
        <RemindPlay v-if="tab.labelDefault === 'membership'" />
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { isInspectMode } from '@/state/inspect'
import { flag } from '@/config'
import TransitionPic from '@/views/appTab/TransitionPic.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import RemindPlay from '@/modules/fastTrade/components/RemindPlay.vue'

const tabs = [
  {
    label: 'tab_2',
    path: ['/', '/home'],
    icon: 'home',
    labelDefault: 'Home',
  }, {
    label: 'tab_3',
    path: ['/market'],
    icon: 'market',
    labelDefault: 'Market',
  },
  {
    label: 'tab_4',
    path: ['/trade'],
    icon: 'trade',
    labelDefault: 'Trade',
  }, {
    label: 'tab_5',
    path: ['/prime'],
    icon: 'prime',
    labelDefault: 'Prime',
  }, {
    label: 'tab_6',
    path: ['/me'],
    icon: 'me',
    labelDefault: 'Me',
  },
]

export default defineComponent({
  name: 'AppTab',
  components: { RemindPlay, TransitionPic },
  setup () {
    const route = useRoute()

    const isMatch = (path: string) => path.indexOf(route.path) !== -1

    return {
      tabs: !isInspectMode()
        ? tabs
        : (
          flag.isLite
            ? tabs.filter(tab => tab.labelDefault !== 'Prime')
            : (
              flag.isIOS
                ? tabs.slice(0, 2)
                : tabs
            )
        ),
      isMatch,
    }
  },
})
</script>

<style scoped lang="scss">
.tab {
  color: var(--color-tip);
  background: var(--color-theme);
  //box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  border-top: 1px solid var(--color-light);
}
</style>
