/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/2
 * @description
 *   actions.ts of FastTrade
 */
import state, { initAccount, PrimeDescriber } from './index'

// 登入
export const login = () => {
  state.login = true
  state.guestMode = false
}

// 游客模式
export const roam = () => {
  state.login = false
  state.guestMode = true
}

export const resetAccount = () => {
  state.account = initAccount
}

// 登出
export const logout = () => {
  state.login = false
  state.guestMode = false
  resetAccount()
}

export const setPrime = (prime: PrimeDescriber) => {
  state.prime = prime
  state.prime.price = prime.prise ?? 0
}
