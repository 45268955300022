
import Icon from '@/components/Icon.vue'
import { events } from '@/config'
import { produceMission } from '@/modules/home/home'
import { readTasks, Task } from '@/modules/home/home.api'
import ForGuest from '@/modules/home/missions/ForGuest.vue'
import { isLogged } from '@/state'
import { off, on } from 'essential/tools/event'
import { Component, defineComponent, onActivated, onBeforeUnmount, shallowRef, watch } from 'vue'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheMissionCard',
  components: { Icon },
  emits: ['dismiss', 'show'],
  setup (props, ctx) {
    const useClose = shallowRef(true)
    const pending = shallowRef(false)
    const mission = shallowRef<Component | null>(null)
    let tasks: Task[] = [] // 可用任务列表
    const closedTasks: Task[] = [] // 关闭的任务列表

    watch(mission, () => {
      useClose.value = true
    })

    const dismiss = () => {
      ctx.emit('dismiss')
    }

    const showUp = () => {
      ctx.emit('show')
    }

    const displayTask = (tasks: Task[]) => {
      if (R.isEmpty(tasks)) {
        return dismiss()
      }

      showUp()

      const usableTask = tasks[0]

      pending.value = Boolean(usableTask.type)

      if (pending.value) {
        mission.value = produceMission(usableTask)
      }

      // 任务组件解析失败 不显示
      if (mission.value === null) {
        pending.value = false
      }
    }

    const next = () => {
      const task = tasks.shift()
      if (task) {
        closedTasks.push(task)
      }
      displayTask(tasks)
    }

    const refresh = () => {
      if (!isLogged()) {
        mission.value = ForGuest
        pending.value = true
      } else {
        readTasks().then(resp => {
          // 不显示用户关闭的task
          tasks = R.differenceWith(R.eqProps('type'), resp, closedTasks)
          displayTask(tasks)
        })
      }
    }

    const restoreAndRefresh = () => {
      pending.value = false
      mission.value = null
      refresh()
    }

    on([events.login, events.logout], restoreAndRefresh)

    onActivated(() => {
      refresh()
    })

    onBeforeUnmount(() => {
      off([events.login, events.logout], restoreAndRefresh)
    })

    return {
      refresh,
      pending,
      mission,
      useClose,
      next,
    }
  },
})
