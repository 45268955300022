
import Line from '@/components/Line.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DialogTemplate',
  components: { Line },
  props: {
    noTitle: Boolean,
    noControls: Boolean,
  },
  setup (props, ctx) {
    return {
      cancel () {
        ctx.emit('cancel')
        ctx.emit('close')
      },
      confirm () {
        ctx.emit('confirm')
        ctx.emit('close')
      },
    }
  },
})
