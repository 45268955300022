/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   Symbol.vue of WeTrade
*/
<template>
<PageWithHeader class="symbol df-col">
  <template #title>{{$route.params.code}}</template>

  <template #right>
    <div class="f-md watch_btn c-title" :class="{'c-tip': added}" @click="toggle">
      <t path="symbol_24" class="mr-4">Watchlist</t>
      <Donut v-if="progress" sm />
      <Icon v-else :name="added ? 'minus' : 'plus'" sm />
    </div>
  </template>

  <template #body>
    <Scroller class="body_wrp">
      <TheCryptoTips v-if="symbol" :code="symbol.code" />
      <TheSymbolMarketState :symbol="symbol" class="mb-8" />
      <TheChart />
      <TheAnalysisRatio />
      <TheMarketClosedTip v-if="isClosed" :symbol="symbol" />
      <DemoAccountTip />
      <GuideTarget name="position" :hide="!(actionGuideGotIt && positionCount !== 0)">
        <t custom #="{ td, t }">
          <NothingOnInspectMode>
            <WTTabs no-scroll nav-class="wt-nav" tab-class="wt-tab-nav">
              <TheProfile :tab="t('symbol_75') || 'Profile'" key="e" :code="$route.params.code" />
              <TheTechnicals :tab="TechnicalsBarVue" key="t" />
              <ThePositions :tab="BarWithBadge" key="c" class="p-16" :code="$route.params.code" />
              <TheCommissions :tab="OrderBarWithBadge" key="d" class="p-16"
                              :code="$route.params.code" />
            </WTTabs>
            <template #else>
              <WTTabs no-scroll nav-class="wt-nav" tab-class="wt-tab-nav">
                <TheProfile :tab="t('symbol_75') || 'Profile'" key="e" :code="$route.params.code" />
              </WTTabs>
            </template>
          </NothingOnInspectMode>
        </t>
        <template #top>
          <GuideContent
            abs
            style="top: -60px; z-index: 1"
            class="mx-16"
            :anchor-align="60"
            anchor-position="bottom"
          >
            <t path="symbol_69">
              Check the position here. Close the position when you are
              satisfied with the profit or when the trend changes.
            </t>
          </GuideContent>
        </template>
      </GuideTarget>
      <div v-if="showSpecialTip" class="x2-tip br-lg d-f p-8 pb-0">
        <Icon name="help" sm style="color: var(--color-asst);" />
        <div class="px-8">
          <t v-if="sepcialType===ESpecialType.DOUBLE"
             path="symbol_104" as="div"
             multiline #="{td}" custom :args="{code: $route.params.code}">
            <p class="c-white mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
          <t v-if="sepcialType===ESpecialType.REVERSE"
             path="symbol_105" as="div"
             multiline #="{td}" custom :args="{code: $route.params.code, origincode: $route.params.code.replace('INV', '')}">
            <p class="c-white mb-8" v-for="(p, index) in td" :key="index">{{p}}</p>
          </t>
        </div>
        <Icon name="close"
              style="background-color:var(--color-white);border-radius: 50%; height:24px;width: 24px;"
              @click="handleCloseTip" />
      </div>
    </Scroller>
  </template>

  <template #footer>
    <NothingOnInspectMode :and="() => flag.isIOS">
      <TheSymbolActions class="sy-bottom" :symbol="symbol" @gotIt="readActionGuide" />
    </NothingOnInspectMode>
  </template>
</PageWithHeader>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import GuideContent from '@/components/userGuide/GuideContent.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import { cachePage, cancelCachePage } from '@/features/dynamicAlive'
import TheChart from '@/modules/market/components/chart/TheChart.vue'
import TheCryptoTips from '@/modules/market/components/TheCryptoTips.vue'
import { MarketState } from '@/modules/market/market.types'
import { usePositionCount } from '@/modules/user/user'
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import CommissionBarWithBadge from '@/views/CommissionBarWithBadge.vue'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import PositionBarWithBadge from '@/views/PositionBarWithBadge.vue'
import WTTabs from '@/components/Tabs.vue'
import TheMarketClosedTip from '@/modules/market/components/TheMarketClosedTip.vue'
import TheSymbolActions from '@/modules/market/components/TheSymbolActions.vue'
import TheSymbolMarketState from '@/modules/market/components/TheSymbolMarketState.vue'
import { useQuote, useToggleState } from '@/modules/market/market'
import TheCommissions from '@/modules/trade/components/TheCommissions.vue'
import ThePositions from '@/modules/trade/components/ThePositions.vue'
import Donut from 'common/loading/Donut.vue'
import Scroller from 'common/providers/Scroller.vue'
import { computed, defineComponent, h, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import TheProfile from '@/modules/market/components/TheProfile.vue'
import TheAnalysisRatio from '@/modules/market/components/TheAnalysisRatio.vue'
import { checkIsSpecial, ESpecialType } from '@/modules/market/market.api'
import { flag, keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import TheTechnicals from './components/TheTechnicals.vue'
import TechnicalsBar from '@/views/TechnicalsBar.vue'

export default defineComponent({
  name: 'Symbol',
  computed: {
    flag () {
      return flag
    },
  },
  components: {
    Scroller,
    TheCryptoTips,
    NothingOnInspectMode,
    Donut,
    Icon,
    TheChart,
    PageWithHeader,
    GuideContent,
    GuideTarget,
    DemoAccountTip,
    TheMarketClosedTip,
    TheSymbolMarketState,
    TheCommissions,
    ThePositions,
    WTTabs,
    TheSymbolActions,
    TheProfile,
    TheAnalysisRatio,
    TheTechnicals,
  },
  beforeRouteLeave (to) {
    if (to.query.f === '1') {
      cachePage('Symbol')
    } else {
      cancelCachePage('Symbol')
    }
  },
  setup () {
    const route = useRoute()
    const symbol = useQuote(route.params.code as string, true)
    const showSpecialTip = shallowRef(false)
    const specialType = shallowRef(ESpecialType.NORMAL)
    const actionGuideGotIt = shallowRef(false)

    const haveShow = localGet(keymap.guide.specialTips) ? (localGet(keymap.guide.specialTips) as string).split(',') : ['0', '0']
    checkIsSpecial({
      contract: route.params.code as string,
    }).then(res => {
      specialType.value = res.specialIdentification
      if (haveShow[specialType.value - 1] === '0') {
        showSpecialTip.value = true
      }
    })

    const handleCloseTip = () => {
      haveShow[specialType.value - 1] = '1'
      localSet(keymap.guide.specialTips, haveShow.join().toString())
      showSpecialTip.value = false
    }

    const { toggle, added, progress } = useToggleState(route.params.code as string)

    const BarWithBadge = h(PositionBarWithBadge, {
      code: route.params.code as string,
    })

    const OrderBarWithBadge = h(CommissionBarWithBadge, {
      code: route.params.code as string,
    })

    function readActionGuide () {
      actionGuideGotIt.value = true
    }

    const TechnicalsBarVue = h(TechnicalsBar)

    return {
      positionCount: usePositionCount(route.params.code as string),
      isClosed: computed(() => symbol.value?.isClosed === MarketState.CLOSED),
      symbol,
      BarWithBadge,
      OrderBarWithBadge,
      toggle,
      added,
      progress,
      showSpecialTip,
      handleCloseTip,
      sepcialType: specialType,
      actionGuideGotIt,
      ESpecialType,
      TechnicalsBarVue,
      readActionGuide,
    }
  },
})
</script>

<style lang="scss" scoped>
::v-deep(.wt-nav) {
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 15px;
}

::v-deep(.page__header) {
  position: fixed !important;
  z-index: 1;
}

.sy-bottom {
  position: fixed !important;
  bottom: 0;
  left: 0;
  width: 100%;
}

.sy-body {
  height: 100%;
  overflow-y: auto;
  scroll-behavior: smooth;

  .sy-content {
    padding-bottom: 56px;
  }
}

.body_wrp {
  padding-bottom: 56px;
}

.watch_btn {
  text-align: right;
  background: none;
  min-width: 80px;
  padding: 0;

  .icon {
    font-size: 12px;
    border-radius: 50%;
    border: 1px solid;
  }
}

.rotate {
  padding: 8px;
  position: fixed;
  top: 102px;
  right: 0;
}

.x2-tip{
  position: fixed;
  top: var(--page-header-height);
  left: 16px;
  background-color: rgba(24, 29, 36, 0.9);
  width: calc(100vw - 32px);
}
</style>
