import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { openDialog } from '@/components/popup/popup'
import TheDailyBonusList from '@/modules/home/notification/TheDailyBonusList.vue'
import { readDailyBonus } from '@/modules/home/home.api'
import * as R from 'ramda'
import { YesOrNo } from '@/types'
import { shallowRef } from 'vue'

const progress = shallowRef(false)

export const useDailyBonus = (everyTime = false) => {
  const today = (new Date(new Date().toLocaleDateString()).getTime()).toString()
  const showStatus = localGet(keymap.dailyBonus.isShowToday)
  const condition = everyTime ? true : (!showStatus || showStatus !== today)
  if (condition) {
    progress.value = true
    readDailyBonus().then(res => {
      if (!res) return
      let currIndex = R.findIndex(R.propEq('isCurrFlg', 1))(res.list)
      currIndex = currIndex === -1 ? 0 : currIndex
      const list = res.list.map((item, index) => {
        if (item.isCurrFlg === YesOrNo.YES) {
          item.participated = item.showFlg !== YesOrNo.YES
        } else {
          item.participated = index < currIndex
        }
        return item
      })
      progress.value = false
      openDialog(TheDailyBonusList, {
        wrapperClass: 'center',
        info: {
          dayEnduranceAmount: res.dayEnduranceAmount,
          list: list,
          participated: list[currIndex].showFlg !== YesOrNo.YES,
        },
        onClose () {
          localSet(keymap.dailyBonus.isShowToday, today)
        },
      })
    })
  }
}

export const loading = progress
