/**
* @description DefaultSkeleton.vue
*/
<template>
  <div v-once class="skeleton-sample">
    <div
      v-for="item in 2" :key="item"
      class="mt-12 skeleton-bar"
      :style="{height: `${getRandomHeight}px`}"
    ></div>
    <div class="d-f mt-12">
      <div
        v-for="item in 2" :key="item"
        :class="{[`flex-${getRandomCol}`]: true}"
        class="mr-16 skeleton-bar" style="height: 10px"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent, computed } from 'vue'
import { randomInt } from '@/common/common'

const height = [10, 20]
const col = [1, 2]

export default defineComponent(
  {
    name: 'DefaultSkeleton',
    setup () {
      const getRandomHeight = computed(() => R.apply(randomInt, height))
      const getRandomCol = computed(() => R.apply(randomInt, col))

      return {
        getRandomHeight,
        getRandomCol,
      }
    },
  },
)
</script>

<style scoped lang="scss">
.skeleton-sample:not(:first-child) {
  padding-top: 12px;
}

.skeleton-bar {
  background: var(--color-light);
}
</style>
