
import { useAccounts } from '@/state/accountType'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheAccountSwitch',
  setup () {
    const { accounts, change, index } = useAccounts()

    return {
      accounts,
      index,
      change,
    }
  },
})
