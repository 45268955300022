<template>
<DialogTemplate>
  <template #title>
    <t path="notification_79">Bonus have been issued!</t>
  </template>

  <div class="df-col df-middle df-center pt-16 pb-32 px-16">
    <Pic src="events/firstDeposit/gift" width="88" height="88" />
    <t path="notification_80" class="mt-16">Prime and Beginner Pack hace been issued. Please check your email</t>
  </div>

  <template #controls>
    <button class="flex-1 btn block primary" @click="$emit('close')">
      <t path="notification_81" custom #="{td}">
        {{ td || 'OK' }}
      </t>
    </button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'FirstDepositDialog',
  components: {
    DialogTemplate,
    Pic,
  },
  setup () {
    return {}
  },
})
</script>

<style scoped>

</style>
