<template>
<div class="pb-32">
  <t as="p" path="home_51" class="t-center f-xxl f-bold">Newbie’s First 14 days</t>
  <div class="d-f mt-16 px-20">
    <t as="p" path="home_52" class="mr-16">Use WeTrade more than 8 days during the first 14 days after registration to get $2 coupon</t>
    <Pic src="home/coupon$2" width="80" height="56" />
  </div>
  <div class="s-days-wrap df-between df-middle br-lg px-16">
    <div class="s-days br-lg df-middle">
      <div class="d-item flex-1" :class="{'active': days>=item}" v-for="(item, index) in 14" :key="index">
      </div>
    </div>
    <p class="f-bold">
      <span class="c-success">{{ days }}</span>
      <span class="c-theme-reverse">/14 <t path="home_53">days</t></span>
    </p>
  </div>
</div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForStock',
  components: { Pic },
  props: {
    days: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.s-days-wrap{
  background: var(--color-theme);
  height: 48px;
  margin-top: 24px;
  .s-days{
    border: 1px solid #00C756;
    height: 24px;
    padding: 0 6px 0 8px;
    .d-item{
      width: 10px;
      height: 16px;
      background: #DAF6E6;
      border-radius: 3px;
      margin-right: 2px;
      &.active{
        background: #00B167;
      }
    }
  }
}
</style>
