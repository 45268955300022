
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'

export default defineComponent({
  name: 'ServiceNotice',
  components: { DialogTemplate },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
})
