/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/26
* @description
*   TradeResult.vue of WeTrade
*/
<template>
  <ResultDialogTemplate>
    <template v-if="resText[0][textIndex]" #title>
      <t :path="isDemoAccount ? resText[0][textIndex].title : resText[1][textIndex].title">
        Trade result
      </t>
    </template>
    <template v-else #title>Trade result</template>
    <t v-if="resText[0][textIndex]" as="p"
       :path="isDemoAccount ? resText[0][textIndex].content : resText[1][textIndex].content"
       :args="{amount: `${prop('weight', order)} ${prop('unit', order)}`, symbol: prop('code', order), price: prop('closePrice', order)}"
    >
      {{prop('weight', order)}} {{prop('unit', order)}} of {{prop('code', order)}}
      {{closeType[order.closeType]}} executed@
      <span class="f-bold">{{prop('closePrice', order)}}</span> .
    </t>
    <p v-else>{{prop('weight', order)}} {{prop('unit', order)}} of {{prop('code', order)}}
              {{closeType[order.closeType]}} executed@
      <span class="f-bold">{{prop('closePrice', order)}}</span> .</p>
    <template #controls>
      <router-link #="{navigate}" custom to="/me/history">
        <button class="flex-1 btn block" @click="$emit('close', navigate)">
          <t path="notification_31" custom #="{td}">
            {{td || 'Check'}}
          </t>
        </button>
      </router-link>
    </template>
  </ResultDialogTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { isDemoAccount } from '@/state/accountType'

export default defineComponent({
  name: 'TradeResult',
  components: { ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const resText = [
      [
        {
          title: 'notification_20',
          content: 'notification_21',
        }, {
          title: 'notification_22',
          content: 'notification_23',
        }, {
          title: 'notification_24',
          content: 'notification_25',
        }, {
          title: 'notification_26',
          content: 'notification_27',
        },
      ], [
        {
          title: 'notification_6',
          content: 'notification_7',
        }, {
          title: 'notification_8',
          content: 'notification_9',
        }, {
          title: 'notification_10',
          content: 'notification_11',
        }, {
          title: 'notification_12',
          content: 'notification_13',
        },
      ],
    ]

    return {
      isDemoAccount,
      closeType: ['', '', '', 'Manual', 'Take profit', 'Stop loss', 'Stop out', 'Auto close'],
      resText,
      textIndex: props.order.closeType - 4,
    }
  },
})
</script>
