/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   private.ts of WeTrade
 */
import markPrivate from '@/router/markPrivate'
import * as R from 'ramda'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { StudyType } from 'happychart/types'

const privateRoutes: Array<RouteRecordRaw> = R.map(markPrivate, [
  {
    path: '/in',
    name: 'deposit',
    component: () => import('@/modules/in/In.vue'),
    meta: {
      redirectWithCC: true,
    },
    children: [
      {
        path: '91',
        name: 'depositFor91',
        component: () => import('@/modules/in/Channel.91.vue'),
        meta: {
          redirectWithCC: false,
        },
      },
      {
        path: '63',
        name: 'depositFor63',
        component: () => import('@/modules/in/Channel.63.vue'),
        meta: {
          redirectWithCC: false,
        },
      },
      {
        path: ':cc',
        name: 'depositForCommon',
        component: () => import('@/modules/in/Channel.common.vue'),
        meta: {
          redirectWithCC: false,
        },
      },
    ],
  },
  {
    path: '/in/fill',
    name: 'fillInBaseForm',
    component: () => import('@/modules/in/FillInUp.vue'),
    meta: {
      redirectWithCC: true,
    },
    children: [
      {
        path: '63',
        name: 'fillInBaseFormFor63',
        component: () => import('@/modules/in/FillInUp.63.vue'),
        meta: {
          redirectWithCC: false,
        },
      },
      {
        path: ':cc',
        name: 'fillInBaseFormForCommon',
        component: () => import('@/modules/in/FillInUp.common.vue'),
        meta: {
          redirectWithCC: false,
        },
      },
    ],
  },
  {
    path: '/out',
    name: 'withdraw',
    component: () => import('@/modules/out/Out.vue'),
  },
  {
    path: '/out/verify',
    name: 'verify',
    component: () => import('@/modules/out/Verify.vue'),
  },
  {
    path: '/out/verify/ph',
    name: 'verifyWithID',
    component: () => import('@/modules/out/VerifyWithID.vue'),
  },
  {
    path: '/create/:code/:type',
    name: 'create',
    component: () => import('@/modules/trade/Create.vue'),
  },
  {
    path: '/edit/:code/:orderId',
    name: 'edit',
    component: () => import('@/modules/trade/Edit.vue'),
  },
  // {
  //   path: '/post/new',
  //   name: 'newPost',
  //   component: () => import('@/modules/community/communities/Create.vue'),
  // },
  {
    path: '/user/interest',
    name: 'interest',
    component: () => import('@/pages/interest/Interest.vue'),
  },
  {
    path: '/me/coupons',
    name: 'coupons',
    component: () => import('@/pages/coupons/Coupons.vue'),
  },
  {
    path: '/me/setting',
    name: 'setting',
    component: () => import('@/pages/setting/Setting.vue'),
  },
  {
    path: '/me/setting/nick',
    name: 'nickname',
    component: () => import('@/pages/setting/components/TheNickname.vue'),
  },
  {
    path: '/me/io',
    name: 'transaction',
    component: () => import('@/pages/transaction/Transactions.vue'),
  },
  {
    path: '/me/history',
    name: 'history',
    component: () => import('@/pages/history/History.vue'),
  },
  {
    path: '/out/bindBank',
    name: 'bindBank',
    component: () => import('@/modules/out/BindBankAccount.vue'),
  },
  {
    path: '/out/bindUpi',
    name: 'bindUpi',
    component: () => import('@/modules/out/BindUPIAccount.vue'),
  },
  {
    path: '/membership/level',
    name: 'level',
    component: () => import('@/modules/membership/LevelPoints.vue'),
  },
  {
    path: '/membership/tournament',
    name: 'tournament',
    component: () => import('@/modules/membership/tournament/Tournament.vue'),
  },
  {
    path: '/membership/level/points',
    name: 'points',
    component: () => import('@/modules/membership/Points.vue'),
  },
  {
    path: '/membership/store',
    name: 'store',
    component: () => import('@/modules/membership/PointsStore.vue'),
  },
  {
    path: '/membership/tournament/:tournamentId',
    name: 'participate',
    component: () => import('@/modules/membership/tournament/TournamentDetail.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/live/:liveId/:chatId',
    name: 'live',
    // component: () => import('@/modules/membership/Live.vue'),
    component: () => import('@/modules/membership/LiveV2.vue'),
    meta: {
      immersive: '#181D24',
    },
  },
  {
    path: '/membership/signal',
    name: 'signal',
    component: () => import('@/modules/membership/signal/Signal.vue'),
    children: [
      {
        path: '',
        alias: String(StudyType.TREND),
        name: String(StudyType.TREND),
        component: () => import('@/modules/membership/signal/Trend.vue'),
      }, {
        path: String(StudyType.TRENDPRO),
        name: String(StudyType.TRENDPRO),
        component: () => import('@/modules/membership/signal/TrendPro.vue'),
      },
    ],
  },
  {
    path: '/trade/fast',
    name: 'fastTrade',
    component: () => import('@/modules/fastTrade/FastTrade.index.vue'),
  },
  {
    path: '/share/invite',
    name: 'invite',
    component: () => import('@/pages/invite/InviteShare.vue'),
    meta: {
      immersive: '#326BFE',
    },
  },
  {
    path: '/trade/copy',
    name: 'copyTrade',
    component: () => import('@/modules/copyTrade/CopyTrade.index.vue'),
  },
  {
    path: '/trade/copy/:orderId',
    name: 'copyDetail',
    component: () => import('@/modules/copyTrade/Detail.vue'),
    props: (to: RouteLocationNormalized) => to.params,
  },
  {
    path: '/events/chrismas',
    name: 'chrismas',
    component: () => import('@/events/christmas/Christmas.index.vue'),
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/events/christmas/AddressEdit.vue'),
  },
  {
    path: '/cardGame',
    name: 'cardGame',
    component: () => import('@/events/cardGame/CardGame.vue'),
  },
  {
    path: '/cup/wt/my',
    name: 'myWTCup',
    component: () => import('@/modules/weTradeCup/MyPrize.vue'),
  },
  {
    path: '/me/setting/address',
    name: 'myAddress',
    component: () => import('@/pages/setting/AddressEdit.vue'),
  },
  {
    path: '/me/io/feedback',
    name: 'feedback',
    component: () => import('@/pages/transaction/Feedback.vue'),
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('@/pages/customerService/CustomerService.vue'),
  },
])

export default privateRoutes
