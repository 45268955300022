
import Pic from '@/components/Pic.vue'
import { defineComponent, shallowRef } from 'vue'
import { LiveRoom } from '@/modules/membership/member.api'
import router from '@/router'

export default defineComponent({
  name: 'TheLiveItem',
  components: { Pic },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const roomInfo = shallowRef<LiveRoom>(props.room as LiveRoom)
    const handleLiveLink = () => {
      if (roomInfo.value.channelType === 2) {
        const url = `/liveForQuickPlay/${roomInfo.value.channelId}` +
                    `/${roomInfo.value.chatRoomId}`
        router.push(url)
      } else {
        const url = `/live/${roomInfo.value.channelId}` +
                    `/${roomInfo.value.chatRoomId}` +
                    `?poster=${roomInfo.value.backgroundPicture}` +
                    `&user=${roomInfo.value.onlineNumber}` +
                    `&tip=${roomInfo.value.announcement}` +
                    `&levelLimit=${roomInfo.value.liveLevel}` +
                    `&time=${roomInfo.value.liveTimeTwo}` +
                    `&desc=${roomInfo.value.tips}` +
                    `&authorUrl=${roomInfo.value.authorUrl}` +
                    `&authorDesc=${roomInfo.value.tips}`
        router.push(url)
      }
    }

    return {
      handleLiveLink,
    }
  },
})
