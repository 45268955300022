import { requestByAccountType } from '@/common/request/request'
import { TradeDirection, YesOrNo } from '@/types'

export type Order = {
  code: string;
  amount: number;
  orderId: number;
  productId: string;
  createPrice: string;
  closePrice: string;
  type: TradeDirection;
  closeType: number; // 3：手动平仓 4：止盈平仓 5：止损平仓 6：爆仓
  fee: number;
  margin: string;
  stopLoss: string;
  stopProfit: string;
  createTime: number;
  closeTime: number;
  profitLoss: number;
  stopLossPoint: number;
  stopProfitPoint: number;
  isJuan: YesOrNo;
  unit: string;
  weight: string;
  days: string;
  levelType: number;
  leverage: string;
}

export const readHistory = requestByAccountType<Array<Order>>('history')
