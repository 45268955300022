/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   Trade.vue of WeTrade
*/
<template>
<Page class="px-16">
  <div class="py-6">
    <GuideTarget name="switchAccount">
      <TheAccountSwitch class="mx-16" />
      <template #bottom>
        <GuideContent :anchor-align="20" class="mt-12">
          <t path="trade_20">
            Click to switch to Live account when you are ready to trade and make real profit
          </t>
        </GuideContent>
      </template>
    </GuideTarget>
  </div>
  <!-- <OnlyGroupA><TheEarnings /></OnlyGroupA> -->
  <!-- <TheEarnings /> -->
  <div class="main mb-16">
    <TheAsset class="mt-16">
      <AccountRelated use-in-real>
        <div class="my-12 px-16 d-f">
          <router-link #="{ navigate }" custom to="/out">
            <button class="flex-3 btn block sm out br-sm" @click="navigate">
              <t path="trade_11" #="{td}" custom>{{td || 'Withdrawal'}}</t>
            </button>
          </router-link>
          <router-link #="{navigate}" custom to="/in">
            <button class="flex-5 btn success sm ml-10 br-sm" @click="navigate">
              <t path="trade_12" #="{td}" custom>{{td || 'Deposit'}}</t>
            </button>
          </router-link>
        </div>
      </AccountRelated>
    </TheAsset>
    <AccountRelated use-in-real>
      <TheFirstDepositCard />
    </AccountRelated>

    <AccountRelated use-in-real>
      <TheDailyBonusEnter />
    </AccountRelated>

    <Holder :content="allCommissionDone" :skeleton-repeat="4">
      <div class="wedge" style="height: 24px;"></div>
      <WTTabs>
        <TabPane :tab="PositionBarWithBadge" key="a">
          <ThePositions />
        </TabPane>
        <TabPane :tab="CommissionBarWithBadge" key="b">
          <TheCommissions />
        </TabPane>
      </WTTabs>
      <div v-if="!positions.length && !commissions.length" class="d-f px-16 trade-guide">
        <router-link to="/market" class="flex-1 px-16 py-12 box">
          <div class="df-middle mb-6">
            <Pic src="trade/market" width="24" height="24" />
            <t as="p" path="tab_3" class="ml-6 c-success f-medium flex-1">Community</t>
            <Icon name="caret-right" sm />
          </div>
          <t as="p" path="trade_22" class="f-md">Find inspiration for trading</t>
        </router-link>
        <router-link to="/" class="flex-1 px-16 py-12 ml-16 box">
          <div class="df-middle mb-6">
            <Pic src="trade/community" width="24" height="24" />
            <t as="p" path="tab_2" class="ml-6 c-asst f-medium flex-1">Market</t>
            <Icon name="caret-right" sm />
          </div>
          <t as="p" path="trade_21" class="f-md">Track the most popular instruments</t>
        </router-link>
      </div>
    </Holder>
  </div>
</Page>
</template>

<script lang="ts">
import WTTabs from '@/components/Tabs.vue'
import GuideContent from '@/components/userGuide/GuideContent.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import TheAccountSwitch from '@/modules/trade/components/TheAccountSwitch.vue'
import TheAsset from '@/modules/trade/components/TheAsset.vue'
import TheCommissions from '@/modules/trade/components/TheCommissions.vue'
import ThePositions from '@/modules/trade/components/ThePositions.vue'
import { readTradeState } from '@/modules/trade/prepare'
import AccountRelated from '@/provider/AccountRelated.vue'
import Holder from '@/provider/Holder.vue'
// import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import Page from '@/provider/Page.vue'
import { isDemoAccount } from '@/state/accountType'
import CommissionBarWithBadge from '@/views/CommissionBarWithBadge.vue'
import PositionBarWithBadge from '@/views/PositionBarWithBadge.vue'
import TabPane from 'common/tab/TabPane.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import state from '@/state'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import { Commission, readCommissions } from '@/modules/trade/trade.api'
import TheFirstDepositCard from '@/modules/trade/components/TheFirstDepositCard.vue'
// import TheEarnings from '@/modules/trade/components/TheEarnings.vue'
import TheDailyBonusEnter from '@/modules/trade/components/TheDailyBonusEnter.vue'

export default defineComponent({
  name: 'Trade',
  components: {
    // OnlyGroupA,
    GuideContent,
    GuideTarget,
    Icon,
    Pic,
    AccountRelated,
    TheCommissions,
    ThePositions,
    TabPane,
    WTTabs,
    Holder,
    TheAccountSwitch,
    TheAsset,
    Page,
    TheFirstDepositCard,
    // TheEarnings,
    TheDailyBonusEnter,
  },
  setup () {
    // 任务进度 与 完成标识
    const taskStep = shallowRef(0)
    const allCommissionDone = shallowRef<boolean | null>(null)
    const commissions = shallowRef<Commission[]>([])

    const readyToTrade = computed(() => {
      return allCommissionDone.value || isDemoAccount.value
    })

    // useCustomStatusBar()

    readTradeState().then(([result, step]) => {
      allCommissionDone.value = result
      taskStep.value = step
    })

    readCommissions().then(resp => {
      commissions.value = resp
    })

    return {
      devEnable () {
        allCommissionDone.value = true
        taskStep.value = 3
      },
      allCommissionDone,
      PositionBarWithBadge,
      CommissionBarWithBadge,
      taskStep,
      readyToTrade,
      positions: computed(() => state.account.list),
      commissions,
    }
  },
})
</script>

<style scoped lang="scss">
// .trade-guide {
//   position: absolute;
//   bottom: 40px;
//   left: 0;
//   right: 0;
// }

.btn {
  height: 36px;
}

.out {
  color: var(--color-theme-reverse);
  border: 1px solid var(--color-text);
}

// @media only screen and (max-width: 320px) {
//   .trade-guide {
//     bottom: 10px;
//   }
// }
</style>
