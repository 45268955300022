
import Pic from '@/components/Pic.vue'
import Button from '@/components/Button.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'DrawLineDialog',
  components: { Pic, DialogTemplate, Button },
  emits: ['close', 'confirm'],
  setup (props, ctx) {
    const loading = shallowRef(false)
    console.log(loading.value)

    onBeforeUnmount(() => {
      loading.value = false
    })

    return {
      confirm () {
        loading.value = true
        ctx.emit('confirm')
      },
      loading,
    }
  },
})
