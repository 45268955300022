/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/1
* @description
*   ResultDialogTemplate.vue of WeTrade
*/
<template>
  <DialogTemplate>
    <template #title>
      <slot name="title">Dialog Title</slot>
    </template>
    <div class="flex-1 t-center p-16">
      <Icon name="check" class="bg-success mb-20" style="font-size: 40px;color: white;border-radius: 20px" />
      <slot />
    </div>
    <template #controls>
      <slot name="controls" />
      <Button class="block flex-1 primary" @click="$emit('close')">
        <t path="notification_32" custom #="{td}">
          {{ td || 'OK' }}
        </t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ResultDialogTemplate',
  components: { Icon, Button, DialogTemplate },
})
</script>
