<template>
  <tr>
    <td colspan="4" class="stocks-category" style="padding-right: 12px;">
      <div class="df-middle df-between" @click="toggleSlide">
        <div class="f-md">
          <t :path="`stockcat_cat_${stocks[0].industryType}`"></t>
        </div>
        <Icon :name="show ? 'caret-up' : 'caret-down'" sm />
      </div>
    </td>
  </tr>
  <template v-if="show">
    <TheMarketItem v-for="item in stocks" :key="item.code" :market-item="item" />
  </template>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import TheMarketItem from '@/modules/market/components/TheMarketItem.vue'

export default defineComponent({
  name: 'TheStockList',
  components: { TheMarketItem, Icon },
  props: {
    stocks: {
      type: Array,
      required: true,
    },
  },
  setup () {
    const show = ref(true)
    return {
      show,
      toggleSlide: () => show.value = !(show.value),
    }
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/index" as mixins;

.stocks-category {
  line-height: 32px;
  text-align: left !important;
  background: var(--color-light);
  @include mixins.half-pixel-border(bottom, var(--color-grey));
}
</style>
