/**
* @author ayou[beica1@outook.com]
* @date 2022/1/5 11:23
* @description
*   TheQuizBoard.vue of WeTrade
*/
<template>
<div class="quiz br-lg">
  <div class="t-center clip">
    <Pic src="home/clip" width="180" />
  </div>

  <div class="board br-lg p-16 df-col" v-if="doneStatus===0">
    <p class="title t-center f-lg f-bold" style="line-height: 48px">{{ qaList[questionIndex].title }}</p>
    <Line />
    <div class="flex-1">
      <div class="d-f mt-16">
        <!--进度-->
        <span class="progress">{{questionIndex + 1}}/{{qaList.length}}</span>
        <!--问题-->
        <p class="flex-1 question ml-12">{{question.question}}</p>
      </div>
      <!--选择按钮-->
      <div class="d-f mt-32" :class="{answered}">
        <button class="btn flex-1 choice"
          :class="{valid: question.answer === YES}"
          @click="makeChoice(YES)">
          <t path="quiz_2">Yes</t>
          <Icon
            v-show="showAnsweredTip(YES)"
            :name="question.answer === YES ? 'check' : 'close'"
            sm />
        </button>
        <button
          class="btn flex-1 choice ml-16"
          :class="{valid: question.answer === NO}"
          @click="makeChoice(NO)">
          <t path="quiz_3">No</t>
          <Icon
            v-show="showAnsweredTip(NO)"
            :name="question.answer === NO ? 'check' : 'close'" sm />
        </button>
      </div>
      <!--问题解析-->
      <p v-show="answered" class="c-success mt-16 f-md">
        {{question.description}}
      </p>
    </div>
    <button class="primary btn f-lg f-bold" :class="{disabled: !answered}" @click="next">
      <t :path="isLastQuestion ? 'quiz_5' : 'quiz_4'">Next</t>
    </button>
  </div>

  <div class="board br-lg p-16 df-col" v-else>
    <p class="title t-center f-lg f-bold" style="line-height: 48px">{{ qaList[questionIndex].title }}</p>
    <Line />
    <div class="flex-1 df-middle df-center">
      <div v-if="finishCheck">
        <t path="quiz_6" as="p" v-if="doneStatus===1">You have completed this quiz.</t>
        <t path="quiz_7" as="p" v-if="doneStatus===2">It's not the time for the quiz, please come at the designated time.</t>
      </div>
      <Loading v-else/>
    </div>
    <button class="primary btn f-lg f-bold" @click="close">
      <t path="withdrawal_34">OK</t>
    </button>
  </div>

  <Icon name="close" class="close" @click="close" />
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import Pic from '@/components/Pic.vue'
import { RookieQuiz, finishTest, checkTestFinish, completeRookieQuiz } from '@/modules/home/home.api'
import { YesOrNo } from '@/types'
import { computed, defineComponent, shallowRef } from 'vue'
import useRequest from '@/hooks/useRequest'
import Loading from 'common/loading/Donut.vue'

export default defineComponent({
  name: 'TheQuizBoard',
  components: { Line, Icon, Pic, Loading },
  props: {
    qaList: {
      type: Array,
      required: true,
    },
    taskType: {
      type: Boolean,
      default: false,
      required: false,
    },
    eventId: {
      type: Number,
      required: false,
    },
  },
  setup (props, ctx) {
    const choice = shallowRef<YesOrNo | null>(null)
    const answered = computed(() => choice.value !== null)
    const questionIndex = shallowRef(0)
    const isLastQuestion = shallowRef(props.qaList.length === 1)
    const question = computed(() => (props.qaList as RookieQuiz[])[questionIndex.value])
    const doneStatus = shallowRef()
    const finishCheck = shallowRef(false)

    const [check, loading] = useRequest(checkTestFinish)
    finishCheck.value = !computed(() => loading.value).value

    if (props.taskType) {
      doneStatus.value = 0
    } else {
      check({
        eventId: props.eventId,
      }).then(res => {
        doneStatus.value = res.status
      })
    }

    const makeChoice = (_choice: YesOrNo) => {
      if (choice.value === null) {
        choice.value = _choice
      }

      // complete task after the last question answered
      if (isLastQuestion.value) {
        if (props.taskType) {
          completeRookieQuiz().then(() => {
            ctx.emit('complete')
          })
        } else {
          finishTest().then(() => {
            ctx.emit('complete')
          })
        }
      }
    }

    const close = () => {
      ctx.emit('close')
    }

    const next = () => {
      if (questionIndex.value < props.qaList.length - 1) {
        questionIndex.value += 1
        choice.value = null
      } else {
        close()
      }
      isLastQuestion.value = questionIndex.value >= props.qaList.length - 1
    }

    const showAnsweredTip = (_choice: YesOrNo) => answered.value && (_choice === choice.value || question.value.answer === _choice)

    return {
      question,
      questionIndex,
      isLastQuestion,
      answered,
      doneStatus,
      finishCheck,
      makeChoice,
      next,
      close,
      showAnsweredTip,
    }
  },
})
</script>

<style scoped lang="scss">
.quiz {
  position: relative;
  width: 100%;
  margin: 0 24px;
  padding: 16px;
  background: var(--color-grey);
}

.clip {
  position: absolute;
  left: 0;
  right: 0;
  top: -30px;
}

.board {
  min-height: 400px;
  background: var(--color-white);
  box-shadow: 4px 4px 0 0 rgba(164, 178, 195, 0.3);
}

.progress {
  padding: 0 6px;
  border: 1px solid var(--color-tip);
  border-radius: 11px;
  font-size: 13px;
  color: var(--color-title-80);
  display: inline-block;
  height: 20px;
  line-height: 20px;
}

.choice {
  position: relative;
  height: 36px;
  font-weight: bold;
  color: var(--color-text);

  .icon {
    position: absolute;
    right: 16px;
    border-radius: 50%;
    color: var(--color-white);
    background: var(--color-danger);
  }
}

.answered {
  .choice {
    color: var(--color-tip);
  }

  .valid {
    color: var(--color-success);
    background: #DAF6E6;

    .icon {
      background: var(--color-success);
    }
  }
}

.close {
  position: absolute;
  top: -40px;
  right: 0;
  border-radius: 50%;
  border: 1px solid var(--color-white);
  color: var(--color-white);
}
</style>
