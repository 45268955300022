/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/30
* @description
*   Nullable.vue of WeTrade
*/
<template>
  <slot v-if="isNull" name="empty">
    <div class="t-center my-32 flex-1">
      <Pic src="blank" width="32" height="32" />
      <p class="c-tip f-sm">
        <slot name="emptyMsg">There is no data!</slot>
      </p>
    </div>
  </slot>
  <slot v-else />
</template>

<script lang="ts">
import * as R from 'ramda'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Nullable',
  components: { Pic },
  props: {
    content: [Array, Object, String, Number],
  },
  computed: {
    isNull (): boolean {
      return R.or(R.isNil(this.content), R.isEmpty(this.content))
    },
  },
})
</script>
