/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 15:08
* @description
*   ForGuest.vue of WeTrade
*   未登录用户的任务卡片
*/
<template>
<div class="t-center">
  <Pic type="svg" src="home/user" width="56" height="56" />
  <t as="p" path="home_9" class="f-xxl f-bold mt-20">Sign Up Now</t>
  <t as="p" path="home_10" class="mt-16">Trade on the best mobile trading platform</t>
  <router-link to="/loginSwich/0" custom #="{navigate}">
    <button class="login mt-16 f-bold br-lg" @click="navigate">
      <t path="home_11">Sign Up</t>
    </button>
  </router-link>
</div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForGuest',
  emits: ['forbiddenClose'],
  components: { Pic },
  setup (props, ctx) {
    ctx.emit('forbiddenClose')
  },
})
</script>

<style scoped lang="scss">
.login {
  height: 36px;
  width: 63%;
  margin-bottom: 40px;
  border: 1px solid var(--color-text);
  background: transparent;
}
</style>
