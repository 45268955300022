<template>
  <div class="deposit-dialog t-center">
    <div class="header bg-primary py-16">
      <p class="close-icon">
        <Icon name="close" class="c-primary" style="background: white;border-radius: 12px" @click="$emit('close')" />
      </p>
      <Pic src="trade/coin" width="88" height="64" />
    </div>
    <div class="p-24">
      <t as="p" path="trade_23" class="f-lg f-bold">Congratulations!</t>
      <t as="p" path="trade_24" class="mt-16 f-md">
        It seems that you have a talent for trading, switch to real account and make real profits!
      </t>
    </div>
    <div class="d-f f-bold">
      <Button class="flex-1 btn block button" @click="$emit('close')">
        <t path="notification_33" #="{td}" custom>{{ td || 'Later' }}</t>
      </Button>
      <Button class="flex-1 btn block primary button" @click="deposit">
        <t path="trade_12" #="{td}" custom>{{ td || 'Deposit Now' }}</t>
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'
import { useAccounts } from '@/state/accountType'
import { AccountType } from '@/state'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'DepositDialog',
  components: { Icon, Pic, Button },
  emits: ['close'],
  setup (props, ctx) {
    const router = useRouter()
    const { accounts, change } = useAccounts()
    return {
      deposit () {
        change(accounts[AccountType.REAL])
        router.push('/in')
        ctx.emit('close')
      },
    }
  },
})
</script>

<style scoped>
.deposit-dialog {
  width: 73%;
  border-radius: 4px;
  overflow: hidden;
  background: var(--color-white);
}

.header {
  position: relative;
}

.close-icon {
  position: absolute;
  right: 8px;
  top: 8px;
}

.button {
  border-radius: 0;
}

</style>
