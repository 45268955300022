
import TheToggleStateButton from '@/modules/market/components/TheToggleStateButton.vue'
import { useToggleState } from '@/modules/market/market'
import { MarketItem } from '@/modules/market/market.types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheWatchSymbolButton',
  components: { TheToggleStateButton },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return useToggleState((props.marketItem as MarketItem).code)
  },
})
