<template>
<router-link
  :to="`/advice/${advice.id}`"
>
  <div class="header df-middle f-sm my-16">
    <Pic
      src="logo" fallback="/img/user/Avatar@2x.png"
      width="32" height="32" style="border-radius: 50%"
    />
    <div class="flex-1 ml-16 f-sm">
      <p class="mb-4">WeTrade Analysis</p>
      <Time :time="advice.createTime" format="MM-dd-yyyy HH:mm:ss" class="c-tip" />
    </div>
    <slot />
  </div>
  <div class="df-middle df-between my-16 list-item">
    <span class="f-bold">{{advice.code}}</span>
    <Icon class="p-0 ml-4" :class="{'c-success': isUp, 'c-danger': !isUp}" sm
      :name=" isUp ? 'up': 'down'" />
    <div class="t-right f-sm c-tip flex-1">
      <p class="f-medium my-4 f-nm" :class="{ 'c-success': isUp, 'c-danger': !isUp }">
        {{advice.informactionContent && advice.informactionContent.split(',')[0]}}
      </p>
    </div>
  </div>
</router-link>
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'
import Time from '@/components/Time.vue'
import { formatDateTime } from '@/common/format'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheAnalysisItem',
  components: { Time, Icon, Pic },
  props: {
    advice: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const today = formatDateTime('MM-dd-yy', new Date().getTime())

    const createDay = computed(() => {
      return formatDateTime('MM-dd-yy', props.advice.createTime)
    })

    const isUp = computed(() => {
      return props.advice.direction === TradeDirection.BUY
    })

    return {
      today,
      createDay,
      isUp,
    }
  },
})

</script>
