
import { defineComponent, onActivated, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import Clock from '@/modules/home/components/TheClock.vue'
import { readUpComing, EventItem } from '@/modules/home/home.api'
import { formatDateTime } from '@/common/format'
import { eventEnter } from '@/modules/home/home'

export default defineComponent({
  name: 'TheEvents',
  components: { Pic, Clock },
  setup () {
    const event = shallowRef()
    const hasEvents = shallowRef(false)
    const getEventList = async () => {
      const res = await readUpComing()
      if (!res) {
        return false
      }
      hasEvents.value = true
      const item = res
      event.value = {
        time: [formatDateTime('HH:mm', item.startTime), item.endTime ? formatDateTime('HH:mm', item.endTime) : '--:--'],
        section: Boolean(item.timeType === 2),
        ...item,
      }
    }
    onActivated(() => {
      getEventList()
    })

    const eventClick = (item: EventItem) => {
      eventEnter(item)
    }

    return {
      event,
      hasEvents,
      eventClick,
    }
  },
})
