/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   TheAsset.vue of WeTrade
*/
<template>
<div class="asset t-center br-lg">
  <div class="d-f">
    <div class="col flex-1">
      <t as="p" path="trade_4" class="label">Balance</t>
      <money as="p" class="f-medium" :v="account.balance" />
    </div>
    <div class="col flex-1">
      <t as="p" path="trade_5" class="label">Equity</t>
      <money as="p" class="f-medium" :v="account.equity" />
    </div>
    <div class="col flex-1">
      <t as="p" path="trade_6" class="label">Free Margin</t>
      <money as="p" class="f-medium" :v="account.freeMargin" />
    </div>
  </div>
  <div class="d-f">
    <div class="col flex-1">
      <t as="p" path="trade_7" class="label">Margin Used</t>
      <money as="p" class="f-medium" :v="account.margin" />
    </div>
    <div class="col flex-1">
      <t as="p" path="trade_8" class="label">Margin Level</t>
      <p class="f-medium" v-if="account.marginLevel === 0">--</p>
      <p class="f-medium" v-else :class="{'c-danger': account.marginLevel < 100}">
        {{account.marginLevel}}%</p>
    </div>
    <div class="col flex-1">
      <t as="p" path="trade_9" class="label">Floating P/L</t>
      <money as="p" class="f-medium c-success" :v="account.profit"
        :class="{'c-danger': account.profit < 0}" />
    </div>
  </div>
  <AccountRelated use-in-real>
    <div v-if="stickAlertFlag" class="d-f px-16 c-danger f-sm">
      <Icon name="warn" sm />
      <t as="p" path="trade_32" class="flex-1 t-left pl-8" style="font-style: italic" />
    </div>
  </AccountRelated>
  <AccountRelated use-in-demo>
    <div class="px-16 mb-16" v-if="showTopUp">
      <Button class="success br-sm top-btn" @click="topUpClick" :progress="progress">
        <t path="trade_33">Top up the demo account</t>
      </Button>
    </div>
  </AccountRelated>
  <DemoAccountTip />
  <slot />
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import state from '@/state'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import Button from '@/components/Button.vue'
import { computed, defineComponent, shallowRef, watch } from 'vue'
import { demoBalanceRecharge } from '@/modules/trade/trade'
import { format } from '@/config'
import { isDemoAccount } from '@/state/accountType'
import { readDemoAccountInfo } from '@/modules/trade/trade.api'

export default defineComponent({
  name: 'TheAsset',
  components: { Icon, AccountRelated, DemoAccountTip, Button },
  setup: function () {
    const account = computed(() => state.account)
    const showTopUp = shallowRef(false)

    showTopUp.value = account.value.freeMargin < 500 * format.moneyFactor && isDemoAccount.value

    // todo 暂时改为每次切换demo重新请求接口获取最新freeMargin
    watch(() => isDemoAccount.value, (isDemo) => {
      if (isDemo) {
        readDemoAccountInfo().then(res => {
          showTopUp.value = res.freeMargin < 500 * format.moneyFactor
        })
      }
    }, { immediate: true })

    const [topUpClick, progress] = demoBalanceRecharge(() => {
      showTopUp.value = state.account.freeMargin < 500 * format.moneyFactor
    })

    return {
      stickAlertFlag: state.account.marginLevel < 100 && state.account.marginLevel > 0,
      account,
      showTopUp,
      topUpClick,
      progress,
    }
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/_index.scss" as mixins;

.asset {
  overflow: hidden;
  background: var(--color-light);
}

.label {
  color: var(--color-title);
  font-size: 13px;
  margin-bottom: 4px;
}

.col {
  padding: 8px;
  margin: 8px 0;

  &:not(:first-child) {
    @include mixins.half-pixel-border(left, var(--color-tip));
  }
}

.top-btn {
  width: 100%;
  height: 36px;
}
</style>
