<template>
  <div class="dbe-main df-middle br-lg mt-16 pl-16 pr-8" v-if="total > 0"  @click="() => useDailyBonus(true)">
    <!-- <Pic src="trade/gift" width="32" height="32" /> -->
    <t path="trade_41" class="flex-1">Daily bonus</t>
    <div class="coupon f-bold c-white df-middle">
      <div class="coupon-content df-middle t-center">
        <money :v="total"></money>
      </div>
    </div>
    <div class="icon-wrap ml-12">
      <Donut v-if="loading"  />
      <Icon v-else name="caret-right" />
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, shallowRef } from 'vue'
// import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import Donut from 'common/loading/Donut.vue'
import { useDailyBonus, loading } from '@/modules/home/dailyBonus'
import { readDailyBonus } from '@/modules/home/home.api'

export default defineComponent({
  name: 'TheDailyBonusEnter',
  components: {
    // Pic,
    Icon,
    Donut,
  },
  setup () {
    const total = shallowRef(0)
    onMounted(() => {
      readDailyBonus().then(res => {
        if (!res || res.list.length === 0) {
          return
        }
        res.list.map(item => {
          total.value = total.value + item.amount
        })
      })
    })
    return {
      total,
      loading,
      useDailyBonus,
    }
  },
})
</script>

<style scoped lang='scss'>
.dbe-main{
  height: 56px;
  width: 100%;
  background: var(--color-light);
  font-size: var(--font-md);
}
.coupon{
  width: 54px;
  height: 30px;
  background: url(/img/trade/coupon@2x.png) no-repeat;
  background-size: 100% 100%;
  padding-left: 14px;
}
.coupon-content{
  height: 30px;
  width: 40px;
}
.icon-wrap{
  max-width: 24px;
  min-width: 24px;
}
</style>
