/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/7/23
 * @description
 *   chart.ts of main
 */
import { parseToJson } from '@/common/format'
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { StudyOptions } from 'happychart/study/StudyRenderer'
import { Periodicity, StudyType } from 'happychart/types'
import * as R from 'ramda'

export enum BarTypes {
  K_4H = 9,
  K_1,
  L_1S,
  K_5S,
  K_15S,
  K_30S,
  K_5 = 2, // 5M
  K_15,
  K_30,
  K_60,
  K_1D, // 1D
  K_1W,
}

export const precisions = {
  XAUUSD: 2,
  USOIL: 2,
  EURUSD: 5,
  USDJPY: 3,
  GBPUSD: 5,
  AUDUSD: 5,
  XADUSD: 3,
  COPPER: 3,
  NICKEL: 2,
  USDCAD: 5,
  USDCHF: 5,
  NZDUSD: 5,
  BTCUSD: 2,
  ETHUSD: 2,
}

export type StudyConfig = {
  label: string;
  fullName: string;
  value: StudyType;
  input: StudyOptions[StudyType];
  elite?: boolean;
  default?: boolean,
  class?: string;
  configurable?: boolean;
}

export const overlayStudies: Array<StudyConfig> = [
  {
    label: 'Trend',
    fullName: 'Trend',
    default: true,
    elite: true,
    value: StudyType.TRENDPRO,
    configurable: false,
    input: {
      period: 3,
      fillColor: '#CCF4DD',
      fillColor2: '#FCDBD8',
    },
  },
  {
    label: 'MA',
    fullName: 'Moving Averages',
    value: StudyType.SMA,
    input: {
      series: [
        {
          period: 5,
          lineColor: '#04ff00',
        }, {
          period: 10,
          lineColor: '#ff00d3',
        }, {
          period: 20,
          lineColor: '#8600ff',
        }, {
          period: 30,
          lineColor: '#19e6df',
        }, {
          period: 60,
          lineColor: '#2026ff',
        },
      ],
    },
  },
  {
    label: 'EMA',
    fullName: 'Exponential Moving Averages',
    value: StudyType.EMA,
    input: {
      series: [
        {
          period: 5,
          lineColor: '#04ff00',
        }, {
          period: 8,
          lineColor: '#ff00d3',
        }, {
          period: 13,
          lineColor: '#8600ff',
        }, {
          period: 21,
          lineColor: '#19e6df',
        }, {
          period: 34,
          lineColor: '#2026ff',
        },
      ],
    },
  },
  {
    label: 'BOLL',
    fullName: 'Bollinger Band',
    value: StudyType.BOLL,
    input: {
      period: 20,
      deviation: 2,
      lineColor: '#8600ff',
      fillColor: 'rgba(16, 43, 137, 0.1)',
      borderColor: '#326bef',
    },
  },
  {
    label: 'SAR',
    fullName: 'Parabolic SAR',
    value: StudyType.SAR,
    input: {
      minAF: 0.02,
      maxAF: 0.2,
      lineColor: '#12aba6',
      fillColor: '#fff',
    },
  },
]

export const panelStudies: StudyConfig[] = [
  {
    label: 'MACD',
    fullName: 'Moving Average Convergence Divergence',
    default: true,
    value: StudyType.MACD,
    input: {
      fastPeriod: 12,
      slowPeriod: 26,
      signalPeriod: 9,
      lineColor: '#46AFFF',
      lineColor2: '#F2863A',
    },
  }, {
    label: 'Stoch',
    fullName: 'Stochastic',
    value: StudyType.KDJ,
    input: {
      period: 14,
      slowPeriod: 1,
      smoothPeriod: 3,
      lineColor: '#46AFFF',
      lineColor2: '#F2863A',
    },
  }, {
    label: 'RSI',
    fullName: 'Relative Strength Index',
    value: StudyType.RSI,
    input: {
      period: 14,
      lineColor: '#46AFFF',
    },
  }, {
    label: 'CCI',
    fullName: 'Commodity Channel Index',
    value: StudyType.CCI,
    input: {
      period: 20,
      lineColor: '#46AFFF',
    },
  },
]

export type ResolutionConfig = {
  default?: boolean;
  label: string;
  value: BarTypes;
  period: Periodicity;
}

export const resolutions: ResolutionConfig[] = [
  {
    label: 'M1',
    value: BarTypes.K_1,
    period: {
      interval: 1,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'M5',
    value: BarTypes.K_5,
    period: {
      interval: 5,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'M15',
    value: BarTypes.K_15,
    period: {
      interval: 15,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'M30',
    value: BarTypes.K_30,
    period: {
      interval: 30,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'H1',
    value: BarTypes.K_60,
    period: {
      interval: 60,
      timeUnit: 'minute',
      offset: 1,
    },
  },
  {
    label: 'H4',
    value: BarTypes.K_4H,
    period: {
      interval: 60 * 4,
      timeUnit: 'minute',
      offset: 0.5,
    },
  },
  {
    label: 'D1',
    value: BarTypes.K_1D,
    period: {
      interval: 1,
      timeUnit: 'day',
    },
  },
  {
    label: 'W1',
    value: BarTypes.K_1W,
    period: {
      interval: 7,
      timeUnit: 'day',
    },
  },
]

export const readResolutions = (): ResolutionConfig[] => resolutions.map(item => ({
  ...item,
  default: item.value === Number(localGet(keymap.label.resolution) ?? BarTypes.K_30),
}))

let freshConfig: StudyConfig[] | null = null

const parseStudyConfig = (): StudyConfig[] => {
  const config = parseToJson(localGet(keymap.label.studyConfig) ?? '').json
  return config ?? R.clone(panelStudies.concat(overlayStudies))
}

/**
 * 更新配置并缓存
 * @param config
 */
export const saveStudyConfig = (config: StudyConfig) => {
  if (freshConfig === null) {
    freshConfig = parseStudyConfig()
  }

  const index = freshConfig.findIndex(item => item.value === config.value)

  if (index !== -1) {
    freshConfig[index] = config

    localSet(keymap.label.studyConfig, JSON.stringify(freshConfig))
  }
}

/**
 * 读取最新或默认配置
 * @param value
 * @param origin
 */
export const readStudyConfig = (value?: StudyType, origin = false): StudyConfig | undefined => {
  if (origin) {
    return R.clone(panelStudies.concat(overlayStudies).find(config => config.value === value))
  }

  if (freshConfig === null) {
    freshConfig = parseStudyConfig()
  }

  return R.clone(freshConfig.find(config => config.value === value))
}

/**
 * 读取副图指标配置
 */
export const readPanelStudies = () => panelStudies.map(
  config => readStudyConfig(config.value) ?? config)

/**
 * 读取主图指标配置
 */
export const readOverlayStudies = () => overlayStudies.map(
  config => readStudyConfig(config.value) ?? config)
