/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 15:14
* @description
*   ForRookie.vue of WeTrade
*   新手任务卡片
*/
<template>
<div>
  <t as="p" path="home_12" class="f-xxl f-bold t-center">New to WeTrade</t>
  <t as="p" path="home_13" class="mt-16">Complete the starting guide and earn points</t>
  <div style="overflow: hidden">
    <!--这里使用tag作为任务的id-->
    <RedPointAlert
      v-for="item in tasks"
      :id="'rt_' + item.tag"
      :key="item.tag"
      :class="{'bg-theme': isCurrentTask(item.tag)}"
      :display="isCurrentTask(item.tag)"
      class="task df-middle br-lg"
      tag="div"
      @click="perform(isCurrentTask(item.tag) ? item : null)"
    >
      <Pic :src="item.icon" fallback="/img/home/flag@2x.png" width="32" height="32" />
      <div class="fill df-col df-center">
        <div class="df-middle">
          <p
            class="flex-1 t-left px-12 c-text"
            :class="{'c-title': !isCurrentTask(item.tag), 'c-tip': item.status === DONE}"
          >{{item.title}}</p>
          <span
            class="f-bold c-title"
            v-if="item.award"
            :class="{'c-tip': item.status === DONE, 'c-success': isCurrentTask(item.tag)}"
          >+{{item.award || 0}} pts</span>
        </div>
        <div v-if="item.tag === TaskType.DEPOSIT$50" class="df-middle px-12 mt-4">
          <Pic src="home/gift" width="16" height="16" />
          <t path="home_57" class="f-md ml-4" style="color: #F07B00;">Free Prime member and more</t>
        </div>
      </div>
      <div class="state">
        <Donut v-if="isCurrentTask(item.tag) && loading" sm />
        <Icon v-else-if="isCurrentTask(item.tag)" name="caret-right" class="c-title" sm />
        <Icon v-else-if="item.status === DONE" name="check" class="c-title" sm />
      </div>
    </RedPointAlert>
  </div>
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { usePerformTask } from '@/modules/home/home'
import { RookieTask, TaskType } from '@/modules/home/home.api'
import RedPointAlert from '@/provider/RedPointAlert.vue'
import Donut from 'common/loading/Donut.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'ForRookie',
  components: { RedPointAlert, Donut, Icon, Pic },
  emits: ['done'],
  props: {
    tasks: {
      type: Array,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const loading = shallowRef(false)

    const done = () => {
      ctx.emit('done')
    }

    const _perform = usePerformTask(done)

    const perform = (task: RookieTask | null) => {
      loading.value = true
      _perform(task).finally(() => {
        loading.value = false
      })
    }

    const isCurrentTask = (tag: number) => tag === (props.task as RookieTask).tag

    return {
      isCurrentTask,
      loading,
      perform,
      TaskType,
    }
  },
})
</script>

<style scoped lang="scss">
.task {
  height: 56px;
  padding: 8px;
  margin-bottom: 8px;

  &:first-child {
    margin-top: 16px;
  }

  &:last-child {
    margin-bottom: 20px;
  }
}

.state {
  text-align: right;
  width: 28px;

  .icon-check {
    border-radius: 50%;
    color: var(--color-white);
    background: var(--color-success);
  }
}
</style>
