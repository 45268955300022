/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 14:10
* @description
*   TheBanner.vue of WeTrade
*/
<template>
  <div
    class="banner"
    :class="{hasBanner: banners.length > 0}"
  >
    <template v-if="banners.length && banners.length > 1">
      <Swipe #="{item}" :list="banners" :dot="true" :autoPlay="true" dot-position="right">
        <router-link :to="item.url">
          <Pic :src="item.imageurl" style="width: 100%; height: 88px; object-fit: cover;" />
        </router-link>
      </Swipe>
    </template>
    <router-link v-else-if="banners.length === 1" :to="banners[0].url">
      <Pic :src="banners[0].imageurl" style="width: 100%; height: 88px; object-fit: cover;" />
    </router-link>
  </div>
</template>

<script lang="ts">
import Swipe from '@/components/Swipe.vue'
import { onActivated, shallowRef, defineComponent } from 'vue'
import Pic from 'common/Pic.vue'
import { readBanner, BannerSchema } from '@/modules/home/home.api'
import useRequest from '@/hooks/useRequest'

export default defineComponent({
  name: 'TheBanner',
  components: { Pic, Swipe },
  setup () {
    const banners = shallowRef<Array<BannerSchema>>([])

    // @todo 暂时使用useRequest解决home页多个onActivated部分不执行bug
    const [request] = useRequest(readBanner)

    const updateBanner = () => {
      request().then(resp => {
        banners.value = []
        banners.value = (resp) || []
      })
    }

    // updateBanner()

    onActivated(() => {
      updateBanner()
    })

    return {
      banners,
    }
  },
})
</script>

<style scoped>
.banner{
  overflow: hidden;
  border-radius: 6px;
}
.hasBanner {
  height: 88px;
  overflow: hidden;
}
</style>
