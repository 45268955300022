
import ColorText from '@/components/ColorText.vue'
import Date from '@/components/Date'
import Icon from '@/components/Icon.vue'
import { openDialog } from '@/components/popup/popup'
import ThePositionCloseConfirmDialog
  from '@/modules/trade/components/ThePositionCloseConfirmDialog.vue'
import ThePositionEditDialog from '@/modules/trade/components/ThePositionEditDialog.vue'
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import { Position } from '@/modules/trade/trade.api'
import ShareDialog from '@/pages/invite/ShareDialog.vue'
import { isDemoAccount } from '@/state/accountType'
import accordion from 'essential/dom/accordion'
import { computed, defineComponent, onMounted, shallowRef, ref } from 'vue'
import DepositDialog from '@/modules/trade/components/DepositDialog.vue'
import { localGet, localSet } from 'essential/store/localStore'
import { keymap } from '@/config'
import * as R from 'ramda'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'ThePositionItem',
  components: { Date, TheTradeLimitDisplay, ColorText, Icon, Pic },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const toggle = shallowRef(() => {
      console.log('accordion effect not work')
    })

    const show = ref(false)

    const acc = shallowRef<HTMLElement | null>(null)
    const byCoupon = computed(() => props.order.isJuan)

    const promptToDeposit = (order: Position) => {
      if (isDemoAccount.value && order?.profitLoss > 0 && !localGet(keymap.guide.depositTip)) {
        // 弹窗引导充值
        openDialog(DepositDialog, { wrapperClass: 'center' })
        localSet(keymap.guide.depositTip, '1')
      }
    }

    const share = (order: Position) => {
      if (order.profitability > 0.3) {
        // if (order.profitability) {
        openDialog(ShareDialog, {
          wrapperClass: 'center',
          order,
        })
      }
    }

    const close = () => openDialog(ThePositionCloseConfirmDialog, {
      order: props.order,
      onClosed: R.juxt([promptToDeposit, share]),
    })

    const edit = () => openDialog(ThePositionEditDialog, { position: props.order })

    onMounted(() => {
      toggle.value = accordion(acc.value as HTMLElement)
    })

    const toggleSlide = () => {
      show.value = !(show.value)
      toggle.value()
    }

    return {
      acc,
      close,
      edit,
      byCoupon,
      show,
      toggleSlide,
    }
  },
})
