
import Pic from '@/components/Pic.vue'
import router from '@/router'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LimitDialogTemplate',
  components: { Pic, DialogTemplate },
  props: {
    i18nPath: String,
    i18nArgs: Object,
    defaultText: String,
  },
  emits: ['close', 'cancel'],
  setup () {
    const handleSeeDetail = () => {
      router.back()
      setTimeout(() => {
        router.push('/prime/detail')
      }, 300)
    }

    return {
      handleSeeDetail,
    }
  },
})
