
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { isDemoAccount } from '@/state/accountType'

export default defineComponent({
  name: 'MarginCall',
  components: { DialogTemplate },
  props: {
    margin: Number,
  },
  setup () {
    return {
      isDemoAccount,
    }
  },
})
