
import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'
import { keymap } from '@/config'
import { localGet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheActivity',
  components: {
    OnlyGroupA,
    Pic,
    RedPointAlert,
  },
  setup () {
    const indiaCountryCode = '91'

    const menus = [
      {
        route: '/membership/store',
        icon: 'user/Points_store',
        mainLabel: 'me_18',
        subLabel: 'me_19',
        title: 'Points store',
        subTitle: 'Exchange points for coupons and iphone',
      },
      {
        route: '/user/leverage',
        icon: 'user/leverage',
        mainLabel: 'me_20',
        subLabel: 'me_21',
        title: 'Leverage adjustment',
        subTitle: 'Choose what’s best for you',
        redPointId: 't_l_a',
      },
      {
        route: '/user/interest',
        icon: 'user/Interest_on_balance',
        mainLabel: 'me_14',
        subLabel: 'me_16',
        title: 'Interest on balance',
        subTitle: 'Up to 6% / YR',
        redPointId: 't_i_o_b',
      },
    ]

    if (localGet(keymap.user.countryCode) !== indiaCountryCode) {
      menus.push({
        route: '/share/invite',
        icon: 'user/Invite_friends',
        mainLabel: 'me_13',
        subLabel: 'me_15',
        title: 'Invite friends',
        subTitle: 'Get $5 coupon',
      })
    }
    return {
      menus,
    }
  },
})
