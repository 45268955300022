/**
* 通过判断value是否小于0或者大于等于0来添加适当的class以标记其颜色
* @author 贝才[beica1@outook.com]
* @date 2021/3/15
* @description
*   ColorText.vue of WeTrade
*/
<template>
  <span :class="[className]">
    <slot :label="label">{{label}}</slot>
  </span>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ColorText',
  props: {
    value: {
      type: Number,
      required: true,
    },
    display: {
      type: Array,
    },
  },
  setup (props) {
    const isNegative = computed(() => Number(props.value) < 0)
    const labels = props.display ?? [props.value, props.value]

    return {
      className: computed(() => isNegative.value ? 'c-danger' : 'c-success'),
      label: computed(() => labels[Number(!isNegative.value)]),
    }
  },
})
</script>
