/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   tagPrivate.ts of WeTrade
 */
import * as R from 'ramda'
import { RouteRecordRaw } from 'vue-router'

const markPrivate = (route: RouteRecordRaw) => {
  return R.assocPath(['meta', 'private'], true, route)
}

export default markPrivate
