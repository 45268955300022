<template>
  <DialogTemplate>
    <template #title>
      <t path="notification_36">Signal：Trend</t>
    </template>
    <div class="flex-1 t-center">
      <Pic :src="`trade/${data.position === 2 ? 'up' : 'down'}`" class="header-pic" />
      <t :path="data.position === 2 ? 'notification_37' : 'notification_38'"
         :args="{symbol: data.code}"
         as="p" class="content px-24"></t>
    </div>
    <template #cancelText>
      <t path="notification_39" custom #="{td}">
        {{td || 'Later'}}
      </t>
    </template>
    <template #confirm>
      <router-link #="{ navigate }" :to="`/symbol/${data.code}`" custom>
        <button class="flex-1 btn block primary" @click="$emit('close', navigate)">
          <t path="notification_40" custom #="{td}">{{td || 'See more'}}</t>
        </button>
      </router-link>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import Pic from '@/components/Pic.vue'
import { Types } from '@/modules/membership/signal/signal.api'

export default defineComponent({
  name: 'SignalTrend',
  components: { DialogTemplate, Pic },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup () {
    return {
      kType: Types.K_1D,
    }
  },
})
</script>

<style scoped lang="scss">
.header-pic {
  width: 40px;
  height: 40px;
  margin: 20px 0 10px;
}

.content {
  overflow: hidden;
  display: -webkit-box;
  line-height: 24px;
  margin-bottom: 24px;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
</style>
