
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionCreateResultDialog',
  components: { Button, Icon, DialogTemplate },
  emits: ['close'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
