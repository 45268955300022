
import { defineComponent } from 'vue'
import { usePositionCount } from '@/modules/user/user'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheActiveItem',
  components: { Pic, Icon },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return {
      isRise: parseFloat(props.marketItem.amplitude) > 0,
      count: usePositionCount(props.marketItem.code),
    }
  },
})
