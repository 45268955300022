/**
* @author zjc[beica1@outook.com]
* @date 2021/8/19 13:19
* @description
*   CurrentQuote.vue of WeTrade
*/
<template>
  <slot :symbol="symbol"></slot>
</template>

<script lang="ts">
import { marketFeed, QuoteMessage } from '@/common/datafeed'
import { readTheSymbol } from '@/modules/market/market.api'
import { defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'CurrentQuote',
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const symbol = shallowRef<QuoteMessage | null>(null)

    watchEffect(() => {
      const cached = marketFeed.getLatestQuote(props.code)
      if (cached) {
        symbol.value = cached
      } else if (props.code) {
        readTheSymbol(props.code).then(resp => {
          symbol.value = resp
        })
      }
    })

    return {
      symbol,
    }
  },
})
</script>

<style scoped>

</style>
