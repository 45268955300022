
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'

export default defineComponent({
  name: 'PostNotice',
  components: { DialogTemplate },
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
})
