
import { MarketItem } from '@/modules/market/market.types'
import { defineComponent, shallowRef } from 'vue'
import { productDetail } from '@/modules/market/market.api'

export default defineComponent({
  name: 'TheProfile',
  props: {
    code: String,
  },
  setup (props) {
    const product = shallowRef<MarketItem | null>(null)
    const profile = shallowRef<string[]>([])

    productDetail({ code: props.code }).then((resp: MarketItem) => {
      product.value = resp
      const temp = resp.profile.split(';')
      profile.value = temp.map(item => {
        return item
      })
    })
    return {
      product,
      profile,
    }
  },
})

