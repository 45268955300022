/**
* @author zjc[beica1@outook.com]
* @date 2021/10/15 17:44
* @description
*   TheStudyBar.vue of WeTrade
*/
<template>
<div class="df-middle">
  <div class="flex-1">
    <TheSelectBar
      :options="studies" #="{item, active, select}" :selected="selected"
    >
      <div
        class="flex-1 f-sm py-4 c-tip"
        :class="['study_item', {active}, item.class]"
        @click="change(item, select)"
      >
        {{item.label}}
        <Icon v-if="item.elite && active" name="membership" class="c-asst" sm />
        <Pic v-else-if="item.elite" src="elite" width="16" height="16" />
      </div>
    </TheSelectBar>
  </div>
  <slot />
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import TheSelectBar from '@/modules/market/components/chart/TheSelectBar.vue'
import { defineComponent } from 'vue'
import { StudyConfig } from 'happychart/core/AbstractStudy'

export default defineComponent({
  name: 'TheStudyBar',
  components: { TheSelectBar, Icon, Pic },
  emits: ['change'],
  props: {
    studies: Array,
    selected: Number,
  },
  setup (props, ctx) {
    const change = (item: StudyConfig, apply: (item: StudyConfig) => void) => {
      ctx.emit('change', item, () => {
        apply(item)
      })
    }

    return {
      change,
    }
  },
})
</script>

<style scoped lang="scss">
.study_item {
  max-width: 70px;
  color: #667C8D;
}

.active {
  color: var(--color-text);
  font-weight: bold;
}
</style>
