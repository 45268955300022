<template>
<div class="px-16 pt-24 pb-32" style="border-top: 1px solid var(--color-border);">
  <t path="symbol_100" :args="{number: trend.analyst}" class="f-bold" style="line-height: 24px;">{{trend.analyst}} Analyst Ratings</t>
  <div class="df-between mt-16 mb-12">
    <div class="c-success">
      {{trend.up}}% <t path="symbol_101">Buy</t>
    </div>
    <div class="c-danger">
      {{trend.down}}% <t path="symbol_102">Sell</t>
    </div>
  </div>
  <div class="bar br-sm">
    <div class="positive" :style="{width: `${trend.up}%`}"></div>
  </div>
</div>
</template>

<script lang="ts">
import { readTradeTrend } from '@/modules/market/market.api'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheAnalysisRatio',
  components: { },
  setup () {
    const route = useRoute()
    const code = route.params.code as string
    const trend = shallowRef({ up: 50, down: 50, analyst: 0 })
    readTradeTrend({
      contract: code,
    }).then(resp => trend.value = resp)

    return {
      trend,
    }
  },
})
</script>

<style scoped lang="scss">

.bar {
  height: 16px;
  overflow: hidden;
  background: var(--color-danger);
}

.positive {
  position: relative;
  height: 100%;
  background: var(--color-success);

  &::after {
    position: absolute;
    top: -50%;
    height: 200%;
    right: -3px;
    width: 6px;
    background: var(--color-theme);
    display: inline-block;
    transform: rotate(25deg);
    content: "";
  }
}
</style>
