/**
* @author 贝才[beica1@outook.com]
* @date 2020/11/27
* @description
*   Popup.vue of FastTrade
*/
<template>
  <teleport v-for="widget in widgets" :key="widget.id" to="body">
    <div :class="['popup', widget.class]" @click="widget.touchToClose ? close(widget.id) : () => {}">
      <TransitionProvider :name="widget.transition" appear @after-leave="remove(widget.id)">
        <component
          :is="widget.component" v-if="widget.render" :id="widget.id"
          :key="widget.id" @close="close(widget.id)"
        />
      </TransitionProvider>
    </div>
  </teleport>
</template>

<script lang="ts">
import TransitionProvider from 'common/providers/TransitionProvider.vue'
import { onBeforeUnmount } from 'vue'
import usePopup from './popup'

export default {
  name: 'Popup',
  components: { TransitionProvider },
  setup () {
    const [widgets, close, remove, clean] = usePopup()

    onBeforeUnmount(clean)

    return {
      widgets,
      close,
      remove,
    }
  },
}
</script>
