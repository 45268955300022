/**
* @author zjc[beica1@outook.com]
* @date 2021/11/16 14:56
* @description
*   TheHorizontalScan.vue of WeTrade
*/
<template>
  <div v-if="crosshair" class="line h" :style="{left: `${crosshair ? crosshair.x : 0}px`}" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheHorizontalScan',
  props: {
    crosshair: Object,
  },
})
</script>

<style scoped>
.line {
  position: absolute;
}

.h {
  top: 0;
  bottom: 0;
  width: 1px;
  background: var(--color-text);
}
</style>
