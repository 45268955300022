<template>
  <router-link
    :to="`/news/${news.articleId}`"
    class="py-16 list-item d-b"
  >
    <div class="header df-middle f-sm">
      <Pic
        :src="news.authorUrl" fallback="/img/user/Avatar@2x.png"
        width="32" height="32" style="border-radius: 50%"
      />
      <div class="flex-1 ml-16 f-sm">
        <p class="mb-4">{{news.authorName}}</p>
        <Time :time="news.createTime" format="MM-dd-yyyy HH:mm:ss" class="c-tip" />
      </div>
      <slot />
    </div>
    <div class="my-16 df-middle">
      <Pic v-if="news.bannerUrl" :src="news.bannerUrl" alt="thumb"
        class="mr-16" width="64"
      />
      <p>{{ news.articleTitle }}</p>
    </div>
  </router-link>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import Time from '@/components/Time.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheNewsItem',
  components: { Pic, Time },
  props: {
    news: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const post = computed(() => {
      return {
        avatarPic: props.news.authorUrl,
        nickName: props.news.authorName,
        createTime: props.news.createTime,
      }
    })
    return {
      post,
    }
  },
})

</script>
