/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/2
* @description
*   Checkbox.vue of WeTrade
*/
<template>
  <label class="checkbox">
    <slot name="prefix" />
    <input v-show="false" :checked="isChecked" type="checkbox" @change="toggle($event.target.checked)">
    <span class="checkbox-ui d-ib v-middle">
      <Icon class="check" name="check" sm />
    </span>
    <slot />
  </label>
</template>

<script lang="ts">
import * as R from 'ramda'
import Icon from '@/components/Icon.vue'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Checkbox',
  components: { Icon },
  emits: ['toggle', 'update:modelValue'],
  props: {
    checked: Boolean,
    value: String,
    modelValue: Array,
  },
  setup (props, ctx) {
    const isChecked = computed(() => {
      if (props.modelValue) {
        return R.includes(props.value, props.modelValue)
      } else return props.checked
    })

    const toggle = (checked: boolean) => {
      ctx.emit('toggle', checked)
      if (props.modelValue) {
        const next = checked ? [...props.modelValue, props.value] : R.reject(
          R.equals(props.value), props.modelValue as Array<string>)
        ctx.emit('update:modelValue', next)
      }
    }

    return {
      isChecked,
      toggle,
    }
  },
})
</script>

<style scoped lang="scss">
.checkbox {
  line-height: 1;
}

.checkbox-ui {
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
  background: var(--color-grey);

  :checked + & {
    background: var(--color-primary);

    .check {
      display: inline-block;
    }
  }
}

.check {
  display: none;
  color: var(--color-white);
  vertical-align: top;
}
</style>
