/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/5
 * @description
 *   trade.api.ts of WeTrade
 */
import requestResult, { requestByAccountType } from '@/common/request/request'
import withAccountUpdate from '@/decorators/withAccountUpdate'
import { MarketState } from '@/modules/market/market.types'
import { TradeDirection, YesOrNo } from '@/types'
import { UserAccount } from '@/state'

export type Product = {
  calculatePoint: number;
  code: string;
  contractVolume: string;
  deferred: string;
  isClosed: MarketState;
  isOften: YesOrNo;
  maxStopLoss: string;
  maxStopProfit: string;
  minStopLoss: string;
  minStopProfit: string;
  pointStep: string;
  price: string;
  cost: string;
  productId: string;
  sxf: string;
  unit: string;
  weight: string;
  yk: string;
  ykUnit: string;
  dotCount: number;
  contract: string;
  choiceId: string;
  orderNumber: number;
}

export type ModelSchema = {
  deferred: YesOrNo;
  type: TradeDirection;
  isJuan: YesOrNo;
  offset?: number;
  orderId?: number;
  orderNumber: number;
  price: string;
  productId: string;
  stopProfit: number;
  stopLoss: number;
  isTrackSt: YesOrNo;
}

export type Position = Required<Product & Omit<ModelSchema, 'deferred'>> & {
  profitability: number;
  profitLoss: number;
  createPrice: string;
  stopProfitPoint: number;
  stopLossPoint: number;
  realTimeProfitLoss: number;
  levelType: number;
  leverage: string;
}

export type Commission = Position & {
  offset: number;
  entrustPrice: string;
  entrustTime: number;
}

export const readCommissions = requestByAccountType<Array<Commission>>('readCommission')

export const readCommission = requestByAccountType<Commission>('ofCommission')

export const createPosition = withAccountUpdate(requestByAccountType<Position>('create'))

export const createCommission = requestByAccountType<Commission>('createCommission')

export const close = withAccountUpdate(requestByAccountType<Position>('close'))

export const cancel = withAccountUpdate(requestByAccountType('cancelCommission'))

export const edit = requestByAccountType('edit')

export const editCommission = requestByAccountType('editCommission')

// export const readProducts = requestResult<Array<Product>>('/api/product/mt/code')
export const readProducts = requestResult<Product>('/api/product/mt/user/product')

export const read = requestByAccountType('history')

export const readPositionDetail = requestResult<Position>('/api/order/mt/history/detail')

export const readProduct = requestResult<Product, { productId: string }>('/api/product/mt/detail')

export const addDemoBalance = withAccountUpdate(requestResult('api/account/balance/demo/add'))

export const readDemoAccountInfo = requestResult<UserAccount>('api/account/mt/demo/info')

export const checkIsFirstDeposit = requestResult<{ first: number, recipients: number }>('/api/recharge/first/info')
