<template>
<div class="header df-middle f-sm">
  <Pic
    :src="postData.avatarPic" fallback="/img/user/Avatar@2x.png"
    width="32" height="32" style="border-radius: 50%"
  />
  <div class="flex-1 ml-12 f-sm">
    <p class="mb-4">{{postData.nickName}}</p>
    <Time :time="postData.createTime" format="MM-dd-yyyy HH:mm:ss" class="c-tip" />
  </div>
  <slot />
</div>
<div class="mt-16 program" :class="{content: !detail}" v-html="useTranslate?formatText:postData.content" @click="handleClick" />
<Button v-if="useTranslate" @click="translate" :progress="progress" class="round f-sm mt-24"
  :class="{primary: isOrigin}" sm style="height: 22px;line-height: 18px">
  <t :path="isOrigin ? 'post_10' : 'post_11'"></t>
</Button>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import useGoogleTranslate from '@/hooks/useGoogleTranslate'
import { renderProgram } from '@/modules/community/communities/community'
import { computed, defineComponent, reactive, watch } from 'vue'
import Pic from 'common/Pic.vue'
import Time from '@/components/Time.vue'

export default defineComponent({
  name: 'ThePostTemplate',
  components: { Button, Time, Pic },
  props: {
    post: {
      type: Object,
      required: true,
    },
    detail: Boolean,
    useTranslate: Boolean,
  },
  setup (props) {
    const postData = reactive(props.post)
    postData.content = renderProgram(postData.content)
    watch(() => props.post, () => {
      postData.avatarPic = props.post.avatarPic
      postData.nickName = props.post.nickName
      postData.createTime = props.post.createTime
      postData.content = renderProgram(props.post.content)
    }, { deep: true })
    const { translate, content, progress, isOrigin } = useGoogleTranslate(postData.content)

    const formatText = computed(() => {
      return renderProgram(content.value)
    })

    return {
      progress,
      translate,
      isOrigin,
      formatText,
      postData,
      handleClick (e: Event) {
        if ((e.target as Element)?.tagName === 'A') {
          e.stopPropagation()
        }
      },
    }
  },
})
</script>

<style scoped>
.program {
  word-break: break-word;
  line-height: 20px;
}

.content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

</style>
