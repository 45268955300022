/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   ThePositionItem.vue of WeTrade
*/
<template>
  <div class="trade-item py-16 px-16 br-lg">
    <div class="brief d-f f-md">
      <div class="flex-1">
        <p class="row">
          <t path="symbol_7" class="label">Volume</t>
          <span class="value">{{prop('weight', order)}} {{prop('unit', order)}}</span>
        </p>
        <p class="row">
          <t path="symbol_11" class="label">Margin</t>
          <money class="value" :v="prop('buyMoney', order)" #="{text}">
            {{text}}
            <Icon v-if="byCoupon" name="coupon" class="c-title" sm />
          </money>
        </p>
      </div>
      <div class="flex-1 d-f">
        <div class="flex-1">
          <p class="row df-middle">
            <t path="symbol_8" class="label">Direction</t>
            <t custom #="{td, t}">
              <ColorText class="f-medium"
                :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
                :value="prop('type', order) - 2" />
            </t>
            <Pic v-if="order.source" src="trade/documentary" width="16" height="16" class="ml-8" />
          </p>
          <p class="row">
            <t path="symbol_12" class="label">Profit</t>
            <money #="{text, origin}" :v="prop('realTimeProfitLoss', order)" class="value">
              <ColorText :value="origin">{{text}}</ColorText>
            </money>
          </p>
        </div>
        <Icon
          :name="show ? 'caret-up' : 'caret-down'"
          class="toggle-icon" sm :class="{up: show}"
          @click="toggleSlide"
        />
      </div>
    </div>
    <div ref="acc">
      <div class="accordion__content more d-f f-md">
        <div class="flex-1">
          <p class="row">
            <t path="symbol_13" class="label">T/P</t>
            <TheTradeLimitDisplay class="value" :value="prop('stopProfitPoint', order)" />
          </p>
          <p class="row">
            <t path="symbol_15" class="label">Entry Price</t>
            <span class="value">{{prop('createPrice', order)}}</span>
          </p>
          <p class="row">
            <t path="symbol_17" class="label">Commission</t>
            <money class="value" :v="prop('fee', order)" />
          </p>
          <p class="row">
            <t path="transactionhistory_25" class="label">Leverage</t>
            <t class="value" :path="prop('leverage', order)"></t>
          </p>
        </div>
        <div class="flex-1">
          <p class="row">
            <t path="symbol_14" class="label">S/L</t>
            <!--<span class="value">{{prop('stopLossPoint', order)}}</span>-->
            <TheTradeLimitDisplay class="value" :value="prop('stopLossPoint', order)" />
          </p>
          <p class="row">
            <t path="symbol_16" class="label">Entry Time</t>
            <Date class="value f-xs" format="MM-dd-yyyy HH:mm:ss"
              :time="prop('createTime', order)" />
          </p>
          <p class="row">
            <t path="symbol_18" class="label">Swap</t>
            <money class="value" :v="prop('deferred', order)" />
          </p>
          <p class="row">
            <t path="transactionhistory_29" class="label">Profit per pip</t>
            <money class="value" :v="prop('floatProfit', order)" #={value}>{{value + ' ' + prop('ykUnit', order)}}</money>
          </p>
        </div>
      </div>
    </div>
    <div v-if="byCoupon" class="tip py-8 f-sm d-f">
      <Icon name="warn" sm class="mr-4" />
      <t path="symbol_19">
        Position with coupons will be automatically closed after the market is closed
      </t>
    </div>
    <div class="controls d-f mt-4">
      <button class="btn flex-2 modify sm br-sm" @click="edit">
        <t path="symbol_20" #="{td}" custom>{{td || 'Modify'}}</t>
      </button>
      <button class="btn flex-3 ml-8 primary line sm br-sm" @click="close(order.orderId)">
        <t path="symbol_21" #="{td}" custom>{{td || 'Close'}}</t>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import ColorText from '@/components/ColorText.vue'
import Date from '@/components/Date'
import Icon from '@/components/Icon.vue'
import { openDialog } from '@/components/popup/popup'
import ThePositionCloseConfirmDialog
  from '@/modules/trade/components/ThePositionCloseConfirmDialog.vue'
import ThePositionEditDialog from '@/modules/trade/components/ThePositionEditDialog.vue'
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import { Position } from '@/modules/trade/trade.api'
import ShareDialog from '@/pages/invite/ShareDialog.vue'
import { isDemoAccount } from '@/state/accountType'
import accordion from 'essential/dom/accordion'
import { computed, defineComponent, onMounted, shallowRef, ref } from 'vue'
import DepositDialog from '@/modules/trade/components/DepositDialog.vue'
import { localGet, localSet } from 'essential/store/localStore'
import { keymap } from '@/config'
import * as R from 'ramda'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'ThePositionItem',
  components: { Date, TheTradeLimitDisplay, ColorText, Icon, Pic },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const toggle = shallowRef(() => {
      console.log('accordion effect not work')
    })

    const show = ref(false)

    const acc = shallowRef<HTMLElement | null>(null)
    const byCoupon = computed(() => props.order.isJuan)

    const promptToDeposit = (order: Position) => {
      if (isDemoAccount.value && order?.profitLoss > 0 && !localGet(keymap.guide.depositTip)) {
        // 弹窗引导充值
        openDialog(DepositDialog, { wrapperClass: 'center' })
        localSet(keymap.guide.depositTip, '1')
      }
    }

    const share = (order: Position) => {
      if (order.profitability > 0.3) {
        // if (order.profitability) {
        openDialog(ShareDialog, {
          wrapperClass: 'center',
          order,
        })
      }
    }

    const close = () => openDialog(ThePositionCloseConfirmDialog, {
      order: props.order,
      onClosed: R.juxt([promptToDeposit, share]),
    })

    const edit = () => openDialog(ThePositionEditDialog, { position: props.order })

    onMounted(() => {
      toggle.value = accordion(acc.value as HTMLElement)
    })

    const toggleSlide = () => {
      show.value = !(show.value)
      toggle.value()
    }

    return {
      acc,
      close,
      edit,
      byCoupon,
      show,
      toggleSlide,
    }
  },
})
</script>

<style scoped lang="scss">
.trade-item {
  border: 1px solid var(--color-border);

  &:not(:first-child) {
    margin-top: 12px;
  }
}

.wedge {
  margin: 0 -16px;
  background: var(--color-light);
}

.row {
  margin-bottom: 12px;
}

.label {
  margin-right: 8px;
  color: var(--color-title);
}

.value {
  font-weight: 500;
  color: var(--color-text);
}

.modify {
  color: var(--color-primary);
  background: #E6EDFE;
}

.tip {
  font-style: italic;
  color: var(--color-title);
}
</style>
