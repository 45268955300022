<template>
  <div class="swipe__wrapper">
    <div
      ref="handle" class="d-f swipe"
      :style="{transform: `translate3d(${offset}px, 0, 0)`}"
    >
      <div
        v-for="(item, index) in list" :key="index"
        class="flex-1 swipe__item" :style="{left: index * 100 + '%'}"
        :class="itemClass"
      >
        <slot :item="item" />
      </div>
    </div>
    <ul v-if="dot" class="indicator"
    :class="{'t-right': dotPosition==='right', 't-left': dotPosition==='left', 't-center': !dotPosition}">
      <li v-for="i in list.length" :key="i" :class="['dot', {active: i - 1 === index, 'dot-dark': dotDark}]" />
    </ul>
  </div>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent } from 'vue'
import swipe from './swipe'

export default defineComponent(
  {
    props: {
      list: {
        type: Array,
        required: true,
      },
      dot: Boolean,
      dotDark: Boolean,
      dotPosition: String,
      autoPlay: Boolean,
      duration: {
        type: Number,
        default: 1000 * 3,
      },
      itemClass: {
        type: String,
        default: '',
      },
    },
    emits: ['change'],
    data () {
      return {
        timer: 0,
        width: 375,
        index: 0,
        transform: 0,
        moveOffset: 0,
      }
    },
    computed: {
      max (): number {
        return this.list.length - 1
      },

      offset (): number {
        return this.transform + this.moveOffset
      },

      handle (): HTMLImageElement {
        return this.$refs.handle as HTMLImageElement
      },
    },
    watch: {
      index (val: number, preVal: number) {
        this.$emit('change', val, preVal)
        this.prepareTransition()
        this.moveOffset = 0
        this.transform = -val * this.width
      },
    },
    mounted () {
      this.width = this.$el.clientWidth

      const slider = swipe(this.$el as Element, {
        dampRate: 0.2,
      })

      slider.on('touchstart', this.moveStart)
      slider.on('touchmove', this.move)
      slider.on('touchend', this.moveEnd)

      this.play()
    },
    activated () {
      this.play()
    },
    deactivated () {
      this.stop()
    },
    methods: {
      play () {
        if (!this.autoPlay || this.max < 1) return
        this.timer = window.setInterval(this.next, this.duration)
      },

      stop () {
        if (this.timer) {
          clearInterval(this.timer)
        }
      },

      cancelTransition () {
        this.handle.style.transition = 'none'
      },

      prepareTransition () {
        this.handle.style.transition = 'transform 0.3s ease-out'
      },

      next () {
        if (this.index >= this.max) {
          this.index = 0
        } else this.forward()
      },

      forward () {
        const next = R.min(this.index + 1, this.max)
        if (next !== this.index) {
          this.index = next
        } else this.reset()
      },

      previous () {
        const pre = R.max(this.index - 1, 0)
        if (pre !== this.index) {
          this.index = pre
        } else this.reset()
      },

      reset () {
        this.prepareTransition()
        this.moveOffset = 0
      },

      moveStart () {
        this.cancelTransition()
        this.stop()
      },

      move (offset: number) {
        this.moveOffset = offset
      },

      moveEnd (offset: number) {
        if (Math.abs(offset) > 50) {
          if (offset > 0) {
            this.previous()
          } else {
            this.forward()
          }
        } else {
          this.reset()
        }
        this.play()
      },
    },
  },
)
</script>

<style scoped lang="scss">
.swipe__wrapper {
  overflow: hidden;
  position: relative;
}
.swipe__wrapper,
.swipe {
  height: 100%;
}

.banner .swipe {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swipe__item:first-child img {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .swipe__item:last-child img {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.swipe__item {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.indicator {
  position: absolute;
  bottom: 4px;
  width: 100%;
}

.dot {
  display: inline-block;
  vertical-align: middle;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);

  &.active {
    background: white;
  }

  &:not(:first-child) {
    margin-left: 6px;
  }

  &:last-child{
    margin-right: 16px;
  }

  &:first-child{
    margin-left: 16px;
  }
}
.dot-dark{
  background: #DDE4EB;
  &.active {
    background: var(--color-text);
  }
}
</style>
