<template>
<router-link #="{ navigate }" :to="`/symbol/${marketItem.contract}`" custom>
  <tr @click="navigate">
    <td>
      <Pic :src="marketItem.icon" width="32" height="32" style="border-radius: 50%" />
    </td>
    <td>
      <p class="f-bold">{{marketItem.contract}}
      </p>
      <p class="f-sm c-title">{{marketItem.tips}}</p>
    </td>
    <td>
      <div class="df-middle df-between f-sm mb-4">
        <p>{{marketItem.up}}%
          <t path="market_40">Buy</t>
        </p>
        <p>{{marketItem.down}}%
          <t path="market_41">Sell</t>
        </p>
      </div>
      <div class="bar">
        <div class="positive" :style="{width: `${marketItem.up}%`}"></div>
      </div>
    </td>
  </tr>
</router-link>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSentiment',
  components: { Pic },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.bar {
  height: 4px;
  overflow: hidden;
  background: var(--color-danger);
}

.positive {
  position: relative;
  height: 100%;
  background: var(--color-success);

  &::after {
    position: absolute;
    top: -50%;
    height: 200%;
    right: -2px;
    width: 2px;
    background: white;
    display: inline-block;
    transform: rotate(45deg);
    content: "";
  }
}
</style>
