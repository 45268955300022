/**
* @author ayou[beica1@outook.com]
* @date 2022/1/26 17:02
* @description
*   MissionResult.vue of WeTrade
*/
<template>
<ResultDialogTemplate>
  <template #title><t path="notification_55"></t></template>
  <p v-if="isCoupon" class="c-asst f-bold">
    +<money :v="award"/> <t path="notification_56"></t>
  </p>
  <p v-else class="c-asst f-bold">
    +{{award}} <t path="notification_57"></t>
  </p>
  <p class="my-16">{{message}}</p>
</ResultDialogTemplate>
</template>

<script lang="ts">
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MissionResult',
  components: { ResultDialogTemplate },
  props: {
    award: {
      type: Number,
      required: true,
    },
    isCoupon: Boolean,
    message: String,
  },
})
</script>

<style scoped>

</style>
