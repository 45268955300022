/**
* @author 贝才[beica1@outook.com]
* @date 2021/5/18
* @description
*   RatioFixedArea.vue of WeTrade
*/
<template>
  <div :style="{'padding-top': `${ratio * 100}%`}" class="p-r">
    <div class="spread" v-bind="$attrs">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RatioFixedArea',
  inheritAttrs: false,
  props: {
    ratio: {
      type: Number,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
</style>
