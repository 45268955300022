/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/1
* @description
*   ThePositionEditResultDialog.vue of WeTrade
*/
<template>
<ResultDialogTemplate>
  <template #title>
    <t path="symbol_39">Modified</t>
  </template>
  <p class="c-title f-md t-left px-24">
    <t path="symbol_40" class="mr-6 f-md">Take Profit</t>
    <TheTradeLimitDisplay class="c-text f-medium" :value="top" />
    <span class="pull-right">
      <t path="symbol_41" class="mr-6 f-md">Stop Loss</t>
      <TheTradeLimitDisplay class="c-text f-medium" :value="bottom" />
    </span>
  </p>
</ResultDialogTemplate>
</template>

<script lang="ts">
import TheTradeLimitDisplay from '@/modules/trade/components/TheTradeLimitDisplay.vue'
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionEditResultDialog',
  components: {
    TheTradeLimitDisplay,
    ResultDialogTemplate,
  },
  props: {
    top: Number,
    bottom: Number,
  },
})
</script>
