
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import ColorText from '@/components/ColorText.vue'
import Money from '@/components.global/Money'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'FastTradeResult',
  components: { Pic, DialogTemplate, ColorText, Money, Icon },
  props: {
    order: Object,
  },
  setup (props) {
    const isUp = computed(() => {
      return props.order?.buyType === TradeDirection.BUY
    })

    return {
      isUp,
    }
  },
})
