<template>
<DialogTemplate>
  <template #title>
    <t>Alert: Horizontal line</t>
  </template>
  <p style="text-align: center;width: 100%;padding: 0 32px;">
    <t as="p" :path="type === 1 ? 'notification_76' : 'notification_75'"
      :args="{ symbol: code, line: price }">
      The price of XAUUSD just touched the 1820.50
    </t>
  </p>
  <template #cancel>
    <Button class="flex-1 btn block" @click="cancel">
      <t path="notification_77" #="{td}" custom>{{td || 'OK'}}</t>
    </Button>
  </template>
  <template #confirm>
    <Button class="flex-1 btn block primary" @click="check(code)">
      <t path="notification_78" #="{td}" custom>{{td || 'View chart'}}</t>
    </Button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import router from '@/router'

export default defineComponent({
  name: 'TouchLineDialog',
  components: { DialogTemplate },
  props: {
    code: String,
    price: Number,
    type: Number, // 1 下穿 2 上穿
  },
  emits: ['close', 'cancel'],
  setup (props, ctx) {
    const check = (code: string | undefined) => {
      ctx.emit('close')
      setTimeout(() => {
        router.push(`/symbol/${code}`)
      }, 200)
    }

    return {
      cancel: () => {
        ctx.emit('close')
        ctx.emit('cancel')
      },
      check,
    }
  },
})
</script>

<style scoped>

</style>
