
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheClock',
  props: {
    value: {
      type: Array,
      required: true,
    },
    section: Boolean,
  },
  setup () {
    return {}
  },
})
