/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/30
* @description
*   AsyncNullableList.vue of WeTrade
*/
<template>
  <div ref="el" class="list__wrapper">
    <Bouncing v-if="loading" class="anim my-12" :style="`top: -${refreshThreshold}px`" />
    <Nullable :content="listData">
      <List :list-data="listData" v-bind="attrs" :class="listClass">
        <template #prepend>
          <slot name="prepend" />
        </template>
        <template #default="{ item }">
          <slot :item="item" />
        </template>
        <template #append>
          <slot name="append" />
        </template>
      </List>
    </Nullable>
  </div>
</template>

<script lang="ts">
import * as R from 'ramda'
import Nullable from '@/components/Nullable.vue'
import useDelay from 'common/hooks/useDelay'
import List from 'common/List/List.vue'
import Bouncing from 'common/loading/Bouncing.vue'
import swipe from 'essential/dom/swipe'
import { defineComponent, onMounted, shallowRef } from 'vue'

export default defineComponent({
  name: 'AsyncNullableList',
  components: { Bouncing, Nullable, List },
  emits: ['refresh'],
  props: {
    listData: {
      type: Array,
      required: true,
    },
    listClass: String,
    pullRefresh: Boolean,
  },
  setup (props, ctx) {
    const refreshThreshold = 42
    const el = shallowRef()
    const delay = useDelay(600)
    const loading = shallowRef(false)

    const pullRefresh = (pullDistance: number, releaseSwipe: () => void) => {
      el.value.style.transition = 'transform 0.1s ease'

      const restore = () => {
        el.value.style.transform = 'none'
        releaseSwipe()
      }

      if (!pullDistance) {
        return restore()
      }

      if (pullDistance < refreshThreshold) {
        restore()
      } else {
        // loading
        loading.value = true
        el.value.style.transform = `translate3d(0, ${refreshThreshold}px, 0)`
        ctx.emit('refresh', delay(() => {
          restore()
          loading.value = false
        }))
      }
    }

    onMounted(() => {
      if (props.pullRefresh && el.value) {
        swipe(el.value, {
          direction: 'vertical',
          range: [0, 200],
          resistanceRatio: 0.8,
          onSwiped ({ delta: [, deltaY] }, long, release) {
            pullRefresh(deltaY ** 0.8, release)
          },
        })
      }
    })

    return {
      refreshThreshold,
      el,
      loading,
      attrs: R.omit(['class', 'style'], ctx.attrs),
    }
  },
})
</script>

<style scoped>
.anim {
  position: absolute;
  left: 0;
  right: 0;
}
</style>
