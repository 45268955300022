/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 15:10
* @description
*   ForThePure.vue of WeTrade
*   未充值用户的任务卡片
*/
<template>
<div class="t-center">
  <Pic type="svg" src="home/dollar" width="56" height="56" />
  <t as="p" path="home_16" class="mt-20 f-xxl f-bold">Get bounds for deposit $50</t>
</div>
<div class="d-f mt-20 px-20">
  <t as="p" path="home_17">Make single deposit of $50 to get a $10 coupon</t>
  <Pic src="home/coupon$5" width="80" height="56" class="ml-16" />
</div>
<div class="t-center">
  <router-link to="/in" custom #="{navigate}">
    <button class="recharge mt-16 f-bold br-lg" @click="navigate">
      <t path="home_38">Deposit</t>
    </button>
  </router-link>
</div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForThePure',
  components: { Pic },
})
</script>

<style scoped lang="scss">
.recharge {
  height: 36px;
  width: 63%;
  margin-bottom: 40px;
  border: 1px solid var(--color-text);
  background: transparent;
}
</style>
