
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import router from '@/router'

export default defineComponent({
  name: 'CupTradeDialog',
  components: { DialogTemplate },
  props: {
    nikeName: String,
    code: String,
    price: Number,
    type: Number,
  },
  emits: ['close', 'cancel'],
  setup (props, ctx) {
    const check = (code: string | undefined) => {
      ctx.emit('close')
      setTimeout(() => {
        router.push(`/symbol/${code}`)
      }, 200)
    }

    return {
      cancel: () => {
        ctx.emit('close')
        ctx.emit('cancel')
      },
      check,
    }
  },
})
