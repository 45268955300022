/**
* @author ayou[beica1@outook.com]
* @date 2022/1/17 11:29
* @description
*   RedPoint.vue of WeTrade
*/
<template>
<component :is="tag" :class="className" @click="read">
  <slot />
</component>
</template>

<script lang="ts">
import { localGet, localSet } from 'essential/store/localStore'
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'RedPointAlert',
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: 'span',
    },
    id: {
      type: String,
      required: true,
    },
    trigger: {
      type: Boolean,
      default: true,
    },
  },
  setup (props) {
    const noRecord = shallowRef(!localGet(props.id))
    const unread = computed(() => props.display && Boolean(props.id) && noRecord.value)
    const className = computed(() => unread.value ? 'unread' : '')

    const read = () => {
      if (props.trigger && props.display) {
        localSet(props.id, '1')
        noRecord.value = false
      }
    }

    return {
      unread,
      className,
      read,
    }
  },
})
</script>

<style scoped lang="scss">
.unread {
  position: relative;

  &::after {
    top: 8px;
    right: 8px;
    position: absolute;
    display: inline-block;
    width: 8px;
    height: 8px;
    background: var(--color-danger);
    border-radius: 8px;
    content: '';
  }
}
</style>
