
import state from '@/state'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'OnlyGroupA',
  components: {},
  props: {
    custom: Boolean,
  },
  setup () {
    return {
      isUserInGroupA: computed(() => state.group === 'A'),
    }
  },
})
