import { ComponentOptions, h } from 'vue'
import { popup } from 'common/popout/popup'
import TheLiveAlert from '@/pages/liveForQuickPlay/components/liveAlert/TheLiveAlert.vue'
import TheNoPointAlert from '@/pages/liveForQuickPlay/components/liveAlert/noPointAlert.vue'
import TheGetPointAlert from '@/pages/liveForQuickPlay/components/liveAlert/TheGetPointAlert.vue'

export const liveAlert = (msg: string | ComponentOptions, opts?: ComponentOptions) => {
  const close = popup({
    class: 'message',
    transition: 'slide-down',
    component: {
      setup () {
        return () => h(TheLiveAlert, {
          autoClose: true,
        }, h(msg, {
          onClose: close,
          ...opts,
        }))
      },
    },
  })
  return close
}

export const noPointAlert = () => {
  liveAlert(TheNoPointAlert)
}

export enum GetPointAlertEnum {
  DEFAULT,
  WATCH_TIME,
  TRADE_TIMES
}

export type GetPointAlertType = {
  title: string,
  point: number,
  time?: number
}

export const getPointAalert = (type: GetPointAlertEnum, opts: GetPointAlertType) => {
  liveAlert(TheGetPointAlert, {
    type,
    ...opts,
  })
}
