/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   TheAccountSwitch.vue of WeTrade
*/
<template>
  <div class="switch df-middle">
    <div
      v-for="(account, idx) in accounts" :key="account.value"
      class="item flex-1" :class="{active: idx === index}"
      @click="change(account)"
    >
      <t :path="account.label">{{account.text}}</t>
    </div>
  </div>
</template>

<script lang="ts">
import { useAccounts } from '@/state/accountType'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheAccountSwitch',
  setup () {
    const { accounts, change, index } = useAccounts()

    return {
      accounts,
      index,
      change,
    }
  },
})
</script>

<style scoped lang="scss">
.switch {
  padding: 6px;
  font-weight: bold;
  border-radius: 6px;
  text-align: center;
  color: rgba(#fff, 0.4);
  background: #174EDC;
}

.item {
  padding: 4px 0;
  border-radius: 3px;

  &:nth-child(2).active {
    background: var(--color-asst);
  }
}

.active {
  color: #fff;
  background: var(--color-primary);
}
</style>
