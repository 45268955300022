/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/2
* @description
*   TheEditListItem.vue of WeTrade
*/
<template>
  <div class="d-f df-middle edit__item">
    <slot />
    <div class="flex-1 content df-middle">
      <div class="flex-1">
        <p class="name" style="font-weight: 400;">{{item.code}}</p>
        <p class="f-sm">{{item.tips}}</p>
      </div>
      <div>
        <Icon name="top" sm @click="$emit('toTop', item.code, $el)" />
        <span class="d-ib wedge" style="width: 60px"></span>
        <Icon name="menu" sm />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheEditListItem',
  components: { Icon },
  emits: ['toTop'],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/_index.scss" as mixins;

.edit__item {
  height: 56px;
  color: var(--color-title);
}

.content {
  height: 100%;
  @include mixins.half-pixel-border(bottom, var(--color-light));
}

.name {
  color: var(--color-text);
}
</style>
