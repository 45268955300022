<template>
<div v-if="show" class="user-guide px-16 py-8 br-lg f-md d-f">
  <p class="flex-1 d-f">
    <Pic src="fast_trade/description" width="16" height="16" />
    <t class="flex-1 ml-8" as="p" path="symbol_87">
      Please be informed that when trading cryptocurrencies on Contracts for Difference (CFDs),
      which are over-the-counter derivatives, you have no entitlement, right or obligation to the
      underlying financial asset.
    </t>
  </p>
  <div class="ml-16">
    <Icon name="close" class="close" @click="gotIt" />
  </div>
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { isCrypto } from '@/decorators/withoutCryptos'
import { localGet, localSet } from 'essential/store/localStore'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import { keymap } from '@/config'

export default defineComponent({
  name: 'TheCryptoTips',
  components: { Icon, Pic },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const show = shallowRef(false)

    isCrypto(props.code).then(
      yes => show.value = !localGet(keymap.guide.cryptoTrade) && yes)

    const gotIt = () => {
      localSet(keymap.guide.cryptoTrade, '1')
      show.value = false
    }

    return {
      show,
      gotIt,
    }
  },
})
</script>

<style scoped lang="scss">
.user-guide {
  color: var(--color-white);
  background: rgba(#181D24, 0.9);
  width: calc(100vw - 32px);
  position: fixed;
  top: 48px;
  left: 16px;
  z-index: 2;
}

.close {
  color: rgba(#181D24, 0.9);
  background: var(--color-white);
  border-radius: 50%;
}
</style>
