/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/12
* @description
*   GuideContent.vue of WeTrade
*   提供一个relative的容器环境 当guideContent为absolute时有用
*/
<template>
  <div class="guide-content p-r">
    <slot v-if="show" name="top" :gotIt="gotIt" />
    <slot />
    <slot v-if="show" name="bottom" :gotIt="gotIt" />
  </div>
</template>

<script lang="ts">
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { computed, defineComponent, onDeactivated, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'GuideTarget',
  emits: ['gotIt'],
  props: {
    name: {
      type: String,
      requiredF: true,
    },
    hide: Boolean,
  },
  setup (props, ctx) {
    const key = keymap.guide[props.name as keyof typeof keymap.guide]

    const haveDisplayHistory = shallowRef(!key || (key && Boolean(localGet(key))))

    const show = computed(() => !props.hide && !haveDisplayHistory.value)

    function gotIt () {
      ctx.emit('gotIt')
    }

    const read = () => {
      localSet(key, '1')
    }

    watchEffect(() => {
      if (show.value) {
        read()
      } else {
        gotIt()
      }
    })

    onDeactivated(() => {
      read()
      haveDisplayHistory.value = true
    })

    return { show, gotIt }
  },
})
</script>

<style scoped>
.guide-content {
  z-index: 1;
}
</style>
