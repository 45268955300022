<template>
  <div class="google-auth" v-if="visible" @click="bind">
    <div class="content">
      <Pic src="home/google" width="32" height="32" />
      <t path="home_65" class="flex-1 pl-8">Connect to Google account</t>
      <span class="coupon">$ {{ sendVoucherAmount }}</span>
      <Pic src="home/google_go" width="16" height="16"/>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef, onActivated, onMounted, onUnmounted } from 'vue'
import Pic from '@/components/Pic.vue'
import { useBindGoogle } from '@/features/googleLogin/googleLogin.fns'
import { checkIsFirstDeposit } from '@/modules/trade/trade.api'
import { format, events } from '@/config'
import { readGoogleCoupon } from '../home.api'
import { on, off } from 'essential/tools/event'

export default defineComponent({
  name: 'TheGoogleAuth',
  components: {
    Pic,
  },
  setup () {
    const visible = shallowRef(false)
    const sendVoucherAmount = shallowRef(0)
    const { hasBind, bind } = useBindGoogle()

    onActivated(() => {
      visible.value = false
      if (!hasBind.value) {
        checkIsFirstDeposit().then(res => {
          if (res.first === 0) {
            readGoogleCoupon().then(res => {
              sendVoucherAmount.value = (res.sendVoucherAmount || 0) / format.moneyFactor
              visible.value = true
            })
          }
        })
      }
    })

    onMounted(() => {
      on(events.googleLogin, () => {
        visible.value = false
      })
    })

    onUnmounted(() => {
      off(events.googleLogin)
    })

    return {
      sendVoucherAmount,
      visible,
      bind,
    }
  },
})
</script>

<style scoped lang='scss'>
.google-auth{
  position: fixed;
  top: calc(100vh - 120px);
  left: 0;
  width: 100vw;
  height: 56px;
  background: url(/img/home/google_banner@2x.png) no-repeat;
  background-size: 100% 100%;
}
.content{
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 8px 0 16px;
  color: #fff;
}
.coupon{
  position: absolute;
  top: 32px;
  right: 13vw;
  width: 14vw;
  text-align: center;
}
</style>
