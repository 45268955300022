<template>
  <div v-if="isShowBubbles === 1 && !remindPlay" class="main d-f t-left">
    <t path="market_27" class="f-md c-white flex-1" as="p" style="line-height: 18px;">
      Try “Fast play” to get 100% profit within several minutes
    </t>
    <Icon name="close" class="close c-text" @click="close" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import state from '@/state'
import Icon from '@/components/Icon.vue'
import { localGet, localSet } from 'essential/store/localStore'
import { keymap } from '@/config'

export default defineComponent({
  name: 'RemindPlay',
  components: { Icon },
  setup () {
    const isShowBubbles = computed(() => state.account.isShowBubbles)

    const remindPlay = ref(localGet(keymap.guide.remindPlay))

    const close = () => {
      localSet(keymap.guide.remindPlay, '1')
      remindPlay.value = '1'
    }

    return {
      isShowBubbles,
      remindPlay,
      close,
    }
  },
})
</script>

<style scoped lang="scss">
  .main {
    position: absolute;
    width: 232px;
    right: 50%;
    margin-right: -100px;
    bottom: calc(100% + 4px);
    border-radius: 2px;
    padding: 8px 8px 8px 12px;
    background: var(--color-text);
    z-index: 1;

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: calc(100px - 4px);
      bottom: -4px;
      width: 8px;
      height: 8px;
      background: var(--color-text);
      transform: rotateZ(45deg);
    }

  }

  .close {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    border-radius: 50%;
    background: var(--color-white);
  }
</style>
