/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   redirect.ts of WeTrade
 */
import { localGet } from 'essential/store/localStore'
import * as R from 'ramda'
import { NavigationGuard, RouteLocationRaw } from 'vue-router'
import { flag, keymap } from '@/config'
import { isLogged, isNewLogin, isRoam } from '@/state'
import { muteOnInspectMode, onlyInspectMode } from '@/state/inspect'

const redirect: NavigationGuard = (
  to,
  from,
  next,
) => {
  // 测试环境不做限制
  // if (flag.isDevMode) return next()

  // 限制登录用户访问仅漫游模式可见的页面
  const isForbiddenForLoggedUser = R.pathEq(['meta', 'roamOnly'], true, to)
  const fromHome = R.propEq('path', '/', from)

  /**
   * 蛇和模式取消app首次启动时到登录页的重定向
   */
  const dest = muteOnInspectMode(() => {
    // 新用户首次打开重定向去登录页
    if (
      isNewLogin() &&
      fromHome &&
      R.not(isRoam()) &&
      R.not(isForbiddenForLoggedUser)
    ) {
      return { name: 'login' }
    }
  })

  if (dest) {
    return next(dest as RouteLocationRaw)
  }

  // 限制未登录用户访问私有页面
  const isPrivate = R.pathEq(['meta', 'private'], true, to)
  if (isPrivate && !isLogged()) {
    return next((to.meta.routeFallback as string) ?? { name: 'login' })
  }

  // 已经登录的用户限制访问login等页面
  if (
    isLogged() &&
    isForbiddenForLoggedUser
  ) {
    return next({ name: 'home' })
  }

  // 根据用户的countryCode重定向到特定的页面
  const isNeedRedirect = R.pathEq(['meta', 'redirectWithCC'], true, to)
  if (isNeedRedirect) {
    const cc = localGet(keymap.user.countryCode) ?? '*'
    return next({
      path: `${to.path}/${cc}`,
      query: to.query,
    })
  }

  /**
   * 审核模式重定向登录页的路由到home
   */
  const noLogin = onlyInspectMode(() => {
    if (to.path === '/login') {
      return next('/home')
    }
  }, () => flag.isIOS)

  if (!noLogin) {
    next()
  }
}

export default redirect
