/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/1
* @description
*   TheCommissionCreatedDialog.vue of WeTrade
*/
<template>
  <ResultDialogTemplate>
    <template #title><t path="placeorder_38">Limit Order Submitted</t></template>
    <t :path="order.type === 2 ? 'placeorder_39' : 'placeorder_40'"
      :args="{amount: `${prop('weight', order)} ${prop('unit', order)}`, symbol: prop('code', order), price: prop('entrustPrice', order)}"
      as="p">
      Bought {{prop('weight', order)}} {{prop('unit', order)}} of <span class="f-bold">{{prop('code', order)}}</span>
    </t>
  </ResultDialogTemplate>
</template>

<script lang="ts">
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheCommissionCreatedDialog',
  components: { ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
</script>
