import jsBridge from '@/common/jsBridge'
import Money from '@/components.global/Money'
import T from '@/components.global/T.vue'
import config, { flag } from '@/config'
import { i18nInstance } from '@/i18n'
import { configApp } from '@/modules/app/app'
import { checkInspectVersion } from '@/modules/app/app.api'
import { useInspectMode } from '@/state/inspect'
import '@/theme/index.scss'
import { YesOrNo } from '@/types'
import * as R from 'ramda'
import { createApp } from 'vue'
import App from './App.vue'
import NoService from './NoService.vue'
import './registerServiceWorker'
import router from './router'

if (flag.isPre) {
  import('vconsole').then(({ default: VConsole }) => {
    // eslint-disable-next-line no-new
    new VConsole()
  })
}

jsBridge.registerJSAdaptor()

const start = () => {
  createApp(App)
    .component('t', T)
    .component('money', Money)
    .mixin({
      // 防止魔法数字的便捷变量注入
      data () {
        return {
          DONE: YesOrNo.YES,
          YES: YesOrNo.YES,
          NO: YesOrNo.NO,
        }
      },
      // 便捷缺省变量函数注入
      methods: {
        prop (key: string, data: Data) {
          return R.propOr('--', key, data)
        },
        object (target: Record<string, unknown>) {
          return target ?? {}
        },
        array (target: Array<unknown>) {
          return target ?? []
        },
      },
    })
    .use(i18nInstance)
    .use(router)
    .mount('#app')
}

configApp()
  .then(noService => {
    if (noService) throw new Error('WeTrade is not available in yor country')

    // if (!config.flag.isIOS) return start()

    checkInspectVersion()
      .then(({ reviewVersion }) => {
        if (reviewVersion === config.request.staticRequestData.version) {
          useInspectMode()
        }
      })
      .finally(() => {
        start()
      })
  })
  .catch(() => {
    createApp(NoService).mount('#app')
  })
