
import { defineComponent } from 'vue'
// import Pic from 'common/Pic.vue'
// import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheHot',
  components: {
    // Icon,
    // Pic,
  },
  props: {
    topics: {
      type: Array,
      required: true,
    },
  },
  setup () {
    const w = document.getElementById('app')?.clientWidth || 375
    return {
      w,
    }
  },
})
