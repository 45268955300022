/**
* @author ayou[beica1@outook.com]
* @date 2022/1/26 13:26
* @description
*   ForFormalTrade.vue of WeTrade
*/
<template>
<t as="p" path="home_21" class="t-center f-xxl f-bold">Try Formal Trading</t>
<div class="d-f mt-16 px-20">
  <t as="p" path="home_22" class="mr-16">Place an order at formal trading on live account and get a
    $1 coupon
  </t>
  <Pic src="home/coupon$1" width="80" height="56" />
</div>
<div class="p-12 br-lg mt-24">
  <table class="f-sm">
    <tr>
      <td></td>
      <td colspan="2">
        <t path="home_23">Quick play</t>
        <span class="vs d-ib">VS</span>
        <t path="home_24">Formal trading</t>
      </td>
    </tr>
    <tr>
      <td>
        <t path="home_25">Profits</t>
      </td>
      <td>
        <t path="home_26">100%</t>
      </td>
      <td>
        <t path="home_27">Unlimited</t>
      </td>
    </tr>
    <tr>
      <td>
        <t path="home_28">Risk</t>
      </td>
      <td>
        <t path="home_29">High</t>
      </td>
      <td>
        <t path="home_30">Low</t>
      </td>
    </tr>
    <tr>
      <td>
        <t path="home_31">Predictability</t>
      </td>
      <td>
        <t path="home_32">Low</t>
      </td>
      <td>
        <t path="home_33">High</t>
      </td>
    </tr>
    <tr>
      <td>
        <t path="home_34">Easy</t>
      </td>
      <td>
        <t path="home_35">Yes</t>
      </td>
      <td>
        <t path="home_36">Yes</t>
      </td>
    </tr>
  </table>
</div>
<div class="t-center">
  <router-link to="/symbol/BTCUSD">
    <t as="button" class="br-lg try mt-24 c-text f-bold" path="home_37">
      Try Buying $1 Bitcoin
    </t>
  </router-link>
</div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForFormalTrade',
  components: { Pic },
})
</script>

<style scoped lang="scss">
table {
  background: var(--color-white);
  border-collapse: collapse;
  width: 100%;
}

tr {
  &:nth-child(1) {
    font-weight: bold;
    background: rgba(#B5BBCA, 20%);
    font-size: 11px;
  }

  &:nth-child(odd) {
    background: rgba(#B5BBCA, 9%);
  }
}

.vs {
  margin: 0 10px;
  padding: 2px;
  border-radius: 50%;
  font-size: 9px;
  font-weight: normal;
  color: white;
  background: var(--color-text);
}

td {
  height: 32px;
  padding: 0;

  &:nth-child(1) {
    padding-left: 16px;
    font-size: 11px;
    width: 100px;
    color: var(--color-title);
    background: rgba(#B5BBCA, 9%);
  }

  &:not(:nth-child(1)) {
    text-align: center;
  }

  &:nth-child(3) {
    color: var(--color-success);
    font-weight: bold;
  }
}

.try {
  height: 36px;
  width: 70%;
  margin-bottom: 32px;
  border: 1px solid var(--color-text);
  background: transparent;
}
</style>
