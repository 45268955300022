
import { defineComponent } from 'vue'
import { open as _open } from '@/common/jsBridge.api'

export default defineComponent({
  name: 'TermsAndPolicy',
  setup () {
    function open (path: string) {
      console.log('open url via system browser', location.origin + path)
      _open({ url: location.origin + path, target: 'system' })
    }

    return {
      open,
    }
  },
})
