/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/25
* @description
*   TheMarketItem.vue of WeTrade
*/
<template>
  <router-link #="{ navigate }" :to="`/symbol/${symbol && symbol.code}`" custom>
    <RealTimeQuote :symbol="symbol" #="{price, buy, spread, margin, mp, isRise}">
      <tr class="row f-md" @click="navigate">
        <td>
          <Pic :src="symbol.icon" width="32" height="32" style="border-radius: 50%" />
        </td>
        <td>
          <p class="f-medium f-nm df-middle">
            {{symbol && symbol.code}} <span v-if="count">({{count}})</span>
            <span v-if="isClosed" class="close ml-4">Closed</span>
            <Icon v-if="marketItem.specialIdentification===1||marketItem.specialIdentification===2" sm name="wt_mark" class="ml-4" style="color: var(--color-primary);" />
          </p>
          <p class="c-tip">{{marketItem.tips}}</p>
        </td>
        <td></td>
        <td>
          <span class="f-medium badge-market success" :class="{'danger': !isRise}">
            <p class="px-6 f-nm">{{price}}</p>
          </span>
          <p class="c-success mt-4 " :class="{'c-danger': !isRise}">{{mp}}</p>
        </td>
      </tr>
    </RealTimeQuote>
  </router-link>
</template>

<script lang="ts">
import { MarketState } from '@/modules/market/market.types'
import { usePositionCount } from '@/modules/user/user'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { computed, defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheMarketItem',
  components: {
    RealTimeQuote,
    Pic,
    Icon,
  },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const symbol = computed(() => {
      return {
        ...props.marketItem.realTimeMicroQuotationBean,
        icon: props.marketItem.icon,
      }
    })

    return {
      symbol,
      isClosed: computed(() => symbol.value && symbol.value.isClosed === MarketState.CLOSED),
      count: usePositionCount(props.marketItem.code),
    }
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/index" as mixins;

td {
  height: 64px;

  &:first-child {
    width: 40px;
  }
}

.diff {
  padding: 0 1px;
  font-size: 11px;
  border: 1px solid var(--color-title);
  border-radius: 2px;
}

.close {
  padding: 0 4px;
  background-color: var(--color-light);
  border-radius: 8px;
  color: var(--color-title);
  font-weight: normal;
  font-size: 11px;
}

.double{
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  height: 12px;
  width: 12px;
}
</style>
