
import state from '@/state'
import { computed, defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'ThePositionLines',
  props: {
    fy: {
      type: Function,
      required: true,
    },
    refreshId: {
      type: Number,
      required: true,
    },
  },
  setup () {
    const code = useRoute().params.code

    return {
      orders: computed(() => state.account.list.filter(order => order.code === code)),
    }
  },
})
