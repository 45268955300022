/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/3
* @description
*   Line.vue of WeTrade
*/
<template>
  <div class="line"></div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Line',
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/_index.scss" as mixins;

.line {
  height: 1px;
  @include mixins.half-pixel-border(top);
}
</style>
