
import { defineComponent } from 'vue'
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { isDemoAccount } from '@/state/accountType'

export default defineComponent({
  name: 'TradeResult',
  components: { ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const resText = [
      [
        {
          title: 'notification_20',
          content: 'notification_21',
        }, {
          title: 'notification_22',
          content: 'notification_23',
        }, {
          title: 'notification_24',
          content: 'notification_25',
        }, {
          title: 'notification_26',
          content: 'notification_27',
        },
      ], [
        {
          title: 'notification_6',
          content: 'notification_7',
        }, {
          title: 'notification_8',
          content: 'notification_9',
        }, {
          title: 'notification_10',
          content: 'notification_11',
        }, {
          title: 'notification_12',
          content: 'notification_13',
        },
      ],
    ]

    return {
      isDemoAccount,
      closeType: ['', '', '', 'Manual', 'Take profit', 'Stop loss', 'Stop out', 'Auto close'],
      resText,
      textIndex: props.order.closeType - 4,
    }
  },
})
