<template>
<div class="main-wraper">
  <OnlyGroupA custom #="{ hint }">
    <div v-for="menu in menus" :key="menu.route">
      <router-link v-if="!(!hint && menu.route === '/user/interest')" :to="menu.route" custom
        #="{navigate}">
        <div @click="navigate">
          <RedPointAlert :display="Boolean(menu.redPointId)" :id="menu.redPointId || ''"
            class="df-middle mb-8 py-12 px-16 box">
            <Pic :src="menu.icon" width="32" height="32" />
            <div class="flex-1 ml-12">
              <div class="desc df-center df-col">
                <t as="p" :path="menu.mainLabel" class="f-nm f-bold c-text">{{menu.title}}</t>
                <t as="p" :path="menu.subLabel" class="f-md f-400 c-text mt-4">{{menu.subTitle}}</t>
              </div>
            </div>
          </RedPointAlert>
        </div>
      </router-link>
    </div>
  </OnlyGroupA>
</div>
</template>

<script lang="ts">
import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'
import RedPointAlert from '@/provider/RedPointAlert.vue'
import { keymap } from '@/config'
import { localGet } from 'essential/store/localStore'

export default defineComponent({
  name: 'TheActivity',
  components: {
    OnlyGroupA,
    Pic,
    RedPointAlert,
  },
  setup () {
    const indiaCountryCode = '91'

    const menus = [
      {
        route: '/membership/store',
        icon: 'user/Points_store',
        mainLabel: 'me_18',
        subLabel: 'me_19',
        title: 'Points store',
        subTitle: 'Exchange points for coupons and iphone',
      },
      {
        route: '/user/leverage',
        icon: 'user/leverage',
        mainLabel: 'me_20',
        subLabel: 'me_21',
        title: 'Leverage adjustment',
        subTitle: 'Choose what’s best for you',
        redPointId: 't_l_a',
      },
      {
        route: '/user/interest',
        icon: 'user/Interest_on_balance',
        mainLabel: 'me_14',
        subLabel: 'me_16',
        title: 'Interest on balance',
        subTitle: 'Up to 6% / YR',
        redPointId: 't_i_o_b',
      },
    ]

    if (localGet(keymap.user.countryCode) !== indiaCountryCode) {
      menus.push({
        route: '/share/invite',
        icon: 'user/Invite_friends',
        mainLabel: 'me_13',
        subLabel: 'me_15',
        title: 'Invite friends',
        subTitle: 'Get $5 coupon',
      })
    }
    return {
      menus,
    }
  },
})
</script>

<style scoped lang="scss">
.desc {
  height: 48px;
}
</style>
