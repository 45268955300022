/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/8
 * @description
 *   request.ts of WeTrade
 */
import nativeExe from '@/common/request/exe.native'
import apis, { APINames } from '@/common/request/request.apis'
import { showAlert } from '@/components/popup/popup'
import { flag, events, request as requestConfig, market } from '@/config'
import { useAccountType } from '@/state/accountType'
import axios, { AxiosResponse } from 'axios'
import { emit } from 'essential/tools/event'
import makeRequestBy, {
  RequestConfig,
  RequestPayload,
  ResponseResult,
} from 'essential/net/http/makeRequestBy'
import * as R from 'ramda'

const attachGlobalParams = (payload: RequestPayload) => ({
  ...payload,
  data: {
    isTest: Number(flag.isDevMode),
    ...requestConfig.staticRequestData,
    ...requestConfig.dynamicRequestData,
    ...payload.data,
  },
})

const attachExchangeParams = (payload: RequestPayload) => R.mergeDeepRight(payload, {
  data: R.startsWith('/quotation', payload.url) ? { excode: market.excode } : {},
})

const exeAxiosPost = (payload: RequestPayload) => axios.post(
  payload.url, payload.data, payload.config)

interface ServerResponse<T = unknown> {
  code: string;
  data: T;
  msg: string;
}

const SERVER_RESPONSE_CODE = {
  SUCCESS: '0',
  ERROR: '-0',
  TOKEN_EXPIRED: '100014',
  TOKEN_RESET: '100013',
  ILLEGAL_MOBIL: '100002',
  MAINTAIN: '500018',
  OUT_FORBIDDEN: '300019',
  NO_NAME: '400002',
}

const parseAxiosResponse = (response: AxiosResponse) => {
  const serverResponse: ServerResponse = response.data

  return {
    s: serverResponse.code === SERVER_RESPONSE_CODE.SUCCESS,
    d: serverResponse.data,
    m: serverResponse.msg,
    c: serverResponse.code,
  }
}

const emitError = (resp: AxiosResponse) => {
  return ({
    s: false,
    m: resp.statusText,
    c: resp.status,
  })
}

const axiosRequest = makeRequestBy(
  R.pipe(exeAxiosPost, R.andThen(parseAxiosResponse), R.otherwise(emitError)),
  [attachGlobalParams, attachExchangeParams],
)

const nativeRequest = makeRequestBy(
  R.pipe(nativeExe, R.andThen(R.identity), R.otherwise(R.identity)),
  [attachGlobalParams, attachExchangeParams],
)

const request = flag.isDevMode ? axiosRequest : nativeRequest

/**
 * 显示异常信息
 * @param resp
 * @param url
 */
const bubbleException = (resp: ResponseResult, url: string) => {
  const message = R.cond<ResponseResult, string>(
    [
      [R.propEq('c', 404), R.always('Server is not available[10002]')],
      [R.T, R.propOr('Uncaught server error occurred![10003]', 'm')],
    ])(resp)
  const extra = flag.isDevContext ? `[${url}]` : ''
  showAlert(`${message}[${resp.c ?? -1}]${extra}`)
}

const isTokenError = R.o(
  R.contains(R.__, [
    SERVER_RESPONSE_CODE.TOKEN_EXPIRED,
    SERVER_RESPONSE_CODE.TOKEN_RESET,
  ]), R.prop<string, any>('c'))

/**
 * 响应特定的错误码
 * @param resp
 * @todo 异常去重处理 避免重复的逻辑调用
 */
const respondException = R.cond<ResponseResult, void>(
  [
    [isTokenError, () => emit(events.tokenExpired)],
  ],
)

const handleResponseError = (url: string, e: Error | ResponseResult, setting?: RequestConfig) => {
  const mute = !flag.isDevMode && setting?.silent
  if (!mute) {
    bubbleException(e as ResponseResult, url)
    respondException(e as ResponseResult)
  }
}

// @todo native 和axios异步逻辑有问题
export const requestResult = <T, P = unknown> (url: string) => {
  const exe = request<T, P>(url)
  return (...args: Parameters<typeof exe>) => new Promise<T>((resolve, reject) => {
    const [, setting] = args
    exe(...args)
      .then(resp => {
        if (resp.s) resolve(resp.d as T)
        else {
          handleResponseError(url, resp, setting)
          reject(resp)
        }
      }).catch(reject)
  })
}

export default requestResult

export const jsonp = <T> (url: string, data?: Data) => {
  return new Promise<T>((resolve, reject) => {
    const callback = 'callback_' + Math.random().toString(36).slice(2)

    const script = document.createElement('script')

    const remove = () => document.body.removeChild(script)

    ;(window as any)[callback] = (resp: T) => {
      resolve(resp)
      delete (window as any)[callback]
      remove()
    }

    const dataStr = R.pipe(R.toPairs, R.map(R.join('=')), R.join('&'))(data ?? {})

    script.src = url + '?callback=' + callback + '&' + dataStr

    script.onerror = () => {
      reject()
      remove()
    }

    document.body.appendChild(script)
  })
}

export const jsonpRequest = <T> (url: string) => async (data?: Data) => {
  const result = await jsonp<ServerResponse<T>>(url, data)
  if (result.code === SERVER_RESPONSE_CODE.SUCCESS) {
    return result.data
  } else {
    if (result.msg) {
      showAlert(result.msg)
    }
    throw new Error(result.msg ?? 'uncaught jsonp error')
  }
}

const getRequestUrlByAccountType = (name: APINames) => {
  const type = useAccountType()
  return apis[name][type.value]
}

type U = Parameters<ReturnType<typeof request>>

/**
 * 根据不同的账户类型调用不同的接口
 * @param name
 */
export const requestByAccountType = <T> (name: APINames) => (...args: U) => {
  const url = getRequestUrlByAccountType(name)
  return requestResult<T>(url)(...args)
}

export const get = axios.get
