/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/2
* @description
*   TheWatchSymbolButton.vue of WeTrade
*/
<template>
  <TheToggleStateButton :progress="progress" :on="added" @click="toggle(marketItem.code)">
    {{prop('code', marketItem)}}
    <template #suffix>
      {{prop('tips', marketItem)}}
    </template>
  </TheToggleStateButton>
</template>

<script lang="ts">
import TheToggleStateButton from '@/modules/market/components/TheToggleStateButton.vue'
import { useToggleState } from '@/modules/market/market'
import { MarketItem } from '@/modules/market/market.types'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheWatchSymbolButton',
  components: { TheToggleStateButton },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return useToggleState((props.marketItem as MarketItem).code)
  },
})
</script>
