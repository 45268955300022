
import Button from '@/components/Button.vue'
import { openDialog } from '@/components/popup/popup'
import { events } from '@/config'
import ThePositionEditResultDialog from '@/modules/trade/components/ThePositionEditResultDialog.vue'
import { useEdit } from '@/modules/trade/trade'
import { Position, readProduct } from '@/modules/trade/trade.api'
import TradeModel from '@/modules/trade/TradeModel'
import HoldOn from '@/provider/HoldOn.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { emit } from 'essential/tools/event'
import { defineComponent, provide, shallowReadonly, shallowRef } from 'vue'
import Line from '@/components/Line.vue'
import TheStopLoss from '@/modules/trade/components/TheStopLoss.vue'
import TheTakeProfit from '@/modules/trade/components/TheTakeProfit.vue'

export default defineComponent({
  name: 'ThePositionEditDialog',
  components: {
    HoldOn,
    Button,
    Line,
    TheStopLoss,
    TheTakeProfit,
    DialogTemplate,
  },
  emits: ['close'],
  props: {
    position: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const loading = shallowRef(true)
    const position = props.position as Position

    const model = new TradeModel(position.type, {
      orderId: position.orderId,
      price: position.createPrice,
      isJuan: position.isJuan,
      stopProfit: position.stopProfitPoint,
      stopLoss: position.stopLossPoint,
      offset: position.offset,
      orderNumber: position.orderNumber,
      isTrackSt: position.isTrackSt,
    })

    model.setOffset(position.createPrice)

    provide('model', shallowReadonly(model))

    readProduct({ productId: position.productId })
      .then(resp => {
        model.setProduct(resp)
        loading.value = false
      })

    const [commit, progress] = useEdit()

    return {
      loading,
      progress,
      product: model.getProduct(),
      model: model.getValue(),
      saveEdit () {
        commit(model.getValue()).then(() => {
          emit(events.transactionUpdate)
          ctx.emit('close', () => {
            openDialog(ThePositionEditResultDialog, {
              top: model.getValue().stopProfit,
              bottom: model.getValue().stopLoss,
            })
          })
        })
      },
    }
  },
})
