/**
* @description
*   TheProfile.vue
*/
<template>
  <div class="p-16 pt-8 pb-24 profile f-nm">
    <!-- {{ product && product.profile }} -->
    <div v-for="(item, index) in profile" :key="index">
      <p v-if="item" class="mb-8">{{item}}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { MarketItem } from '@/modules/market/market.types'
import { defineComponent, shallowRef } from 'vue'
import { productDetail } from '@/modules/market/market.api'

export default defineComponent({
  name: 'TheProfile',
  props: {
    code: String,
  },
  setup (props) {
    const product = shallowRef<MarketItem | null>(null)
    const profile = shallowRef<string[]>([])

    productDetail({ code: props.code }).then((resp: MarketItem) => {
      product.value = resp
      const temp = resp.profile.split(';')
      profile.value = temp.map(item => {
        return item
      })
    })
    return {
      product,
      profile,
    }
  },
})

</script>

<style scoped lang="scss">
.profile {
  line-height: 24px;
  img {
    display: block;
    width: 100%;
    margin: 4px 0;
  }
}
.frame {
  border: none;
  width: 100%;
  min-height: 100%;
}
</style>
