/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/26
* @description
*   GuideOrderDialog.vue of WeTrade
*/
<template>
<div class="p-r guide bg-primary t-center mx-24 p-16 pt-24">
  <Icon name="close" class="close" @click="$emit('close')" />
  <Pic src="flow" width="40" height="40" />
  <t as="p" path="market_15" class="title f-lg" />
  <t as="p" path="market_16" class="f-lg f-bold c-title header mt-12">New Order</t>
  <div class="body">
    <div class="brief df-middle px-16 c-black" style="border-top: 1px solid #CFD8E3;">
      <div class="flex-1 t-left">
        <p class="code">{{prop('code', symbol)}}</p>
        <p class="f-sm c-title">{{tips}}</p>
      </div>
      <RealTimeQuote :symbol="symbol" #="{margin, mp, isRise}">
        <div class="flex-1 t-right">
          <p class="c-success" :class="{' c-danger': !isRise}">{{margin}}</p>
          <span :class="{danger: !isUp}" class="badge success">{{mp}}</span>
        </div>
      </RealTimeQuote>
    </div>
    <div class="detail br-lg df-middle">
      <div class="flex-1">
        <t as="p" path="market_17" class="c-title"></t>
        <p class="f-bold mt-4">$200</p>
      </div>
      <div class="flex-1">
        <t as="p" path="market_18" class="c-title"></t>
        <p class="mt-4">
          <t :path="isUp ? 'market_19': 'market_20'" class="f-bold">Buy</t>
          <Icon
            :class="{'bg-danger': !isUp}" :name="isUp ? 'up' : 'down'" class="bg-success ml-8" sm
            style="color: white"
          />
        </p>
      </div>
    </div>
    <t as="p" :path="isUp ? 'market_21' : 'market_22'" class="t-left my-16 c-title"
      :args="{symbol: symbol.code}"
    />
    <Button :progress="progress" class="btn success block f-lg f-bold" :class="{danger: !isUp}"
      @click="create">
      <t path="market_23">Place Order</t>
    </Button>
  </div>
</div>
</template>

<script lang="ts">
import datafeed from '@/common/datafeed'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import useRequest from '@/hooks/useRequest'
import { placeOrder } from '@/modules/market/market'
import { SymbolSnapshot } from '@/modules/market/market.types'
import { Product } from '@/modules/trade/trade.api'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { defineComponent, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'GuideOrderDialog',
  components: { RealTimeQuote, Button, Icon, Pic },
  props: {
    tips: {
      type: String,
      required: true,
    },
    symbol: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const router = useRouter()
    const code = (props.symbol as SymbolSnapshot).code

    datafeed.subscribe([props.symbol.code], true)

    const isBuy = parseFloat((props.symbol as SymbolSnapshot).margin) > 0

    const [commit, progress] = useRequest(placeOrder)

    onBeforeUnmount(() => {
      datafeed.unSubscribe([props.symbol.code], true)
    })

    return {
      isUp: isBuy,
      progress,
      create () {
        commit(props.product as Product, isBuy).then(() => {
          ctx.emit('complete')
          ctx.emit('close', () =>
            router.push({
              name: 'symbol',
              params: { code },
            }))
        })
      },
    }
  },
})
</script>

<style scoped lang="scss">
.guide {
  border-radius: 4px;
}

.title {
  color: var(--color-white);
}

.header {
  line-height: 48px;
  background: url(/img/ticket_header@2x.png) top center;
  background-size: 100% 100%;
}

.body {
  padding: 0 24px 24px;
  background: url(/img/ticket_bd@2x.png) top center repeat-y;
  background-size: 100% 100%;
}

.close {
  background: var(--color-theme);
  border-radius: 50%;
  position: absolute;
  top: 16px;
  right: 16px;
}

.brief,
.detail {
  height: 64px
}

.detail {
  background: var(--color-background);
}

.order {
  border-radius: 4px;
  overflow: hidden;
  //background: var(--color-white);
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
