/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/30 16:26
 * @description
 *   invite.ts of WeTrade
 */
import { getLangIndex, keymap } from '@/config'
import useRequest from '@/hooks/useRequest'
import { inviteRegister } from '@/pages/invite/invite.api'
import { localGet } from 'essential/store/localStore'
import { getQueryParam } from 'essential/util'
import { useRoute } from 'vue-router'

export const makeShareUrl = () => {
  return `${location.origin}?lang=${localGet(keymap.label.language)}`
}

export const makeShareQuery = () =>
  `invitationCode=${localGet(keymap.user.invitationCode)}&appId=${getQueryParam(keymap.search.appId)}&vestBagId=${getQueryParam(keymap.search.vestBagId) || 1}&language=${getLangIndex()}`

export const useInvite = () => {
  const route = useRoute()
  const { orderId } = route.query
  const [request, progress] = useRequest(inviteRegister)
  const commit = (data: Data) => {
    return request({
      ...data,
      channelFlag: orderId ? 'profitShare' : 'inviteFriends',
    })
  }
  return { commit, progress }
}
