/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/14
* @description
*   ThePositionCloseConfirmDialog.vue of WeTrade
*/
<template>
  <DialogTemplate>
    <template v-if="done" #title><t path="symbol_32">Position closed</t></template>
    <template v-else #title><t path="symbol_28">Close position</t></template>
    <div class="flex-1 t-center">
      <p v-if="done">
        <Icon name="check" class="bg-success mb-20" style="font-size: 40px;color: white;border-radius: 20px" />
      </p>
      <t v-else path="symbol_29"
        :args="{amount: `${prop('weight', order)} ${prop('unit', order)}`, symbol: prop('code', order)}"
        as="p">
        Close trade: 1 lot of <span class="f-bold">{{prop('code', order)}}?</span>
      </t>
    </div>
    <template v-if="done" #controls>
      <Button class="block primary" @click="close">
        <t path="symbol_33" #="{td}" custom>{{ td || 'OK' }}</t>
      </Button>
    </template>
    <template v-else #confirm>
      <Button class="flex-1 primary" @click="submit" :progress="progress">
        <t path="symbol_31" #="{td}" custom>{{ td || 'Submit' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { events } from '@/config'
import useRequest from '@/hooks/useRequest'
import { close, Position } from '@/modules/trade/trade.api'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { emit } from 'essential/tools/event'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'ThePositionCloseConfirmDialog',
  components: { Icon, Button, DialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    let order: Position | null = null
    const done = shallowRef(false)
    const [commit, progress] = useRequest(close)

    return {
      done,
      close () {
        ctx.emit('close')
        ctx.emit('closed', order)
      },
      submit () {
        return commit({ orderId: props.order.orderId })
          .then(resp => {
            order = resp
            emit(events.transactionUpdate)
            done.value = true
          })
      },
      progress,
    }
  },
})
</script>
