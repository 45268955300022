/**
* @author zjc[beica1@outook.com]
* @date 2021/8/18 17:34
* @description
*   ThePriceLabel.vue of WeTrade
*/
<template>
  <span v-if="price" class="price_label p-a df-middle" :style="position">
    <span class="price">{{price}}</span>
  </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePriceLabel',
  props: {
    price: String,
    position: Object,
  },
})
</script>

<style scoped lang="scss">
.price_label {
  height: 15px;
  right: 0;

  &::before {
    display: inline-block;
    width: 100%;
    border-top: 1px dashed var(--color-asst);
    content: '';
  }
}

.price {
  color: var(--color-asst);
  border: 1px solid var(--color-asst);
  background: var(--color-asst-light);
  font-size: 10px;
}
</style>
