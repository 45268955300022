/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   market.api.ts of WeTrade
 */
import requestResult from '@/common/request/request'
import { market } from '@/config'
import withoutCryptos from '@/decorators/withoutCryptos'
import { MarketItem, SymbolSnapshot } from '@/modules/market/market.types'

export const requestSaveWatchlist = requestResult('/api/contactproduct/watch/save')

export const readMarketList = requestResult<Array<MarketItem>>('/api/contactproduct/list')

export const readMarketListWithoutCryptos = withoutCryptos(readMarketList)

export const readWatchlist = withoutCryptos(() => requestResult<Array<MarketItem>>(
  '/api/contactproduct/watch/list')({
  excode: market.excode,
}))

export enum BarTypes {
  M1 = 10,
  S1, // 1 second
  S5,
  S15,
  S30,
  M5 = 2, // 5 minutes
  M15,
  M30,
  M60,
  D1, // 1 day
}

type QuoteObject = {
  o: string;
  h: string;
  l: string;
  c: string;
  u: number;
}
export type ChangeProductItem = {
  code: string;
  icon: string;
  price: number;
  ratio: number;
  type: number;
}

export const readBars = requestResult<QuoteObject[]>('/quotation/kChart')

export const readSymbol = requestResult<SymbolSnapshot[]>('/quotation/realTime')

export const readTheSymbol = async (code: string) => {
  const symbols = await requestResult<SymbolSnapshot[]>('/quotation/realTime')({
    codes: `${market.excode}|${code}`,
  })
  return symbols[0]
}

export const readTradeTrend = requestResult<{ up: number, down: number, analyst: number }>('/api/order/mt/contract/ratio')

export const productDetail = requestResult<MarketItem>('/api/product/mt/contract/detail')

export const readChangeProduct = withoutCryptos(requestResult<ChangeProductItem[]>('/api/changeProduct/data'))

export enum LineType {
  LINE = 1,
  TREND
}

export enum AlertState {
  ON = 1,
  OFF
}

export type Line = {
  code: string;
  color: string;
  horizontalLinePrice: number;
  id: number;
  slashEndPrice: number;
  slashEndTime: number;
  slashStartPrice: number;
  slashStartTime: number;
  type: LineType;
  warning: AlertState;
  kLineType: BarTypes;
  msg: string;
  textPrice: number;
  textTime: number;
  arrowPrice: number;
  arrowTime: number;
}

export enum ESpecialType {
  NORMAL,
  REVERSE,
  DOUBLE,
}

export type SinalItem = {
  articleId: string;
  articleTime: number;
  buyType: number;
  code: string;
  comment: string;
  difference: string;
  last: string;
  pivot: string;
  sl2Price: string;
  term: string;
  tp1Price: string;
  tp2Price: string;
}

export enum Direction {
  FALL = 1,
  RISE
}

export const readLines = requestResult<Line[]>('/api/line/list')

export const drawLine = requestResult<Line>('/api/line/draw')

export const removeLine = requestResult('/api/line/delete')

export const checkIsSpecial = requestResult<{breedFlag: number, specialIdentification: ESpecialType}>('/api/contactproduct/special')

export const readTechnicals = requestResult<SinalItem[]>('/api/sinal/list')
