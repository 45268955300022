
import Icon from '@/components/Icon.vue'
import MarketTab from '@/modules/market/components/MarketTab.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import { defineComponent } from 'vue'
import Page from '@/provider/Page.vue'
import TheDepositTip from '@/modules/market/components/TheDepositTip.vue'
import TheTopMovers from '@/modules/market/components/TheTopMovers.vue'

export default defineComponent({
  name: 'Market',
  components: {
    MarketTab,
    NothingOnInspectMode,
    TheDepositTip,
    DemoAccountTip,
    Icon,
    AccountRelated,
    Page,
    TheTopMovers,
  },
})
