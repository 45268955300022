/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/9
* @description
*   TabPage.vue of WeTrade
*/
<template>
  <div class="tab-page spread">
    <div class="fill p-r">
      <router-view #="{ Component, route }">
        <TransitionProvider :name="route.meta.transition">
          <keep-alive :include="['Home', 'Market']">
            <component :is="Component" />
          </keep-alive>
        </TransitionProvider>
      </router-view>
    </div>
    <Tab class="tab" />
  </div>
</template>

<script lang="ts">
import Page from '@/provider/Page.vue'
import TransitionProvider from '@/provider/TransitionProvider.vue'
import Tab from '@/views/appTab/AppTab.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TabPage',
  components: { Page, TransitionProvider, Tab },
})
</script>

<style scoped>
.tab-page {
  padding-bottom: 64px;
}

.tab {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 64px;
}
</style>
