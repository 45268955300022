/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/9
 * @description
 *   datafeed.ts of WeTrade
 */
import { showAlert } from '@/components/popup/popup'
import { events, market, server } from '@/config'
import makeFeed, { SubscribeMode } from 'essential/market/feed'
import { on } from 'essential/tools/event'
import * as R from 'ramda'
import { h, shallowRef } from 'vue'
import ReConnect from '@/components/popup/ReConnect.vue'

export type QuoteMessage = {
  excode: string;
  code: string;
  last_close: string;
  sell: string;
  buy: string;
  pointDiff: string;
  margin: string;
  mp: string;
  updatetime: string;
  open: string;
  top: string;
  low: string;
}

const quoteReg = new RegExp(`^${market.excode},`)

/**
 * @param message
 * @example ''
 * excode,code,sell,open,lastclose,high,low,margin,mp,updatetime,buy 格式是这个
 */
const parseStringMessage = (message: string) => {
  if (message && quoteReg.test(message)) {
    const [excode, code, sell, margin, mp, updatetime, buy, pointDiff, open, top, low] = (message as string).split(
      ',')
    return {
      excode,
      code,
      last_close: sell,
      sell,
      margin,
      mp,
      updatetime,
      buy,
      pointDiff,
      open,
      top,
      low,
    }
  }
  return null
}

const parseBufferMessage = (message: ArrayBuffer) => {
  const unit8Array = new Uint8Array(message)
  const stringMessage = String.fromCharCode.apply(null, unit8Array as unknown as [])
  return parseStringMessage(stringMessage)
}

const parseBlobMessage = (message: Blob) => {
  return null
}

/**
 * 接续socket message
 * @implements parseQuoteMessage
 * @param message
 * @example parseQuoteMessage('excode,code,sell,open,lastclose,high,low,margin,mp,updatetime,buy')
 */
export const parseQuoteMessage = (message: string | Blob | ArrayBuffer | null): QuoteMessage | null => {
  if (typeof message === 'string') {
    return parseStringMessage(message)
  }
  if (message instanceof ArrayBuffer) {
    return parseBufferMessage(message)
  }
  if (message instanceof Blob) {
    return parseBlobMessage(message)
  }
  return null
}

/**
 * 格式化订阅消息
 * @implements generateSubscribeCodes
 * @param codes
 */
export const generateSubscribeCodes = (codes: string[]): string => {
  const prefix = R.concat(`${market.excode}|`)
  return R.map(prefix, codes).toString()
}

enum CONNECT_STATE {
  NORMAL = -1,
  ING,
  DONE,
  ERR,
}

const connectState = shallowRef(CONNECT_STATE.NORMAL)

// 连接提示
const displayConnectTip = () => {
  if (connectState.value === CONNECT_STATE.ING) {
    return
  }

  connectState.value = CONNECT_STATE.ING

  showAlert({
    setup () {
      return () => h(ReConnect, {
        state: connectState,
      })
    },
  }, {
    zIndex: 3,
    autoClose: false,
  })
}

const datafeed = makeFeed<QuoteMessage>({
  subscribeMode: SubscribeMode.REPLACE,
  server: server.quotation,
  generateSubscribeCodes,
  parseQuoteMessage,
  on: {
    connecting () {
      displayConnectTip()
    },
    open () {
      connectState.value = CONNECT_STATE.DONE
    },
    closed () {
      connectState.value = CONNECT_STATE.ERR
    },
  },
})

on(events.activated, () => {
  datafeed.reConnect()
})

export default datafeed

export const marketFeed = datafeed
