/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/25
* @description
*   CommissionBarWithBadge.vue of WeTrade
*/
<template>
<!--<div><t path="symbol_6">Orders</t> <span class="badge">{{count}}</span></div>-->
<div>
  <t path="symbol_6">Orders</t>
  ({{count}})
</div>
</template>

<script lang="ts">
import { events } from '@/config'
import { off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { readCommissions } from '@/modules/trade/trade.api'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'CommissionBarWithBadge',
  props: {
    code: String,
  },
  setup (props) {
    const count = shallowRef(0)
    const load = () => readCommissions(null, { silent: true })
      .then(resp => {
        count.value = props.code ? R.length(R.filter(R.propEq('code', props.code), resp))
          : R.length(resp)
      })

    load()

    on([events.transactionUpdate, events.accountChanged], load)

    onBeforeUnmount(() => {
      off([events.transactionUpdate, events.accountChanged], load)
    })

    return {
      count,
    }
  },
})
</script>
