
import WTTabs from '@/components/Tabs.vue'
import GuideContent from '@/components/userGuide/GuideContent.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import TheAccountSwitch from '@/modules/trade/components/TheAccountSwitch.vue'
import TheAsset from '@/modules/trade/components/TheAsset.vue'
import TheCommissions from '@/modules/trade/components/TheCommissions.vue'
import ThePositions from '@/modules/trade/components/ThePositions.vue'
import { readTradeState } from '@/modules/trade/prepare'
import AccountRelated from '@/provider/AccountRelated.vue'
import Holder from '@/provider/Holder.vue'
// import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import Page from '@/provider/Page.vue'
import { isDemoAccount } from '@/state/accountType'
import CommissionBarWithBadge from '@/views/CommissionBarWithBadge.vue'
import PositionBarWithBadge from '@/views/PositionBarWithBadge.vue'
import TabPane from 'common/tab/TabPane.vue'
import { computed, defineComponent, shallowRef } from 'vue'
import state from '@/state'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import { Commission, readCommissions } from '@/modules/trade/trade.api'
import TheFirstDepositCard from '@/modules/trade/components/TheFirstDepositCard.vue'
// import TheEarnings from '@/modules/trade/components/TheEarnings.vue'
import TheDailyBonusEnter from '@/modules/trade/components/TheDailyBonusEnter.vue'

export default defineComponent({
  name: 'Trade',
  components: {
    // OnlyGroupA,
    GuideContent,
    GuideTarget,
    Icon,
    Pic,
    AccountRelated,
    TheCommissions,
    ThePositions,
    TabPane,
    WTTabs,
    Holder,
    TheAccountSwitch,
    TheAsset,
    Page,
    TheFirstDepositCard,
    // TheEarnings,
    TheDailyBonusEnter,
  },
  setup () {
    // 任务进度 与 完成标识
    const taskStep = shallowRef(0)
    const allCommissionDone = shallowRef<boolean | null>(null)
    const commissions = shallowRef<Commission[]>([])

    const readyToTrade = computed(() => {
      return allCommissionDone.value || isDemoAccount.value
    })

    // useCustomStatusBar()

    readTradeState().then(([result, step]) => {
      allCommissionDone.value = result
      taskStep.value = step
    })

    readCommissions().then(resp => {
      commissions.value = resp
    })

    return {
      devEnable () {
        allCommissionDone.value = true
        taskStep.value = 3
      },
      allCommissionDone,
      PositionBarWithBadge,
      CommissionBarWithBadge,
      taskStep,
      readyToTrade,
      positions: computed(() => state.account.list),
      commissions,
    }
  },
})
