
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import i18nByType from '@/pages/bigNews/bigNews'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'BigNewsDialog',
  components: { DialogTemplate },
  props: {
    forecast: {
      type: String,
      required: true,
    },
    actual: {
      type: String,
      required: true,
    },
    eventId: String || Number,
    type: String || Number,
  },
  setup (props, ctx) {
    const router = useRouter()
    const dTitle = shallowRef()
    const up = shallowRef(false)
    const down = shallowRef(false)
    up.value = parseFloat(props.actual) > parseFloat(props.forecast)
    down.value = parseFloat(props.actual) < parseFloat(props.forecast)
    dTitle.value = i18nByType[Number(props.type)].dialogTitle

    const handleDetail = () => {
      ctx.emit('close')
      setTimeout(() => {
        router.push(`/eventsList/bigNews?id=${props.eventId}&type=${props.type}`)
      }, 300)
    }

    const handleClose = () => {
      ctx.emit('close')
    }
    return {
      dTitle,
      up,
      down,
      handleDetail,
      handleClose,
    }
  },
})
