/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/4
* @description
*   TheSymbolActions.vue of WeTrade
*/
<template>
<GuideTarget name="create">
  <div class="df-middle t-center">
    <router-link :to="`/trade/fast?c=${$route.params.code}`" custom #="{navigate}">
      <button @click="navigate" class="ft_btn q-paly btn lg df-middle df-center">
        <Pic src="trade/quickplay" width="24" height="24" />
      </button>
    </router-link>
    <RealTimeQuote :symbol="symbol" #="{price, buy}">
      <router-link class="action flex-1 bg-danger" :to="`/create/${$route.params.code}/1?f=1`">
        <Icon name="down" sm />
        <t path="symbol_23">Sell</t>
        <p class="f-md f-medium">
          {{price}}
        </p>
      </router-link>
      <router-link class="action flex-1 bg-success" :to="`/create/${$route.params.code}/2?f=1`">
        <Icon name="up" sm />
        <t path="symbol_22">Buy</t>
        <p class="f-md f-medium">{{buy}}</p>
      </router-link>
    </RealTimeQuote>
  </div>
  <template #top="{ gotIt }">
    <GuideContent :anchor-align="70" abs anchor-position="bottom" class="mx-16"
      style="bottom: 120%" @gotIt="gotIt">
      <t path="symbol_68">
        Buy: Profit if price rises, lose if price drops.
        Sell: Profit if price drops, lose if price rises.
      </t>
    </GuideContent>
  </template>
</GuideTarget>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import GuideContent from '@/components/userGuide/GuideContent.vue'
import { useToggleState } from '@/modules/market/market'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheSymbolActions',
  components: { Pic, RealTimeQuote, GuideTarget, GuideContent, Icon },
  props: {
    symbol: Object,
  },
  setup () {
    const route = useRoute()
    return useToggleState(route.params.code as string)
  },
})
</script>

<style scoped lang="scss">
.action {
  display: inline-block;
  vertical-align: middle;
  height: 56px;
  padding: 8px 0;
  line-height: 22px;
  color: var(--color-white);
  font-size: 17px;
  font-weight: bold;
}
.q-paly{
  min-width: 56px;
  max-width: 56px;
}
.ft_btn {
  color: var(--color-text);
  background: var(--color-background);
}
</style>
