
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MissionResult',
  components: { ResultDialogTemplate },
  props: {
    award: {
      type: Number,
      required: true,
    },
    isCoupon: Boolean,
    message: String,
  },
})
