/**
* @author zjc[beica1@outook.com]
* @date 2021/10/14 19:42
* @description
*   TheResolutionBar.vue of WeTrade
*/
<template>
<div class="d-f" style="border-bottom: 1px solid #A1AEB6; height: 32px;">
  <TheSelectBar
    :options="resolutions" class="c-title flex-1"
    #="{item, active, select}"
  >
    <div
      class="flex-1 py-4" @click="change(item, select)"
      :class="[...(active ? ['active', 'c-text', 'f-bold'] : [])]"
      style="max-width: 50px"
    >
      {{item.label}}
    </div>
  </TheSelectBar>
  <slot />
</div>
</template>

<script lang="ts">
import { keymap } from '@/config'
import { ResolutionConfig, readResolutions } from '@/modules/market/components/chart/chartConfig'
import TheSelectBar from '@/modules/market/components/chart/TheSelectBar.vue'
import { localSet } from 'essential/store/localStore'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheResolutionBar',
  components: { TheSelectBar },
  props: {
    showRotate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  setup (props, ctx) {
    return {
      resolutions: readResolutions(),
      change (item: ResolutionConfig, apply: (item: ResolutionConfig) => void) {
        ctx.emit('change', item)
        localSet(keymap.label.resolution, item.value.toString())
        apply(item)
      },
    }
  },
})
</script>

<style scoped lang="scss">
.active {
  position: relative;

  &::after {
    position: absolute;
    height: 2px;
    right: 15%;
    bottom: 0;
    left: 15%;
    background: var(--color-primary);
    content: '';
  }
}
</style>
