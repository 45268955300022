import * as R from 'ramda'
import assert from '@/common/assert'
import { createStore } from '@/common/store'

const defaultSettings = {
  dampRate: 0,
  direction: 'horizontal',
  minOffset: -Infinity,
  maxOffset: Infinity,
}

export default (selectorOrEl, settings = defaultSettings) => {
  const { get, set } = createStore()

  set('offset', 0)

  const coordinates = {
    vertical: 'y',
    horizontal: 'x',
  }

  const getValues = R.ifElse(
    R.hasIn('touches'),
    R.pipe(R.prop('touches'), R.nth(0), R.props(['pageX', 'pageY'])),
    R.props(['clientX', 'clientY']),
  )

  const getPoint = R.o(R.zipObj(['x', 'y']), getValues)

  const getStepOffset = curPoint => {
    const lastPoint = get('lastPoint')
    const axis = coordinates[get('direction')]
    return (curPoint[axis] - lastPoint[axis]) * (1 - get('dampRate'))
  }

  const updateLastPoint = set('lastPoint')

  const updateOffset = set('offset')

  const emit = (name, ...rest) => {
    R.when(R.is(Function), R.converge(R.apply, [R.identity, R.always(rest)]))(get(name))
  }

  const adapt = distance => {
    if (distance >= get('maxOffset')) {
      emit('overflow', end)
      updateOffset(get('maxOffset'))
      return false
    }
    if (distance < get('minOffset')) {
      emit('underflow', end)
      updateOffset(get('minOffset'))
      return false
    }
    emit('touchmove', distance)
    return true
  }

  // 触摸移动
  const move = e => {
    const curPoint = getPoint(e)
    const offset = get('offset')
    const stepOffset = getStepOffset(curPoint)
    const distance = offset + stepOffset
    const shouldContinue = adapt(distance)
    if (!shouldContinue) { return }
    updateOffset(distance)
    updateLastPoint(curPoint)
  }

  // 触摸开始
  const start = e => {
    e.stopPropagation()
    const point = getPoint(e)
    set('startPoint', point)
    set('lastPoint', point)
    document.addEventListener('touchmove', move, { passive: false })
    document.addEventListener('mousemove', move, { passive: false })
    emit('touchstart')
  }

  // 触摸结束
  const end = () => {
    document.removeEventListener('touchmove', move)
    document.removeEventListener('mousemove', move)
    emit('touchend', get('offset'))
    set('offset', 0)
  }

  const listen = () => {
    const dom = get('dom')
    dom.addEventListener('touchstart', start)
    dom.addEventListener('touchend', end)
    dom.addEventListener('touchcancel', end)
  }

  const config = R.pipe(R.pick(R.keys(defaultSettings)), R.mapObjIndexed(R.flip(set)))

  let dom

  if (typeof selectorOrEl === 'string') {
    dom = document.querySelector(selectorOrEl)
  } else if (selectorOrEl instanceof Node) {
    dom = selectorOrEl
  }

  assert('isNotNil', dom, 'swipe target should not be null')

  config(R.mergeRight(defaultSettings, settings))
  set('dom', dom)
  listen()

  return {
    on: set,
    set: R.curryN(2, R.pipe(R.objOf, config)),
  }
}
