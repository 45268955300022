<template>
  <div class="df-middle" @click="handleClick">
    <t path="symbol_106" class="mr-4">Technicals</t>
    <Pic src="trade/star" width="14" height="14" />
  </div>
</template>

<script lang='ts'>
import { defineComponent, computed } from 'vue'
import Pic from '@/components/Pic.vue'
import { YesOrNo } from '@/types'
import state from '@/state'
import { openDialog } from '@/components/popup/popup'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'

export default defineComponent({
  name: 'TechnicalsBar',
  components: {
    Pic,
  },
  setup () {
    const isPrime = computed(() => state.prime.member === YesOrNo.YES)

    const handleClick = (e: Event) => {
      if (!isPrime.value) {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'symbol_124',
        })
        e.stopPropagation()
      }
    }
    return {
      handleClick,
    }
  },
})
</script>

<style scoped lang='scss'>

</style>
