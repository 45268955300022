
import SymbolList from '@/modules/market/SymbolList.vue'
import Scroller from 'common/providers/Scroller.vue'
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import TheWatchSymbolButton from '@/modules/market/components/TheWatchSymbolButton.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AddToWatchList',
  components: { SymbolList, Scroller, TheWatchSymbolButton, PageHeader, FullScreen },
})
