/**
* @author ayou[beica1@outook.com]
* @date 2022/1/12 16:29
* @description
*   TheVBlock.vue of WeTrade
*/
<template>
<div class="v__block">
  <slot />
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheVBlock',
})
</script>

<style scoped lang="scss">
.v__block {
  position: relative;
  background: #383E58;
  overflow: hidden;

  &::before,
  &::after {
    z-index: 1;
    position: absolute;
    right: -3px;
    bottom: -10px;
    left: 50%;
    height: 20px;
    background: var(--color-theme);
    transform: rotate3d(1, 1, 1, -10deg);
    content: '';
    outline: 1px solid transparent;
  }

  &::before {
    left: -3px;
    right: 50%;
    transform: rotate3d(1, 1, 1, 369deg);
  }
}
</style>
