
import { events } from '@/config'
import { off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { readCommissions } from '@/modules/trade/trade.api'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'CommissionBarWithBadge',
  props: {
    code: String,
  },
  setup (props) {
    const count = shallowRef(0)
    const load = () => readCommissions(null, { silent: true })
      .then(resp => {
        count.value = props.code ? R.length(R.filter(R.propEq('code', props.code), resp))
          : R.length(resp)
      })

    load()

    on([events.transactionUpdate, events.accountChanged], load)

    onBeforeUnmount(() => {
      off([events.transactionUpdate, events.accountChanged], load)
    })

    return {
      count,
    }
  },
})
