/**
* @author zjc[beica1@outook.com]
* @date 2021/8/30 14:35
* @description
*   TheShareOrder.vue of WeTrade
*/
<template>
  <Pic src="invite/bg1" class="img" />
  <div class="order">
    <div class="t-center mb-12">
      <t path="sharelink_3" class="c-title f-sm profit px-8">Profit</t>
      <div class="c-success f-medium" style="font-size: 32px; line-height: 40px;">
        +{{(prop('profitability', order) * 100).toFixed(2)}}%
      </div>
    </div>
    <div class="bg-background df-middle py-10 content">
      <div class="detail t-center">
        <p class="f-nm f-bold" style="line-height: 24px;">{{prop('code', order)}}</p>
        <p class="f-md c-title mt-4">{{prop('tips', order)}}</p>
      </div>
      <div class="detail f-md pl-12">
        <p class="c-success" :class="{'c-danger': !isBuy}">
          <t :path="isBuy ? 'sharelink_4' : 'sharelink_5'">
            {{isBuy ? 'Buy' : 'Sell'}}
          </t>
          @{{prop('createPrice', order)}}
        </p>
        <p class="c-text mt-8">
          <t path="sharelink_6">Close</t>
          @{{prop('closePrice', order)}}
        </p>
      </div>
    </div>
    <div class="df-middle f-md pb-24 mt-12 df-center" style="color: #667C8D;">
      <Time format="MM/dd/yy HH:mm" :time="prop('createTime', order)" />
      <Pic src="fast_trade/arrival" width="16" height="16" class="mx-4" />
      <Time format="MM/dd/yy HH:mm" :time="prop('closeTime', order)" />
    </div>
    <slot />
  </div>
  <Pic src="invite/bg3" class="img" />
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import Time from '@/components/Time.vue'
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheShareOrder',
  components: { Pic, Time },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isBuy = computed(() => props.order.type === TradeDirection.BUY)

    return {
      isBuy,
    }
  },
})
</script>

<style scoped lang="scss">
.img {
  width: 100%;
  display: block;
}

.order {
  padding: 0 10%;
  background: url("/img/invite/bg2@2x.png") center top repeat-y;
  background-size: 100% auto;
}

.profit {
  border: 1px solid var(--color-tip);
  border-radius: 8px;
  line-height: 16px;
}

.content {
  line-height: 16px;
  border-radius: 2px;
  position: relative;

  .detail {
    max-width: 50%;
    min-width: 50%;
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 1px;
    top: 10px;
    bottom: 10px;
    left: calc(50% - 2px);
    background: var(--color-grey);
  }
}
</style>
