
import Icon from '@/components/Icon.vue'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import Switch from '@/modules/membership/signal/components/Switch.vue'
import Pic from '@/components/Pic.vue'
import { useProductLimit } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import { shallowRef } from '@vue/reactivity'
import { computed, defineComponent, inject } from 'vue'
import { TradeDirection } from '@/types'

const MIN = 10
const MAX = 200

export default defineComponent({
  name: 'TheStopLoss',
  components: {
    Icon,
    Switch,
    Pic,
  },
  props: {
    type: {
      type: Number,
      default: TradeDirection.BUY,
    },
    value: Number,
    showDistance: Boolean,
  },
  setup (props) {
    const order = inject('model') as TradeModel
    const slChecked = shallowRef(false)
    const tslChecked = shallowRef(order.isStopTrailing())
    const showDescription = shallowRef(false)
    const stopLoss = shallowRef(0)
    const inputType = shallowRef('text')
    const percent = shallowRef(10)
    const label = computed(() => '-' + percent.value + '%')

    const {
      pips,
      profit,
      estPrice,
      distance,
      limit,
      limitByPoint,
    } = useProductLimit(pips => {
      order.setBottomLimit(pips)
    }, props.type === TradeDirection.BUY)

    if (props.value) {
      slChecked.value = true
      limitByPoint(props.value, v => percent.value = v)
    }

    const update = (input: number) => {
      inputType.value = 'text'

      if (input > MAX || input < MIN) {
        showAlert(translate('toast_16'))
      }

      const next = Math.min(200, Math.max(10, input))

      if (next !== percent.value) {
        percent.value = next
        limit(next)
      }
    }

    const reduce = (step = 5) => {
      update(percent.value - step)
    }

    const increase = (step = 5) => {
      update(percent.value + step)
    }

    const format = (v: number) => {
      const next = Number(v)

      if (Number.isNaN(next)) return

      update(v)
    }

    const toggleLimit = () => {
      slChecked.value = !slChecked.value

      limit(slChecked.value ? 10 : 0)
    }

    const toggleTrailing = () => {
      tslChecked.value = !tslChecked.value
      order.setStopTrailing(tslChecked.value)
    }

    const isBound = (max = false) => {
      return max ? percent.value >= MAX : percent.value <= MIN
    }

    return {
      label,
      inputType,
      slChecked,
      tslChecked,
      showDescription,
      stopLoss,
      pips,
      profit,
      estPrice,
      distance,
      isBound,
      reduce,
      increase,
      format,
      toggleLimit,
      toggleTrailing,
    }
  },
})
