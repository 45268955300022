
import GuideContent from '@/components/userGuide/GuideContent.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import TheMarketList from '@/modules/market/components/TheMarketList.vue'
import { useWatchlist } from '@/modules/market/market'
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheWatchList',
  components: { TheMarketList, GuideContent, GuideTarget, Icon },
  setup () {
    const { read, add, edit } = useWatchlist()

    return {
      edit,
      add,
      read,
    }
  },
})
