/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/25
* @description
*   List.vue of WeTrade
*/
<template>
  <component :is="as" v-if="as">
    <slot name="prepend" />
    <slot
      v-for="(item, index) in listData"
      :key="identify(item, index)"
      :index="index"
      :item="item"
    />
    <slot name="append" />
  </component>
  <template v-else>
    <slot name="prepend" />
    <slot
      v-for="(item, index) in listData"
      :key="identify(item, index)"
      :index="index"
      :item="item"
    />
    <slot name="append" />
  </template>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'List',
  props: {
    listData: {
      type: Array,
      required: true,
    },
    identify: {
      type: Function,
      default: (item: unknown, index: number) => index,
    },
    as: String,
  },
})
</script>
