<template>
<t as="p" path="membership_7" class="f-bold my-8">Live</t>
<HoldOn :content="list" :height="180">
  <AsyncNullableList :list-data="list" #="{item}">
    <TheLiveItem :room="item" />
  </AsyncNullableList>
</HoldOn>
</template>

<script lang="ts">
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import TheLiveItem from '@/modules/membership/components/TheLiveItem.vue'
import { LiveRoom, readLiveList } from '@/modules/membership/member.api'
import HoldOn from '@/provider/HoldOn.vue'
import { defineComponent, shallowRef } from 'vue'
import { readLiveList as entertainmentList } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'

export default defineComponent({
  name: 'LiveList',
  components: { HoldOn, TheLiveItem, AsyncNullableList },
  setup () {
    const list = shallowRef<Array<LiveRoom>>([])

    // readLiveList().then(resp => {
    //   list.value = resp || []
    // })

    Promise.all([readLiveList(), entertainmentList()]).then(res => {
      list.value = (res[0] || []).concat(res[1] || [])
    })

    return {
      list,
    }
  },
})
</script>
