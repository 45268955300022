<template>
<router-link
  :to="`/membership/tournament/${list.tournamentId}`"
  class="mb-16 df-middle list box" :class="{closed}"
>
  <Pic :src="list.pic" class="banner" width="88" height="88" fit="cover" />
  <div class="ml-12 flex-1">
    <div class="f-bold mb-8 title">{{list.name}}</div>
    <div class="d-f">
      <div class="mr-32">
        <p class="c-success">
          <span class="mr-4">{{list.endPrize}}</span>
          <t path="membership_13">pts</t>
        </p>
        <t as="p" path="membership_12" class="f-sm c-title">Prize</t>
      </div>
      <div>
        <p>{{list.participant}}</p>
        <t as="p" path="membership_14" class="f-sm c-title">Participants</t>
      </div>
    </div>
  </div>
</router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTournamentItem',
  components: { Pic },
  props: {
    list: {
      type: Object,
    },
    closed: Boolean,
  },
})
</script>
<style scoped lang="scss">
.list {
  overflow: hidden;
  padding-right: 12px;
}

.title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.banner {
  vertical-align: middle;
}

.closed {
  color: var(--color-tip);

  .banner {
    -webkit-filter: grayscale(1);
    filter: grayscale(100%);
  }

  .c-title {
    color: var(--color-tip);;
  }

  .elite {
    background: var(--color-grey);
  }
}
</style>
