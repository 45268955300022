/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/14
* @description
*   TheContactButton.vue of WeTrade
*/
<template>
  <router-link to="/service">
    <div class="py-8">
      <div class="d-ib p-r" :class="{unread}">
        <Pic src="user/service" width="32" height="32" />
      </div>
      <t path="me_5" as="p">Customer Service</t>
    </div>
  </router-link>
</template>

<script lang="ts">
import { callService, checkCSMsgState } from '@/common/jsBridge.api'
import Pic from '@/components/Pic.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheContactButton',
  components: { Pic },
  setup () {
    const unread = shallowRef(false)

    checkCSMsgState().then(resp => {
      unread.value = (resp as { hasNewMsg: boolean }).hasNewMsg
    })

    return {
      unread,
      callService,
    }
  },
})
</script>

<style scoped>
.unread::after {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: var(--color-danger);
  content: '';
}
</style>
