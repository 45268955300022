/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   Pic.vue of WeTrade
*/
<template>
  <img :src="path" :style="{objectFit: fit}" alt="image" @error="onError" />
</template>

<script lang="ts">
import { defineComponent, shallowRef, watch } from 'vue'
import fallback from './image.svg'

export default defineComponent({
  name: 'Pic',
  props: {
    src: {
      type: String,
      required: true,
    },
    fallback: {
      type: String,
      default: fallback,
    },
    fit: {
      type: String,
      default: 'contain',
    },
  },
  setup (props) {
    const path = shallowRef(props.src)
    let errorHandled = false

    watch(() => props.src, () => {
      errorHandled = false
      path.value = props.src
    })

    const onError = () => {
      if (!errorHandled) {
        errorHandled = true
        path.value = props.fallback as string
      }
    }

    return {
      path,
      onError,
    }
  },
})
</script>
