
import Icon from '@/components/Icon.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import GuideContent from '@/components/userGuide/GuideContent.vue'
import { useToggleState } from '@/modules/market/market'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheSymbolActions',
  components: { Pic, RealTimeQuote, GuideTarget, GuideContent, Icon },
  props: {
    symbol: Object,
  },
  setup () {
    const route = useRoute()
    return useToggleState(route.params.code as string)
  },
})
