/**
* @description
*   TheImgPreviewer.vue of WeTrade
*/
<template>
  <div class="d-f">
    <div v-for="(img, index) in images" :key="index" class="img flex-1">
      <ImgPreviewer>
        <Pic :src="img" fallback="/img/img.svg" style="object-fit: contain; width: 100%; height: 100%;" />
      </ImgPreviewer>
      <Icon v-if="editable" name="close" class="close" @click="mutate(index)" />
    </div>
    <div
      v-if="showIndicator" class="indicator img flex-1 df-cross-center"
      :class="{progress}"
      @click="useImg"
    >
      <Donut v-if="progress" />
    </div>
  </div>
</template>

<script lang="ts">
import useRequest from '@/hooks/useRequest'
import Donut from 'common/loading/Donut.vue'
import { defineComponent } from 'vue'
import { uploadImg } from './imgGallery'
import ImgPreviewer from './ImgPreviewer.vue'
import Pic from 'common/Pic.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent(
  {
    name: 'TheImgGallery',
    components: { Donut, Icon, Pic, ImgPreviewer },
    emits: ['update', 'update:images'],
    props: {
      mutate: {
        type: [Function, Boolean],
        default: false,
      },
      images: {
        type: Array,
        default: () => ([]),
      },
      max: {
        type: Number,
        default: 3,
      },
    },
    computed: {
      editable (): boolean {
        return typeof this.mutate === 'function'
      },
      showIndicator (): boolean {
        return this.editable && this.images?.length < this.max
      },
    },
    setup (props) {
      const [commit, progress] = useRequest(uploadImg)

      return {
        progress,
        useImg () {
          commit(false).then(url => {
            if (typeof props.mutate === 'function') props.mutate(url as string)
          })
        },
      }
    },
  },
)
</script>

<style scoped lang="scss">
.img {
  max-width: 30%;
  height: 72px;
  position: relative;

  &:not(:first-child) {
    margin-left: 5%;
  }
}

.close {
  position: absolute;
  top: -12px;
  right: -12px;
  height: 24px;
  width: 24px;
  background: var(--color-asst);
  border-radius: 50%;
  color: var(--color-white);
}

.indicator {
  border: 0.5px solid #CCCFD5;
  border-radius: 4px;

  &:not(.progress) {
    &::before {
      display: inline-block;
      width: 30px;
      height: 1px;
      background: #CCCFD5;
      content: '';
    }

    &::after {
      position: relative;
      left: -15px;
      display: inline-block;
      width: 1px;
      height: 30px;
      background: #CCCFD5;
      content: '';
    }
  }

}
</style>
