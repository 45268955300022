/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/27
* @description
*   LimitDialogTemplate.vue of WeTrade
*/
<template>
<DialogTemplate>
  <template #title>
    <t path="quickplay_51">Exclusive to Prime</t>
  </template>
  <div class="pt-8 pb-16 t-center flex-1">
    <Pic src="membership/not_qualified" width="88" height="88" class="mb-8" />
    <t as="p" :path="i18nPath" :args="i18nArgs" class="px-16" style="line-height: 24px;">
      {{defaultText}}
    </t>
  </div>
  <template #controls>
    <button class="flex-1 btn block" @click="handleSeeDetail">
      <t path="live_20" custom #="{td}">
        {{td || 'See details'}}
      </t>
    </button>
    <button class="flex-1 btn block primary" @click="$emit('close', navigate)">
      <t path="live_21" custom #="{td}">
        {{td || 'OK'}}
      </t>
    </button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import router from '@/router'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LimitDialogTemplate',
  components: { Pic, DialogTemplate },
  props: {
    i18nPath: String,
    i18nArgs: Object,
    defaultText: String,
  },
  emits: ['close', 'cancel'],
  setup () {
    const handleSeeDetail = () => {
      router.back()
      setTimeout(() => {
        router.push('/prime/detail')
      }, 300)
    }

    return {
      handleSeeDetail,
    }
  },
})
</script>

<style scoped lang="scss">
</style>
