import requestResult from '@/common/request/request'
import useRequest from '@/hooks/useRequest'
import { LiveRoom } from '@/modules/membership/member.api'

type SettingOpts = {
  name: string;
  orderConfirmation: number;
  blackgroundPlayback: number;
  hideLikesAndGifts: number;
}

type LiveAdd = {
  hlsDownstreamAddress: string;
  rtmpDownstreamAddress: string;
  isAuth: number; // 0是试看 1不是
}

export type CouponItem = {
  giftId: number;
  giftName: string;
  giftType: number;
  giftPic: string;
  moneys: number;
  poins: number;
  levelRebateFlag: number;
  gift_spec: string;
  discountPoints: number;
  voucherNumber: number;
  giftSort: number;
}

export type LiveRoomInfo = {
  roomStatus: number;
} & LiveRoom

export type RankItem = {
  userId: number;
  avatarPic: string;
  nickName: string;
  consumePoints: number;
  rank: number;
}

export enum RankType {
  TODAY,
  WEEK
}

export type LiveTaskItem = {
  id: number;
  points: number;
  isCompleted: 0 | 1; // 是否完成 1 完成 0 未完成
  name: string;
}

export type LiveTaskList = {
  completedPoints: number;
  taskPoints: number;
  tasks: LiveTaskItem[];
}

export type GiftItem = {
  giftId: number;
  giftPic: string;
  poins: number;
  levelRebateFlag: 0 | 1; // 是否享受折扣（0:不享受 1：享受）
  gift_spec: string;
  discountPoints: number;
}

export const readLiveList = requestResult<LiveRoomInfo[]>('/api/entertainment/live/list') // 娱乐直播室列表

export const readLiveRoom = requestResult('/api/entertainment/live/entry') // 获取指定直播室

export const saveSetting = requestResult('/api/entertainment/live/setProp')
export const readSetting = requestResult<SettingOpts>('/api/entertainment/live/getProp')

export const sendLike = requestResult<{like: number}>('/api/entertainment/live/like')

export const readLiveAdd = requestResult<LiveAdd>('/api/entertainment/live/channel/address') // 获取直播流的地址

export const readFastCommentList = requestResult<{ comment: string }[]>('/api/entertainment/live/quick/comments') // 获取快捷评论列表

export const readExchangeCouponList = requestResult<{
  noVoucherModels: CouponItem[];
  totalPoints: number;
  validPoints: number;
}>('/api/point/gift/no/list') // 快捷模式券商品列表

export const exchangeCoupon = requestResult('/api/point/gift/redemtion')

export const updateWatchTime = requestResult<{
  numberOfPeople: number;
  like: number;
  roomId: number;
}>('/api/entertainment/live/update/watch/time') // 更新直播观看时间

export const useSaveLiveSetting = () => {
  const [commit, progress] = useRequest(saveSetting)
  const request = async (data: Data) => {
    await commit(data)
  }
  return { request, progress }
}

export const readMaxProfit = requestResult<{userName: string; amount: number;}>('/api/entertainment/live/trade/max/profit')

export const readGiftList = requestResult<GiftItem[]>('/api/point/gift/live/list')

export const exchangeGift = requestResult<{ giftId: number; number: number }>('/api/point/gift/live/redemtion')

export const readLiveRank = requestResult<{
  roomId: string;
  liveSendGiftRankInfoModels: RankItem[];
} & RankItem>('/api/entertainment/live/rank')

export const readLiveTask = requestResult<LiveTaskList>('/api/entertainment/live/task') // 获取直播任务列表

export const liveDepositPointList = requestResult<{list: Record<number, string>[]}>('/api/recharge/live/amount/list')

export enum LimitEventType {
  DEPOSIT = 1,
  TRADE
}

export const checkLimitEvent = requestResult<{ type: LimitEventType, times: number }[]>('/api/entertainment/live/event')
