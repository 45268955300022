/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 14:11
* @description
*   TheEntries.vue of WeTrade
*/
<template>
<div class="d-f">
  <router-link to="/profitRank" class="flex-1 en-item df-middle box">
      <Pic src="rank/Leaderboard" class="mx-12" width="24" height="24" />
      <div class="en-info">
        <t path="home_39" as="p" class="f-bold f-nm c-text">Leaderboard</t>
        <t path="home_40" as="p" class="f-400 f-xs c-text">Daily top orders</t>
      </div>
    </router-link>
  <!-- <RedPointAlert id="t_q_p" class="flex-1 box">
    <router-link to="/trade/fast" class="en-item df-middle">
      <Pic src="home/quickplay" class="mx-12" width="24" height="24" />
      <div class="en-info">
        <t path="home_2" as="p" class="f-bold f-nm c-text">Quick Play</t>
        <t path="home_3" as="p" class="f-400 f-xs c-text">Quick profit artifact</t>
      </div>
    </router-link>
  </RedPointAlert> -->
  <router-link to="/qa" class="flex-1 en-item df-middle ml-8 box">
    <Pic src="home/learn" class="mx-12" width="24" height="24" />
    <div class="en-info">
      <t path="home_4" as="p" class="f-bold f-nm c-text">Learn</t>
      <t path="home_5" as="p" class="f-400 f-xs c-text">Quick profit artifact</t>
    </div>
  </router-link>
</div>
</template>

<script lang="ts">
// import RedPointAlert from '@/provider/RedPointAlert.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheEntries',
  components: {
    // RedPointAlert,
    Pic,
  },
})
</script>

<style scoped lang="scss">
.en-item{
  height: 56px;
}
</style>
