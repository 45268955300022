/**
* @author ayou[beica1@outook.com]
* @date 2022/1/12 15:38
* @description
*   Prime.vue of WeTrade
*/
<template>
<Page>
  <TheHeader />
  <div class="px-16">
    <!-- <OnlyGroupA><TheFastPlayEntry /></OnlyGroupA> -->
<!--    <TheFastPlayEntry />-->
    <div class="wedge" style="height: 12px"></div>
    <LiveList />
    <div class="wedge" style="height: 12px"></div>
    <!-- <TheCopyTradeEntry /> -->
    <TheTechnicalAnalytics />
    <div class="wedge" style="height: 12px"></div>
    <SignalList />
    <div class="wedge" style="height: 12px"></div>
    <TournamentList />
  </div>
</Page>
</template>

<script lang="ts">
import LiveList from '@/modules/membership/components/LiveList.vue'
// import TheCopyTradeEntry from '@/modules/membership/components/TheCopyTradeEntry.vue'
// import TheFastPlayEntry from '@/pages/prime/components/TheFastPlayEntry.vue'
import SignalList from '@/modules/membership/signal/components/SignalList.vue'
import TournamentList from '@/modules/membership/tournament/TournamentList.vue'
import TheHeader from '@/pages/prime/components/TheHeader.vue'
import TheTechnicalAnalytics from '@/pages/prime/components/TheTechnicalAnalytics.vue'
// import OnlyGroupA from '@/provider/OnlyGroupA.vue'
import Page from '@/provider/Page.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Prime',
  components: {
    // OnlyGroupA,
    // TheCopyTradeEntry,
    TheTechnicalAnalytics,
    // TheFastPlayEntry,
    TournamentList,
    SignalList,
    LiveList,
    TheHeader,
    Page,
  },
})
</script>

<style scoped>

</style>
