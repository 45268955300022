
import Swipe from '@/components/Swipe.vue'
import { onActivated, shallowRef, defineComponent } from 'vue'
import Pic from 'common/Pic.vue'
import { readBanner, BannerSchema } from '@/modules/home/home.api'
import useRequest from '@/hooks/useRequest'

export default defineComponent({
  name: 'TheBanner',
  components: { Pic, Swipe },
  setup () {
    const banners = shallowRef<Array<BannerSchema>>([])

    // @todo 暂时使用useRequest解决home页多个onActivated部分不执行bug
    const [request] = useRequest(readBanner)

    const updateBanner = () => {
      request().then(resp => {
        banners.value = []
        banners.value = (resp) || []
      })
    }

    // updateBanner()

    onActivated(() => {
      updateBanner()
    })

    return {
      banners,
    }
  },
})
