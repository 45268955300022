<template>
  <div class="switch" :class="{checked: isChecked}" @click="toggle"></div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'Switch',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    value: String,
    disabled: Boolean,
  },
  emits: ['change'],
  setup (props, ctx) {
    const isChecked = ref(props.checked)

    watch(() => props.checked, () => {
      isChecked.value = props.checked
    })

    const toggle = () => {
      if (!props.disabled) {
        isChecked.value = !(isChecked.value)
        ctx.emit('change', isChecked.value, props.value)
      }
    }
    return {
      isChecked,
      toggle,
    }
  },
})
</script>

<style scoped lang="scss">
  .switch {
    width: 56px;
    height: 28px;
    background: var(--color-grey);
    border-radius: 14px;
    position: relative;
    transition: all .3s;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      left: 8px;
      top: 4px;
      background: var(--color-white);
      border-radius: 50%;
      transition: all .3s;
    }
  }

  .checked {
    background: var(--color-success);
    &::after {
      left: calc(100% - 28px);
    }
  }
</style>
