
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { computed, defineComponent, onDeactivated, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'GuideTarget',
  emits: ['gotIt'],
  props: {
    name: {
      type: String,
      requiredF: true,
    },
    hide: Boolean,
  },
  setup (props, ctx) {
    const key = keymap.guide[props.name as keyof typeof keymap.guide]

    const haveDisplayHistory = shallowRef(!key || (key && Boolean(localGet(key))))

    const show = computed(() => !props.hide && !haveDisplayHistory.value)

    function gotIt () {
      ctx.emit('gotIt')
    }

    const read = () => {
      localSet(key, '1')
    }

    watchEffect(() => {
      if (show.value) {
        read()
      } else {
        gotIt()
      }
    })

    onDeactivated(() => {
      read()
      haveDisplayHistory.value = true
    })

    return { show, gotIt }
  },
})
