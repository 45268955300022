<template>
  <DialogTemplate>
    <template #title><t path="notification_30">Customer Service</t></template>
    <p class="flex-1 content pl-24 pr-20">
<!--      Jane: {{message}}-->
      {{message}}
    </p>
    <template #cancelText>
      <t path="notification_33" custom #="{td}">
        {{ td || 'Later' }}
      </t>
    </template>
    <template #confirm>
      <router-link to="/service" #="{navigate}" custom>
        <button class="flex-1 btn block primary" @click="$emit('close', navigate)">
          <t path="notification_34" custom #="{td}">{{ td || 'See more' }}</t>
        </button>
      </router-link>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'

export default defineComponent({
  name: 'ServiceNotice',
  components: { DialogTemplate },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
.content {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  word-break: break-word;
}
</style>
