/**
 * @author ayou[beica1@outook.com]
 * @date 2022/3/10 13:59
 * @description
 *   inspect.ts of WeTrade
 */
let isInspect = false

export const useInspectMode = () => {
  isInspect = true
}

export const isInspectMode = () => isInspect

export const muteOnInspectMode = (fn: () => unknown) => {
  if (!isInspectMode()) return fn()
}

export const onlyInspectMode = (fn: () => unknown, and = () => true) => {
  if (isInspectMode() && and()) return fn()
}
