<template>
<DialogTemplate>
  <template #title>
    <t :path="dTitle">U.S.Nonfarm Payrolls</t>
  </template>

  <div class="df-middle d-body br-lg mb-32 mt-24">
    <div class="d-item flex-1 df-col df-middle df-center" style="border-right: 1px solid var(--color-grey)">
      <t path="notification_67">Actual</t>
      <p class="f-lg f-bold mt-4" :class="{'c-success':up, 'c-danger':down}">{{ actual }}</p>
    </div>
    <div class="d-item flex-1 df-col df-middle df-center">
      <t path="notification_68">Forecast</t>
      <p class="f-lg f-bold mt-4">{{ forecast }}</p>
    </div>
  </div>

  <template #controls>
    <router-link custom #="{navigate}" :to="`/eventsList/bigNews?id=${eventId}&type=${type}`">
      <button class="flex-1 btn block" @click="handleDetail">
        <t path="notification_69" custom #="{td}">
          {{ td || 'How to trade?' }}
        </t>
      </button>
    </router-link>
    <button class="flex-1 btn block primary" @click="handleClose">
      <t path="notification_70" custom #="{td}">
        {{ td || 'Yes' }}
      </t>
    </button>
  </template>
</DialogTemplate>
</template>

<script lang="ts">
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent, shallowRef } from 'vue'
import i18nByType from '@/pages/bigNews/bigNews'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'BigNewsDialog',
  components: { DialogTemplate },
  props: {
    forecast: {
      type: String,
      required: true,
    },
    actual: {
      type: String,
      required: true,
    },
    eventId: String || Number,
    type: String || Number,
  },
  setup (props, ctx) {
    const router = useRouter()
    const dTitle = shallowRef()
    const up = shallowRef(false)
    const down = shallowRef(false)
    up.value = parseFloat(props.actual) > parseFloat(props.forecast)
    down.value = parseFloat(props.actual) < parseFloat(props.forecast)
    dTitle.value = i18nByType[Number(props.type)].dialogTitle

    const handleDetail = () => {
      ctx.emit('close')
      setTimeout(() => {
        router.push(`/eventsList/bigNews?id=${props.eventId}&type=${props.type}`)
      }, 300)
    }

    const handleClose = () => {
      ctx.emit('close')
    }
    return {
      dTitle,
      up,
      down,
      handleDetail,
      handleClose,
    }
  },
})
</script>

<style scoped>
.d-body{
  height: 72px;
  width: 100%;
  background-color: var(--color-light);
}
</style>
