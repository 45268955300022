/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/23
* @description
*   ThePositionCreateResultDialog.vue of WeTrade
*/
<template>
  <DialogTemplate>
    <template #title><t path="placeorder_34">Market Order Submitted</t></template>
    <div class="t-center flex-1 py-32">
      <Icon name="check" class="bg-success mb-20" style="font-size: 40px;color: white;border-radius: 20px" />
      <t :path="order.type === 2 ? 'placeorder_35' : 'placeorder_36'"
        :args="{amount: `${prop('weight', order)} ${prop('unit', order)}`, symbol: prop('code', order)}"
        as="p">
        Bought 1 Lot of <span class="f-bold">{{prop('code', order)}}@{{prop('createPrice', order)}}</span>
      </t>
      <t v-if="order.rewardPoints > 0" path="pointshop_6" as="p" class="mt-6 points"
        :args="{points: `+${prop('rewardPoints', order)}`}">
        {{ prop('rewardPoints', order) }} Points
      </t>
    </div>
    <template #controls>
      <Button class="block primary" @click="$emit('close')">
        <t path="placeorder_37" #="{td}" custom>{{ td || 'OK' }}</t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionCreateResultDialog',
  components: { Button, Icon, DialogTemplate },
  emits: ['close'],
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
  .points {
    padding: 0 6px;
    display: inline-block;
    border-radius: 24px;
    line-height: 24px;
    border-radius: 2px;
    background: var(--color-asst-light);
    color: var(--color-asst);
  }
</style>
