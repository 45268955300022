<template>
<router-link #="{ navigate }" custom  to="/events/firstDeposit">
<div class="p-16 df-col df-middle df-center br-lg mt-16"
  v-if="isFirst"
  style="background: #FCE9D6;"
   @click="navigate">
  <Pic src="events/firstDeposit/gift" width="64" height="64"/>
  <t path="trade_34" class="f-bold f-lg c-black">First Deposit Bonus</t>
  <div class="df-middle">
    <Pic src="events/firstDeposit/hot" width="16" height="16"/>
    <t path="trade_35" :args="{number: recipients}" class="c-title" style="line-height: 24px;">{{ recipients }} users start up today!</t>
  </div>
  <div class="d-f mt-16">
    <div class="df-middle flex-1 df-between py-12 br-lg" style="background: #213164;">
      <Pic src="events/firstDeposit/VIP_half" width="28" height="44" />
      <t path="trade_36" class="c-white px-8 f-md">1 Week Prime membership</t>
    </div>
    <div class="df-middle flex-1 py-12 ml-12 br-lg" style="background: #4678EF;">
      <Pic src="events/firstDeposit/book_half" width="28" height="44"/>
      <t path="trade_37" class="c-white px-8 f-md">Beginner Pack Forex\CFD eBooks</t>
    </div>
  </div>
</div>
</router-link>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import { checkIsFirstDeposit } from '@/modules/trade/trade.api'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheFirstDepositCard',
  components: {
    Pic,
  },
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
  setup () {
    const isFirst = shallowRef(false)
    const recipients = shallowRef(0)
    checkIsFirstDeposit().then(res => {
      isFirst.value = Boolean(res.first)
      recipients.value = res.recipients
    })
    return {
      isFirst,
      recipients,
    }
  },
})
</script>

<style scoped lang="scss">

</style>
