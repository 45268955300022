<template>
  <DialogTemplate>
    <template #title>
      <t path="home_41">Leverage adjustment</t>
    </template>
    <div class="content df-col df-middle pb-32">
        <Pic src="home/confirmation" width="88" height="88" />
        <div class="px-16">
          <t as="div" path="home_42" :args="{current: curLever}"  multiline #="{td}" custom>
            <p v-for="(p, index) in td" :class="{'f-bold my-8': index===1}" :key="index">{{p}}</p>
          </t>
        </div>
    </div>
    <template #controls>
      <Button class="flex-1 btn block" @click="handleClose" :progress="closeLoading">
        <t path="home_43" :args="{current: curLever}" custom #="{td}">
        {{ td || 'No, stay moderate ' }}
        </t>
      </Button>
      <Button class="flex-1 btn block primary" @click="handleDetail" :progress="okLoading">
        <t path="home_44" custom #="{td}">
          {{ td || 'Yes, see details' }}
        </t>
      </Button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { defineComponent, shallowRef } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { isDemoAccount } from '@/state/accountType'
import Pic from '@/components/Pic.vue'
import { doneTask } from '@/modules/home/home.api'
import { useRouter } from 'vue-router'
import Button from '@/components/Button.vue'
import { translate } from '@/i18n'

export default defineComponent({
  name: 'TheLeverageDialog',
  components: {
    DialogTemplate,
    Pic,
    Button,
  },
  props: {
    lever: {
      type: Number,
      required: true,
    },
  },
  emits: ['close'],
  setup (props, ctx) {
    const leverArr = ['transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']
    const curLever = shallowRef(translate(leverArr[props.lever]))
    const router = useRouter()
    const closeLoading = shallowRef(false)
    const okLoading = shallowRef(false)

    const handleClose = () => {
      closeLoading.value = true
      doneTask({
        type: 1,
        tag: 6,
      }).then(() => {
        closeLoading.value = false
        ctx.emit('close')
      })
    }

    const handleDetail = () => {
      okLoading.value = true
      doneTask({
        type: 1,
        tag: 6,
      }).finally(() => {
        okLoading.value = false
        ctx.emit('close')
        setTimeout(() => {
          router.push('/user/leverage')
        }, 300)
      })
    }
    return {
      isDemoAccount,
      closeLoading,
      okLoading,
      curLever,
      handleClose,
      handleDetail,
    }
  },
})
</script>
<style scoped>
.content{
    width: 100%;
}
</style>
