
import { defineComponent, shallowRef, watch } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheLiveEntran',
  components: {
    Icon,
    Pic,
  },
  props: {
    liveInfo: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const showComponent = shallowRef(false)
    watch(() => props.liveInfo, () => {
      if (props.liveInfo && props.liveInfo.chatRoomId) {
        showComponent.value = true
      }
    }, {
      immediate: true,
      deep: true,
    })

    return {
      showComponent,
    }
  },
})
