/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/1/6
 * @description
 *   withAccountUpdate.ts of FastTradeV3
 */
import { events } from '@/config'
import { refreshAccount } from '@/modules/app/app'
import { emit } from 'essential/tools/event'

const withAccountUpdate = <
  F extends (...args: any[]) => Promise<any>,
  P extends Parameters<F>,
  R = ReturnType<F> extends Promise<infer P> ? P : any,
> (
    fn: F,
    emitUpdate?: boolean,
  ) => {
  return async (...args: P): Promise<R> => {
    const result = await fn(...args)
    if (emitUpdate) emit(events.transactionUpdate)
    await refreshAccount()
    return result
  }
}

export default withAccountUpdate
