
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import TheMarketItem from '@/modules/market/components/TheMarketItem.vue'

export default defineComponent({
  name: 'TheStockList',
  components: { TheMarketItem, Icon },
  props: {
    stocks: {
      type: Array,
      required: true,
    },
  },
  setup () {
    const show = ref(true)
    return {
      show,
      toggleSlide: () => show.value = !(show.value),
    }
  },
})
