
import { defineComponent, shallowRef } from 'vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { useDeposit } from '@/modules/in/in'
import TheChannels from '@/modules/out/components/TheChannels.vue'

export default defineComponent({
  name: 'TheDailyBonusPay',
  components: { Button, Icon, TheChannels },
  emits: ['close'],
  props: {
    amount: Number,
    index: Number,
    currunt: Object,
    channelList: Array,
  },
  setup (props, ctx) {
    const [commit, progress] = useDeposit()
    const channel = shallowRef<string>('')

    const handleClose = () => {
      ctx.emit('close')
    }

    const handlePay = () => {
      const params = {
        amount: props.amount,
        payChannel: channel.value,
        rechargeType: 1,
      }
      commit(params).finally(() => {
        ctx.emit('close')
      })
    }

    return {
      channel,
      progress,
      handleClose,
      handlePay,
    }
  },
})
