/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/12
 * @description
 *   TradeModel.ts of WeTrade
 */
import { format } from '@/config'
import { readLatestQuote } from '@/modules/market/market'
import { SymbolSnapshot } from '@/modules/market/market.types'
import symbol from '@/modules/market/Symbol.vue'
import { ModelSchema, Product } from '@/modules/trade/trade.api'
import { TradeDirection, YesOrNo } from '@/types'
import { div, mul } from 'essential/tools/math'
import { computed, shallowReactive, shallowRef, watchEffect } from 'vue'

export enum LimitType {
  LOSS,
  PROFIT,
}

export enum OrderType {
  POSITION,
  COMMISSION,
}

const defaultModel: ModelSchema = {
  deferred: YesOrNo.YES, // 默认订单可过夜
  type: TradeDirection.BUY,
  offset: 30, // 挂单偏移量
  orderNumber: 1, // 挂单数量
  productId: '',
  isJuan: YesOrNo.NO,
  price: '', // 下单时的市场报价
  stopProfit: 0, // 止盈点数0为不设置止盈 止损 同理
  stopLoss: 0,
  isTrackSt: 0,
}

class TradeModel {
  protected model: ModelSchema
  protected product = shallowRef<null | Product>(null)
  // 实时价格或建仓价格
  staticPrice = ''

  symbol = shallowRef<SymbolSnapshot | null>(null)

  // 订单波动盈亏
  pipProfit = computed(() => {
    if (!this.product.value) return 0

    if (this.product.value.ykUnit === 'USD') {
      return mul(this.product.value?.yk ?? 0, this.model.orderNumber)
    }

    if (!this.symbol.value) return 0

    return div(mul(this.product.value.yk, this.model.orderNumber), this.symbol.value?.sell)
  })

  constructor (type: TradeDirection, initial?: Partial<ModelSchema>) {
    this.model = shallowReactive({
      ...defaultModel,
      ...initial,
    })

    this.model.type = type

    watchEffect(() => {
      if (this.product.value) {
        readLatestQuote(this.product.value?.contract)
          .then(resp => this.symbol.value = resp as SymbolSnapshot)
      }
    })
  }

  setType (type: TradeDirection) {
    this.model.type = type
  }

  setOrderId (orderId: number) {
    this.model.orderId = orderId
  }

  useCoupon (use: boolean) {
    this.model.isJuan = use ? YesOrNo.YES : YesOrNo.NO
  }

  // overwrite update
  updateProduct (product: Product) {
    this.product.value = product
    this.model.productId = product?.productId
    if (product.orderNumber) {
      this.setOrderCount(product.orderNumber)
    }
  }

  // update product if Nil
  setProduct (product: Product) {
    if (!this.product.value) {
      this.updateProduct(product)
    }
  }

  setTopLimit (limit: number) {
    this.model.stopProfit = limit
  }

  setBottomLimit (limit: number) {
    this.model.stopLoss = limit
  }

  setStopTrailing (state: boolean) {
    this.model.isTrackSt = Number(state)
  }

  isStopTrailing () {
    return this.model.isTrackSt === YesOrNo.YES
  }

  setPrice (price: string) {
    this.model.price = price
  }

  setOrderCount (number: number) {
    this.model.orderNumber = number
  }

  setOffset (price: string = this.staticPrice) {
    if (this.product.value) {
      this.model.offset = Math.ceil(div(div(price, 1000), this.product.value.pointStep))
    }

    if (price) this.staticPrice = price
  }

  getProduct () {
    return this.product
  }

  getValue () {
    return this.model
  }

  destroy () {
    this.product.value = null
    this.model = defaultModel
  }
}

export default TradeModel
