/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   PageHeader.vue of WeTrade
*/
<template>
  <div class="page__header df-middle">
    <div class="flex-1">
      <slot v-if="!noLeft" name="left">
        <Icon name="caret-left-lg" @click="$router.back()" />
      </slot>
    </div>
    <div class="flex-3 t-center f-bold f-lg _p-title">
      <slot v-if="!noTitle" name="title">Title</slot>
    </div>
    <div class="flex-1 t-right">
      <slot v-if="!noRight" name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

// @todo z-index 引起的弹窗title bar层级问题
export default defineComponent({
  name: 'PageHeader',
  components: { Icon },
  props: {
    noTitle: Boolean,
    noRight: Boolean,
    noLeft: Boolean,
  },
})
</script>

<style scoped>
.page__header {
  height: var(--page-header-height);
}
._p-title{
  white-space:nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
