
import Page from '@/provider/Page.vue'
import PageHeader from '@/components/PageHeader.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageWithHeader',
  components: { Scroller, Page, PageHeader },
  props: {
    headerClass: String,
    bodyCol: Boolean,
  },
})
