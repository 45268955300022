/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/20 14:42
 * @description
 *   reducer.ts of WeTrade
 */
import { parseToJson } from '@/common/format'
import { openDialog as showDialog } from '@/components/popup/popup'
import { events } from '@/config'
import FastTradeResult from '@/modules/fastTrade/notification/FastTradeResult.vue'
import { Position } from '@/modules/trade/trade.api'
import { isDemoAccount } from '@/state/accountType'
import { Broadcast } from '@/types'
import { emit } from 'essential/tools/event'

enum NotifyType {
  FASTCLOSE = 13,
  DEMO_FAST_CLOSE
}

function fastCloseNotify (message: string, demoOnly = false) {
  if (!demoOnly || (demoOnly && isDemoAccount.value)) {
    const order = parseToJson(message).json as Position
    if (order) {
      emit(events.quickClose, order.orderId)
      showDialog(FastTradeResult, { order })
    }
  }
}

const quickPlayReducer = (broadcast: Broadcast<NotifyType>) => {
  switch (broadcast.cmd) {
    case NotifyType.FASTCLOSE:
      return fastCloseNotify(broadcast.message)
    case NotifyType.DEMO_FAST_CLOSE:
      return fastCloseNotify(broadcast.message, true)
  }
}

export default quickPlayReducer
