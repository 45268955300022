/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   Donut.vue of WeTrade
*/
<template>
  <transition name="fade-in" appear>
    <div class="bounce df-cross-center" :class="{pause}">
      <span class="bouncing__item"></span>
      <span class="bouncing__item"></span>
      <span class="bouncing__item"></span>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from 'vue'

export default defineComponent(
  {
    name: 'Bouncing',
    props: {
      pause: Boolean,
    },
  })
</script>

<style>
@keyframes bouncing-loader {
  from {
    opacity: 1;
    transform: translate3d(0, 4px, 0);
  }
  to {
    opacity: 0.1;
    transform: translate3d(0, -4px, 0);
  }
}
</style>

<style scoped lang="scss">
$bg: var(--color-primary);
.bounce {
  min-height: 16px;
  overflow: hidden;
}

.bouncing__item {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background: $bg;
  border-radius: 50%;
  animation: bouncing-loader 0s infinite alternate;

  &:nth-child(1) {
    opacity: 0.5;
    transform: translate3d(0, 0, 0);
    animation-delay: 0s;
  }

  &:nth-child(2) {
    opacity: 0.7;
    transform: translate3d(0, 2px, 0);
    animation-delay: .1s;
  }

  &:nth-child(3) {
    opacity: 1;
    transform: translate3d(0, 4px, 0);
    animation-delay: .2s;
  }
}

:not(.pause) > .bouncing__item {
  animation-duration: 0.6s;
}
</style>
