
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent, onActivated, ref } from 'vue'
import TheRadarList from '@/modules/market/radar/components/TheRadarList.vue'
import TheActiveItem from '@/modules/market/radar/components/TheActiveItem.vue'
import {
  readRadarActive,
  RadarItemSchema,
  RatioSchma,
  RadarListSchema,
} from '@/modules/market/radar/radar.api'
import TheTermTrending from '@/modules/market/radar/components/TheTermTrending.vue'
import TheSentiment from '@/modules/market/radar/components/TheSentiment.vue'
import * as R from 'ramda'

export default defineComponent({
  name: 'TheRadar',
  components: { Scroller, TheSentiment, TheTermTrending, TheActiveItem, TheRadarList },
  setup () {
    const activeList = ref<Array<RadarItemSchema> | null>(null)
    const longTermList = ref<Array<RadarItemSchema> | null>(null)
    const shortTermList = ref<Array<RadarItemSchema> | null>(null)
    const sentimentList = ref<Array<RatioSchma> | null>(null)

    const setMacdArray = (data: Array<RadarItemSchema>) => {
      const formatData: Array<RadarItemSchema> = []
      R.forEach(x => {
        formatData.push(R.assoc('dataType', 'MACD')(x))
      }, data)
      return formatData
    }

    const read = () => {
      readRadarActive().then((resp: RadarListSchema) => {
        activeList.value = resp.activitiData
        longTermList.value = R.concat(resp.longRrend?.tendAndSarLists ?? [], setMacdArray(resp.longRrend?.macdAndSarLists ?? []))
        shortTermList.value = R.concat(resp.shortRrend?.tendAndSarLists ?? [], setMacdArray(resp.shortRrend?.macdAndSarLists ?? []))
        sentimentList.value = resp.contractRatioModels
      })
    }

    onActivated(read)

    return {
      activeList,
      longTermList,
      shortTermList,
      sentimentList,
    }
  },
})
