<template>
  <ResultDialogTemplate>
    <template #title>
      <!--  SELL = 1, BUY = 2  -->
      <t :path="isDemoAccount ? resText[0][order.type - 1].title : resText[1][order.type - 1].title">
        Limit order executed
      </t>
    </template>
    <t as="p"
      :path="isDemoAccount ? resText[0][order.type - 1].content : resText[1][order.type - 1].content"
      :args="{amount: `${prop('weight', order)} ${prop('unit', order)}`, symbol: prop('code', order), price: prop('createPrice', order)}"
    >
      {{prop('weight', order)}} {{prop('unit', order)}} of {{prop('code', order)}}
      Limit order executed@
      <span class="f-bold">{{prop('createPrice', order)}}</span> .
    </t>
    <t v-if="prop('rewardPoints', order) > 0" path="pointshop_6" as="p" class="mt-6 points"
      :args="{points: `+${prop('rewardPoints', order)}`}">
      {{ prop('rewardPoints', order) }} Points
    </t>
    <template #controls>
      <button class="flex-1 btn block" @click="check">
        <t path="notification_31" custom #="{td}">
          {{ td || 'Check' }}
        </t>
      </button>
    </template>
  </ResultDialogTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { useRouter } from 'vue-router'
import { isDemoAccount } from '@/state/accountType'

export default defineComponent({
  name: 'LimitOrderResult',
  components: { ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const router = useRouter()

    const check = () => {
      ctx.emit('close')
      router.replace(`/symbol/${props.order.code}`)
    }

    const resText = [[{
      title: 'notification_18',
      content: 'notification_19',
    }, {
      title: 'notification_16',
      content: 'notification_17',
    }], [{
      title: 'notification_4',
      content: 'notification_5',
    }, {
      title: 'notification_2',
      content: 'notification_3',
    }]]

    return {
      check,
      isDemoAccount,
      resText,
    }
  },
})
</script>

<style scoped lang="scss">
.points {
  padding: 0 6px;
  display: inline-block;
  border-radius: 24px;
  line-height: 24px;
  border-radius: 2px;
  background: var(--color-asst-light);
  color: var(--color-asst);
}
</style>
