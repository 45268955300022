/**
* @author 贝才[beica1@outook.com]
* @date 2020/12/4
* @description
*   Holdon.vue of FastTradeV3
*/
<template>
  <Holder v-bind="$attrs">
    <template #holder>
      <div class="df-cross-center" :style="{height: `${height}px`}">
        <Loading />
      </div>
    </template>
    <slot />
  </Holder>
</template>

<script lang="ts">
import Loading from 'common/loading/Donut.vue'
import Holder from '@/provider/Holder.vue'

export default {
  name: 'HoldOn',
  components: { Holder, Loading },
  props: {
    height: {
      type: Number,
      default: 20,
      validator (value: number) {
        return value >= 20
      },
    },
  },
}
</script>
