/**
* @author 贝才[beica1@outook.com]
* @date 2021/6/25
* @description
*   ExperienceSelect.vue of WeTrade
*/
<template>
  <div class="p-a spread pt-16 px-32 z-1 bg-theme">
    <Pic src="logo_&_text" width="126" height="32" />
    <div class="wedge" style="height: 48px"></div>
    <t as="p" path="tradingexperience_2"></t>
    <div class="wedge" style="height: 40px"></div>
    <button class="btn block rookie" @click="tag(0)">
      <t path="tradingexperience_3"></t>
    </button>
    <button class="btn block mt-24 veteran" @click="tag(1)">
      <t path="tradingexperience_4"></t>
    </button>
  </div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { tagUser } from '@/modules/user/user.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ExperienceSelect',
  components: { Pic },
  setup (props, ctx) {
    const tag = (hasExp: boolean) => {
      tagUser({ tradeExpFlg: hasExp })
      ctx.emit('close')
    }

    return {
      tag,
    }
  },
})
</script>

<style scoped lang="scss">
.rookie {
  min-height: 72px;
  border: 1px solid var(--color-success);
  color: var(--color-success);
  background: #DAF6E6;
}

.veteran {
  min-height: 72px;
  border: 1px solid var(--color-asst);
  color: var(--color-asst);
  background: var(--color-asst-light);
}
</style>
