
import { defineComponent, computed } from 'vue'
import Pic from '@/components/Pic.vue'
import { YesOrNo } from '@/types'
import state from '@/state'
import { openDialog } from '@/components/popup/popup'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'

export default defineComponent({
  name: 'TechnicalsBar',
  components: {
    Pic,
  },
  setup () {
    const isPrime = computed(() => state.prime.member === YesOrNo.YES)

    const handleClick = (e: Event) => {
      if (!isPrime.value) {
        openDialog(LimitDialogTemplate, {
          i18nPath: 'symbol_124',
        })
        e.stopPropagation()
      }
    }
    return {
      handleClick,
    }
  },
})
