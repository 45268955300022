/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/11
 * @description
 *   app.ts of WeTrade
 */
import jsBridge from '@/common/jsBridge'
import {
  androidRotate,
  customStatusBar,
  firebaseTrack,
  getIds,
  iosRotate,
  ready as appReady,
  resetStatusBar,
} from '@/common/jsBridge.api'
import { requestByAccountType } from '@/common/request/request'
import { openDialog, openFullscreenList, showAlert } from '@/components/popup/popup'
import { events, flag, interval, keymap, request } from '@/config'
import { setState } from '@/decorators/withoutCryptos'
import i18n, { DEFAULT_LANG, SUPPORTED_LANGS } from '@/i18n'
import {
  checkAndroidUpdate,
  loadLocaleFile,
  readDepositRateList,
  readEnv,
  readPrime,
  readWithdrawalRateList,
  reportOpenTime,
  getTimeZone,
} from '@/modules/app/app.api'
import copyTradeReducer from '@/modules/copyTrade/notification/reducer'
import quickPlayReducer from '@/modules/fastTrade/notification/reducer'
import tradeNotificationReducer from '@/modules/trade/notification/reducer'
import state, { isLogged, isNewLogin, UserAccount } from '@/state'
import { useAccountChange } from '@/state/accountType'
import { login, logout, setPrime } from '@/state/actions'
import { TradeFlag, YesOrNo } from '@/types'
import ExperienceSelect from '@/views/ExperienceSelect.vue'
import UpdateDialog from '@/views/UpdateDialog.vue'
import connect from '@/worker/notification.client'
import parsePushMessage from '@/worker/notification.parser'
import { localGet, localRemove, localSet } from 'essential/store/localStore'
import { emit, off, on } from 'essential/tools/event'
import { getQueryParam, uuidV4 } from 'essential/util'
import * as R from 'ramda'
import { nextTick, onBeforeUnmount, shallowRef } from 'vue'
import { Router, useRouter } from 'vue-router'

export const setupNewSession = (force = false) => {
  R.mapObjIndexed(localRemove, keymap.label.session)

  if (force || !localGet(keymap.label.uuid)) {
    localSet(keymap.label.uuid, uuidV4())
  }
}

/**
 * 循环机
 * @param action
 * @param interval
 * @param loopCheck
 * @param onStop
 */
const makeLoopEngine = <T> (
  action: () => Promise<T> | T,
  interval = 3,
  loopCheck: (error: Error | null, result: T | null, preResult: T | null, count: number) => boolean,
  onStop?: Noop,
) => {
  const minInterval = R.max(interval, 1000)
  let running = false
  let count = 0
  let preResult: T | null = null
  let timer = 0
  let stopped = false

  // 检查边界并继续/停止循环
  async function run (loop: () => void, error: Error | null, result: T | null) {
    const forward = await loopCheck(error, result, preResult, count)
    preResult = result
    if (!stopped && forward) {
      timer = window.setTimeout(loop, minInterval)
    } else {
      count = 0
      if (typeof onStop === 'function') onStop()
    }
  }

  async function exe () {
    if (running) return

    running = true
    count += 1

    try {
      // 执行循环的动作
      const result = await action()
      run(exe, null, result)
    } catch (e) {
      run(exe, e as Error || new Error('loop action error occurred'), null)
    } finally {
      running = false
    }
  }

  return {
    start: exe,
    stop () {
      stopped = true
      clearTimeout(timer)
    },
  }
}

// 边界检查
const readyForNext = R.allPass([R.is(Array), R.complement(R.isEmpty)])

const _updateRequest = requestByAccountType<UserAccount>('read')

export const refreshAccount = (silent = false) => _updateRequest(null, { silent }).then(resp => {
  state.account = resp as UserAccount
  return state.account
})
/**
 * 循环准备
 */
const makeLoopMachine = () => {
  let engineStarted = false

  const engine = makeLoopEngine<UserAccount>(
    () => refreshAccount(true),
    interval.accountRefreshInterval,
    (error, result) => {
      if (error) return false
      // 订单数量有变化 同步更新到chart
      // syncToChart(defaultToArray(preResult?.list), defaultToArray(result?.list))
      return readyForNext(result?.list) || false
    },
    () => {
      engineStarted = false
    },
  )

  return () => {
    if (engineStarted) return

    engineStarted = true

    engine.start()
  }
}

export const setupTransactionLoopJob = makeLoopMachine()

/**
 * 更新提示
 * @param resp
 */
const showUpdatePrompt = (resp: Data) => {
  openDialog(
    UpdateDialog, {
      config: resp,
      wrapperClass: 'center',
    }, { sync: true })
}

/**
 * 检查更新
 */
const checkUpdate = async () => {
  if (!flag.isIOS) {
    return checkAndroidUpdate()
      .then(resp => R.when(R.propEq('update', true), showUpdatePrompt)(resp as { update: boolean }))
  }
  return Promise.resolve()
}

/**
 * 链接推送服务器
 */
const connectPushServer = async () => {
  try {
    const server = await connect()
    server.reduce([tradeNotificationReducer, quickPlayReducer, copyTradeReducer])
    on(events.activated, () => {
      if (isLogged()) {
        // 切到前台强制重连
        server.socket.retry(true)
      }
    })
  } catch (e) {
    showAlert('[000002]')
  }
}

/**
 * 标记用户
 */
export const tagUser = () => {
  openFullscreenList(ExperienceSelect, {}, {
    sync: true,
  })
}
/**
 * 根据经验标记用户
 * @param userAccount
 */
const tryTagUser = (userAccount: UserAccount) => {
  setTimeout(() => {
    if (userAccount.tradeExpFlg === TradeFlag.UNSET) {
      tagUser()
    }
  }, 0)
}

/**
 * 用户登录之后的任务处理
 * @param user
 */
const afterLogin = (user: UserAccount) => {
  refreshPrime()

  // 检查android更新
  checkUpdate()

  // 开启交易查询轮询任务
  setupTransactionLoopJob()

  // 链接推送服务器
  connectPushServer()

  tryTagUser(user)
}

/**
 * 利用一次账户信息的获取来验证用户登录
 */
export const verifyLogin = () => {
  refreshAccount()
    .then(user => {
      configApp().then(() => {
        login()
        emit(events.login, user)
      })
    })
    .catch(logout)
}

/**
 * app准备
 * @param router
 */
export const ready = (router: Router) => {
  // 上报开打时间
  reportOpenTime()
  // 通知app
  appReady()
  // firebase追踪
  firebaseTrack()

  // 更新用户账户信息并启动相关处理进程
  if (!isNewLogin()) {
    verifyLogin()
  }

  const p = getQueryParam(keymap.search.pushMessage)
  if (p) {
    parsePushMessage(p, router)
  }
}

/**
 * 全局事件处理
 */
export const monitorGlobalEvents = () => {
  const router = useRouter()

  const toLogin = () => {
    // @todo 对于主动退出的用户这个的logout调用是多余的
    logout()
    return router.push({ name: 'login' })
  }

  // 确认登录
  on(events.login, afterLogin)

  // 鉴权失败
  on([events.tokenExpired, events.logout], toLogin)

  // 持仓更新 开启轮询
  on(events.transactionUpdate, setupTransactionLoopJob)

  // app激活
  jsBridge.onActivated(() => {
    emit(events.activated)
  })

  // 切换账户 刷新账户信息
  useAccountChange(() => {
    // showAlert('catch account change')
    setupTransactionLoopJob()
    refreshAccount()
    // R.juxt([setupTransactionLoopJob, refreshAccount])()
  })

  onBeforeUnmount(() => {
    off(events.transactionUpdate, setupTransactionLoopJob)
    off([events.tokenExpired, events.logout], toLogin)
    off(events.login, afterLogin)
  })
}

/**
 * 系统语言切换
 * @param lang
 */
export const switchLanguage = async (lang?: string) => {
  const usedLang = lang || getQueryParam('lang') || localGet(keymap.label.language)
  let targetLang = usedLang || navigator.language?.split('-')[0]
  const isSupport = R.includes(targetLang, SUPPORTED_LANGS)
  if (isSupport) {
    const loaded = i18n.getLocaleMessage(targetLang)
    if (R.isEmpty(loaded)) {
      const message = await loadLocaleFile(targetLang)
      i18n.setLocaleMessage(targetLang, message.data)
    }
  } else {
    targetLang = DEFAULT_LANG
  }
  await nextTick()
  i18n.locale.value = targetLang
  localSet(keymap.label.language, targetLang)
}

/**
 * 获取实时汇率
 */
export const useRate = (isOut = false) => {
  const rate = shallowRef({
    value: 0,
    unit: '',
    rate: 1,
  })

  const refresh = (data?: Data) => (isOut ? readWithdrawalRateList : readDepositRateList)(
    data).then(
    resp => {
      if (resp) {
        rate.value = {
          rate: Number(resp.rate),
          value: Number((1 / Number(resp.rate)).toFixed(2)),
          unit: resp.code,
        }
      }
    })

  return {
    rate,
    refresh,
  }
}

/**
 *
 * 获取设备唯一性信息
 */
export const withDeviceIdentities = async (action: (data: Data) => void) => {
  const defaults = {
    deviceInformation: getQueryParam(keymap.search.deviceInfo),
    udid: getQueryParam(keymap.search.imei) ?? 'unknown',
    get adid () {
      const id = localGet(keymap.label.adid)
      if (id) return id

      const newId = uuidV4()
      localSet(keymap.label.adid, newId)
      return newId
    },
  }
  if (flag.isDevMode) return action(defaults)
  const ids = await getIds() as Data
  return action(
    {
      ...defaults,
      ...ids,
    },
  )
}

/**
 * 判断欧洲国家
 */
export const isEURCountry = () => R.includes(
  localGet(keymap.user.countryCode), ['31', '32', '33', '34', '351', '39', '44', '48', '49', '90'])

export const refreshPrime = () => readPrime().then(resp => {
  setPrime(resp)
  return resp
})

export const rotate = (dir: 'vertical' | 'horizontal', cb?: () => void) => {
  const data = { dir }

  if (flag.isIOS) {
    return iosRotate(data)
  } else {
    return new Promise(resolve => {
      const done = () => {
        window.removeEventListener('orientationchange', done)
        resolve(window.orientation)
      }

      window.addEventListener('orientationchange', done)

      androidRotate({ dir }, cb)
    })
  }
}

/*
* 主题色初始化
* 主题模式 正常(ligth)  暗色(dark)  跟随系统(system)
*/
export const themeInit = (mode?: string) => {
  let initMode = ''
  if (mode) {
    initMode = mode
  } else {
    initMode = localGet(keymap.label.theme) || 'light'
  }

  localSet(keymap.label.theme, initMode)
  if (initMode === 'system') {
    themeFlowSystem()
  } else {
    setTheme(initMode)
  }
}

/*
*检测系统是否是暗色模式
*/
function checkIsDarkMode () {
  try {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  } catch (err) {
    return false
  }
}

function themeFlowSystem () {
  let initMode = ''
  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
  initMode = isDarkMode ? 'dark' : 'light'
  setTheme(initMode)
  on(events.activated, () => {
    const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches
    initMode = isDarkMode ? 'dark' : 'light'
    setTheme(initMode)
  })
}

function setTheme (initMode: string) {
  if (initMode === 'dark') {
    customStatusBar({
      color: '#1A2033',
      theme: 'dark',
    })
  } else {
    resetStatusBar()
  }
  document.getElementsByTagName('body')[0].classList.remove('light-mode')
  document.getElementsByTagName('body')[0].classList.remove('dark-mode')
  document.getElementsByTagName('body')[0].classList.add(initMode + '-mode')
}

// 返回当前主题设置是否为暗色模式
export const isDarkMode = () => {
  if (localGet(keymap.label.theme) === 'dark') {
    return true
  }
  if (localGet(keymap.label.theme) === 'system') {
    return checkIsDarkMode()
  }
  return false
}

/**
 * 启动前配置APP
 */
export const configApp = async () => {
  let id = ''

  try {
    const ids = await getIds() as { adid: string }
    id = ids.adid
  } catch {
    id = 'unknown'
  }

  request.staticRequestData.adId = id

  // 测试不同的id
  // id = 'abc_' + Math.random().toString(32)

  const config = await readEnv({
    ...getTimeZone(),
    adId: id,
  }).catch(e => {
    return e
  })

  // 设置用户分组
  state.group = config.abFlag === YesOrNo.YES ? 'B' : 'A'

  // 配置加密货币状态
  setState(config.cryptosFlag === YesOrNo.YES)

  // ip分区
  return config.ipFlag === YesOrNo.YES
}
