<template>
<router-view #="{ Component, route }">
  <TransitionProvider :name="route.meta.transition">
    <keep-alive :include="cacheList">
      <component :is="Component" />
    </keep-alive>
  </TransitionProvider>
</router-view>
<Popup />
</template>

<script lang="ts">
import { monitorGlobalEvents, ready, setupNewSession, switchLanguage, themeInit } from '@/modules/app/app'
import TransitionProvider from '@/provider/TransitionProvider.vue'
import Popup from 'common/popout/Popup.vue'
import { computed, onMounted } from 'vue'
import { dynamicAliveList } from '@/features/dynamicAlive'
import { useRouter } from 'vue-router'

export default {
  components: { Popup, TransitionProvider },
  setup () {
    const router = useRouter()

    setupNewSession()

    themeInit()

    monitorGlobalEvents()

    onMounted(() => {
      switchLanguage().then(() => {
        ready(router)
      })
    })

    return {
      cacheList: computed(() => ['TabPage', 'CopyTrade', ...dynamicAliveList.value]),
    }
  },
}
</script>
