/**
* @description
*   TheHot.vue of WeTrade
*/
<template>
  <div id="hot">
    <div class="mt-16 mb-8">
      <router-link
        v-for="(item, index) in topics" :key="index"
        :to="`/topic/${item.label}`" custom #="{navigate}"
      >
        <span class="mr-8 c-primary br-lg hot-item f-md" @click="navigate">#{{item.label}}</span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import Pic from 'common/Pic.vue'
// import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheHot',
  components: {
    // Icon,
    // Pic,
  },
  props: {
    topics: {
      type: Array,
      required: true,
    },
  },
  setup () {
    const w = document.getElementById('app')?.clientWidth || 375
    return {
      w,
    }
  },
})
</script>
<style scoped lang="scss">
.hot-list {
  height: 32px;
  line-height: 32px;
  border-radius: 16px;

  .icon {
    position: relative;
    top: 8px;
    vertical-align: top;
  }
}
.hot-item{
  background-color: var(--color-primary-thin);
  padding: 6px 10px;
}
.list-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
