
import { defineComponent, ref, watch } from 'vue'

export default defineComponent({
  name: 'Switch',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    value: String,
    disabled: Boolean,
  },
  emits: ['change'],
  setup (props, ctx) {
    const isChecked = ref(props.checked)

    watch(() => props.checked, () => {
      isChecked.value = props.checked
    })

    const toggle = () => {
      if (!props.disabled) {
        isChecked.value = !(isChecked.value)
        ctx.emit('change', isChecked.value, props.value)
      }
    }
    return {
      isChecked,
      toggle,
    }
  },
})
