/**
* @author 阿佑[ayooooo@petalmail.com]
* @date 2022/4/14 22:54
* @description
*   MarketTab.vue of WeTrade
*/
<template>
<t #="{ t }" custom>
  <!--因为WTTabs组件有缺陷 这里采用if-else的方式来解决一个特定的问题-->
  <WTTabs
    v-if="shield"
    :tabs="[t('market_2'), t('market_28'), t('market_3'), t('market_4'), t('market_26'), t('market_6')]"
    class="fill flex-1" nav-class="wt-nav px-4" no-scroll
    tab-class="wt-tab-nav"
  >
    <TheWatchList />
    <TheRadar />
    <TheMarketList :read="tabs[0].load" />
    <TheMarketList :read="tabs[1].load" />
    <TheStockCategory :read="tabs[4].load" />
    <TheMarketList :read="tabs[3].load" />
  </WTTabs>
  <WTTabs
    v-else
    :tabs="[t('market_2'), t('market_28'), t('market_3'), t('market_4'), t('market_5'), t('market_26'), t('market_6')]"
    class="fill flex-1" nav-class="wt-nav px-4" no-scroll
    tab-class="wt-tab-nav"
  >
    <TheWatchList />
    <TheRadar />
    <TheMarketList :read="tabs[0].load" />
    <TheMarketList :read="tabs[1].load" />
    <TheMarketList :read="tabs[2].load" />
    <TheStockCategory :read="tabs[4].load" />
    <TheMarketList :read="tabs[3].load" />
  </WTTabs>
</t>
</template>

<script lang="ts">
import WTTabs from '@/components/Tabs.vue'
import { isShield } from '@/decorators/withoutCryptos'
import TheMarketList from '@/modules/market/components/TheMarketList.vue'
import TheStockCategory from '@/modules/market/components/TheStockCategory.vue'
import TheWatchList from '@/modules/market/components/TheWatchList.vue'
import { useMarket } from '@/modules/market/market'
import TheRadar from '@/modules/market/radar/TheRadar.vue'
import { defineComponent, onActivated, shallowRef } from 'vue'

export default defineComponent({
  name: 'MarketTab',
  components: {
    WTTabs,
    TheRadar,
    TheStockCategory,
    TheWatchList,
    TheMarketList,
  },
  setup () {
    const { tabs } = useMarket()
    const shield = shallowRef(true)

    onActivated(() => isShield().then(v => shield.value = v))

    return {
      shield,
      tabs,
    }
  },
})
</script>

<style scoped>
</style>
