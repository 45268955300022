
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import Holder from '@/provider/Holder.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'

export default defineComponent({
  name: 'TheRadarList',
  components: { Icon, AsyncNullableList, Holder },
  props: {
    i18nPath: String,
    i18nDefault: String,
    list: {
      type: Array,
      default: () => ([]),
    },
  },
  setup () {
    const show = ref(true)
    return {
      show,
      toggleSlide: () => show.value = !(show.value),
    }
  },
})
