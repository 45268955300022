/**
* @author zjc[beica1@outook.com]
* @date 2021/11/18 14:19
* @description
*   TheTradeQuoteBar.vue of WeTrade
*/

<template>
<router-link :to="`/symbol/${code || symbol && symbol.code}`" custom #="{navigate}">
  <RealTimeQuote :symbol="symbol" #="synced">
    <div
      :class="{'c-danger': !synced.isRise}"
      class="bar df-middle c-success t-center"
      v-bind="$attrs"
      @click="navigate"
    >
      <slot v-bind="synced">
        <span class="f-bold c-text mr-12">{{synced.code}}</span>
        <div class="flex-1 t-left df-middle">
          <span>{{synced.price}}</span>
          <span class="ml-24">{{synced.margin}}</span>
          <span class="ml-24">{{synced.mp}}</span>
        </div>
      </slot>
    </div>
  </RealTimeQuote>
</router-link>
</template>

<script lang="ts">
import { useQuote } from '@/modules/market/market'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheTradeQuoteBar',
  components: { RealTimeQuote },
  props: {
    symbol: Object,
    code: String,
  },
  setup (props) {
    if (props.symbol) {
      return
    }

    if (props.code) {
      return {
        symbol: useQuote(props.code),
      }
    }
  },
})
</script>

<style scoped>
.bar {
  height: 32px;
}

.diff {
  padding: 0 1px;
  font-size: 11px;
  border: 1px solid var(--color-title-80);
  border-radius: 2px;
}
</style>
