/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/24
* @description
*   TheMarketCloseTip.vue of WeTrade
*/
<template>
  <div class="close-tip df-middle px-16">
    <Pic src="closed" width="32" height="32" />
    <div class="flex-1 f-sm ml-16">
      <t  v-if="isCC" as="p" path="symbol_26" :args="{close: times.join('-')}">
        Closing Time: {{times[0]}} - {{times[1]}} Open on weekends.
      </t>
      <t v-else as="p" path="symbol_26" :args="{close: times.join('-')}">
        Closing Time: {{times[0]}} - {{times[1]}} Closed all day on weekends.
      </t>
    </div>
  </div>
</template>

<script lang="ts">
import * as R from 'ramda'
import Pic from '@/components/Pic.vue'
import { UTC2local } from 'essential/tools/format'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'TheMarketClosedTip',
  components: { Pic },
  props: {
    symbol: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const isCC = computed(() => {
      return R.includes(props.symbol?.code, ['BTCUSD', 'ETHUSD'])
    })

    const times = computed(() => {
      const utcTimes = props.symbol?.closeTimes
      if (utcTimes?.length) {
        const times = utcTimes[0]
        return [
          UTC2local(times.startTime),
          UTC2local(times.endTime),
        ]
      }
      return []
    })

    return {
      isCC,
      times,
    }
  },
})
</script>

<style scoped>
.close-tip {
  height: 48px;
  color: var(--color-white);
  background: var(--color-title);
}
</style>
