<template>
  <div ref="chartEle" class="chart" :style="`width: ${width}px; height: ${height}px;`" />
</template>

<script lang="ts">
import { defineComponent, shallowRef, onMounted } from 'vue'
import CreateChart from '@/modules/home/components/recommendChart/chart'
import { readKchart } from '@/modules/home/home.api'

export default defineComponent({
  name: 'TheRecommendChart',
  props: {
    code: String,
    isRise: Boolean,
    width: {
      type: Number,
      default: 72,
    },
    height: {
      type: Number,
      default: 32,
    },
  },
  setup (props) {
    const chartEle = shallowRef()

    onMounted(() => {
      const chart = CreateChart({
        color: props.isRise ? [0, 199, 86] : [242, 74, 58],
        container: chartEle.value,
      })

      readKchart({
        type: 10,
        excode: 'EX',
        code: props.code,
      }).then((res) => {
        chart.setData(res.reverse())
      })
    })

    return {
      chartEle,
    }
  },
})
</script>

<style scoped lang="scss">

</style>
