
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true,
    },
    version: {
      type: Number,
      default: 1,
    },
    xs: Boolean,
    sm: Boolean,
    lg: Boolean,
  },
  setup (props) {
    return {
      name_: computed(() => props.name.replace(/^(\S+)(.*)/, '$1')),
    }
  },
})
