
import DialogTemplate from '@/views/DialogTemplate.vue'
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'FirstDepositDialog',
  components: {
    DialogTemplate,
    Pic,
  },
  setup () {
    return {}
  },
})
