
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTechnicalAnalytics',
  components: {
    Pic,
  },
})

