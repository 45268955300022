
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ColorText',
  props: {
    value: {
      type: Number,
      required: true,
    },
    display: {
      type: Array,
    },
  },
  setup (props) {
    const isNegative = computed(() => Number(props.value) < 0)
    const labels = props.display ?? [props.value, props.value]

    return {
      className: computed(() => isNegative.value ? 'c-danger' : 'c-success'),
      label: computed(() => labels[Number(!isNegative.value)]),
    }
  },
})
