/**
* @author ayou[beica1@outook.com]
* @date 2022/3/10 14:10
* @description
*   NoInspectVersion.vue of WeTrade
*/
<template>
  <slot v-if="!(isInspectMode() && and() || or())" />
  <slot v-else name="else" />
</template>

<script lang="ts">
import { isInspectMode } from '@/state/inspect'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NothingOnInspectMode',
  props: {
    and: {
      type: Function,
      default: () => true,
    },
    or: {
      type: Function,
      default: () => false,
    },
  },
  setup () {
    return {
      isInspectMode,
    }
  },
})
</script>
