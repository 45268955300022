<template>
<ResultDialogTemplate>
  <template #title>
    <t path="notification_59">Deposit credited</t>
  </template>
  <p class="money">+ <money :v="val" /></p>
  <t as="p" class="mt-8 mb-24" path="notification_60">Your deposit has just credited</t>
</ResultDialogTemplate>
</template>

<script lang="ts">
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RechargeResult',
  components: { ResultDialogTemplate },
  props: {
    val: String,
  },
})
</script>

<style scoped>
.money{
  font-size: 21px;
  font-weight: bold;
  color: #F19231;
  line-height: 24px;
}
</style>
