<template>
  <div class="df-col df-center df-middle">
    <t v-if="type === GetPointAlertEnum.WATCH_TIME"
      path="quickplaylive_13"
      :args="{mins: time}"
      class="c-white"
    >
      WATCH_TIME
    </t>
    <t v-if="type === GetPointAlertEnum.TRADE_TIMES"
      path="quickplaylive_76"
      :args="{tradenum: time}"
      class="c-white"
    >
      TRADE_TIMES
    </t>
    <p v-if="type === GetPointAlertEnum.DEFAULT" class="c-white">{{ title }}</p>
    <p class="c-white t-center mt-8 f-bold f-xl">
      <t path="" class="mr-8">Points</t>
      <span class="a-title">+{{ point }}</span>
    </p>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'
import { GetPointAlertEnum } from '@/pages/liveForQuickPlay/components/liveAlert/liveAlert'

export default defineComponent({
  name: 'TheGetPointAlert',
  props: {
    type: Number,
    title: String,
    point: Number,
    time: Number,
  },
  setup () {
    return {
      GetPointAlertEnum,
    }
  },
})
</script>

<style scoped lang='scss'>
.a-title{
  color: #F07B00;
}
</style>
