import requestResult from '@/common/request/request'

export type RadarItemSchema = {
  code: string;
  tips: string;
  amplitude: string;
  type: number;
}

export type RatioSchma = {
  contract: string;
  down: number;
  up: number;
  max: number;
  tips: string;
}

export type TrendSchema = {
  tendAndSarLists: Array<RadarItemSchema>;
  macdAndSarLists: Array<RadarItemSchema>;
}

export type RadarListSchema = {
  shortRrend: TrendSchema;
  longRrend: TrendSchema;
  activitiData: Array<RadarItemSchema>;
  contractRatioModels: Array<RatioSchma>;
}

export const readRadarActive = requestResult<RadarListSchema>('/api/stockselectionradar/select/data')
