<template>
  <div class="dbl-main">
    <div class="close df-middle df-center" @click="handleClose">
      <Icon name="close" class="close-icon" />
    </div>
    <div>
      <t path="home_67" as="p" class="c-white f-xxl f-bold t-center">Daily bonus</t>
    </div>
    <t path="home_68" as="p" class="c-white mt-12">
      Don’t miss out on your chance to get a coupon with $20 deposit per day!
    </t>

    <div class="list-wrap mt-16">
      <div class="item br-lg df-col df-middle" :class="{'day-10': index === last}"
        v-for="(item, index) in info.list" :key="index">
        <Icon class="check-icon" name="check" sm v-if="item.participated" />
        <div class="flex-1 t-center df-middle f-sm f-bold">
          <p :class="{'disable': item.participated, 'active': item.isCurrFlg === YesOrNo.YES}">
            <t path="home_69" :args="{day: index + 1}">DAY {{ index + 1 }}</t>
          </p>
        </div>
        <div v-if="index === last" class="special-icon">
          <div class="sp-content">
            <Pic src="home/coupon_special" />
            <p class="sc-money c-white f-xxl f-bold">
              <money :v="item.amount" />
            </p>
          </div>
        </div>
        <div v-else class="coupon-nomal">
          <Pic v-if="item.participated" src="home/coupon_disable" />
          <Pic v-else src="home/coupon" />
          <p class="nm-money c-white f-bold" :class="{'disable': item.participated}">
            <money :v="item.amount" />
          </p>
        </div>
      </div>
    </div>

    <div class="mt-24 f-lg f-bold">
      <Button class="btn block success br-lg" :disabled="info.participated" @click="handleDeposit" :progress="currProgress">
        <t path="home_73" v-if="info.participated"></t>
        <t path="home_70" v-else :args="{amount: +info.dayEnduranceAmount / format.moneyFactor}"></t>
      </Button>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef } from 'vue'
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import { YesOrNo } from '@/types'
import TheDailyBonusPay from '@/modules/home/notification/TheDailyBonusPay.vue'
import { readChannelList } from '@/modules/in/in.api'
import { openDialog, showAlert } from '@/components/popup/popup'
import { format } from '@/config'
import { useRate } from '@/modules/app/app'
import { div } from 'essential/tools/math'
import * as R from 'ramda'
import { isIndia } from '@/state'

export default defineComponent({
  name: 'TheDailyBonusList',
  components: {
    Button,
    Pic,
    Icon,
  },
  props: {
    info: Object,
  },
  emits: ['close'],
  setup (props, ctx) {
    const currProgress = shallowRef(false)
    const { rate, refresh } = useRate()
    refresh()

    const handleClose = () => {
      ctx.emit('close')
    }

    const handleDeposit = () => {
      currProgress.value = true
      readChannelList().then(res => {
        if (!res || res.length === 0) {
          showAlert('system error, please try again')
          return false
        }

        if (!rate.value.rate) {
          return
        }

        const realAmount = isIndia() ? Math.ceil(div(props.info?.dayEnduranceAmount, rate.value.rate)) : props.info?.dayEnduranceAmount

        let currIndex = R.findIndex(R.propEq('isCurrFlg', 1))(props.info?.list)
        currIndex = currIndex === -1 ? 0 : currIndex
        openDialog(TheDailyBonusPay, {
          wrapperClass: 'center',
          amount: realAmount,
          index: currIndex,
          currunt: props.info?.list[currIndex],
          channelList: res,
        })
        handleClose()
      }).finally(() => {
        currProgress.value = false
      })
    }

    return {
      last: 9,
      YesOrNo,
      currProgress,
      format,
      handleClose,
      handleDeposit,
    }
  },
})
</script>

<style scoped lang='scss'>
.dbl-main{
  width: 88vw;
  background: #7515EA;
  border-radius: 16px;
  position: relative;
  padding: 4vw;
}
.list-wrap{
  width: 100%;
  height: 78vw;
  background: #FFFFFF;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  padding: 4vw;
}
.item{
  width: 16vw;
  height: 21vw;
  background: #F4F6F8;
  margin-right: 2.65vw;
  margin-bottom: 2.65vw;
  position: relative;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
.check-icon{
  background: var(--color-success);
  color: var(--color-white);
  border-radius: 50%;
  position: absolute;
  right: -4px;
  top: -4px;
  z-index: 1;
}
.coupon-nomal{
  width: 44px;
  height: 48px;
  position: relative;
  img{
    width: 100%;
    height: 100%;
  }
  .nm-money{
    position: absolute;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.day-10{
  width: 53.3vw;
  height: 21vw;
  background: #F4F6F8;
  border-radius: 6px;
  margin-right: 0;
  position: relative;
  display: flex;
  flex-direction: unset;
  padding-left: 20px;
}
.special-icon{
  width: 112px;
  height: 88px;
  position: absolute;
  right: 0;
  top: -4px;
  .sp-content{
    width: 100%;
    height: 100%;
    position: relative;
    img{
      width: 100%;
      height: 100%;
    }
    .sc-money{
      position: absolute;
      transform: rotate(-16deg);
      left: 38px;
      top: 30px;
    }
  }
}
.disable{
  color: #99AAC7;
}
.active{
  padding: 0 6px;
  height: 20px;
  background: #00C756;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}
.close{
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}
.close-icon{
  background: #FFFFFF;
  border-radius: 50%;
}
</style>
