/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   Donut.vue of WeTrade
*/

<template>
<transition name="fade-in" appear>
  <!--transition root el: required-->
  <span>
      <span class="donut"></span>
    </span>
</transition>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount } from 'vue'

export default defineComponent(
  {
    name: 'Donut',
    emits: ['tick'],
    setup (props, ctx) {
      const timer = setInterval(() => {
        ctx.emit('tick')
      }, 1000)

      onBeforeUnmount(() => {
        clearInterval(timer)
      })
    },
  })
</script>

<style>
@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style scoped lang="scss">
.donut {
  display: inline-block;
  vertical-align: middle;
  border: 2px solid;
  border-left-color: transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  opacity: 0.8;
  animation: donut-spin 1s linear infinite;

  &.sm {
    width: 12px;
    height: 12px;
  }

  &.lg {
    width: 20px;
    height: 20px;
  }
}
</style>
