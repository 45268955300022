<template>
<div>
  <div class="df-middle df-between">
    <t path="placeorder_26" class="c-text f-bold f-nm">TheTakeProfit</t>
    <Switch :checked="tpChecked" @change="toggleLimit" />
  </div>
  <div class="con-opt br-lg mt-16 p-16" v-if="tpChecked">
    <div class="df-middle df-between">
      <t path="placeorder_43" class="c-title f-nm">Percentage</t>
      <div class="input-wrap">
        <div :class="['minus', isBound() ? 'c-tip' : '']" @click="reduce()">
          <Icon name="minus" />
        </div>
        <input
          class="br-lg f-bold bg-theme" :type="inputType" :value="label"
          @focus="inputType = 'number'"
          @blur="inputType = 'text'"
          @change="format($event.target.value)"
        >
        <div :class="['plus', isBound(true) ? 'c-tip' : '']" @click="increase()">
          <Icon name="plus" />
        </div>
      </div>
    </div>
    <div class="df-col df-center mt-16">
      <p class="df-between" style="width: 100%;">
        <t path="placeorder_44" class="c-title f-nm">Est, Price</t>
        <span>{{estPrice}}</span>
      </p>
      <p class="c-tip f-md">
        <t path="placeorder_45">Profit</t>: <money :v="profit" />
        <t path="placeorder_46" class="ml-4">Pips</t>: {{pips}} pips
        <template v-if="showDistance">
          <t path="placeorder_48" class="ml-4">Est, dist</t>: {{distance}}
        </template>
      </p>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import Switch from '@/modules/membership/signal/components/Switch.vue'
import { useProductLimit } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import { shallowRef } from '@vue/reactivity'
import { computed, inject, defineComponent } from 'vue'
import { TradeDirection } from '@/types'

const MIN = 10
const MAX = 200

export default defineComponent({
  name: 'TheTakeProfit',
  components: {
    Icon,
    Switch,
  },
  props: {
    type: {
      type: Number,
      default: TradeDirection.BUY,
    },
    value: Number,
    showDistance: Boolean,
  },
  setup (props) {
    const order = inject('model') as TradeModel
    const tpChecked = shallowRef(false)
    const tslChecked = shallowRef(false)
    const showDescription = shallowRef(false)
    const stopLoss = shallowRef(0)
    const inputType = shallowRef('text')
    const percent = shallowRef(10)
    const label = computed(() => percent.value + '%')

    const {
      pips,
      profit,
      estPrice,
      distance,
      limit,
      limitByPoint,
    } = useProductLimit(pips => {
      order.setTopLimit(pips)
    }, props.type === TradeDirection.SELL)

    if (props.value) {
      tpChecked.value = true
      limitByPoint(props.value, v => percent.value = v)
    }

    const update = (input: number) => {
      inputType.value = 'text'

      if (input > MAX || input < MIN) {
        showAlert(translate('toast_17'))
      }

      const next = Math.min(200, Math.max(10, input))

      if (next !== percent.value) {
        percent.value = next
        limit(next)
      }
    }

    const reduce = (step = 5) => {
      update(percent.value - step)
    }

    const increase = (step = 5) => {
      update(percent.value + step)
    }

    const format = (v: number) => {
      const next = Number(v)

      if (Number.isNaN(next)) return

      update(v)
    }

    const toggleLimit = () => {
      tpChecked.value = !tpChecked.value

      limit(tpChecked.value ? 10 : 0)
    }

    const isBound = (max = false) => max ? percent.value >= MAX : percent.value <= MIN

    return {
      label,
      inputType,
      tpChecked,
      tslChecked,
      showDescription,
      stopLoss,
      pips,
      profit,
      estPrice,
      distance,
      isBound,
      reduce,
      increase,
      format,
      toggleLimit,
    }
  },
})
</script>
<style scoped lang="scss">
.con-opt {
  background: var(--color-light);

  .input-wrap {
    position: relative;

    input {
      width: 156px;
      height: 40px;
      border: 1px solid var(--color-border);
      text-align: center;
    }

    .minus {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .plus {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
