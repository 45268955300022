
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import { translate } from '@/i18n'
import TheShareOrder from '@/pages/invite/components/TheShareOrder.vue'
import { makeShareQuery, makeShareUrl } from '@/pages/invite/invite'
import { share as _share } from '@/common/jsBridge.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShareDialog',
  components: { TheShareOrder, Button, Pic },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const share = () => {
      _share({
        title: translate('sharelink_27', 'The best mobile trading platform.'),
        url: `${makeShareUrl()}/#/link/profit?${makeShareQuery()}&order=${props.order.orderId}`,
        image: `${location.origin}/img/logo@2x.png`,
      })
      ctx.emit('close')
    }

    return {
      share,
    }
  },
})
