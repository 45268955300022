
import datafeed from '@/common/datafeed'
import TheTradeGroupBar from '@/modules/trade/components/TheTradeQuoteBar.vue'
import { Position } from '@/modules/trade/trade.api'
import List from 'common/List/List.vue'
import * as R from 'ramda'
import { defineComponent, computed, onActivated } from 'vue'

export default defineComponent({
  name: 'TheTradeGroup',
  components: { TheTradeGroupBar, List },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  setup (props) {
    const groupedList = computed(() => R.groupBy(R.prop('code'), props.list as Position[]))
    datafeed.subscribe(R.keys(groupedList.value))
    // 解决切换tab后数据不监听的bug
    onActivated(() => {
      datafeed.subscribe(R.keys(groupedList.value))
    })

    return {
      groupedList,
    }
  },
})
