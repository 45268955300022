
import Icon from '@/components/Icon.vue'
import AccountRelated from '@/provider/AccountRelated.vue'
import state from '@/state'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import Button from '@/components/Button.vue'
import { computed, defineComponent, shallowRef, watch } from 'vue'
import { demoBalanceRecharge } from '@/modules/trade/trade'
import { format } from '@/config'
import { isDemoAccount } from '@/state/accountType'
import { readDemoAccountInfo } from '@/modules/trade/trade.api'

export default defineComponent({
  name: 'TheAsset',
  components: { Icon, AccountRelated, DemoAccountTip, Button },
  setup: function () {
    const account = computed(() => state.account)
    const showTopUp = shallowRef(false)

    showTopUp.value = account.value.freeMargin < 500 * format.moneyFactor && isDemoAccount.value

    // todo 暂时改为每次切换demo重新请求接口获取最新freeMargin
    watch(() => isDemoAccount.value, (isDemo) => {
      if (isDemo) {
        readDemoAccountInfo().then(res => {
          showTopUp.value = res.freeMargin < 500 * format.moneyFactor
        })
      }
    }, { immediate: true })

    const [topUpClick, progress] = demoBalanceRecharge(() => {
      showTopUp.value = state.account.freeMargin < 500 * format.moneyFactor
    })

    return {
      stickAlertFlag: state.account.marginLevel < 100 && state.account.marginLevel > 0,
      account,
      showTopUp,
      topUpClick,
      progress,
    }
  },
})
