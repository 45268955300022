<template>
<div>
  <div class="df-middle df-between">
    <t path="placeorder_30" class="c-text f-bold f-nm">TheStopLoss</t>
    <Switch :checked="slChecked" @change="toggleLimit" />
  </div>
  <div class="con-opt br-lg mt-16 p-16" v-if="slChecked">
    <div class="df-middle df-between">
      <t path="placeorder_43" class="c-title f-nm">Percentage</t>
      <div class="input-wrap">
        <div :class="['minus', isBound() ? 'c-tip' : '']" @click="reduce()">
          <Icon name="minus" />
        </div>
        <input
          class="br-lg f-bold bg-theme" :type="inputType" :value="label"
          @focus="inputType = 'number'"
          @blur="format($event.target.value)"
          @change="format($event.target.value)"
        >
        <div :class="['plus', isBound(true) ? 'c-tip' : '']" @click="increase()">
          <Icon name="plus" />
        </div>
      </div>
    </div>
    <div class="df-col df-center mt-16">
      <p class="df-between" style="width: 100%;">
        <t path="placeorder_44" class="c-title f-nm">Est, Price</t>
        <span>{{estPrice}}</span>
      </p>
      <p class="c-tip f-md">
        <t path="placeorder_45">Profit</t>: -<money :v="profit" />
        <t path="placeorder_46" class="ml-4">Pips</t>: {{pips}} pips
        <template v-if="showDistance">
          <t path="placeorder_48" class="ml-4">Est, dist</t>
          : {{distance}}
        </template>
      </p>
    </div>
    <div class="df-middle df-between mt-16 p-r">
      <div class="df-middle">
        <t path="placeorder_47" class="c-title f-nm">Trailing Stop Loss</t>
        <div class="description" @click="() => showDescription = true">
          <Pic src="trade/description" width="16" height="16" />
        </div>
      </div>
      <Switch :checked="tslChecked" @change="toggleTrailing" />
      <div class="de-info br-lg" v-if="showDescription">
        <Pic src="trade/stoploss" style="width: 100%;" />
        <t as="p" path="placeorder_49" class="c-white f-nm" style="margin-top: 24px;">Trailing stop
          loss lets you
          set a maximum percentage of loss you can incur on a trade.
        </t>
        <div class="kb-wrap mt-16">
          <div class="know-btn br-sm df-middle px-12" @click="() => showDescription = false">
            <t path="placeorder_50" class="c-white f-nm">I Know</t>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { showAlert } from '@/components/popup/popup'
import { translate } from '@/i18n'
import Switch from '@/modules/membership/signal/components/Switch.vue'
import Pic from '@/components/Pic.vue'
import { useProductLimit } from '@/modules/trade/trade'
import TradeModel from '@/modules/trade/TradeModel'
import { shallowRef } from '@vue/reactivity'
import { computed, defineComponent, inject } from 'vue'
import { TradeDirection } from '@/types'

const MIN = 10
const MAX = 200

export default defineComponent({
  name: 'TheStopLoss',
  components: {
    Icon,
    Switch,
    Pic,
  },
  props: {
    type: {
      type: Number,
      default: TradeDirection.BUY,
    },
    value: Number,
    showDistance: Boolean,
  },
  setup (props) {
    const order = inject('model') as TradeModel
    const slChecked = shallowRef(false)
    const tslChecked = shallowRef(order.isStopTrailing())
    const showDescription = shallowRef(false)
    const stopLoss = shallowRef(0)
    const inputType = shallowRef('text')
    const percent = shallowRef(10)
    const label = computed(() => '-' + percent.value + '%')

    const {
      pips,
      profit,
      estPrice,
      distance,
      limit,
      limitByPoint,
    } = useProductLimit(pips => {
      order.setBottomLimit(pips)
    }, props.type === TradeDirection.BUY)

    if (props.value) {
      slChecked.value = true
      limitByPoint(props.value, v => percent.value = v)
    }

    const update = (input: number) => {
      inputType.value = 'text'

      if (input > MAX || input < MIN) {
        showAlert(translate('toast_16'))
      }

      const next = Math.min(200, Math.max(10, input))

      if (next !== percent.value) {
        percent.value = next
        limit(next)
      }
    }

    const reduce = (step = 5) => {
      update(percent.value - step)
    }

    const increase = (step = 5) => {
      update(percent.value + step)
    }

    const format = (v: number) => {
      const next = Number(v)

      if (Number.isNaN(next)) return

      update(v)
    }

    const toggleLimit = () => {
      slChecked.value = !slChecked.value

      limit(slChecked.value ? 10 : 0)
    }

    const toggleTrailing = () => {
      tslChecked.value = !tslChecked.value
      order.setStopTrailing(tslChecked.value)
    }

    const isBound = (max = false) => {
      return max ? percent.value >= MAX : percent.value <= MIN
    }

    return {
      label,
      inputType,
      slChecked,
      tslChecked,
      showDescription,
      stopLoss,
      pips,
      profit,
      estPrice,
      distance,
      isBound,
      reduce,
      increase,
      format,
      toggleLimit,
      toggleTrailing,
    }
  },
})
</script>

<style scoped lang="scss">
.con-opt {
  background: var(--color-light);

  .input-wrap {
    position: relative;

    input {
      width: 156px;
      height: 40px;
      border: 1px solid var(--color-border);
      text-align: center;
    }

    .minus {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .plus {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .description {
    position: relative;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .de-info {
    position: absolute;
    width: calc(100vw - 32px);
    background: rgba(24, 29, 36, 0.9);
    z-index: 1;
    bottom: 28px;
    box-sizing: border-box;
    left: -16px;
    padding: 24px 16px;
  }

  .kb-wrap {
    display: flex;
    justify-content: flex-end;

    .know-btn {
      background-color: var(--color-primary);
      height: 24px;
    }
  }
}
</style>
