<template>
<div class="buoy-wrap" v-if="showBuoy">
  <div class="buoy-main fill df-middle df-center">
    <div class="close df-middle df-center" @click="closeClick">
      <Icon name="close" class="close-icon" />
    </div>
    <Pic :src="buoyType===4?'home/QuizBuoy':'home/GachaBuoy'"
    width="72" height="72" @click="linkClick" />
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, onActivated, shallowRef } from 'vue'
import { checkEvent, readEventQa } from '@/modules/home/home.api'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import router from '@/router'
import { openDialog } from '@/components/popup/popup'
import TheQuizBoard from '@/modules/home/components/TheQuizBoard.vue'

export default defineComponent({
  name: 'TheBuoy',
  components: { Icon, Pic },
  setup () {
    const showBuoy = shallowRef(false)
    const buoyType = shallowRef(1) // 1,"抽卡" 4,"小测验"
    const eventId = shallowRef()
    const check = async () => {
      const res = await checkEvent()
      buoyType.value = res.eventType
      showBuoy.value = Boolean(res.chance)
      eventId.value = res.eventId
    }

    onActivated(() => {
      check()
    })

    const closeClick = () => {
      showBuoy.value = false
    }

    const linkClick = () => {
      if (buoyType.value === 1) {
        router.push('/cardGame')
      }
      if (buoyType.value === 4) {
        startQuiz()
      }
    }

    // 打开答题弹窗
    async function startQuiz () {
      const qaList = await readEventQa({
        eventId: eventId.value,
      })

      if (!qaList?.length) {
        return Promise.reject('question list is empty!')
      }
      openDialog(TheQuizBoard, {
        qaList,
        taskType: false,
        eventId: eventId.value,
        wrapperClass: 'center',
      }, {
        transition: 'slide-down',
      })
    }
    return {
      showBuoy,
      buoyType,
      closeClick,
      linkClick,
    }
  },
})
</script>

<style scoped>
.buoy-wrap{
  top: calc(100vh - 144px);
  right: 8px;
  position: fixed;
  height: 72px;
  width: 72px;
  z-index: 1;
}
.buoy-main{
  position: relative;
}
.close{
  height: 34px;
  width: 34px;
  position: absolute;
  right: -8px;
  top: -17px;
}
.close-icon{
  border: 1px solid var(--color-text);
  border-radius: 50%;
  font-size: var(--font-md);
}
</style>
