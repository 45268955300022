
import sortable from '@/modules/market/components/sortable'
import Holder from '@/provider/Holder.vue'
import * as R from 'ramda'
import PageHeader from '@/components/PageHeader.vue'
import { useSortWatchlist } from '@/modules/market/market'
import Button from '@/components/Button.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import Checkbox from '@/components/Checkbox.vue'
import TheEditListItem from '@/modules/market/components/TheEditListItem.vue'
import List from '@/components/AsyncNullableList.vue'
import { computed, defineComponent, nextTick, onBeforeUnmount, shallowRef, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'EditWatchList',
  components: { Holder, PageHeader, Button, TheEditListItem, Checkbox, List, FullScreen },
  emits: ['save'],
  setup (props, ctx) {
    const router = useRouter()
    const { list, toTop, removeItems, save, sort } = useSortWatchlist()
    const selected = shallowRef<Array<string>>([])

    const selectedCount = computed(() => selected.value.length)

    const isAllSelected = computed(() => list.value.length !== 0 && (list.value.length === selectedCount.value))

    const toggleAll = (select: boolean) => {
      if (select) selected.value = R.pluck('code', list.value)
      else selected.value = []
    }

    const saveModify = () => {
      return save().then(() => {
        router.back()
      })
    }

    /**
     * 这里的清理逻辑比较随意，脆弱
     */
    const remove = () => {
      removeItems(selected.value)
      selected.value = []
    }

    let clean = () => {
      console.log('nth to clean')
    }

    watchEffect(() => {
      if (list.value.length) {
        clean()
        nextTick(() => {
          clean = sortable('.sortable-watch__list', (index, dir) => {
            sort(list.value[index].code, dir)
          })
        })
      }
    })

    const toTopDom = (code: string, el: HTMLElement) => {
      toTop(code)
      document.querySelector('.sortable-watch__list')?.prepend(el)
    }

    onBeforeUnmount(() => {
      ctx.emit('save')
    })

    return {
      list,
      selected,
      selectedCount,
      isAllSelected,
      toggleAll,
      toTop: toTopDom,
      remove,
      save: saveModify,
    }
  },
})
