/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/27
* @description
*   TheLiveItem.vue of WeTrade
*/
<template>
<div class="df-middle live box" @click="handleLiveLink">
  <div class="p-r">
    <Pic :src="prop('authorUrl', room)" width="88" height="88" class="avatar" />
    <span v-if="prop('channelStatus', room) === YES" class="on f-xs">On air</span>
  </div>
  <div class="flex-1 ml-12 mr-16">
    <p class="mt-8 f-sm flex-1">{{prop('introduction', room)}}</p>
    <p class="mt-8">
      <span class="f-bold">{{prop('authorName', room)}}</span>
      <span class="f-sm pull-right">{{prop('liveTime', room)}}</span>
    </p>
  </div>
</div>
</template>

<script lang="ts">
import Pic from '@/components/Pic.vue'
import { defineComponent, shallowRef } from 'vue'
import { LiveRoom } from '@/modules/membership/member.api'
import router from '@/router'

export default defineComponent({
  name: 'TheLiveItem',
  components: { Pic },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    const roomInfo = shallowRef<LiveRoom>(props.room as LiveRoom)
    const handleLiveLink = () => {
      if (roomInfo.value.channelType === 2) {
        const url = `/liveForQuickPlay/${roomInfo.value.channelId}` +
                    `/${roomInfo.value.chatRoomId}`
        router.push(url)
      } else {
        const url = `/live/${roomInfo.value.channelId}` +
                    `/${roomInfo.value.chatRoomId}` +
                    `?poster=${roomInfo.value.backgroundPicture}` +
                    `&user=${roomInfo.value.onlineNumber}` +
                    `&tip=${roomInfo.value.announcement}` +
                    `&levelLimit=${roomInfo.value.liveLevel}` +
                    `&time=${roomInfo.value.liveTimeTwo}` +
                    `&desc=${roomInfo.value.tips}` +
                    `&authorUrl=${roomInfo.value.authorUrl}` +
                    `&authorDesc=${roomInfo.value.tips}`
        router.push(url)
      }
    }

    return {
      handleLiveLink,
    }
  },
})
</script>

<style scoped lang="scss">
.live {
  &:not(:first-child) {
    margin-top: 20px;
  }
}

.on {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  line-height: 16px;
  text-align: center;
  color: var(--color-white);
  background: var(--color-success);
  opacity: 0.85;
}

.avatar {
  vertical-align: middle;
  object-fit: cover;
}

.play {
  height: 20px;
  border-radius: 10px;
}
</style>
