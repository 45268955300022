<template>
<div class="">
  <div class="df-middle df-between pl-16 pr-12 radar-header" @click="toggleSlide">
    <t :path="i18nPath" class="f-md f-medium">{{i18nDefault}}</t>
    <Icon :name="show ? 'caret-up' : 'caret-down'" sm />
  </div>
  <div class="px-16 py-8" v-if="show">
    <Holder :content="list">
      <AsyncNullableList
        :identify="v => v.code"
        :list-data="list" as="table"
        list-class="table-list"
      >
        <template #="{ item }">
          <slot :item="item" />
        </template>
      </AsyncNullableList>
    </Holder>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'
import Holder from '@/provider/Holder.vue'
import AsyncNullableList from '@/components/AsyncNullableList.vue'

export default defineComponent({
  name: 'TheRadarList',
  components: { Icon, AsyncNullableList, Holder },
  props: {
    i18nPath: String,
    i18nDefault: String,
    list: {
      type: Array,
      default: () => ([]),
    },
  },
  setup () {
    const show = ref(true)
    return {
      show,
      toggleSlide: () => show.value = !(show.value),
    }
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/index" as mixins;

.radar-header {
  line-height: 32px;
  background: var(--color-light);
  @include mixins.half-pixel-border(bottom, var(--color-grey));
}

::v-deep(.table-list) {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  img {
    vertical-align: middle;
  }

  td {
    height: 64px;
    padding: 0;

    &:first-child {
      width: 40px;
    }
  }
}
</style>
