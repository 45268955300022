
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ForStock',
  components: { Icon, Pic },
  props: {
    stocks: {
      type: Array,
      required: true,
    },
  },
})
