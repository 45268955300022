/**
 * 监听服务端通知推送
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/22
 * @description
 *   notification.ts of main
 */
import { parseToJson } from '@/common/format'
import { keymap, server } from '@/config'
import Socket from 'essential/net/websocket/WebSocket'
import { localGet } from 'essential/store/localStore'
import idMaker from 'essential/tools/idMaker'
import * as R from 'ramda'

const ID = idMaker()

enum CMD {
  HEART,
  BIND,
}

type Message = {
  cmd: number;
  id: number;
  success: number;
  message: string;
}

export interface Reducer {
  (message: Message): void;
}

const HEART_BEAT_INTERVAL = 10
const MAX_NO_RESPONSE_INTERVAL = 2

class NotificationClient {
  private readonly callback
  private readonly reducers: Array<Reducer> = []
  private respTimer = 0

  readonly socket

  constructor (server: string, connectCallback: (ok: boolean) => void) {
    this.callback = connectCallback

    this.socket = new Socket(server, {
      heartInterval: HEART_BEAT_INTERVAL,
      retryLimit: 3,
      heartSignal: () => JSON.stringify({ cmd: CMD.HEART, id: ID.next() }),
    })
      .on('message', this.dispatch.bind(this))
      .on('heartbeat', this.retryOnAbort.bind(this))
      .on('open', this.bind.bind(this))
      .connect()
  }

  /**
   * 2秒内心跳无响应，则强制重连
   */
  retryOnAbort () {
    this.respTimer = window.setTimeout(() => {
      this.socket.retry(true)
    }, Math.min(HEART_BEAT_INTERVAL, MAX_NO_RESPONSE_INTERVAL) * 1000)
  }

  onHeartbeatResp (betaId: number) {
    if (betaId === ID.value()) {
      clearTimeout(this.respTimer)
    }
  }

  dispatch (message: string) {
    const messageObj = parseToJson(message).json as Message
    if (messageObj) {
      switch (messageObj.cmd) {
        case CMD.BIND:
          this.callback(messageObj.success === 0)
          break
        case CMD.HEART:
          this.onHeartbeatResp(messageObj.id)
          break
        default:
          R.map(reducer => reducer(messageObj), this.reducers)
      }
    }
  }

  // @todo token验证失败处理
  bind () {
    this.socket.send(JSON.stringify({
      cmd: CMD.BIND,
      id: ID.next(),
      message: {
        token: localGet(keymap.user.token) as string,
      },
    }))
  }

  reduce (reducers: Array<Reducer>) {
    this.reducers.push(...reducers)
  }

  destroy () {
    clearTimeout(this.respTimer)
    if (this.socket) {
      this.socket.release()
    }
  }
}

type ServerConfigItem = {
  protocol: 1 | 2;
  ip: string;
  port: number;
}

const SOCKET_PROTOCOLS = ['ws', 'ws', 'wss']

declare const connectConfig: Partial<{
  ws: Array<ServerConfigItem>;
}>

export const getNotificationServer = () => new Promise<Array<ServerConfigItem>>(
  (resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.onload = () => setTimeout(resolve, 0, connectConfig.ws)
    script.onerror = reject
    script.src = server.pushSeed
    document.body.appendChild(script)
  },
)

export const getSocketServerViaGlobalConfig = (server: ServerConfigItem) => `${SOCKET_PROTOCOLS[server.protocol]}://${server.ip}:${server.port}/ws`

const connectSocket = (servers: Array<ServerConfigItem>): Promise<NotificationClient> => new Promise(
  (resolve, reject) => {
    let serverIndex = 0

    function connect () {
      const server = new NotificationClient(
        getSocketServerViaGlobalConfig(servers[serverIndex]), ok => {
          if (ok) resolve(server)
          else {
            if (server) server?.destroy()
            if (++serverIndex < servers.length) {
              connect()
            } else reject()
          }
        })
    }

    connect()
  })

const connect = async () => {
  // 获取服务器列表
  const servers = await getNotificationServer()
  return connectSocket(servers)
  // const count = servers.length
  // const serverIndex = count > 1 ? randomInt(0, servers.length - 1) : 0
  // // 随机服务器地址
  // const server = servers[serverIndex]
  // return new NotificationClient(getSocketServerViaGlobalConfig(server))
}

export default connect
