/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/2/26
 * @description
 *   popup.ts of WeTrade
 */
import { popup, PopupPublicOptions } from 'common/popout/popup'
import { ComponentOptions, h, shallowRef, watch } from 'vue'
import Alert from '@/components/popup/Alert.vue'

type AlertOption = {
  zIndex?: number,
  autoClose?: boolean,
}

export const showAlert = (msg: string | ComponentOptions, opt?: AlertOption) => {
  const close = popup({
    class: 'message',
    transition: 'slide-down',
    component: {
      setup () {
        return () => h(Alert, {
          autoClose: opt?.autoClose,
          zIndex: opt?.zIndex || 2,
        }, (typeof msg === 'string') ? () => h('p', {
          class: 'alert-msg',
        }, msg) : () => h(msg, {
          onClose: close,
        }))
      },
    },
  })
  return close
}

export const openDialog = <P> (
  Component: ComponentOptions<P>,
  props?: P & Data | null,
  options?: Partial<PopupPublicOptions>,
) => {
  const closePopup = popup({
    component: {
      setup () {
        return () => h('div', {
          name: 'Dialog',
          class: ['dialog__wrapper', props?.wrapperClass],
          onClick: (e: Event) => {
            if (options?.touchToClose) {
              e.stopPropagation()
            }
          },
        }, h(Component, {
          ...props,
          onClose (cb?: () => void) {
            closePopup()

            if (typeof props?.onClose === 'function') {
              // props.onClose()
            }

            if (typeof cb === 'function') {
              setTimeout(cb, 100)
            }
          },
        } as unknown as P))
      },
    },
    backToClose: true,
    class: 'dialog',
    transition: 'slide-up',
    ...options,
    onClose: props?.onClose as () => void,
  })

  return closePopup
}

export const openFullscreenList = <P> (
  Component: ComponentOptions<P>,
  props?: P & Data,
  options?: Partial<PopupPublicOptions>,
) => {
  openDialog(Component, props, {
    ...options,
    wrapperClass: 'operation',
    class: 'dialog __fs-class',
  })
}
