/**
* @author 贝才[beica1@outook.com]
* @date 2021/7/5
* @description
*   TheToggleStateButton.vue of WeTrade
*/
<template>
  <div class="button df-middle df-between px-8 br-lg" :class="{on}">
    <div>
      <p class="name" style="font-weight: 400;">
        <slot />
      </p>
      <p class="f-xs" style="line-height: 1">
        <slot name="suffix"></slot>
      </p>
    </div>
    <Donut v-if="progress" />
    <Icon v-else class="icon" :name="on ? 'minus': 'plus'" sm />
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import Donut from 'common/loading/Donut.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheToggleStateButton',
  components: { Donut, Icon },
  props: {
    progress: Boolean,
    on: Boolean,
  },
})
</script>

<style scoped lang="scss">
.button {
  color: var(--color-title);
  background: var(--color-light);
  width: 100%;
  height: 48px;
  margin-bottom: 8px;

  &:not(.on) .name {
    color: var(--color-text);
  }
}

.icon {
  border: 1px solid var(--color-primary);
  border-radius: 1px;
  color: var(--color-white);
  background: var(--color-primary);
}

%color {
  color: var(--color-title);
  background: none;
}

.on {
  border: 1px solid #D5DEE8;
  @extend %color;

  .icon {
    border-color: var(--color-tip);
    @extend %color;
  }
}
</style>
