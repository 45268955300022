/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/26
* @description
*   MarginCall.vue of WeTrade
*/
<template>
  <DialogTemplate>
    <template #title>
      <t :path="isDemoAccount ? 'notification_28' : 'notification_14'">Margin Call</t>
    </template>
    <t :path="isDemoAccount ? 'notification_29' : 'notification_15'"
      :args="{marginlevel: `${margin}%`}"
      as="p" class="t-center flex-1">
      Margin call triggered:Margin level
      <span class="f-bold">{{margin}}%</span>.
    </t>
    <template #controls>
      <router-link custom #="{navigate}" to="/trade">
        <button class="flex-1 btn block" @click="$emit('close', navigate)">
          <t path="notification_31" custom #="{td}">
            {{ td || 'Check' }}
          </t>
        </button>
      </router-link>
      <button class="flex-1 btn block primary" @click="$emit('close')">
        <t path="notification_32" custom #="{td}">
          {{ td || 'OK' }}
        </t>
      </button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import { isDemoAccount } from '@/state/accountType'

export default defineComponent({
  name: 'MarginCall',
  components: { DialogTemplate },
  props: {
    margin: Number,
  },
  setup () {
    return {
      isDemoAccount,
    }
  },
})
</script>
