<template>
  <DialogTemplate>
    <template #title>
      <t path="quickplay_38">Trade result</t>
    </template>
    <div class="c-text flex-1">
      <div class="mb-12 df-cross-center">
        <t class="mr-8 f-medium f-xl"
           :path="order.result === 1 ? 'quickplay_39' : 'quickplay_40'">
          {{order.result === 1 ? 'Take profit' : 'Stop loss'}}
        </t>
        <Money class="f-medium f-xl" #="{text, origin}" :v="prop('profit', order)">
          <ColorText :value="origin">{{text}}</ColorText>
        </Money>
      </div>
      <div class="order-info df-cross-center mx-16">
        <span>{{order.contactCode}}</span>
        <Icon class="badge p-0 mx-8" :class="{success: isUp, danger: !isUp}" sm
          :name=" isUp ? 'up': 'down'" />
        <span>{{prop('createPrice', order)}}</span>
        <Pic src="fast_trade/arrival" width="16" height="16" class="mx-4" />
        <span>{{prop('closePrice', order)}}</span>
      </div>
    </div>
    <template #controls>
      <button class="flex-1 btn block primary" @click="$emit('close')">
        <t path="quickplay_41" custom #="{td}">
          {{ td || 'OK' }}
        </t>
      </button>
    </template>
  </DialogTemplate>
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { computed, defineComponent } from 'vue'
import DialogTemplate from '@/views/DialogTemplate.vue'
import ColorText from '@/components/ColorText.vue'
import Money from '@/components.global/Money'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'FastTradeResult',
  components: { Pic, DialogTemplate, ColorText, Money, Icon },
  props: {
    order: Object,
  },
  setup (props) {
    const isUp = computed(() => {
      return props.order?.buyType === TradeDirection.BUY
    })

    return {
      isUp,
    }
  },
})
</script>

<style scoped>
  .order-info {
    height: 40px;
    border-radius: 20px;
    background: var(--color-light);
  }
</style>
