
import { isInspectMode } from '@/state/inspect'
import { flag } from '@/config'
import TransitionPic from '@/views/appTab/TransitionPic.vue'
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import RemindPlay from '@/modules/fastTrade/components/RemindPlay.vue'

const tabs = [
  {
    label: 'tab_2',
    path: ['/', '/home'],
    icon: 'home',
    labelDefault: 'Home',
  }, {
    label: 'tab_3',
    path: ['/market'],
    icon: 'market',
    labelDefault: 'Market',
  },
  {
    label: 'tab_4',
    path: ['/trade'],
    icon: 'trade',
    labelDefault: 'Trade',
  }, {
    label: 'tab_5',
    path: ['/prime'],
    icon: 'prime',
    labelDefault: 'Prime',
  }, {
    label: 'tab_6',
    path: ['/me'],
    icon: 'me',
    labelDefault: 'Me',
  },
]

export default defineComponent({
  name: 'AppTab',
  components: { RemindPlay, TransitionPic },
  setup () {
    const route = useRoute()

    const isMatch = (path: string) => path.indexOf(route.path) !== -1

    return {
      tabs: !isInspectMode()
        ? tabs
        : (
          flag.isLite
            ? tabs.filter(tab => tab.labelDefault !== 'Prime')
            : (
              flag.isIOS
                ? tabs.slice(0, 2)
                : tabs
            )
        ),
      isMatch,
    }
  },
})
