/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   EditWatchList.vue of WeTrade
*/
<template>
  <FullScreen class="edit px-16">
    <PageHeader>
      <template #title>
        <t path="market_8">Manage watchlist</t>
      </template>
    </PageHeader>
    <div class="header df-middle f-400">
      <div class="flex-1">
        <Checkbox @toggle="toggleAll" :checked="isAllSelected">
          <t path="managewatchlist_2" class="ml-16 f-400 c-text">Select All</t>
        </Checkbox>
      </div>
      <span class="flex-1 f-400 c-text">
        <span v-if="selectedCount" @click="remove">
          <t custom #="{td, t}">{{t('managewatchlist_3') || 'Remove'}}</t> ({{selectedCount}})
        </span>
        <span v-else style="color: var(--color-tip)">
          <t custom #="{td, t}">{{t('managewatchlist_3') || 'Remove'}}</t>
        </span>
      </span>
      <Button class="save f-400 primary text p-0" @click="save">
        <t path="managewatchlist_4" #="{td}" custom>{{td || 'Save'}}</t>
      </Button>
    </div>
    <Holder :content="list">
      <div class="d-f f-xs c-tip py-4 mt-16 f-400">
        <span class="flex-1 pl-32">Asset</span>
        <p class="df-between" style="width: 92px">
          <span>Top</span>
          <span>Drag</span>
        </p>
      </div>
      <List
        #="{ index, item }" :identify="v => v.code"
        :list-data="list" as="div" list-class="sortable-watch__list"
      >
        <TheEditListItem :item="item" @toTop="toTop">
          <Checkbox class="mr-16" :value="item.code" v-model="selected" />
        </TheEditListItem>
      </List>
    </Holder>
  </FullScreen>
</template>

<script lang="ts">
import sortable from '@/modules/market/components/sortable'
import Holder from '@/provider/Holder.vue'
import * as R from 'ramda'
import PageHeader from '@/components/PageHeader.vue'
import { useSortWatchlist } from '@/modules/market/market'
import Button from '@/components/Button.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import Checkbox from '@/components/Checkbox.vue'
import TheEditListItem from '@/modules/market/components/TheEditListItem.vue'
import List from '@/components/AsyncNullableList.vue'
import { computed, defineComponent, nextTick, onBeforeUnmount, shallowRef, watchEffect } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'EditWatchList',
  components: { Holder, PageHeader, Button, TheEditListItem, Checkbox, List, FullScreen },
  emits: ['save'],
  setup (props, ctx) {
    const router = useRouter()
    const { list, toTop, removeItems, save, sort } = useSortWatchlist()
    const selected = shallowRef<Array<string>>([])

    const selectedCount = computed(() => selected.value.length)

    const isAllSelected = computed(() => list.value.length !== 0 && (list.value.length === selectedCount.value))

    const toggleAll = (select: boolean) => {
      if (select) selected.value = R.pluck('code', list.value)
      else selected.value = []
    }

    const saveModify = () => {
      return save().then(() => {
        router.back()
      })
    }

    /**
     * 这里的清理逻辑比较随意，脆弱
     */
    const remove = () => {
      removeItems(selected.value)
      selected.value = []
    }

    let clean = () => {
      console.log('nth to clean')
    }

    watchEffect(() => {
      if (list.value.length) {
        clean()
        nextTick(() => {
          clean = sortable('.sortable-watch__list', (index, dir) => {
            sort(list.value[index].code, dir)
          })
        })
      }
    })

    const toTopDom = (code: string, el: HTMLElement) => {
      toTop(code)
      document.querySelector('.sortable-watch__list')?.prepend(el)
    }

    onBeforeUnmount(() => {
      ctx.emit('save')
    })

    return {
      list,
      selected,
      selectedCount,
      isAllSelected,
      toggleAll,
      toTop: toTopDom,
      remove,
      save: saveModify,
    }
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/_index.scss" as mixins;

.header {
  height: 48px;
  color: var(--color-title);
  @include mixins.half-pixel-border(bottom);
}

.save::v-deep(.deck) {
  margin: 0;
}
.f-400 {
  font-size: 400;
}
</style>
