<template>
  <div class="df-col df-center df-middle">
    <t as="div" path="quickplaylive_42" multiline #="{td}" custom>
      <p as="p" v-for="(p, index) in td" :key="index"
        :class="{'a-title f-bold': index === 0, 'c-white t-center mt-8': index === 1}"
      >
        {{p}}
      </p>
    </t>
  </div>
</template>

<script lang='ts'>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'noPointAlert',
  components: {

  },
  setup () {
    return {
    }
  },
})
</script>

<style scoped lang='scss'>
.a-title{
  color: #F07B00;
}
</style>
