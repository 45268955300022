
import WTTabs from '@/components/Tabs.vue'
import { isShield } from '@/decorators/withoutCryptos'
import TheMarketList from '@/modules/market/components/TheMarketList.vue'
import TheStockCategory from '@/modules/market/components/TheStockCategory.vue'
import TheWatchList from '@/modules/market/components/TheWatchList.vue'
import { useMarket } from '@/modules/market/market'
import TheRadar from '@/modules/market/radar/TheRadar.vue'
import { defineComponent, onActivated, shallowRef } from 'vue'

export default defineComponent({
  name: 'MarketTab',
  components: {
    WTTabs,
    TheRadar,
    TheStockCategory,
    TheWatchList,
    TheMarketList,
  },
  setup () {
    const { tabs } = useMarket()
    const shield = shallowRef(true)

    onActivated(() => isShield().then(v => shield.value = v))

    return {
      shield,
      tabs,
    }
  },
})
