/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/29
 * @description
 *   custom.ts of WeTrade
 */
import { customStatusBar, resetStatusBar } from '@/common/jsBridge.api'
import * as R from 'ramda'
import { NavigationGuard } from 'vue-router'
import { localGet } from 'essential/store/localStore'
import { keymap } from '@/config'

const custom: NavigationGuard = (to, from, next) => {
  const fromImmersive = R.path(['meta', 'immersive'], from)
  const toImmersive = R.path(['meta', 'immersive'], to)

  if (toImmersive && toImmersive !== fromImmersive) {
    const color = typeof toImmersive === 'string' ? toImmersive : '#326BFE'
    customStatusBar({
      color,
      theme: 'dark',
    })
  }

  if (fromImmersive && !toImmersive) {
    const theme = localGet(keymap.label.theme)
    const color = theme === 'dark' ? '#1A2033' : '#326BFE'
    if (theme === 'dark') {
      customStatusBar({
        color,
        theme: 'dark',
      })
    } else {
      resetStatusBar()
    }
  }
  next()
}

export default custom
