/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/4
* @description
*   Radio.vue of WeTrade
*/
<template>
  <label class="radio">
    <slot name="prefix" />
    <input v-show="false" v-model="model" :name="name" :value="value" type="radio">
    <span class="radio-ui"></span>
    <slot />
  </label>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'Radio',
  props: {
    name: String,
    value: [String, Number, Boolean],
    modelValue: [String, Number, Boolean],
  },
  emits: ['update:modelValue'],
  setup (props) {
    return {
      model: computed(() => props.modelValue),
    }
  },
})
</script>

<style scoped lang="scss">
.radio-ui {
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 1px solid var(--color-grey);
  border-radius: 50%;

  &::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin: 3px;
    border-radius: 6px;
    background: var(--color-primary);
    content: '';
    transition: all var(--trans-duration-s) var(--trans-fn);
  }

  :checked + & {
    border-color: var(--color-primary);

    &::after {
      width: 12px;
      height: 12px;
    }
  }
}
</style>
