
import PositionMagic from 'common/PositionMagic.vue'
import TabPane from 'common/tab/TabPane.vue'
import swipe from 'essential/dom/swipe'
import { emit, once } from 'essential/tools/event'
import { isInRange } from 'essential/util'
import * as R from 'ramda'
import {
  computed,
  defineComponent,
  onMounted,
  shallowRef,
  VNode,
} from 'vue'

export default defineComponent({
  name: 'Tabs',
  components: { TabPane, PositionMagic },
  props: {
    tabs: Array,
    tabClass: String,
    navClass: String,
    contentClass: String,
    activeKey: String,
    tabInBottom: Boolean,
  },
  emits: ['switch'],
  setup (props, ctx) {
    const navs$ = shallowRef<HTMLElement | null>(null)

    if (typeof ctx.slots.default !== 'function') return
    const tabId = `tab_${Math.random().toString(36).slice(2, 7)}`

    const [navs, panes] = ((_panes: VNode[]): [string[], VNode[]] => {
      const navs: string[] = []
      const panes: VNode[] = []

      _panes.map((pane, index) => {
        const title = props.tabs?.[index] ?? pane.props?.tab
        /**
         * 忽略没有name和标题的组件
         */
        if (typeof pane.type !== 'symbol' && title) {
          navs.push(title)
          panes.push(pane)
        }
      })

      return [navs, panes]
    })(ctx.slots.default())

    // const panes: Array<VNode> = ctx.slots.default().filter(pane => pane.type?.name)

    const viewWidth = shallowRef(0)

    const containerWidth = computed(() => {
      return viewWidth ? (viewWidth.value * panes.length + 'px') : '100%'
    })

    // 缓存子节点
    // 从子节点抓取tabList
    // const navs = computed(() => {
    //   return props.tabs || R.map(pane => pane.props?.tab, panes).filter(R.identity)
    // })

    const defaultActiveIndex = props.activeKey
      ? R.findIndex<VNode>(R.propEq('key', props.activeKey), panes)
      : 0
    const activeIndex = shallowRef(~defaultActiveIndex ? 0 : defaultActiveIndex)
    const offset = shallowRef(activeIndex.value * viewWidth.value)

    const switchTo = (index: number) => {
      if (index !== activeIndex.value) {
        activeIndex.value = index
        ctx.emit('switch', index)
      }
      // 利用一个很小的偏移误差来保证offset得到更新
      offset.value = -index * viewWidth.value + Math.random() * 0.001

      if (navs$.value) {
        // TODO 修复滚动到可视区域会导致在y轴方向上的滚动
        // ((navs$.value as HTMLElement).children.item(index) as HTMLElement).scrollIntoView(false)
      }
    }

    const el = shallowRef()
    onMounted(() => {
      viewWidth.value = parseFloat(window.getComputedStyle(el.value).width)
      swipe(el.value, {
        direction: 'horizontal',
        range: [-(panes.length - 1) * viewWidth.value, 0],
        onSwiped (transformer, long, done) {
          const offsetX = transformer.delta[0]
          if (Math.abs(offsetX) > 50) {
            const nextIndex = offsetX > 0
              ? (activeIndex.value - 1)
              : (
                offsetX < 0
                  ? (activeIndex.value + 1)
                  : activeIndex.value
              )
            if (isInRange(0, panes.length - 1, nextIndex)) {
              switchTo(nextIndex)
            } else {
              switchTo(activeIndex.value)
            }
          } else {
            switchTo(activeIndex.value)
          }
          once(`${tabId}_te`, () => {
            done()
          })
        },
      })
    })

    return {
      viewWidth,
      containerWidth,
      offset,
      el,
      panes,
      tabId,
      tabPosition: props.tabInBottom ? 'bottom' : 'top',
      navs,
      navs$,
      switchTo,
      activeIndex,
      onSwiped () {
        emit(`${tabId}_te`)
      },
    }
  },
})
