
import { computed, defineComponent, ref } from 'vue'
import state from '@/state'
import Icon from '@/components/Icon.vue'
import { localGet, localSet } from 'essential/store/localStore'
import { keymap } from '@/config'

export default defineComponent({
  name: 'RemindPlay',
  components: { Icon },
  setup () {
    const isShowBubbles = computed(() => state.account.isShowBubbles)

    const remindPlay = ref(localGet(keymap.guide.remindPlay))

    const close = () => {
      localSet(keymap.guide.remindPlay, '1')
      remindPlay.value = '1'
    }

    return {
      isShowBubbles,
      remindPlay,
      close,
    }
  },
})
