/**
* @author 贝才[beica1@outook.com]
* @date 2020/11/5
* @description
*   Page.vue of FastTrade
*/
<template>
  <Scroller css-only class="page spread">
    <slot />
  </Scroller>
</template>

<script lang="ts">
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Page',
  components: { Scroller },
})
</script>

<style scoped>
.page {
  background: var(--color-theme);
  /* overflow: hidden auto; */
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
