/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/5
* @description
*   TheTradeLimitList.vue of WeTrade
*/

<template>
  <FullScreen class="df-col">
    <PageHeader class="px-16">
      <template #title>
        <t :path="title.label">{{title.lableDefault}}</t>
      </template>
    </PageHeader>
    <div class="flex-1 p-r fill">
      <div class="spread scroll-y pb-8">
        <t as="div" path="placeorder_27" class="mx-16 option df-cross-center f-medium br-lg" :class="{active: choice === 0}"
           @click="choice = 0">
          None
        </t>
        <List :list-data="options" class="px-16" #="{item}">
          <div :class="{active: item.pip === choice}" class="option df-middle t-center br-lg" @click="choice = item.pip">
            <!-- <money as="div" class="flex-1" :v="item.value" /> -->
            <money class="flex-1" :v="item.value" #={value}>{{value + ' ' + prop('ykUnit', product)}}</money>
            <div class="flex-1">{{item.pip}} pips</div>
          </div>
        </List>
      </div>
    </div>
    <div class="controls d-f f-bold f-lg">
      <Button class="flex-1" @click="$emit('close')">
        <t path="placeorder_28" #="{td}" custom>{{td || 'Cancel'}}</t>
      </Button>
      <Button class="flex-1 primary" @click="confirm">
        <t path="placeorder_29" #="{td}" custom>{{td || 'Submit'}}</t>
      </Button>
    </div>
  </FullScreen>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import PageHeader from '@/components/PageHeader.vue'
import FullScreen from '@/components/popup/FullScreen.vue'
import { generateLimitList } from '@/modules/trade/trade'
import { Product } from '@/modules/trade/trade.api'
import { LimitType } from '@/modules/trade/TradeModel'
import List from '@/components/AsyncNullableList.vue'
import { defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheTradeLimitList',
  components: { List, Button, PageHeader, FullScreen },
  emits: ['close', 'change'],
  props: {
    type: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
      default: 0,
    },
  },
  setup (props, ctx) {
    const choice = shallowRef(props.value)
    const options = generateLimitList(props.type, props.product as Product)

    return {
      choice,
      confirm () {
        if (choice.value !== props.value) {
          ctx.emit('change', choice.value)
        }
        ctx.emit('close')
      },
      options,
      title: props.type === LimitType.LOSS
        ? { label: 'placeorder_30', lableDefault: 'Stop-loss' }
        : { label: 'placeorder_26', lableDefault: 'Take-profit' },
    }
  },
})
</script>

<style scoped lang="scss">
.option {
  height: 40px;
  margin-top: 8px;
  padding: 0 24px;
  border: 1px solid transparent;
  background: var(--color-light);

  &.active {
    border-color: var(--color-primary);
    background: #E6EDFE;
  }
}
</style>
