/**
* @author 贝才[beica1@outook.com]
* @date 2020/12/24
* @description
*   Transition.vue of FastTradeV3
*/
<template>
  <transition
    @before-enter="ready = false"
    @after-enter="ready = true"
    @after-leave="ready = false"
  >
    <slot />
  </transition>
</template>

<script lang="ts">
import { provide, shallowRef, defineComponent } from 'vue'

export default defineComponent(
  {
    name: 'TransitionProvider',
    props: {
      provide: {
        type: String,
        default: '_transition_in_state_',
      },
    },
    setup (props) {
      const ready = shallowRef(false)

      provide(props.provide, ready)

      return { ready }
    },
  },
)
</script>
