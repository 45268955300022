
import { defineComponent, shallowRef, onActivated, onMounted, onUnmounted } from 'vue'
import Pic from '@/components/Pic.vue'
import { useBindGoogle } from '@/features/googleLogin/googleLogin.fns'
import { checkIsFirstDeposit } from '@/modules/trade/trade.api'
import { format, events } from '@/config'
import { readGoogleCoupon } from '../home.api'
import { on, off } from 'essential/tools/event'

export default defineComponent({
  name: 'TheGoogleAuth',
  components: {
    Pic,
  },
  setup () {
    const visible = shallowRef(false)
    const sendVoucherAmount = shallowRef(0)
    const { hasBind, bind } = useBindGoogle()

    onActivated(() => {
      visible.value = false
      if (!hasBind.value) {
        checkIsFirstDeposit().then(res => {
          if (res.first === 0) {
            readGoogleCoupon().then(res => {
              sendVoucherAmount.value = (res.sendVoucherAmount || 0) / format.moneyFactor
              visible.value = true
            })
          }
        })
      }
    })

    onMounted(() => {
      on(events.googleLogin, () => {
        visible.value = false
      })
    })

    onUnmounted(() => {
      off(events.googleLogin)
    })

    return {
      sendVoucherAmount,
      visible,
      bind,
    }
  },
})
