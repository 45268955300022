<template>
  <div class="p-16">
    <div class="df-middle">
      <Pic src="trade/technical" width="40" height="40"/>
      <div class="ml-12">
        <t path="symbol_133" as="p" class="f-bold">Technical view</t>
        <t path="symbol_107" as="p" class="f-sm">powered by Trading Central</t>
      </div>
    </div>
    <div class="technicals mt-16" v-if="!showLoading">
      <div class="intraday i-border" v-if="intradayData">
        <div class="i-header df-between df-middle px-16">
          <t path="symbol_108" class="f-bold f-color">Intraday</t>
          <t path="symbol_111" :args="{articleTime: formatDateTime('MM-dd-yy HH:mm:ss', intradayData.articleTime)}" class="f-xs c-title"></t>
        </div>
        <div class="p-16">
          <div class="title df-middle df-between">
            <div>
              <t path="symbol_112" as="p" class="f-bold">Turning point</t>
              <div class="df-middle mt-4">
                <span class="f-color">{{intradayData.pivot}}</span>
                <div class="chart-btn f-sm" @click="handleDrawLine(intradayData.pivot, '#F07B00')">
                  <t path="symbol_113">Mark it on the chart</t>
                </div>
              </div>
            </div>
            <div class="arr" @click="showIntraday = !showIntraday">
              <Icon :name="showIntraday ? 'caret-up': 'caret-down'" sm />
            </div>
          </div>
          <div class="content mt-16" v-if="showIntraday">
            <Line />
            <t as="p" path="symbol_114" class="f-bold mt-16">Trading Strategy</t>
            <t as="p" path="symbol_118"
              :args="{pivot: intradayData.pivot, tp1Price: intradayData.tp1Price, tp2Price: intradayData.tp2Price}"
              class="mt-4 c-title"
              v-if="intradayData.buyType === Direction.FALL"
            ></t>
            <t as="p" path="symbol_116"
              :args="{pivot: intradayData.pivot, tp1Price: intradayData.tp1Price, tp2Price: intradayData.tp2Price}"
              class="mt-4 c-title" v-else
            ></t>
            <t as="p" path="symbol_115" class="f-bold mt-16">Alternative strategy</t>
            <t as="p" path="symbol_119" class="mt-4 c-title"
              :args="{pivot: intradayData.pivot, sl1Price: intradayData.sl1Price, sl2Price: intradayData.sl2Price}"
              v-if="intradayData.buyType === Direction.FALL"
            ></t>
            <t as="p" path="symbol_117" class="mt-4 c-title"
              :args="{pivot: intradayData.pivot, sl1Price: intradayData.sl1Price, sl2Price: intradayData.sl2Price}"
              v-else
            ></t>
            <t as="p" path="symbol_120" class="f-bold mt-16">Technical Comments</t>
            <p class="mt-4 c-title">{{ intradayData.comment }}</p>
          </div>
        </div>
      </div>

      <div class="short i-border mt-12" v-if="shortTermData">
        <div class="i-header df-between df-middle px-16">
          <t path="symbol_109" class="f-bold f-color">Intraday</t>
          <t path="symbol_111" :args="{articleTime: formatDateTime('MM-dd-yy HH:mm:ss', shortTermData.articleTime)}" class="f-xs c-title"></t>
        </div>
        <div class="p-16">
          <div class="title df-middle df-between">
            <div>
              <t path="symbol_112" as="p" class="f-bold">Turning point</t>
              <div class="df-middle mt-4">
                <span class="f-color">{{shortTermData.pivot}}</span>
                <div class="chart-btn f-sm" @click="handleDrawLine(shortTermData.pivot, '#326BFE')">
                  <t path="symbol_113">Mark it on the chart</t>
                </div>
              </div>
            </div>
            <div class="arr" @click="showShort = !showShort">
              <Icon :name="showShort ? 'caret-up': 'caret-down'" sm />
            </div>
          </div>
          <div class="content mt-16" v-if="showShort">
            <Line />
            <t as="p" path="symbol_114" class="f-bold mt-16">Trading Strategy</t>
            <t as="p" path="symbol_118"
              :args="{pivot: shortTermData.pivot, tp1Price: shortTermData.tp1Price, tp2Price: shortTermData.tp2Price}"
              class="mt-4 c-title"
              v-if="shortTermData.buyType === Direction.FALL"
            ></t>
            <t as="p" path="symbol_116"
              :args="{pivot: shortTermData.pivot, tp1Price: shortTermData.tp1Price, tp2Price: shortTermData.tp2Price}"
              class="mt-4 c-title" v-else
            ></t>
            <t as="p" path="symbol_115" class="f-bold mt-16">Alternative strategy</t>
            <t as="p" path="symbol_119" class="mt-4 c-title"
              :args="{pivot: shortTermData.pivot, sl1Price: shortTermData.sl1Price, sl2Price: shortTermData.sl2Price}"
              v-if="shortTermData.buyType === Direction.FALL"
            ></t>
            <t as="p" path="symbol_117" class="mt-4 c-title"
              :args="{pivot: shortTermData.pivot, sl1Price: shortTermData.sl1Price, sl2Price: shortTermData.sl2Price}"
              v-else
            ></t>
            <t as="p" path="symbol_120" class="f-bold mt-16">Technical Comments</t>
            <p class="mt-4 c-title">{{ shortTermData.comment }}</p>
          </div>
        </div>
      </div>

      <div class="long i-border mt-12" v-if="midTermData">
        <div class="i-header df-between df-middle px-16">
          <t path="symbol_110" class="f-bold f-color">Intraday</t>
          <t path="symbol_111" :args="{articleTime: formatDateTime('MM-dd-yy HH:mm:ss', midTermData.articleTime)}" class="f-xs c-title"></t>
        </div>
        <div class="p-16">
          <div class="title df-middle df-between">
            <div>
              <t path="symbol_112" as="p" class="f-bold">Turning point</t>
              <div class="df-middle mt-4">
                <span class="f-color">{{midTermData.pivot}}</span>
                <div class="chart-btn f-sm" @click="handleDrawLine(midTermData.pivot, '#7515EA')">
                  <t path="symbol_113">Mark it on the chart</t>
                </div>
              </div>
            </div>
            <div class="arr" @click="showLong = !showLong">
              <Icon :name="showLong ? 'caret-up': 'caret-down'" sm />
            </div>
          </div>
          <div class="content mt-16" v-if="showLong">
            <Line />
            <t as="p" path="symbol_114" class="f-bold mt-16">Trading Strategy</t>
            <t as="p" path="symbol_118"
              :args="{pivot: midTermData.pivot, tp1Price: midTermData.tp1Price, tp2Price: midTermData.tp2Price}"
              class="mt-4 c-title"
              v-if="midTermData.buyType === Direction.FALL"
            ></t>
            <t as="p" path="symbol_116"
              :args="{pivot: midTermData.pivot, tp1Price: midTermData.tp1Price, tp2Price: midTermData.tp2Price}"
              class="mt-4 c-title" v-else
            ></t>
            <t as="p" path="symbol_115" class="f-bold mt-16">Alternative strategy</t>
            <t as="p" path="symbol_119" class="mt-4 c-title"
              :args="{pivot: midTermData.pivot, sl1Price: midTermData.sl1Price, sl2Price: midTermData.sl2Price}"
              v-if="midTermData.buyType === Direction.FALL"
            ></t>
            <t as="p" path="symbol_117" class="mt-4 c-title"
              :args="{pivot: midTermData.pivot, sl1Price: midTermData.sl1Price, sl2Price: midTermData.sl2Price}"
              v-else
            ></t>
            <t as="p" path="symbol_120" class="f-bold mt-16">Technical Comments</t>
            <p class="mt-4 c-title">{{ midTermData.comment }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="loading-box df-middle df-center mt-24" v-if="showLoading">
      <Loading />
    </div>

    <div class="intro d-f mt-24">
      <Icon name="warn" sm/>
      <t as="p" path="symbol_121" class="ml-8" style="line-height: 20px">
        The reference point and analysis point of view of the trading strategy ate provided by Trading Central, a third-party provider, and the strategic point of view is only the independent assessment and judgment of the analyst, without considering the investor’s investment goals and financial status. All strategic views do not constitute investment advice or recommendations and are for informational purposes only, Our company also does not guarantee tne accuracy of the strategic views, and does not
      </t>
    </div>
  </div>
</template>

<script lang='ts'>
import { translate } from '@/i18n'
import {
  readTechnicals,
  drawLine,
  LineType,
  BarTypes,
  SinalItem,
  Direction,
} from '@/modules/market/market.api'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import Line from '@/components/Line.vue'
import { useRoute } from 'vue-router'
import { emit, off, on } from 'essential/tools/event'
import { localGet } from 'essential/store/localStore'
import { events, keymap } from '@/config'
import * as R from 'ramda'
import Loading from 'common/loading/Donut.vue'
import { formatDateTime } from '@/common/format'
import { openDialog, showAlert } from '@/components/popup/popup'
import DrawLineDialog from './DrawLineDialog.vue'
import router from '@/router'

export default defineComponent({
  name: 'TheTechnicals',
  components: {
    Pic,
    Icon,
    Line,
    Loading,
  },
  setup () {
    const route = useRoute()
    const showIntraday = shallowRef(true)
    const showShort = shallowRef(false)
    const showLong = shallowRef(false)
    const resolutions = shallowRef(Number(localGet(keymap.label.resolution)) || BarTypes.M30)

    const intradayData = shallowRef<SinalItem | undefined>(undefined)
    const shortTermData = shallowRef<SinalItem | undefined>(undefined)
    const midTermData = shallowRef<SinalItem | undefined>(undefined)

    const showLoading = shallowRef(true)
    let timer = 0

    let pageNum = 0
    const pageSize = 20

    const isContinue = () => {
      return R.isNil(intradayData.value) || R.isNil(shortTermData.value) || R.isNil(midTermData.value)
    }

    const loopData = () => {
      pageNum = pageNum + 1
      readTechnicals({
        code: route.params.code,
        page: pageNum,
        pageSize: pageSize,
      }).then(res => {
        if (R.isNil(intradayData.value)) {
          intradayData.value = R.find(R.propEq('term', 'INTRADAY'))(res || []) as SinalItem | undefined
        }
        if (R.isNil(shortTermData.value)) {
          shortTermData.value = R.find(R.propEq('term', 'ST'))(res || []) as SinalItem | undefined
        }
        if (R.isNil(midTermData.value)) {
          midTermData.value = R.find(R.propEq('term', 'MT'))(res || []) as SinalItem | undefined
        }

        if (isContinue() && res.length === pageSize) {
          loopData()
        } else {
          showLoading.value = false
        }
      })
    }

    loopData()

    on(events.resolutionChange, val => {
      resolutions.value = val
    })

    onBeforeUnmount(() => {
      off(events.resolutionChange)
    })

    const handleDrawLine = (price: string, color: string) => {
      openDialog(DrawLineDialog, {
        onConfirm: () => {
          drawLine({
            code: route.params.code,
            type: LineType.LINE,
            color: color,
            kLineType: resolutions.value,
            horizontalLinePrice: price,
          }).then(() => {
            showAlert(translate('symbol_132'))
            emit(events.redraw)
            timer = window.setTimeout(() => {
              router.back()
            }, 500)
          }).catch(() => {
            router.back()
          })
        },
      })
    }

    onBeforeUnmount(() => {
      clearTimeout(timer)
    })

    return {
      showIntraday,
      showShort,
      showLong,
      intradayData,
      shortTermData,
      midTermData,
      showLoading,
      Direction,
      handleDrawLine,
      formatDateTime,
    }
  },
})
</script>

<style scoped lang='scss'>
.intraday{
  &.i-border{
    border-color: #F19231;
    .i-header{
      background: rgba(240,123,0,0.1);
    }
  }
  .chart-btn{
    border-color: #F07B00;
    background-color: rgba(240, 123, 0, 0.1);
    color: #F07B00;
  }
  .f-color{
    color: #F07B00;
  }
}

.short{
  &.i-border{
    border-color: #326BFE;
    .i-header{
      background: rgba(50, 107, 254, 0.10);
    }
  }
  .chart-btn{
    border-color: #326BFE;
    background-color: rgba(50, 107, 254, 0.10);
    color: #326BFE;
  }
  .f-color{
    color: #326BFE;
  }
}

.long{
  &.i-border{
    border-color: #7515EA;
    .i-header{
      background: rgba(117, 21, 234, 0.10);
    }
  }
  .chart-btn{
    border-color: #7515EA;
    background-color: rgba(117, 21, 234, 0.10);
    color: #7515EA;
  }
  .f-color{
    color: #7515EA;
  }
}
.i-header{
  height: 40px;
}
.i-border{
  border: 1px solid;
  background: #FFFFFF;
  border-radius: 6px;
}
.arr{
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.chart-btn{
  height: 28px;
  border-radius: 15px 15px 16px 15px;
  border: 1px solid;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-left: 14px;
}
.loading-box{
  height: 40px;
}
</style>
