/**
* @author 贝才[beica1@outook.com]
* @date 2021/7/6 14:49
* @description
*   SymbolList.vue of wetrade
*/
<template>
<Holder :content="list" :skeleton-repeat="4">
  <div v-for="[key, group] in list" :key="key" class="group py-8">
    <t as="p" :path="types[key].label" class="group__name f-bold">
      {{types[key].labelDefault}}
    </t>
    <Matrix :list="group" :column="2" #="{ item }" :identify="v => v.code">
      <slot :symbol="item" />
    </Matrix>
  </div>
</Holder>
</template>

<script lang="ts">
import { isShield } from '@/decorators/withoutCryptos'
import { readMarketList } from '@/modules/market/market.api'
import { MarketItem } from '@/modules/market/market.types'
import Holder from '@/provider/Holder.vue'
import Matrix from 'common/Matrix.vue'
import * as R from 'ramda'
import { defineComponent, onBeforeUnmount, shallowRef } from 'vue'

enum Groups {
  ALL,
  COMMODITIES,
  FOREX,
  CRYPTO,
  INDICES,
  STOCKS,
}

export default defineComponent({
  name: 'SymbolList',
  components: {
    Holder,
    Matrix,
  },
  emits: ['save'],
  setup (props, ctx) {
    const list = shallowRef()
    const group = (resp: Array<MarketItem>) => R.groupBy(
      R.o(String, R.prop('typeId')), resp)

    const swapFirstTwoEls = R.apply(R.flip(R.unapply(R.identity)))

    readMarketList()
      .then(resp => {
        const grouped = group(resp as Array<MarketItem>)

        isShield()
          .then(yes => {
            list.value = swapFirstTwoEls(
              R.toPairs(yes ? R.omit([Groups.CRYPTO.toString()], grouped) : grouped))
          })
      })

    onBeforeUnmount(() => {
      ctx.emit('save')
    })

    return {
      list,
      // types: ['All', 'Commodities', 'Forex', 'Crypto'],
      types: {
        [Groups.ALL]: {
          label: 'managewatchlist_2',
          labelDefault: 'All',
        },
        [Groups.COMMODITIES]: {
          label: 'market_4',
          labelDefault: 'Commodities',
        },
        [Groups.FOREX]: {
          label: 'market_3',
          labelDefault: 'Forex',
        },
        [Groups.CRYPTO]: {
          label: 'market_5',
          labelDefault: 'Crypto',
        },
        [Groups.INDICES]: {
          label: 'market_6',
          labelDefault: 'Indices',
        },
        [Groups.STOCKS]: {
          label: 'market_26',
          labelDefault: 'Stocks',
        },
      },
    }
  },
})
</script>

<style scoped lang="scss">
.group {
  margin-bottom: 8px;
}

.group__name {
  line-height: 40px;
  color: var(--color-text);

  // &::before {
  //   display: inline-block;
  //   width: 5px;
  //   height: 10px;
  //   border-radius: 3px;
  //   margin-right: 10px;
  //   background: var(--color-grey);
  //   content: '';
  // }
}
</style>
