import requestResult from '@/common/request/request'

export enum VoucherStatus {
  USABLE = 1,
  USED,
  EXPIRED,
  DELETE,
}

export type VoucherSchema = {
  beginDate: string;
  endDate: string;
  useDays: number;
  price: number;
  status: number;
  profit: number;
}

export const readVoucherList = requestResult<Array<VoucherSchema>>('/api/account/voucher/mt/list')

export const readLiveVoucherList = requestResult<Array<VoucherSchema>>('/api/account/voucher/no/list')
