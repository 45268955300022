<template>
  <div class="share bg-primary mx-32">
    <div class="p-r">
      <Pic src="invite/img1" class="img" />
      <t as="p" path="sharelink_2" class="title f-lg c-white f-bold df-middle pt-4">
        Great Trade
      </t>
    </div>
    <TheShareOrder :order="order">
      <div class="df-middle f-bold f-lg">
        <Button class="flex-1 btn block mr-6" @click="$emit('close')">
          <t path="sharelink_7" #="{td}" custom>{{td || 'Cancel'}}</t>
        </Button>
        <Button class="btn success flex-1 block df-cross-center share-btn" @click="share">
          <t path="sharelink_8" #="{td}" custom>{{td || 'Share'}}</t>
          <Pic src="invite/share" width="24" height="24" />
        </Button>
      </div>
    </TheShareOrder>
  </div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import { translate } from '@/i18n'
import TheShareOrder from '@/pages/invite/components/TheShareOrder.vue'
import { makeShareQuery, makeShareUrl } from '@/pages/invite/invite'
import { share as _share } from '@/common/jsBridge.api'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ShareDialog',
  components: { TheShareOrder, Button, Pic },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const share = () => {
      _share({
        title: translate('sharelink_27', 'The best mobile trading platform.'),
        url: `${makeShareUrl()}/#/link/profit?${makeShareQuery()}&order=${props.order.orderId}`,
        image: `${location.origin}/img/logo@2x.png`,
      })
      ctx.emit('close')
    }

    return {
      share,
    }
  },
})
</script>

<style scoped lang="scss">
.img {
  width: 100%;
  display: block;
}

.order {
  padding: 0 10%;
  background: url("/img/invite/bg2@2x.png") center top repeat-y;
  background-size: 100% auto;
}

.title {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 0;
  bottom: 0;
}

.share-btn {
  ::v-deep(span) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
