
import Icon from '@/components/Icon.vue'
import GuideContent from '@/components/userGuide/GuideContent.vue'
import GuideTarget from '@/components/userGuide/GuideTarget.vue'
import { cachePage, cancelCachePage } from '@/features/dynamicAlive'
import TheChart from '@/modules/market/components/chart/TheChart.vue'
import TheCryptoTips from '@/modules/market/components/TheCryptoTips.vue'
import { MarketState } from '@/modules/market/market.types'
import { usePositionCount } from '@/modules/user/user'
import NothingOnInspectMode from '@/provider/NohingOnInspectMode.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import CommissionBarWithBadge from '@/views/CommissionBarWithBadge.vue'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import PositionBarWithBadge from '@/views/PositionBarWithBadge.vue'
import WTTabs from '@/components/Tabs.vue'
import TheMarketClosedTip from '@/modules/market/components/TheMarketClosedTip.vue'
import TheSymbolActions from '@/modules/market/components/TheSymbolActions.vue'
import TheSymbolMarketState from '@/modules/market/components/TheSymbolMarketState.vue'
import { useQuote, useToggleState } from '@/modules/market/market'
import TheCommissions from '@/modules/trade/components/TheCommissions.vue'
import ThePositions from '@/modules/trade/components/ThePositions.vue'
import Donut from 'common/loading/Donut.vue'
import Scroller from 'common/providers/Scroller.vue'
import { computed, defineComponent, h, shallowRef } from 'vue'
import { useRoute } from 'vue-router'
import TheProfile from '@/modules/market/components/TheProfile.vue'
import TheAnalysisRatio from '@/modules/market/components/TheAnalysisRatio.vue'
import { checkIsSpecial, ESpecialType } from '@/modules/market/market.api'
import { flag, keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import TheTechnicals from './components/TheTechnicals.vue'
import TechnicalsBar from '@/views/TechnicalsBar.vue'

export default defineComponent({
  name: 'Symbol',
  computed: {
    flag () {
      return flag
    },
  },
  components: {
    Scroller,
    TheCryptoTips,
    NothingOnInspectMode,
    Donut,
    Icon,
    TheChart,
    PageWithHeader,
    GuideContent,
    GuideTarget,
    DemoAccountTip,
    TheMarketClosedTip,
    TheSymbolMarketState,
    TheCommissions,
    ThePositions,
    WTTabs,
    TheSymbolActions,
    TheProfile,
    TheAnalysisRatio,
    TheTechnicals,
  },
  beforeRouteLeave (to) {
    if (to.query.f === '1') {
      cachePage('Symbol')
    } else {
      cancelCachePage('Symbol')
    }
  },
  setup () {
    const route = useRoute()
    const symbol = useQuote(route.params.code as string, true)
    const showSpecialTip = shallowRef(false)
    const specialType = shallowRef(ESpecialType.NORMAL)
    const actionGuideGotIt = shallowRef(false)

    const haveShow = localGet(keymap.guide.specialTips) ? (localGet(keymap.guide.specialTips) as string).split(',') : ['0', '0']
    checkIsSpecial({
      contract: route.params.code as string,
    }).then(res => {
      specialType.value = res.specialIdentification
      if (haveShow[specialType.value - 1] === '0') {
        showSpecialTip.value = true
      }
    })

    const handleCloseTip = () => {
      haveShow[specialType.value - 1] = '1'
      localSet(keymap.guide.specialTips, haveShow.join().toString())
      showSpecialTip.value = false
    }

    const { toggle, added, progress } = useToggleState(route.params.code as string)

    const BarWithBadge = h(PositionBarWithBadge, {
      code: route.params.code as string,
    })

    const OrderBarWithBadge = h(CommissionBarWithBadge, {
      code: route.params.code as string,
    })

    function readActionGuide () {
      actionGuideGotIt.value = true
    }

    const TechnicalsBarVue = h(TechnicalsBar)

    return {
      positionCount: usePositionCount(route.params.code as string),
      isClosed: computed(() => symbol.value?.isClosed === MarketState.CLOSED),
      symbol,
      BarWithBadge,
      OrderBarWithBadge,
      toggle,
      added,
      progress,
      showSpecialTip,
      handleCloseTip,
      sepcialType: specialType,
      actionGuideGotIt,
      ESpecialType,
      TechnicalsBarVue,
      readActionGuide,
    }
  },
})
