/**
* @author ayou[beica1@outook.com]
* @date 2022/1/4 14:11
* @description
*   TheEvents.vue of WeTrade
*/
<template>
<div v-if="hasEvents">
  <div class="ev-title df-between df-middle">
    <t path="home_6" class="f-nm f-bold c-text">Upcoming event</t>
    <div class="df-middle">
      <router-link class="df-middle" to="/eventsList">
        <t path="home_7" style="line-height: 16px" class="f-md c-tip">See more</t>
        <Pic src="home/go" class="ml-4" width="16" height="16" />
      </router-link>
    </div>
  </div>
  <div class="main-ev mt-8 df-middle px-16 box" @click="eventClick(event)">
    <Clock :value="event.time" :section="event.section" />
    <div class="flex-1 df-middle" style="overflow: hidden">
      <Pic v-if="event.icon" :src="event.icon" class="ml-16" width="24" height="24" />
      <p class="text-nowrap ml-8">{{event.title}}</p>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent, onActivated, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import Clock from '@/modules/home/components/TheClock.vue'
import { readUpComing, EventItem } from '@/modules/home/home.api'
import { formatDateTime } from '@/common/format'
import { eventEnter } from '@/modules/home/home'

export default defineComponent({
  name: 'TheEvents',
  components: { Pic, Clock },
  setup () {
    const event = shallowRef()
    const hasEvents = shallowRef(false)
    const getEventList = async () => {
      const res = await readUpComing()
      if (!res) {
        return false
      }
      hasEvents.value = true
      const item = res
      event.value = {
        time: [formatDateTime('HH:mm', item.startTime), item.endTime ? formatDateTime('HH:mm', item.endTime) : '--:--'],
        section: Boolean(item.timeType === 2),
        ...item,
      }
    }
    onActivated(() => {
      getEventList()
    })

    const eventClick = (item: EventItem) => {
      eventEnter(item)
    }

    return {
      event,
      hasEvents,
      eventClick,
    }
  },
})
</script>

<style scoped lang="scss">
.main-ev {
  height: 56px;
}

.text-nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
