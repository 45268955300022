
import datafeed from '@/common/datafeed'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Pic from '@/components/Pic.vue'
import useRequest from '@/hooks/useRequest'
import { placeOrder } from '@/modules/market/market'
import { SymbolSnapshot } from '@/modules/market/market.types'
import { Product } from '@/modules/trade/trade.api'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { defineComponent, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'GuideOrderDialog',
  components: { RealTimeQuote, Button, Icon, Pic },
  props: {
    tips: {
      type: String,
      required: true,
    },
    symbol: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const router = useRouter()
    const code = (props.symbol as SymbolSnapshot).code

    datafeed.subscribe([props.symbol.code], true)

    const isBuy = parseFloat((props.symbol as SymbolSnapshot).margin) > 0

    const [commit, progress] = useRequest(placeOrder)

    onBeforeUnmount(() => {
      datafeed.unSubscribe([props.symbol.code], true)
    })

    return {
      isUp: isBuy,
      progress,
      create () {
        commit(props.product as Product, isBuy).then(() => {
          ctx.emit('complete')
          ctx.emit('close', () =>
            router.push({
              name: 'symbol',
              params: { code },
            }))
        })
      },
    }
  },
})
