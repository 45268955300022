
import datafeed from '@/common/datafeed'
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import { events } from '@/config'
import TheMarketItem from '@/modules/market/components/TheMarketItem.vue'
import { SORT_STATE } from '@/modules/market/market'
import { MarketItem } from '@/modules/market/market.types'
import Holder from '@/provider/Holder.vue'
import Scroller from 'common/providers/Scroller.vue'
import { on, off } from 'essential/tools/event'
import * as R from 'ramda'
import { computed, defineComponent, onActivated, onBeforeUnmount, shallowRef } from 'vue'

export default defineComponent({
  name: 'TheMarketList',
  components: { Scroller, Holder, AsyncNullableList, TheMarketItem },
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    read: {
      type: Function,
    },
    type: String,
    holderClass: String,
  },
  setup (props) {
    const iList = shallowRef<Array<MarketItem> | Record<string, Array<MarketItem>>>([])
    const items = computed(() => iList.value || props.list)

    const groupStocks = (list: Array<MarketItem>) => R.groupBy<MarketItem, string>(
      R.prop('industryType'), list)

    const refresh = async () => {
      if (typeof props.read === 'function') {
        return props.read().then((resp: Array<MarketItem>) => {
          iList.value = props.type === 'stocks' ? groupStocks(resp) : resp
          datafeed.subscribe(R.pluck('code', resp))
          return resp
        })
      }
      return items.value
    }

    const sort = (key: string, dir: SORT_STATE) => {
      refresh().then(resp => {
        if (dir !== SORT_STATE.NONE) {
          const sortFn = dir === SORT_STATE.DESC
            ? R.descend
            : R.ascend
          const formatSortValue = (v: MarketItem) => parseFloat(
            R.path(['realTimeMicroQuotationBean', key], v) as string)
          const sortResp = R.sort(sortFn(formatSortValue), resp)
          iList.value = props.type === 'stocks' ? groupStocks(sortResp) : sortResp
        } else {
          iList.value = props.type === 'stocks' ? groupStocks(resp) : resp
        }
      })
    }

    onActivated(refresh)

    on([events.watchListUpdate, events.login, events.activated], refresh)

    onBeforeUnmount(() => {
      off([events.watchListUpdate, events.login, events.activated], refresh)
    })

    return {
      items,
      load (done: () => void) {
        return refresh().then(done)
      },
      sort,
    }
  },
})
