/**
 * @description
 *   community.ts of WeTrade
 */
import { translate } from '@/i18n'
import { emit } from 'essential/tools/event'
import * as R from 'ramda'
import { useImg } from '@/common/jsBridge.api'
import useArray from '@/hooks/useArray'
import useLoadMore from 'common/hooks/useLoadMore'
import {
  Comment,
  create,
  Post,
  readComments,
  readPost,
  readHotComments, PostResult,
  readTopics,
  Topic, CommentsResp,
} from '@/modules/community/communities/community.api'
import { nextTick, shallowRef } from 'vue'
import { onBeforeRouteLeave, useRouter } from 'vue-router'
import emojiList from './emojis'
import { openDialog, showAlert } from '@/components/popup/popup'
import ThePostConfirmDialog
  from '@/modules/community/communities/components/ThePostConfirmDialog.vue'
import { events, regExps } from '@/config'

export const uploadImg = async (multiple: boolean) => {
  const resp = await useImg({ multiple }) as { pic: string }
  return resp.pic
}

// 加载指定的帖子
export const usePost = (postId: string) => {
  const post = shallowRef<Post | null>(null)
  readPost({ postId }).then(resp => post.value = (resp as PostResult)?.post || null)
  return post
}

export type RequestData = (Data & { _t?: string }) | null

// 分页加载所有评论
export const useComments = (postId: string) => {
  const load = (config?: RequestData) => readComments(
    {
      postId,
      ...config,
    },
  )

  const { loaded, refresh, loadMore, response } = useLoadMore<Comment, CommentsResp>(load, 10, resp => resp.commentList)

  refresh()

  return {
    response,
    comments: loaded,
    loadMore,
    refresh,
  }
}

export const hotComments = (postId: string) => {
  const loadHot = () => readHotComments({ postId })
  const { loaded, refresh } = useLoadMore(loadHot)
  refresh()

  return {
    commentsHot: loaded,
  }
}

/**
 * 响应处理用户输入的文本与选择的图片信息
 */
export const useInput = () => {
  const text = shallowRef('')
  const { arr: images, mutate: mutateImg, clean } = useArray()

  const reset = () => nextTick(() => {
    text.value = ''
    clean()
  })
  return { text, images, mutateImg, reset }
}

export const useCreatePost = () => {
  let isPositiveAction = false

  onBeforeRouteLeave(async (to, from, next) => {
    if (isPositiveAction) next()
    else {
      // openDialog(ThePostCancelDialog)
      next()
      // else next(false)
    }
  })

  const content = shallowRef('')
  const imageUrl = shallowRef([])
  const router = useRouter()

  const post = () => {
    return create({
      content: content.value,
      imageUrl: imageUrl.value.toString(),
      label: R.match(regExps.topic, content.value).toString(),
    }).then(() => {
      showAlert(translate('toast_5', 'Post has been Published'))
      emit(events.postRefresh)
      isPositiveAction = true
      router.back()
    })
  }

  const publish = () => {
    openDialog(
      ThePostConfirmDialog,
      {
        postContent: {
          content: content.value,
          imageUrl: imageUrl.value.toString(),
        },
        onConfirm: post,
      },
    )
  }

  return {
    content, imageUrl, post, publish,
  }
}

const emojiToImg = (t: string) => {
  const name = t.slice(1, -1)
  if (name.split('*')[0] === 'live_like') {
    return `<img src="img/live/live_like@2x.png" style="display: inline-block; vertical-align: top;" width="20px" height="20px" >x${name.split('*')[1]}`
  } else {
    return R.includes(name, emojiList)
      ? `<img alt="${name}" src="/emoji/${name}.png" style="display: inline-block; vertical-align: top;" width="20px" height="20px" >`
      : t
  }
}

export const renderEmoji = (t: string) => {
  try {
    return t.replace(regExps.emoji, emojiToImg)
  } catch (e) {
    return JSON.stringify(e)
  }
}

export const renderTopic = (t: string) => {
  try {
    return t.replace(
      regExps.topic,
      matched => `<a class="c-primary" href="#/topic/${matched.slice(1)}">${matched}</a>`,
    )
  } catch (e) {
    return JSON.stringify(e)
  }
}

export const renderProgram = R.pipe(renderTopic, renderEmoji)

export const renderTopics = () => {
  const topics = shallowRef<Array<Topic>>([])
  readTopics(null, { silent: true }).then(resp => {
    topics.value = resp as Array<Topic>
  })
  return topics
}
