<template>
<t as="p" path="membership_32" class="f-bold my-8">
  Quick play
</t>
<HoldOn :content="list" :height="160">
  <AsyncNullableList :list-data="list" #="{item}">
    <router-link :to="`/trade/fast`" class="df-middle box">
      <img :src="item.banner" class="banner" width="88" height="88" alt="" />
      <div class="ml-12">
        <t as="p" :path="item.profileI18nPath" class="f-sm profile">
          {{prop('profile', item)}}
        </t>
      </div>
    </router-link>
  </AsyncNullableList>
</HoldOn>
</template>

<script lang="ts">
import AsyncNullableList from '@/components/AsyncNullableList.vue'
import HoldOn from '@/provider/HoldOn.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SignalList',
  components: { HoldOn, AsyncNullableList },
  setup () {
    return {
      list: [
        {
          profileI18nPath: 'membership_33',
          profile: 'Exclusive trading mode. Earn +100% profit in minutes',
          banner: '/img/prime/quick_play@2x.png',
        },
      ],
    }
  },
})

</script>

<style scoped lang="scss">
.banner {
  object-fit: cover;
  background-color: var(--color-primary);
}

.profile {
  line-height: 16px;
}
</style>
