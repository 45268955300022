
import { defineComponent, shallowRef } from 'vue'
import Button from '@/components/Button.vue'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'
import { YesOrNo } from '@/types'
import TheDailyBonusPay from '@/modules/home/notification/TheDailyBonusPay.vue'
import { readChannelList } from '@/modules/in/in.api'
import { openDialog, showAlert } from '@/components/popup/popup'
import { format } from '@/config'
import { useRate } from '@/modules/app/app'
import { div } from 'essential/tools/math'
import * as R from 'ramda'
import { isIndia } from '@/state'

export default defineComponent({
  name: 'TheDailyBonusList',
  components: {
    Button,
    Pic,
    Icon,
  },
  props: {
    info: Object,
  },
  emits: ['close'],
  setup (props, ctx) {
    const currProgress = shallowRef(false)
    const { rate, refresh } = useRate()
    refresh()

    const handleClose = () => {
      ctx.emit('close')
    }

    const handleDeposit = () => {
      currProgress.value = true
      readChannelList().then(res => {
        if (!res || res.length === 0) {
          showAlert('system error, please try again')
          return false
        }

        if (!rate.value.rate) {
          return
        }

        const realAmount = isIndia() ? Math.ceil(div(props.info?.dayEnduranceAmount, rate.value.rate)) : props.info?.dayEnduranceAmount

        let currIndex = R.findIndex(R.propEq('isCurrFlg', 1))(props.info?.list)
        currIndex = currIndex === -1 ? 0 : currIndex
        openDialog(TheDailyBonusPay, {
          wrapperClass: 'center',
          amount: realAmount,
          index: currIndex,
          currunt: props.info?.list[currIndex],
          channelList: res,
        })
        handleClose()
      }).finally(() => {
        currProgress.value = false
      })
    }

    return {
      last: 9,
      YesOrNo,
      currProgress,
      format,
      handleClose,
      handleDeposit,
    }
  },
})
