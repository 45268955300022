
import Nullable from '@/components/Nullable.vue'
import ThePositionItem from '@/modules/trade/components/ThePositionItem.vue'
import TheTradeGroup from '@/modules/trade/components/TheTradeGroup.vue'
import Holder from '@/provider/Holder.vue'
import state from '@/state'
import List from 'common/List/List.vue'
import * as R from 'ramda'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositions',
  components: { Nullable, Holder, List, TheTradeGroup, ThePositionItem },
  emits: ['togglePlaceHolder'],
  props: {
    code: String,
  },
  setup (props) {
    const leverage = ['', 'transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']
    return {
      showAll: !props.code,
      positions: computed(() => {
        const all = state.account.list.map(item => {
          if (item.levelType && item.levelType > 0) {
            item.leverage = leverage[item.levelType]
          }
          item.weight = String(Number(item.weight))
          return item
        })
        if (!props.code) return all
        return R.filter(R.propEq('code', props.code), all)
      }),
    }
  },
})
