<template>
  <div class="dbr-main p-16">
    <div class="close df-middle df-center" @click="handleClose">
      <Icon name="close" class="close-icon" />
    </div>
    <div>
      <t path="home_74" as="p" class="c-white f-xxl f-bold t-center">Select deposit method</t>
    </div>
    <t path="home_75" as="p" class="c-white mt-12"></t>

    <div class="channel-wrap px-16 pb-24 pt-8 mt-12">
      <TheChannels :channels="channelList" v-model:channel="channel" value-field="code"
        @change="channelChange"
        label-field="channel"
      />
    </div>

    <div class="mt-24 f-lg f-bold">
      <Button class="btn block success br-lg" @click="handlePay" :progress="progress">
        <t path="home_76">Proceed to payment</t>
      </Button>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, shallowRef } from 'vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import { useDeposit } from '@/modules/in/in'
import TheChannels from '@/modules/out/components/TheChannels.vue'

export default defineComponent({
  name: 'TheDailyBonusPay',
  components: { Button, Icon, TheChannels },
  emits: ['close'],
  props: {
    amount: Number,
    index: Number,
    currunt: Object,
    channelList: Array,
  },
  setup (props, ctx) {
    const [commit, progress] = useDeposit()
    const channel = shallowRef<string>('')

    const handleClose = () => {
      ctx.emit('close')
    }

    const handlePay = () => {
      const params = {
        amount: props.amount,
        payChannel: channel.value,
        rechargeType: 1,
      }
      commit(params).finally(() => {
        ctx.emit('close')
      })
    }

    return {
      channel,
      progress,
      handleClose,
      handlePay,
    }
  },
})
</script>

<style scoped lang='scss'>
.dbr-main{
  width: 88vw;
  background: #7515EA;
  border-radius: 16px;
  position: relative;
}
.close{
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
  height: 40px;
}
.close-icon{
  background: #FFFFFF;
  border-radius: 50%;
}
.channel-wrap{
  background: var(--color-white);
  border-radius: 8px;
}
</style>
