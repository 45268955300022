/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/18
* @description
*   ThePositions.vue of WeTrade
*/
<template>
  <Holder :content="positions" sync>
    <Nullable :content="positions">
      <!--分组显示所有持仓单-->
      <TheTradeGroup v-if="showAll" :list="positions" #="{item}">
        <ThePositionItem :order="item" class="position" />
      </TheTradeGroup>
      <!--不分组显示指定品种的持仓-->
      <List v-else :list-data="positions" #="{item}">
        <ThePositionItem :order="item" class="position mb-8" />
      </List>
      <template #emptyMsg><t path="symbol_25">There are currently no positions</t></template>
    </Nullable>
  </Holder>
</template>

<script lang="ts">
import Nullable from '@/components/Nullable.vue'
import ThePositionItem from '@/modules/trade/components/ThePositionItem.vue'
import TheTradeGroup from '@/modules/trade/components/TheTradeGroup.vue'
import Holder from '@/provider/Holder.vue'
import state from '@/state'
import List from 'common/List/List.vue'
import * as R from 'ramda'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositions',
  components: { Nullable, Holder, List, TheTradeGroup, ThePositionItem },
  emits: ['togglePlaceHolder'],
  props: {
    code: String,
  },
  setup (props) {
    const leverage = ['', 'transactionhistory_26', 'transactionhistory_27', 'transactionhistory_28']
    return {
      showAll: !props.code,
      positions: computed(() => {
        const all = state.account.list.map(item => {
          if (item.levelType && item.levelType > 0) {
            item.leverage = leverage[item.levelType]
          }
          item.weight = String(Number(item.weight))
          return item
        })
        if (!props.code) return all
        return R.filter(R.propEq('code', props.code), all)
      }),
    }
  },
})
</script>

<style scoped lang="scss">
@use "~@/theme/mixins/_index.scss" as mixins;

.position:not(:last-child) {
  @include mixins.half-pixel-border(bottom);
}
</style>
