/**
* @author 贝才[beica1@outook.com]
* @date 2021/2/26
* @description
*   FullScreen.vue of WeTrade
*/
<template>
  <div class="fs__container">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FullScreen',
})
</script>
