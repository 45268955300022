**
* @description Holder.vue
*/
<template>
  <!--以下  注释元素会影响transition子节点渲染【惊】-->
  <transition name="fade-in" appear>
    <!--保证存在单一动画执行容器 -- 必要-->
    <div v-if="loaded" v-bind="$attrs">
      <!--content loaded-->
      <slot />
    </div>
    <div v-else>
      <!--loading state render-->
      <slot name="holder">
        <div class="skl" :class="holderClass">
          <DefaultSkeleton v-for="i in skeletonRepeat" :key="i" />
        </div>
      </slot>
    </div>
  </transition>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent, shallowRef, watch } from 'vue'
import DefaultSkeleton from '@/provider/DefaultSkeleton.vue'

const isNotNil = R.both(Boolean, R.complement(R.isEmpty))

export default defineComponent(
  {
    name: 'Holder',
    components: { DefaultSkeleton },
    props: {
      content: [Array, Object, String, Boolean],
      sync: Boolean,
      skeletonRepeat: {
        type: Number,
        default: 2,
      },
      holderClass: String,
    },
    setup (props) {
      const loaded = shallowRef(isNotNil(props.content) || props.sync)

      watch(() => props.content, () => {
        loaded.value = true
      })

      return { loaded }
    },
  },
)
</script>

<style scoped lang="scss">
.skl {
  background: linear-gradient(125deg, transparent 10%, rgba(0, 0, 0, 0.02) 12%, transparent 14%);
  animation: wave 3s linear infinite 1s;
  background-size: 300% 300%;
  background-position-x: 30%;
}
</style>

<style>
@keyframes wave {
  0% {
    background-position-x: 30%;
  }
  50% {
    background-position-x: -30%;
  }
  100% {
    background-position-x: -100%;
  }
}
</style>
