
import Pic from '@/components/Pic.vue'
import { OutChannel } from '@/modules/out/out.api'
import { defineComponent, watch, ref, computed } from 'vue'
import Radio from '@/components/Radio.vue'
import router from '@/router'

export default defineComponent(
  {
    components: { Pic, Radio },
    emits: ['update:channel', 'change'],
    props: {
      channel: [Number, String],
      channels: {
        type: Array,
        required: true,
      },
      valueField: {
        type: String,
        default: 'type',
      },
      labelField: {
        type: String,
        default: 'showName',
      },
    },
    setup (props, ctx) {
      const valueKey = props.valueField as keyof OutChannel
      const selectedIndex = ref(0)
      const selectedValue = computed(
        () => (props.channels as Array<OutChannel>)[selectedIndex.value][valueKey])

      watch([props.channels, selectedIndex], () => {
        const channel = props.channels[selectedIndex.value] as OutChannel
        ctx.emit('update:channel', channel[valueKey])
      }, {
        immediate: true,
      })

      const visaMaterIntro = () => {
        router.push('/in/visaMasterIntro')
      }

      return {
        selectedValue,
        selectedIndex,
        visaMaterIntro,
        select (index: number) {
          ctx.emit('change', index)
          selectedIndex.value = index
        },
      }
    },
  },
)
