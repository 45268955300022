
import { defineComponent } from 'vue'
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { useRouter } from 'vue-router'
import { isDemoAccount } from '@/state/accountType'

export default defineComponent({
  name: 'LimitOrderResult',
  components: { ResultDialogTemplate },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  setup (props, ctx) {
    const router = useRouter()

    const check = () => {
      ctx.emit('close')
      router.replace(`/symbol/${props.order.code}`)
    }

    const resText = [[{
      title: 'notification_18',
      content: 'notification_19',
    }, {
      title: 'notification_16',
      content: 'notification_17',
    }], [{
      title: 'notification_4',
      content: 'notification_5',
    }, {
      title: 'notification_2',
      content: 'notification_3',
    }]]

    return {
      check,
      isDemoAccount,
      resText,
    }
  },
})
