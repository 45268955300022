/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/31
 * @description
 *   redirect.ts of WeTrade
 */
import custom from '@/router/custom'
import prepareTransition from '@/router/pageTransition'
import redirect from '@/router/redirect'
import { createRouter, createWebHashHistory } from 'vue-router'
import publicRoutes from '@/router/routes.public'
import privateRoutes from '@/router/routes.private'

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...publicRoutes,
    ...privateRoutes,
    {
      path: '/:pathMatch(.*)*',
      redirect: '/home',
    },
  ],
})

router.beforeEach(redirect)

router.beforeEach(custom)

router.afterEach(prepareTransition)

export default router
