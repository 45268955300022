
import ResultDialogTemplate from '@/views/ResultDialogTemplate.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'RechargeResult',
  components: { ResultDialogTemplate },
  props: {
    val: String,
  },
})
