
import { readTradeTrend } from '@/modules/market/market.api'
import { defineComponent, shallowRef } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: 'TheAnalysisRatio',
  components: { },
  setup () {
    const route = useRoute()
    const code = route.params.code as string
    const trend = shallowRef({ up: 50, down: 50, analyst: 0 })
    readTradeTrend({
      contract: code,
    }).then(resp => trend.value = resp)

    return {
      trend,
    }
  },
})
