
import Icon from '@/components/Icon.vue'
import { isCrypto } from '@/decorators/withoutCryptos'
import { localGet, localSet } from 'essential/store/localStore'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import { keymap } from '@/config'

export default defineComponent({
  name: 'TheCryptoTips',
  components: { Icon, Pic },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup (props) {
    const show = shallowRef(false)

    isCrypto(props.code).then(
      yes => show.value = !localGet(keymap.guide.cryptoTrade) && yes)

    const gotIt = () => {
      localSet(keymap.guide.cryptoTrade, '1')
      show.value = false
    }

    return {
      show,
      gotIt,
    }
  },
})
