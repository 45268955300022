/**
* File: TermsAndPolicy.vue of WeTrade
* Author: 阿佑[ayooooo@petalmail.com]
* Date: 2024/2/28 18:43
*/

<script lang="ts">
import { defineComponent } from 'vue'
import { open as _open } from '@/common/jsBridge.api'

export default defineComponent({
  name: 'TermsAndPolicy',
  setup () {
    function open (path: string) {
      console.log('open url via system browser', location.origin + path)
      _open({ url: location.origin + path, target: 'system' })
    }

    return {
      open,
    }
  },
})
</script>

<template>
  <t custom #="{t}">
    <a to="/common/link?url=/terms_of_use.html&title=Terms"
       class="c-primary f-medium" @click="open('/terms_of_use.html')">
      {{ t('signinup_11') || 'Term & Conditions' }}
    </a>
    {{ t('signinup_12') }}
    <a to="/common/link?url=/privacy_policy.html&title=Policy"
       class="c-primary f-medium" @click="open('/privacy_policy.html')">
      {{ t('signinup_13') || 'Privacy Policy' }}
    </a>
  </t>
</template>
