/**
 * @author zjc[beica1@outook.com]
 * @date 2021/10/20 22:15
 * @description
 *   AbstractShape.ts of WeTrade
 */
import { Selection } from 'd3'
import * as d3 from 'd3'
import { defaultShapeOptions, defaultShapeStyle } from '../defaults'
import { confirmFunctionalProps, isNumeric } from '../helper'
import { Bar, ConfirmFunctionalKey, ISelection, OptionsOf, ShapeStyle } from '../types'
import AbstractRenderer from './AbstractRenderer'
import IChart from '../interface/IChart'

export type ShapeOptionalOptions = {
  mountType: 'g' | 'path';
}

export type ShapeRequiredOptions = {
  container: string;
}

export type ShapeOptions = OptionsOf<ShapeStyle & ShapeOptionalOptions, ShapeRequiredOptions>

export type ExtendShapeOptions<T> = ShapeOptions['call'] & T

abstract class AbstractShape<T = unknown, DT = Bar[], ST extends Selection<any, any, any, any> = ISelection<SVGGElement>> extends AbstractRenderer {
  protected readonly options: T & ConfirmFunctionalKey<ShapeOptions['define']>
  chart: IChart
  fy
  fx
  dashArray

  containerD3El: ISelection<SVGGElement>
  initD3El
  d3El

  defaultLineFy
  defaultLineRenderer

  constructor (
    chart: IChart,
    options: ExtendShapeOptions<T>,
    functionKeys: string[] = [],
  ) {
    super()

    this.chart = chart

    this.fy = this.chart.yAxis.fy

    this.fx = this.chart.xAxis.fx

    this.options = confirmFunctionalProps(
      {
        ...defaultShapeStyle,
        ...defaultShapeOptions,
        ...options,
      },
      ['lineColor', 'fillColor'].concat(functionKeys) as (keyof ExtendShapeOptions<T>)[],
    )

    this.dashArray = this.options.lineStyle === 'solid'
      ? '0'
      : (
        this.options.lineStyle === 'dashed'
          ? '4'
          : '1'
      )

    this.containerD3El = d3
      .select(`#${chart.id}`)
      .insert('g', '.drawings')
      .attr('class', 'shape') as ISelection<SVGGElement>

    const mountType = this.options.mountType ?? 'g'

    this.initD3El = this.containerD3El.append(mountType).attr('class', 'init') as ST

    this.d3El = this.containerD3El.append(mountType).attr('class', 'latest') as ST

    this.defaultLineFy = this.chart.yAxis.fy

    this.defaultLineRenderer = d3
      .line<Bar>()
      .defined(d => isNumeric(d?.c))
      .x(d => this.chart.xAxis.fx(d.t) ?? 0)
      .y(d => this.defaultLineFy(d.c))
  }

  /**
   * @deprecated
   * @param g
   */
  style (g: ST): ST {
    g
      .attr('fill', 'none')
      .attr('stroke', this.options.lineColor())
      .attr('stroke-width', this.options.lineWidth)
    return g
  }

  showCandidate (): this {
    this.d3El.style('display', '')
    return this
  }

  hideCandidate () {
    this.d3El.style('display', 'none')
    return this
  }

  remove () {
    this.containerD3El.remove()
  }

  abstract render (g: ST, quotes: DT[]): void
}

export default AbstractShape
