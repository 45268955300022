/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/19
* @description
*   TheSymbolMarketState.vue of WeTrade
*/
<template>
<div class="px-16" style="border-bottom: 1px solid var(--color-border);">
  <RealTimeQuote :symbol="symbol" #="{price, buy, spread, margin, mp, isRise, open, top, low}">
    <div
      class="df-middle c-success t-center f-bold"
      style="height: 40px;"
      :class="{'c-danger': !isRise}">
      <div class="flex-3 t-left df-middle f-lg">
        {{price}}
        <span class="diff c-text mx-8 f-bold br-sm" :class="{'bg-success': isRise,'bg-danger': !isRise}"> {{spread}} </span>
        {{buy}}
      </div>
      <div class="flex-1 t-right">{{isRise?'+':''}}{{margin}}</div>
      <div class="flex-1 t-right">{{isRise?'+':''}}{{mp}}</div>
    </div>

    <div class="df-middle df-between" style="height: 32px;">
      <div class="f-md">
        <t path="symbol_97" class="c-title mr-4">High</t><span>{{ top }}</span>
      </div>
      <div class="f-md">
        <t path="symbol_98" class="c-title mr-4">Low</t><span>{{ low }}</span>
      </div>
      <div class="f-md">
        <t path="symbol_99" class="c-title mr-4">Open</t><span>{{ open }}</span>
      </div>
    </div>
  </RealTimeQuote>
</div>
</template>

<script lang="ts">
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheSymbolMarketState',
  components: { RealTimeQuote },
  props: {
    symbol: {
      type: Object,
      required: true,
    },
  },
  setup () {
    return {}
  },
})
</script>

<style scoped lang="scss">
.diff {
  padding: 2px;
  font-size: 11px;
  color: var(--color-white);
}
</style>
