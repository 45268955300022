/**
*  @file         src/provider/OnlyGroupA.vue created by WebStorm
*  @project      WeTradeMobile
*  @author       阿佑[ayooooo@petalmail.com]
*  @date         2022/9/22 16:19
*  @description
*/
<template>
<slot v-if="custom || isUserInGroupA" :hint="isUserInGroupA" />
</template>

<script lang="ts">
import state from '@/state'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'OnlyGroupA',
  components: {},
  props: {
    custom: Boolean,
  },
  setup () {
    return {
      isUserInGroupA: computed(() => state.group === 'A'),
    }
  },
})
</script>
