<template>
  <router-link  #="{ navigate }" :to="`/symbol/${marketItem.code}`" custom>
    <tr @click="navigate">
      <td>
        <Pic :src="marketItem.icon" width="32" height="32" style="border-radius: 50%" />
      </td>
      <td>
        <p class="f-medium df-middle">
          {{marketItem.code}} <span v-if="count">({{count}})</span>
          <Icon v-if="marketItem.specialIdentification===1||marketItem.specialIdentification===2" sm name="wt_mark" class="ml-4" style="color: var(--color-primary);" />
        </p>
        <p class="f-xs c-tip">{{marketItem.tips}}</p>
      </td>
      <td class="t-right">
        <span class="badge-market px-6 f-nm success" :class="{danger: !isRise}">{{marketItem.amplitude}}</span>
        <t path="market_33" as="p" class="f-sm c-text mt-4">in last 30 mins</t>
      </td>
    </tr>
  </router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { usePositionCount } from '@/modules/user/user'
import Pic from '@/components/Pic.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheActiveItem',
  components: { Pic, Icon },
  props: {
    marketItem: {
      type: Object,
      required: true,
    },
  },
  setup (props) {
    return {
      isRise: parseFloat(props.marketItem.amplitude) > 0,
      count: usePositionCount(props.marketItem.code),
    }
  },
})
</script>

<style scoped>
.double{
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  height: 12px;
  width: 12px;
}
</style>
