
import { localGet, localSet } from 'essential/store/localStore'
import { computed, defineComponent, shallowRef } from 'vue'

export default defineComponent({
  name: 'RedPointAlert',
  props: {
    display: {
      type: Boolean,
      default: true,
    },
    tag: {
      type: String,
      default: 'span',
    },
    id: {
      type: String,
      required: true,
    },
    trigger: {
      type: Boolean,
      default: true,
    },
  },
  setup (props) {
    const noRecord = shallowRef(!localGet(props.id))
    const unread = computed(() => props.display && Boolean(props.id) && noRecord.value)
    const className = computed(() => unread.value ? 'unread' : '')

    const read = () => {
      if (props.trigger && props.display) {
        localSet(props.id, '1')
        noRecord.value = false
      }
    }

    return {
      unread,
      className,
      read,
    }
  },
})
