/**
* @description
*   ThePost.vue of WeTrade
*/
<template>
<router-link :to="`/post/${postData.postId}`" class="d-b py-16">
  <ThePostTemplate :post="postData">
    <div v-if="noPin && postData.topStatus" class="pin">
      <Icon name="pin" sm />
    </div>
    <div v-if="postData.isBoutique" class="pin">
      <Icon name="star" sm />
    </div>
  </ThePostTemplate>
  <TheImgGallery v-if="hasImgs" :images="postData.imageUrls" class="mt-20" />
  <div class="footer df-middle t-center mt-16 f-sm">
    <div class="df-middle">
      <TheLikeButton
        :id="postData.postId" :count="postData.likeCount"
        :like="postData.likeStatus" :type="1" :user="postData.userId" sm
      />
    </div>
    <div class="df-middle ml-16">
        <span class="df-cross-center">
          <Icon name="comment" sm />
          <span class="count d-i ml-4">{{postData.commentCount}}</span>
        </span>
    </div>
  </div>
</router-link>
</template>

<script lang="ts">
import * as R from 'ramda'
import { defineComponent, ref, watch } from 'vue'
import TheImgGallery from './TheImgGallery.vue'
import TheLikeButton from './TheLikeButton.vue'
import ThePostTemplate from './ThePostTemplate.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'ThePostItem',
  components: { Icon, TheImgGallery, TheLikeButton, ThePostTemplate },
  props: {
    post: {
      type: Object,
      required: true,
    },
    noPin: Boolean,
  },
  setup (props) {
    const postData = ref(props.post)
    watch(() => props.post, () => {
      postData.value = props.post
    }, { deep: true, immediate: true })
    return {
      hasImgs: R.complement(R.isNil)(props.post.imageUrls),
      postData,
    }
  },
})
</script>

<style scoped lang="scss">
.footer {
  color: var(--color-grey);
}

.pin {
  width: 16px;
  height: 16px;
  background: var(--color-asst);
  border-radius: 2px;
  color: var(--color-white);

  .icon {
    vertical-align: top;
  }
}
</style>
