/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/23
* @description
*   TheWatchList.vue of WeTrade
*/
<template>
<div v-if="!empty" class="main-wrap px-16">
  <t as="p" path="market_42" class="f-bold c-text f-nm py-12">Top movers</t>
  <Swipe #="{item}" :list="tops" :dot="true" dotDark style="height:114px">
    <div class="items-wrap d-f mb-12" style="font-weight: 400; height: 88px;">
      <div class="flex-1" v-for="(citem, index) in item" :key="citem.id"
        :class="{'mr-8': index < item.length-1}">
        <CurrentQuote :code="citem.code" #="{symbol}">
          <router-link #="{ navigate }" :to="`/symbol/${symbol && symbol.code}`" custom>
            <RealTimeQuote :symbol="symbol" #="{price, buy, spread, margin, mp, isRise}">
              <div class="box df-col df-center pl-16 py-12"
                :class="{'mr-8': index+1 < citem.length}" @click="navigate">
                <div class="df-middle mb-4">
                  <Pic :src="citem.icon" width="16" height="16" style="border-radius: 50%" />
                  <p class="c-text f-md ml-4">{{citem.code}}</p>
                </div>
                <div class="f-nm c-text mb-4 f-medium" style="line-height: 20px;">{{price}}</div>
                <div class="df-middle">
                  <p class="f-nm" :class="{'c-success': isRise, 'c-danger': !isRise}">{{mp}}</p>
                  <Icon class="p-0 ml-4" :class="{'c-success': isRise, 'c-danger': !isRise}"
                    sm
                    :name=" isRise ? 'up': 'down'" />
                </div>
              </div>
            </RealTimeQuote>
          </router-link>
        </CurrentQuote>
      </div>
    </div>
  </Swipe>
</div>
</template>

<script lang="ts">
import { computed, defineComponent, onActivated, onBeforeUnmount, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Icon from '@/components/Icon.vue'
import { readChangeProduct, ChangeProductItem } from '@/modules/market/market.api'
import datafeed from '@/common/datafeed'
import * as R from 'ramda'
import Swipe from '@/components/Swipe.vue'

export default defineComponent({
  name: 'TheTopMovers',
  components: {
    Pic,
    Icon,
    CurrentQuote,
    RealTimeQuote,
    Swipe,
  },
  setup () {
    const tops = shallowRef<ChangeProductItem[][]>([])
    const empty = computed(() => tops.value.length === 0)

    const getList = async () => {
      const resp = await readChangeProduct()
      const arr: ChangeProductItem[][] = []

      R.splitEvery(3, resp).map(item => {
        arr.push(item)
      })

      tops.value = arr
      datafeed.subscribe(R.pluck('code', resp), true)
    }

    onActivated(getList)

    onBeforeUnmount(() => {
      datafeed.unSubscribe([], true)
    })

    return {
      empty,
      tops,
    }
  },
})
</script>
<style scoped lang="scss">
::v-deep(.dot-dark.active) {
  background-color: var(--color-primary);
}
</style>
