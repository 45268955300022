/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/1
* @description
*   PageWithHeader.vue of WeTrade
*/
<template>
  <Page class="with-header">
    <PageHeader class="px-16" :class="headerClass">
      <template #title>
        <slot name="title">WeTrade</slot>
      </template>
      <template #left>
        <slot name="left" />
      </template>
      <template #right>
        <slot name="right" />
      </template>
    </PageHeader>
    <div class="fill p-r df-col">
      <slot name="header" />
      <slot name="body">
        <Scroller css-only class="flex-1">
          <slot />
        </Scroller>
      </slot>
      <slot name="footer" />
    </div>
  </Page>
</template>

<script lang="ts">
import Page from '@/provider/Page.vue'
import PageHeader from '@/components/PageHeader.vue'
import Scroller from 'common/providers/Scroller.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'PageWithHeader',
  components: { Scroller, Page, PageHeader },
  props: {
    headerClass: String,
    bodyCol: Boolean,
  },
})
</script>

<style scoped>
.with-header {
  padding-top: var(--page-header-height);
}

::v-deep(.page__header) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.bg-prime-title{
  background: #383E58;
  color: var(--color-white);
}
</style>
