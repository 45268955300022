/**
 * @author 贝才[beica1@outook.com]
 * @date 2021/3/10
 * @description
 *   jsBridge.api.ts of WeTrade
 */
import { thunkCall, thunkCallForResult } from '@/common/jsBridge'

// APP准备就绪
export const ready = thunkCall('ready')

// razorPay
export const razorPay = thunkCallForResult('razorPay')

// 聚合支付
export const easeBuzzPay = thunkCallForResult('easebuzz')

// 客服
export const callService = thunkCall('callService')

// 服务请求
export const xhr = thunkCall('xhr')

// 更新头像
export const updateAvatar = thunkCallForResult('updateAvatar')

// 登录
export const login = thunkCall('login')

// 登出
export const logout = thunkCall('logout')

// 版本更新
export const update = thunkCall('update')

// firebase
export const firebaseTrack = thunkCall('firebaseGet')

// 打开连接
export const open = thunkCallForResult('open')

// 获取设备唯一id 及其备用值
export const getIds = thunkCallForResult('getIds')

// 上传认证img
export const uploadID = thunkCallForResult('uploadID')

// 评论或者发帖传图
export const useImg = thunkCallForResult('useImg')

export const updateImg = thunkCallForResult('updateImg')

export const fbLogin = thunkCallForResult('fbLogin')

export const googleLogin = thunkCallForResult('googleLogin')

// 显示原生chart
export const showChart = thunkCall('showChart')

// 关闭显示原生chart
export const hideChart = thunkCall('hideChart')

// 刷新行情
export const flush = thunkCall('flush')

// 同步滚动
export const scroll = thunkCall('scroll')

// 自定系统状态栏颜色
export const customStatusBar = thunkCall('customStatusBarColor')

// 重置系统状态栏颜色
export const resetStatusBar = thunkCall('resetStatusBarColor')

// 检查客服消息状态
export const checkCSMsgState = thunkCallForResult('checkCSMsgState')

export const playVideo = thunkCall('useVideoPlayer')

export const stopVideo = thunkCall('closeVideoPlayer')

export const share = thunkCall('share')

export const androidRotate = thunkCall('rotate')

export const iosRotate = thunkCallForResult('iosRotate')
