/**
 * @author 贝才[beica1@outook.com]
 * @date 2020/12/2
 * @description
 *   format.ts of FastTrade
 */
import * as R from 'ramda'
import { format } from '@/config'

const MONTH_EN = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
]

const WEEK_EN = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

// yy => 1996  Y => 96 MM => 03 M => 3 (时分秒同理)
export const formatDateTime = (format: string, t?: number) => {
  const date = t ? new Date(t) : new Date()
  const time: Data = {
    YY: date.getFullYear(), // 年份
    'y+': date.getFullYear(), // 年份
    'M+': date.getMonth() + 1, // 月份
    'd+': date.getDate(), // 日期
    'H+': date.getHours(), // 小时
    'm+': date.getMinutes(), // 分
    's+': date.getSeconds(), // 秒
    'EN-w': WEEK_EN[date.getDay() - 1], // 星期 英
    'EN-m': MONTH_EN[date.getMonth()], // 月份 英
  }

  for (const key in time) {
    if (new RegExp(`(${key})`).test(format)) {
      const t = (time[key] as string).toString()
      if (RegExp.$1 === 'YY') {
        format = t.slice(2)
      } else {
        const reg = RegExp.$1.length === 1 ? t : t.length === 1 ? `0${t}` : t
        format = format.replace(RegExp.$1, reg)
      }
    }
  }
  return format
}

const CURRENCY_SYMBOL = '$'

export type FormattedMoney = {
  origin: number;
  symbol: string; // 符号位
  value: string; // 数值位
  text: string; // 符号以及数值
  int?: string; // 整数部分
  decimal?: string; // 小数部分
}

// @todo 兼容性处理
export const formatMoney = (no: string | number, locales = 'en-US'): FormattedMoney => {
  const money = Number(no) / format.moneyFactor

  if (Number.isNaN(money)) {
    return {
      origin: money,
      symbol: CURRENCY_SYMBOL,
      value: no as string,
      text: no as string,
    }
  }

  const value = money.toLocaleString(locales, {
    maximumFractionDigits: 5,
  })

  const partials = value.split('.')

  return {
    origin: money,
    symbol: CURRENCY_SYMBOL,
    value,
    text: CURRENCY_SYMBOL + value,
    int: partials[0],
    decimal: partials[1],
  }
}

export const parseToJson = R.tryCatch(
  R.o(R.objOf('json'), JSON.parse), R.always({ json: undefined }))
