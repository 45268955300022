/**
* @author 贝才[beica1@outook.com]
* @date 2020/12/3
* @description
*   Matrix.vue of FastTrade
*/
<template>
  <div class="matrix">
    <div
      v-for="(item, index) in list" :key="identify(item)" :style="metaWithStyle"
      class="matrix__item"
    >
      <slot :item="item" :index="index">{{item}}</slot>
    </div>
    <!--空位补充-->
    <span v-for="item in additions" :key="item" class="flex-1" :style="metaWithStyle" />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'Matrix',
  emits: ['update:select'],
  props: {
    list: {
      type: Array,
      required: true,
    },
    column: {
      type: Number,
      default: 4,
    },
    colGap: {
      type: Number,
      default: 2,
    },
    identify: {
      type: Function,
      default: (v: unknown) => v,
    },
  },
  setup (props) {
    const max = computed(() => {
      return Math.floor(100 / props.column) - props.colGap
    })
    const metaWithStyle = computed(() => {
      return {
        'min-width': props.column === 1 ? '100%' : `${max.value - 1}%`,
        'max-width': props.column === 1 ? '100%' : `${max.value}%`,
      }
    })

    const remainder = computed(() => Math.ceil(props.list.length % props.column))
    const additions = computed(() => remainder.value ? (props.column - remainder.value) : 0)

    return {
      metaWithStyle,
      additions,
    }
  },
})
</script>

<style scoped lang="scss">
.matrix {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.matrix__item {
  flex: 1;
}
</style>
