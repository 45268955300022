/**
* @author 贝才[beica1@outook.com]
* @date 2021/4/14
* @description
*   TransitionPic.vue of WeTrade
*/
<template>
<div class="df-cross-center" :class="{active}" style="height: 32px">
  <Icon :name="path" />
  <!-- <Pic class="pic" :src="path" width="24" height="24" />
  <Pic class="pic active_pic" :src="`${path}_active`" width="24" height="24" /> -->
</div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TransitionPic',
  components: { Icon },
  props: {
    path: {
      type: String,
      required: true,
    },
    active: Boolean,
  },
})
</script>

<style scoped lang="scss">
.pic {
  //transition: all var(--trans-duration) var(--trans-fn);
  position: absolute;
}

.active_pic {
  background: white;
  transform: scale3d(0, 0, 1);
}

.active {
  color: var(--color-primary);
  .pic {
    width: 24px;
    height: 24px;
  }

  .active_pic {
    transform: scale3d(1, 1, 1);
  }
}
</style>
