import requestResult, { jsonpRequest } from '@/common/request/request'
import { server } from '@/config'
import { Position } from '@/modules/trade/trade.api'

export const readShareInfo = requestResult<{ invitatedUsers: string[] }>('/api/user/share/info')

export const inviteRegister = jsonpRequest(server.api + '/api/user/register/jsonp')

export const readCCList = jsonpRequest(server.api + '/api/user/country/list/jsonp')

export const requestCode = (data: Data) => jsonpRequest(server.api + '/api/sms/send/jsonp')({
  ...data,
  type: 1,
})

export const readPositionDetail = jsonpRequest<Position>(
  server.api + '/api/order/mt/history/detail/jsonp')
