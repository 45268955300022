/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/11
* @description
*   DialogTemplate.vue of WeTrade
*/
<template>
  <div class="common__dialog">
    <div class="px-16">
      <p v-if="!noTitle" class="t-center f-bold f-lg py-12">
        <slot name="title">Dialog Title</slot>
      </p>
      <Line v-if="!noTitle" />
      <div class="dialog__body df-middle">
        <slot></slot>
      </div>
    </div>
    <div v-if="!noControls" class="dialog__controls f-lg f-bold d-f">
      <slot name="controls">
        <slot name="cancel">
          <button class="flex-1 btn block" @click="cancel">
            <slot name="cancelText">
              <t path="withdrawal_30" #="{td}" custom>{{td || 'Cancel'}}</t>
            </slot>
          </button>
        </slot>
        <slot name="confirm">
          <button class="flex-1 btn block primary" @click="confirm">
            <slot name="confirmText">
              <t path="withdrawal_31" #="{td}" custom>{{td || 'Confirm'}}</t>
            </slot>
          </button>
        </slot>
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import Line from '@/components/Line.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DialogTemplate',
  components: { Line },
  props: {
    noTitle: Boolean,
    noControls: Boolean,
  },
  setup (props, ctx) {
    return {
      cancel () {
        ctx.emit('cancel')
        ctx.emit('close')
      },
      confirm () {
        ctx.emit('confirm')
        ctx.emit('close')
      },
    }
  },
})
</script>

<style scoped>
.common__dialog {
  background: var(--color-theme);
}

.dialog__body {
  min-height: 120px;
}
</style>
