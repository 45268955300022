<template>
<t as="p" path="membership_34" class="f-bold my-8">
  Technical Analytics
</t>
<router-link to="/prime/tc" class="df-middle box">
    <Pic src="prime/technical" class="banner" width="88" height="88" alt="" />
    <div class="ml-12">
    <t as="p" path="membership_35" class="f-bold">
      Technical view
    </t>
    <t as="p" path="membership_36" class="f-sm profile mt-8">
      Dive confident decidions with simple, actionable trading plans.
    </t>
    </div>
</router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheTechnicalAnalytics',
  components: {
    Pic,
  },
})

</script>

<style scoped lang="scss">
.banner {
    object-fit: cover;
}

.profile {
    line-height: 16px;
}
</style>
